import axiosClient from "./axiosClient";

const purchaseApi = {
  putPurchaseItem: (id, params) =>
    axiosClient.put(`/api/purchasing/purchase-item/${id}?${params}`),
  getListOrders: (payload) =>
    axiosClient.get("/api/purchasing/list-orders", { params: payload }),
  getListPendingVariants: (payload) =>
    axiosClient.get("/api/purchasing/list-pending-variants", {
      params: payload,
    }),
  getSupplier: (payload, id) =>
    axiosClient.get(`/api/purchasing/supplier/${id}`, { params: payload }),
  postCreateSupplier: (payload) =>
    axiosClient.post(`/api/purchasing/create-supplier`, { params: payload }),
  getPurchaseOrder: (id) =>
    axiosClient.get(`/api/purchasing/purchase-order/${id}`),
  postMakePurchaseOrder: (payload) =>
    axiosClient.post("/api/purchasing/make-purchase-order", payload),
  getPurchaseItem: (id) =>
    axiosClient.get(`/api/purchasing/get-purchase-item/${id}`),
  getListPurchaseOrders: (payload) =>
    axiosClient.get(`/api/purchasing/list-purchase-orders`, {
      params: payload,
    }),
  putUpdateProofOfPurchase: (id, proofId) =>
    axiosClient.put(
      `/api/purchasing/update-proof-of-purchase/${id}/${proofId}`
    ),
  updatePurchaseOrder: (id,payload) =>
    axiosClient.put(`/api/purchasing/update-purchase-order/${id}`, payload),
  //packOrder: (payload) => axiosClient.post("/api/packing", payload),
  checkInOrder: (payload) => axiosClient.post("/api/purchasing/check-in", payload),
  partialCheckInOrder: (payload) => axiosClient.post("/api/purchasing/partial-check-in", payload),
  getCheckIn: (id) => axiosClient.get("/api/purchasing/get-check-in/" + id),
  setOutOfStock: (payload) => axiosClient.post("/api/purchasing/out-of-stock", payload),

  //COP Webhook
  notifyItemsPurchase: (payload) => axiosClient.post("/api/purchasing/notify-items-purchase", payload),
  notifyItemsArrival: (payload) => axiosClient.post("/api/purchasing/notify-items-arrival", payload),
  notifyPacking: (payload) => axiosClient.post("/api/packing/notify-packing", payload),
  notifyCheckIn: (payload) => axiosClient.post("/api/purchasing/notify-items-check-in", payload),
  notifyOutOfStock: (payload) => axiosClient.post("/api/purchasing/notify-out-of-stock", payload),
};

export default purchaseApi;
