import { Col, Form, Input, Row, Select, Button, Modal, message, Checkbox } from "antd";
import { FormWrapper } from "./index.style";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import accountingApi from "apis/accountingApi";
import moment from "moment";

import { Title } from "pages/example/Example.styles";

const CardTxnForm = ({openCardTxnForm, setOpenCardTxnForm, transaction, setRefreshFlag}) => {
    const [cardForm] = Form.useForm();

    const handleOk = async () => {
        const payload = {
            cardNum: transaction.cardNum,
            refKey: transaction.usageKey,
            changes: {
                category: cardForm.getFieldValue("category"),
                orderNo: cardForm.getFieldValue("orderNo"),
                notes: cardForm.getFieldValue("notes"),
                notMatched: cardForm.getFieldValue("notMatched")
            }
        };
        const res = await accountingApi.updateCardTxn(payload);
        if(res){
            setOpenCardTxnForm(false);
            message.success("Transaction updated successfully");
            //Below is setting refreshFlag random number, to trigger useEffect in index.js
            setRefreshFlag(Math.floor(Math.random() * 101)); 
        }
    };

    const handleCancel = () => {
        setOpenCardTxnForm(false);
    };

    useEffect(() =>{
        if(transaction){
            cardForm.setFieldsValue({
                category: transaction.category,
                orderNo: transaction.orderNo,
                notes: transaction.notes,
                notMatched: transaction.notMatched
            });
        }
     
    },[transaction]);
    return(  
        <Modal
            title="Card Transaction"
            visible={openCardTxnForm}
            onOk={handleOk}           
            //confirmLoading={confirmLoading} 
            onCancel={handleCancel}
                    
        >
            <FormWrapper>
                <Form form={cardForm} layout='vertical'>
                    <Title>Transaction Info</Title>
                    <br/>
                    <Row><Col md={8}>Corp#</Col><Col md={16}>{transaction?.corpNum}</Col></Row>
                    <Row><Col md={8}>Card#</Col><Col md={16}>{transaction?.cardNum}</Col></Row>
                    <Row><Col md={8}>Time (KST)</Col><Col md={16}>{moment(transaction?.usageTime).utcOffset("+0900").format("YYYY-MM-DD HH:mm")}</Col></Row>
                    <Row><Col md={8}>Approv#</Col><Col md={16}>{transaction?.approvalNum}</Col></Row>
                    <Row><Col md={8}>Approv#</Col><Col md={16}>{transaction?.approvalNum}</Col></Row>
                    <Row><Col md={8}>Approv Type</Col><Col md={16}>{transaction?.approvalType}</Col></Row>
                    <Row><Col md={8}>Approv Cost</Col><Col md={16}>{transaction?.approvalCost}</Col></Row>
                    <Row><Col md={8}>Amount</Col><Col md={16}>{transaction?.amount}</Col></Row>
                    <Row><Col md={8}>Tax</Col><Col md={16}>{transaction?.tax}</Col></Row>
                    <Row><Col md={8}>Service Charge</Col><Col md={16}>{transaction?.serviceCharge}</Col></Row>
                    <Row><Col md={8}>Store#</Col><Col md={16}>{transaction?.storeNum}</Col></Row>
                    <Row><Col md={8}>Store Name</Col><Col md={16}>{transaction?.storeName}</Col></Row>
                    <Row><Col md={8}>Store Ceo</Col><Col md={16}>{transaction?.storeCeo}</Col></Row>
                    <Row><Col md={8}>Usage Key</Col><Col md={16}>{transaction?.usageKey}</Col></Row>
                    <br/><br/>
                    <Form.Item 
                        label='Category' 
                        name='category'
                        rules={[
                            {
                                required: false,                            
                            },
                        ]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item 
                        label='Order Number' 
                        name='orderNo'
                        rules={[
                            {
                                required: false,                            
                            },
                        ]}
                    >
                        <Input placeholder="Order Number" />
                    </Form.Item>
                    <Form.Item 
                        label='Notes' 
                        name='notes'
                        rules={[
                            {
                                required: false,                            
                            },
                        ]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item 
                        label='Not Matched?' 
                        name='notMatched'
                        rules={[
                            {
                                required: false,                            
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox>Not Matched?</Checkbox>
                    </Form.Item>
                </Form>
            </FormWrapper>
        </Modal>
    );
}

export default CardTxnForm;