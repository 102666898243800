import React, { useState } from "react";
import AllTable from "./AllTable";
import Filter from "./Filter";

const AllPendingPurchases = ({
  params,
  setParams,
  isLoading,
  setIsLoading,
  dataList,
  setDataList,
  gettingProductExportList,
  setDeleteExport,
  isDeleteExport,
  deleteList,
  setDeleteList
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  // const [selectedDelete, setSelectedDelete] = useState([]);
  // const [isDeleteExport, setDeleteExport] = useState(false);
  const [filter, setFilter] = useState({
    showAllPending: true,
    showPendingPurchasedForOrdersPlaces: true,
  });

  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        dataList={dataList}
        selectedRows={selectedRows}
        setIsLoading={setIsLoading}
        gettingProductExportList={gettingProductExportList}
        params={params}
        setParams={setParams}
      />
      <AllTable
        params={params}
        setParams={setParams}
        setSelectedRows={setSelectedRows}
        selectedRows={selectedRows}
        dataList={dataList}
        setDataList={setDataList}
        gettingProductExportList={gettingProductExportList}
        isLoading={isLoading}
        // removeExport={removeExport}
        setDeleteExport={setDeleteExport}
        isDeleteExport={isDeleteExport}
        deleteList={deleteList}
        setDeleteList={setDeleteList}
        isDeleteLoading={isDeleteLoading}
        setIsDeleteLoading={setIsDeleteLoading}
      />
    </>
  );
};

export default AllPendingPurchases;
