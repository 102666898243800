import { Button, Space, Spin, Affix, Card, Divider } from "antd";
import React, { useState, useRef, Fragment, useCallback } from "react";
import { formatToCurrency } from "utils/funcs";
import { CourierButton, CustomerDetails, FormBody, FormHeader, FormWrapper } from "./index.style";
import moment from "moment";
import { useSelector } from "react-redux";
import { WarningOutlined } from "@ant-design/icons";
import { debounce } from "lodash";

function getOffset(el) {
    const rect = el.getBoundingClientRect();
    return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
    };
}

const RightColumn = ({
    isLoading,
    activeCourier,
    rates,
    ratesError,
    createShipmentError,
    summary,
    setActiveCourier,
    form,
    onFinish,
    shippingAccounts,
    companyNameChecker,
    etdPltInfo,
}) => {
    const { currency } = useSelector((state) => state.common);
    const [affixed, setAffixed] = useState(false);
    const contentRef = useRef(null);
    const [renderFlag, setRenderFlag] = useState(Number(0));
    const forceUpdate = useCallback(() => setRenderFlag(Math.random()),[]);
    const activeCourierHandler = (accountId, packagingType, shippingRate, currency) => {
        const activeShippingAcc = shippingAccounts.find((item) => item.id === accountId);
        const companyName = form.getFieldValue("companyName");
        
        form.setFieldsValue({
            companyName: companyNameChecker(companyName, activeShippingAcc),
            courier: accountId,
            fedexPackagingType: packagingType === "YOUR_PACKAGING" || packagingType === "DEFAULT" ? "YOUR_PACKAGING" : packagingType,
            fedexDutiesPaidAccount: activeShippingAcc.accountNumber,
            fedexServiceType: activeShippingAcc.fedexServiceType === null ? undefined : activeShippingAcc.fedexServiceType,
            shippingCost: shippingRate,
            shippingCurrency: currency,
        });
        
        if(global.insuranceSet){

            form.setFieldsValue({
                insuranceRequested: true,
                insuranceCost: Number(form.getFieldValue("totalDeclaredValue"))
            });
        }

         //Add packagingType to activeCourier so the buttons 
        //`isActive` behaves accordingly
        activeShippingAcc['packagingType'] = packagingType;

        return setActiveCourier(activeShippingAcc);
    };

    console.log("CREATE SHIPMENT ERROR", createShipmentError);

    const handleChangeAffix = (value) => {
        setTimeout(() => {
            setAffixed(value);
        }, 200);
    };

    return (
        <Affix offsetTop={6} target={() => document.getElementsByClassName("content-box")[0]} onChange={handleChangeAffix}>
            <div
                className="content-right-cols"
                style={{
                    overflow: "auto",
                    height: affixed && contentRef?.current ? `calc(100vh - ${getOffset(contentRef?.current)?.top + 12}px)` : "auto",
                }}
                ref={contentRef}
            >
                <FormWrapper>
                    <Button disabled={!activeCourier} block type="primary" size="large" onClick={onFinish} loading={isLoading}>
                        Create Shipment
                    </Button>
                </FormWrapper>
                {!!ratesError?.length && (
                    <FormWrapper>
                        <FormHeader
                            className="redBoldTitle error"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            Shipment Rate Error <WarningOutlined />
                        </FormHeader>
                        <FormBody>
                            {ratesError.length > 0
                                ? ratesError?.map((item, index) => {
                                      if (item.hasOwnProperty("courier") && item.courier !== "fedex") {
                                          return (
                                              <Fragment key={index}>
                                                  <Card>
                                                      <strong>{item?.courier.toUpperCase()}</strong>
                                                      <ul>
                                                          {item?.errors?.map((listError, index) => (
                                                              <li key={index}>
                                                                  <p>{listError?.message}</p>
                                                              </li>
                                                          ))}
                                                      </ul>
                                                  </Card>
                                              </Fragment>
                                          );
                                      } else if (item.hasOwnProperty("courier") && item.courier === "fedex" && item.errors.length > 0) {
                                          return (
                                              <Fragment key={index}>
                                                  <Card>
                                                      <strong>{item?.courier.toUpperCase()}</strong>
                                                      <ul>
                                                          {item?.errors?.map((listError, index) => (
                                                              <li key={index}>
                                                                  <p>{listError?.message}</p>
                                                              </li>
                                                          ))}
                                                      </ul>
                                                  </Card>
                                              </Fragment>
                                          );
                                      } else if (item.hasOwnProperty("courier") && item.courier === "fedex") {
                                          return (
                                              <Fragment key={index}>
                                                  <Card>
                                                      <strong>{item?.courier.toUpperCase()}</strong>
                                                      <p>{item?.errors.message}</p>
                                                  </Card>
                                              </Fragment>
                                          );
                                      } else if (item.hasOwnProperty("errorFields")) {
                                          return (
                                              <Fragment key={index}>
                                                  <Card>{/* <strong>{item.}</strong> */}</Card>
                                              </Fragment>
                                          );
                                      } else {
                                          return (
                                              <Fragment key={index}>
                                                  <Space direction="vertical">
                                                      <strong>{item?.name[0]}</strong>
                                                      <ul>
                                                          {item?.errors?.map((listError, index) => (
                                                              <li key={index}>
                                                                  <p>{listError}</p>
                                                              </li>
                                                          ))}
                                                      </ul>
                                                  </Space>
                                              </Fragment>
                                          );
                                      }
                                  })
                                : "No Error At The Moment"}
                        </FormBody>
                    </FormWrapper>
                )}

                {!!createShipmentError?.length && (
                    <FormWrapper>
                        <FormHeader
                            className="redBoldTitle error"
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            Create Shipment Error <WarningOutlined />
                        </FormHeader>
                        <FormBody>
                            {createShipmentError?.map((err, index) => {
                                if (err.hasOwnProperty("error")) {
                                    return (
                                        <Fragment key={index}>
                                            <Card>
                                                <strong>{err.error}</strong>
                                                <Divider />
                                                <p style={{ marginBottom: 0 }}>{err.message}</p>
                                            </Card>
                                        </Fragment>
                                    );
                                } else if (err?.data?.detail.match("7008")) {
                                    // logic resend call without PLT
                                    const additionalCourier = form.getFieldValue("additionalCourierOptions");

                                    form.setFieldsValue({
                                        additionalCourierOptions: additionalCourier.filter((item) => item !== "pltShipment"),
                                    });

                                    return (
                                        <Fragment key={index}>
                                            <Card>
                                                <strong>
                                                    {err?.data?.message} {err?.data?.status}
                                                </strong>
                                                <Divider />
                                                <p style={{ marginBottom: 0 }}>PLT Not allowed for destination country</p>
                                            </Card>
                                        </Fragment>
                                    );
                                } else {
                                    return (
                                        <Fragment key={index}>
                                            <Card>
                                                <strong>
                                                    {err?.data?.message} {err?.data?.status}
                                                </strong>
                                                <Divider />
                                                <p style={{ marginBottom: 0 }}>{err?.data?.detail}</p>
                                            </Card>
                                        </Fragment>
                                    );
                                }
                            })}
                        </FormBody>
                    </FormWrapper>
                )}
                <FormWrapper>
                    <FormHeader className="greenBoldTitle success">Shipment Rates</FormHeader>
                    <FormBody>
                        {!!rates
                            ? rates.map((item, index) => (
                                  <CourierButton
                                      key={index}
                                      name={item.courier}
                                      onClick={() => {activeCourierHandler(item.accountId, item.packagingType, item.rateNetCharge, item.currency);forceUpdate();}}
                                      className={(activeCourier.id === item.accountId && activeCourier.packagingType === item.packagingType) ? "isActive" : ""}
                                      style={{ marginTop: "10px" }}
                                  >
                                      <div
                                          style={{
                                              display: "flex",
                                              flexDirection: "column",
                                          }}
                                      >
                                          <strong>{`${item.accountName} - ${item.courier.toUpperCase()} - ${item.accountNumber}`.toUpperCase()}</strong>
                                          <div>{item.packagingType}</div>
                                          <div>{isLoading ? <Spin /> : <strong>{formatToCurrency(item.currency, item.rateNetCharge)}</strong>}</div>
                                      </div>
                                  </CourierButton>
                              ))
                            : "Click 'Check Rates' to see shipment rates"}
                    </FormBody>
                </FormWrapper>
                <FormWrapper>
                    <FormHeader className="greenBoldTitle success">Customer & Orders</FormHeader>
                    <FormBody>
                        <ol>
                            <li>
                                <span className="greyText">Customers:</span>
                                <CustomerDetails>{summary?.personName}</CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText">Phone:</span>
                                <CustomerDetails>{summary?.personPhone}</CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText">Email:</span>
                                <CustomerDetails
                                    style={{
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                >
                                    {summary?.personEmail}
                                </CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText">Shipping Address:</span>
                                <CustomerDetails>
                                    {summary?.address1},{summary?.address2},{summary?.postal},{summary?.city},{summary?.countryCode}
                                </CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText">Order ID:</span>
                                <CustomerDetails>{summary?.invoice}</CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText">Shipment Date:</span>
                                <CustomerDetails>{moment(summary?.shipmentDate).format("YYYY-MM-DD")}</CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText">Invoice Date:</span>
                                <CustomerDetails>{moment(summary?.invoiceDate).format("YYYY-MM-DD")}</CustomerDetails>
                            </li>
                        </ol>
                    </FormBody>
                </FormWrapper>
                <FormWrapper>
                    <FormHeader className="greenBoldTitle success">Order Details :</FormHeader>
                    <FormBody>
                        <ol>
                            <li>
                                <span className="greyText"> ETD or PLT :</span>
                                <CustomerDetails>
                                    <ol>
                                        <li>{`Inbound ${String(etdPltInfo.inBound ? etdPltInfo.inBound : "-").toUpperCase()}`}</li>
                                        <li>{`Outbound ${String(etdPltInfo.outBound ? etdPltInfo.outBound : "-").toUpperCase()}`}</li>
                                        <li style={{ color: "red" }}>{etdPltInfo.info && etdPltInfo.info}</li>
                                    </ol>
                                </CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText"> Contents :</span>
                                <CustomerDetails>{summary?.contents || "Not Yet Entered"}</CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText"> Number of items :</span>
                                <CustomerDetails>{summary?.exportDeclarationItems?.length || "Not Yet Entered"}</CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText"> Number of Pieces :</span>
                                <CustomerDetails>{summary?.pieces?.length || "Not Yet Entered"}</CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText">Total Declared Value:</span>
                                <CustomerDetails>
                                    {currency} {summary?.totalDeclaredValue}
                                </CustomerDetails>
                            </li>
                            <li>
                                <span className="greyText">Total Declared Weight:</span>
                                <CustomerDetails>{summary?.totalDeclaredWeight || "Not Yet Entered"} KG</CustomerDetails>
                            </li>
                        </ol>
                    </FormBody>
                </FormWrapper>
            </div>
        </Affix>
    );
};

export default RightColumn;
