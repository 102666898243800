import styled from 'styled-components'
import { Col, Row } from "antd";

export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1,
  a {
    margin: none !important;
  }
`
export const OpControls = styled(Row)`
  height:50px;
  .op{
    margin-top: 10px;
    margin-bottom: auto;
    float: right;
  }
`
export const FormWrapper = styled.div`
    background-color: #fff;
    margin: 10px;
    border-radius: 10px;
    border: 1px solid #f3f3f3;

    .greenBoldTitle {
        font-size: 1rem;
        font-weight: 600;
        color: #2d9a2a;
    }
    .redBoldTitle {
        font-size: 1rem;
        font-weight: 600;
        color: #dc143c;
    }
`;
