import { Button, Card, Col, Row, Space, Table, Typography, Collapse, Spin, Modal, DatePicker, message } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { Link, useParams, useHistory } from "react-router-dom";
import Path from "../../../commons/breadcrumbs";
import Breadcrumb from "../../../components/breadcrumb/Breadcrumb";
import Container from "../../../components/container/Container";
import { OrderDetailPageStyled, TitleBox } from "./index.styles"
import LinkButton from "components/linkButton/LinkButton";
import queryString from "query-string";
import CombineModal from "pages/orders/CombineModal";
import ordersApi from "apis/ordersApi";
import productsApi from "apis/productsApi";
import moment from "moment";

const { RangePicker } = DatePicker;
const { Title } = Typography;

function BulkFetch() {
    //const [minDate, setMinDate] = useState('');
    //const [maxDate, setMaxDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [orderDateRange, setOrderDateRange] = useState([moment().subtract(90, "days"), moment()]);
    const [prodDateRange, setProdDateRange] = useState([moment().subtract(90, "days"), moment()]);
    const fetchOrders = () => {
        const minDate = orderDateRange ? orderDateRange[0].format('YYYY-MM-DD') : '';
        const maxDate = orderDateRange ? orderDateRange[1].format('YYYY-MM-DD') : '';  //this results to enddate -1 day
        
        console.log('minDate', minDate);
        console.log('maxDate', maxDate);
        
        ordersApi.bulkFetchOrders(minDate, maxDate)
        .catch((err) => {
            message.error('Bulk fetch for orders failed. ' + err.toString());
        });

        message.info('Bulk fetch for orders is running in the backend');
    };

    const fetchProducts = () => {
        const minDate = prodDateRange ? prodDateRange[0].format('YYYY-MM-DD') : '';
        const maxDate = prodDateRange ? prodDateRange[1].format('YYYY-MM-DD') : '';  //this results to enddate -1 day
        
        console.log('minDate', minDate);
        console.log('maxDate', maxDate);

        productsApi.bulkFetchProducts(minDate, maxDate)
        .catch((err) => {
            message.error('Bulk fetch for products failed. ' + err.toString());
        });

        message.info('Bulk fetch for products is running in the backend');
    };
    
    const fetchAllProducts = () => {
        productsApi.bulkFetchAllProducts()
        .catch((err) => {
            message.error('Bulk fetch for products failed. ' + err.toString());
        });

        message.info('Bulk fetch for all products is running in the backend');
    };
    return(
        <>
            <TitleBox className="title-page">
                <Title>
                    Bulk Fetch
                </Title>
                <Breadcrumb items={[{ name: "Home", path: "/" }, Path.BULK_FETCH]} />
            </TitleBox>
            <OrderDetailPageStyled>
                <Container>
                    <Spin spinning={isLoading}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={18}>
                                <Space direction="vertical" size={20}>
                                    <Card title="Fetch Orders  by Date Range">
                                        <Space direction="horizontal" size={12}>
                                            <RangePicker defaultValue={orderDateRange} onChange={setOrderDateRange}/>
                                            <Button type="primary" onClick={fetchOrders}> Fetch </Button>
                                        </Space>                                    
                                    </Card>
                                    <Card title="Fetch Products by Date Range">
                                        <Space direction="horizontal" size={12}>
                                            <RangePicker defaultValue={prodDateRange} onChange={setProdDateRange}/>
                                            <Button type="primary" onClick={fetchProducts}> Fetch </Button>
                                            <Button type="primary" onClick={fetchAllProducts}> Fetch All </Button>
                                        </Space> 
                                                                        
                                    </Card>
                                </Space>                                
                            </Col>
                        </Row>
                    </Spin>
                </Container>
            </OrderDetailPageStyled>
        </>
    );
}

export default BulkFetch;