import { message, Pagination, Spin, Typography, Table, Modal, Button, Card, Row, Col, Descriptions, Space, Input, Select, DatePicker, Tag } from "antd";
import purchaseApi from "apis/purchaseApi";
import ordersApi from "apis/ordersApi";
import Container from "components/container/Container";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Path from "../../commons/breadcrumbs";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import FilterPurchaseOrder from "./FilterPurchaseOrder";
import { CardGrid, TitleBox } from "./index.style";
import { Link, useHistory,useParams } from "react-router-dom";
import moment from "moment";
import PurchaseOrderCardItem from "./PurchaseOrderCardItem";
import { render } from "nprogress";
import LinkButton from "components/linkButton/LinkButton";
import queryString from "query-string";

const { Title } = Typography;

const PurchaseOrders = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [listPurchaseOrders, setListPurchaseOrders] = useState([]);
  const [selectedPurch, setSelectedPurch] = useState();
  const [poDetails, setPoDetails] = useState();
  const [purchItems, setPurchItems] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchPO, setsearchPO] = useState("");
  const [searchOrder, setSearchOrder] = useState("");
  const [status, setStatus] = useState();
  const [pagination, setPagination] = useState({});
  const { RangePicker } = DatePicker;
  const [tableLoading, setTableLoading] = useState(false);
  const [dateRange, setDateRange] = useState([moment().subtract(1, "month"), moment()]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    totalCount: 0,
  });
 
  const orderNumberParam = queryString.parse(history.location.search)?.orderNumber;

  const changePage = (page, pageSize) => {
    pagination.current = page.current;
    setPagination(pagination);

    console.log('pagination',pagination);

    params.pageSize = page.pageSize;
    params.page = page.current;
    setParams(params);

    search(1);
  };

  const getPurchaseOrder = () => {
    const param = {
      // page: params.page,
      // pageSize: params.pageSize,
      // startDate: moment(dateRange[0]).format("YYYY-MM-DD"),
      // endDate: moment(dateRange[1]).format("YYYY-MM-DD")
      page: params.page,
      pageSize: params.pageSize,
      productName: searchKeyword,
      purchaseOrder : searchPO,
      orderNumber : orderNumberParam ? orderNumberParam : searchOrder,
      status: status,
      startDate: dateRange ? moment(dateRange[0]).format("YYYY-MM-DD") : '',
      endDate: dateRange ? moment(dateRange[1]).format("YYYY-MM-DD") : ''
    };
    
    setTableLoading(true);
    purchaseApi
      .getListPurchaseOrders(param)
      .then(({ purchaseOrderList, totalCount }) => {     
         
        setListPurchaseOrders((prev) => purchaseOrderList);

        console.log('purchaseOrderList',purchaseOrderList?.length);
        
        pagination.total = totalCount;
        pagination.current = params.page;
        pagination.pageSize = params.pageSize;

        setPagination(pagination);

        console.log('pagination',pagination);

        setParams((prev) => {
          return { ...prev, totalCount };
        });

        console.log('params', params);
      
      })
      .catch((error) => {
        message.error(error, 5);
      
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const showPurchaseDetails = (purch) => {
    setIsDetailsLoading(true);
    setSelectedPurch(purch);
    setShowDetails(true);
  
    purchaseApi.getPurchaseOrder(purch.orderNumber)
    .then(r => {      
      setPoDetails(r);
      setPurchItems(r.purchaseOrderDetails.purchaseItems);
      setIsDetailsLoading(false);
    })
    .catch( err => {
      console.log("PO retrieval err", err);
    });
   
  };

  const goToOrder = (orderNumber) => {
    const payload = { orderNumber: orderNumber }
    ordersApi.getOrderByNum(payload)
    .then(r => {
      console.log("ORDER",r);
      if (r.length > 1) {
        message.error("Multiple orders found for the same number. Redirection to order page failed");
      } else {
        message.success("Redirecting to order details page");
        history.push("/orders/order-details/" + r[0].id);
      }
    })
    .catch(err => {
      console.log("ERR",err);
    })
  }

  const itemsColumns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (item, record, index) => {
        return(<>{index+1}</>)
      }
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Quantity',
      dataIndex: 'purchasedQty',
      key: 'purchasedQty',
    }
  ];

  const columns = [
    {
      title: '#',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (item, record, index) => {
        return(<>{((params.page-1) * params.pageSize) + (index+1)}</>)
      }
    },
    {
      title: 'PO Number',
      dataIndex: 'orderNumber',
      key: 'orderNumber',
      render: (item, record) => {
        return (<Link to={Path.PURCHASE_ORDER_DETAILS.pathParam(item)}>{item}</Link>)
      }
    },   
    {
      title: 'Order Number',
      dataIndex: 'linkedOrder',
      key: 'linkedOrder',
      render: (item) => {
        return( <div style={{height:"80px",overflow:"scroll", width: "350px"}}>   [
          {item?.map((i,index) => <>
            <a style={{ cursor:"pointer" }} href={null} onClick={() => goToOrder(i)}>{i}</a>
            {(index + 1) < item.length? (<>,&nbsp;</>) : (<></>) }
          </>)}
        ]</div>)
      }
    },
    {
      title: 'Product',
      key: 'product',
      render: (record, index) => {
        // console.log('record',record)
        return(<>{record?.purchaseItems.map((i,index) => <div>
           {i.product_name + '\n'} 
          {(index + 1) < record.purchaseItems.length? (<>&nbsp;</>) : (<></>) }
        </div>)}</>)
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    },
    {
      title: 'Purchased Qty',
      dataIndex: 'totalPurchasedQty',
      key: 'totalPurchasedQty'
    },
    {
      title: 'Pending Qty',
      dataIndex: 'totalPendingQty',
      key: 'totalPendingQty',
    },
    {
      title: 'Date & Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.createdAt)- moment(b.createdAt),
      render: (item) => {
        return(<>{moment(item).format("DD-MM-YYYY, hh:mm A")}</>);
      }
    }
  ];

  const changePageHandler = (page, pageSize) => {
    setParams({ ...params, page, pageSize });
  };

  const makePurchase = () => {
    const link = `/purchases/pending-purchases/make-purchase?purchaseOrderId=${selectedPurch.orderNumber}`;
    history.push(link);
  };

  const fullCheckIn = () => {
    const items = purchItems.map(p => {
      return {
        productVariantId: p.variantId,
        quantity: p.purchasedQty,
      };
    });
    
    const purchaseOrder = poDetails.purchaseOrderDetails;
    const payload = {
      items: items,
      orderNum: purchaseOrder.linkedOrder,
      purchaseId: purchaseOrder.orderNumber,
      remark: "INSTANT CHECK-IN",
      checkInType: "FULL"
    };

    purchaseApi.checkInOrder(payload)
    .then(r => {
      message.success("Purchase order succesfully checked in");     

      if (process.env.REACT_APP_DEVELOPMENT) {
        if (purchaseOrder.linkedSource === "custom" || purchaseOrder.linkedSource === "cop") { 
          const webhookPayload = {
            orderNo: purchaseOrder.linkedOrder,
            items: items
          }
          purchaseApi.notifyCheckIn(webhookPayload);
        }
      }      
    })
    .catch(err => {
      console.log("ERR ", err);
      message.error("Check in failed. Error: ", err);
    });
  };

  const partialCheckIn = () => {
    history.push("/purchases/pending-purchases/partial-check-in?id=" + selectedPurch.orderNumber);
  };
  
  const closeAsComplete = () => {};

  const search = (stat) => {
    console.log("setSearchOrder", searchOrder)

    if (!stat) {
      const query = {
        page: params.page,
        pageSize: params.pageSize,
        productName: searchKeyword,
        purchaseOrder : searchPO,
        orderNumber : searchOrder,
        status: status,
        startDate: dateRange ? moment(dateRange[0]).format("YYYY-MM-DD") : '',
        endDate: dateRange ? moment(dateRange[1]).format("YYYY-MM-DD") : ''
      };
      purchaseApi.getListPurchaseOrders(query)
      .then(r => {
        setListPurchaseOrders(r.purchaseOrderList);
        pagination.total = r.totalCount;
        console.log('pagination', pagination);
        setPagination(pagination);
      })
      .catch(err => {console.log("Search error ",err)})
      .finally(() => {setTableLoading(false)});
    } else {
      setParams(params);
      console.log('searchorder',searchOrder)
      const query = {
        page: params.page,
        pageSize: params.pageSize,
        productName: searchKeyword,
        purchaseOrder : searchPO,
        orderNumber : searchOrder,
        status: status,
        startDate: dateRange ? moment(dateRange[0]).format("YYYY-MM-DD") : '',
        endDate: dateRange ? moment(dateRange[1]).format("YYYY-MM-DD") : ''
      };
      console.log('query', query);
      purchaseApi.getListPurchaseOrders(query)
      .then(r => {
        setListPurchaseOrders(r.purchaseOrderList);
        pagination.total = r.totalCount;
        setPagination(pagination);
      })
      .catch(err => {console.log("Search error ",err)})
      .finally(() => {setTableLoading(false)});
    }
    setTableLoading(true);
    
    
  }

  const onChangeStatus = (value) => {
    setStatus(value);
  };

  useEffect(() => {
    getPurchaseOrder();
    setPagination(pagination);
    // setSearchOrder(orderNumberParam ? orderNumberParam : searchOrder );
   
  }, [params.page,params.pageSize]);

  return (
    <>
      <TitleBox className="title-page">
        <Title>{t("Purchase Orders")}</Title>
        <Breadcrumbs
          items={[
            { name: "Home", path: "/" },
            { name: Path.PURCHASE_ORDERS.name },
          ]}
        />
      </TitleBox>
      <Container>
        <Row>
          <Col md={24}>
            <Row style={{marginTop:"0px", padding:"15px" ,backgroundColor:"#edf1f5", border:"#D7D7D7 solid 1px"}}>
              <Space direction="horizontal">
                Purchase Order
                <Input style={{width:"100px", marginRight:"10px"}}  value={searchPO}
                  onChange={(e) => 
                    {setsearchPO(e.target.value)}
                  } 
                />
                Product Name
                <Input style={{width:"200px", marginRight:"10px"}} value={searchKeyword}
                  onChange={(e) => {setSearchKeyword(e.target.value)}} 
                />
                Order Number
                <Input style={{width:"100px", marginRight:"10px"}}  value={searchOrder}
                  onChange={(e) => {
                    setSearchOrder(e.target.value)}} 
                />
                Status 
                <Select                                
                  style={{marginRight:"10px", width:"200px"}}     
                  defaultValue={null}       
                  onChange={onChangeStatus}      
                  options={[
                    { label: "<All>", value: null},
                    { label: "Saved", value: "saved" },
                    { label: "Completed", value: "completed" },
                    { label: "Partially Checked-in", value: "partialCheckIn"}
                  ]}
                />
                Date Range
                <RangePicker 
                    defaultValue={dateRange} 
                    onChange={setDateRange} 
                    style={{marginRight:"10px"}}
                />                
              </Space>
              <Button type="primary" onClick={() => search(1)} style={{marginLeft:"20px"}}>Search</Button>
            </Row>
          </Col>
        </Row>
        <br/>

        <Table 
          columns={columns}
          dataSource={listPurchaseOrders}
          pagination={pagination}
          loading={tableLoading}
          onChange={changePage}
        />
        <br/>
        
        
        {/* <Pagination
          style={{ marginTop: "10px" }}
          showSizeChanger
          onChange={changePageHandler}
          total={params.totalCount}
          showTotal={(total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`
          }
          current={params.page}
          pageSize={params.pageSize}
          defaultPageSize={10}
          pageSizeOptions={[10, 20, 30]}
        /> */}
        {/* <CardGrid>
          {isLoading ? (
            <Spin />
          ) : listPurchaseOrders ? (
            listPurchaseOrders.map((item, index) => (
              <PurchaseOrderCardItem
                key={item.orderNumber}
                index={index}
                item={item}
              />
            ))
          ) : (
            "No Data"
          )}
        </CardGrid> */}
      </Container>
      
      <Modal
        visible={showDetails}
        width={1000}
        footer={[
            <Button type="primary" onClick={() =>{setShowDetails(false)}}>Close</Button>
        ]}   
           
      >      
        <Spin spinning={isDetailsLoading}>
          <Row>
            <Col md={24}>
                <br/>
                <Card title="Purchase Order Details">
                  <Descriptions bordered>
                    <Descriptions.Item label="Purchase Quantity">{selectedPurch?.totalPurchasedQty}</Descriptions.Item>
                    <Descriptions.Item label="Product" >{selectedPurch?.purchaser}</Descriptions.Item>
                    <Descriptions.Item label="Status" >{selectedPurch?.status}</Descriptions.Item>
                    <Descriptions.Item label="Order Number" >TBA</Descriptions.Item>
                    <Descriptions.Item label="Domestic Shipping Fee" >TBA</Descriptions.Item>
                  </Descriptions>
                </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24}> 
              <br/>
              <Card title="Purchased Items">
                <Table
                  pagination={false}
                  dataSource={purchItems}
                  columns={itemsColumns}
                />
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <br/>
              <Card title="Checked-ins">
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <br/>
              <Button type="primary" style={{marginRight:"40px"}} onClick={makePurchase}>Make Purchase</Button>                           
              <Button type="primary" style={{marginLeft:"15px",float:"right"}}>Close As Completed</Button>
              <Button type="primary" style={{marginLeft:"15px",float:"right"}} onClick={partialCheckIn}>Partial Check-in</Button>   
              <Button type="primary" style={{marginLeft:"15px",float:"right"}} onClick={fullCheckIn}>Full Check-in</Button>          
            </Col>
          </Row>
        </Spin>          
      </Modal>
    </>
  );
};

export default PurchaseOrders;
