import axios from "axios";
import {
    Button,
    Col,
    Divider,
    Input,
    message,
    Row,
    Select,
    Space,
    Table,
    Typography,
    List,
    Spin,
    Avatar,
    Image,
    InputNumber,
    Card,
    Tag
} from "antd";
import { TitleBox } from "./index.style";
import Path from "../../commons/breadcrumbs";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useHistory } from "react-router";
import purchaseApi from "apis/purchaseApi";
import productsApi from "apis/productsApi";
import Container from "components/container/Container";
import moment from "moment";
import LocalStorage from "utils/storage";
import produce from "immer";
import { currencyExchangeApi } from "apis/currencyExchangeApi";
import { currencyExchangeFunc } from "utils/funcs";
import { useSelector } from "react-redux";
import { xorBy } from "lodash";
import { render } from "nprogress";
import ordersApi from "apis/ordersApi";
import DragDropFile from "components/drag-drop-image";

const { Title } = Typography;
const { Column } = Table;

const CreatePurchaseOrder = () => {
    let state = [];
    const [orderData, setOrderData] =useState();
    const { search, /*state*/ } = useLocation();
    const [orders, setOrders] = useState([]);
    const [display, setDisplay] = useState([]);
    const [orderIds, setOrderIds] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const history = useHistory();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // <-- array
    const [purchaseItem, setPurchaseItem] = useState([]); // <-- array
    const [isLoading, setIsLoading] = useState(false);
    const curr = useSelector((state) => state.common.currency);
    const [prettyNote, setPrettyNote] = useState();
    const [refunds, setRefunds] = useState();
    const [totalRefunds, setTotalRefunds] = useState();
    const [uploaded, setUploaded] = useState(false);
    const [domesticFee, setDomesticFee] = useState();
    
    //const [supplierPrice, setSupplierPrice] = useState(0);
    // {
    //     model: OrderCreationAlert,
    //     as: 'alerts',
    //     required: false,     
    //     attributes: { exclude: ['deletedAt']},
    //   }
    
    //Retrieve refund for this order if applicable
    //     ordersApi.getRefunds(id)
    //     .then((r) => {
    //         console.log('REFUNDS', r);
    //         const refundsArr = [];
    //         if (r.length > 0) {
    //         r.forEach(refund => {                                
    //             //order adj refund
    //             if (refund.json.order_adjustments.length > 0) {                                                                        
    //                 refund.json.order_adjustments.forEach(x => {                                        
    //                     const refundObj = {
    //                         details: 'Order Adjustment Refund',
    //                         reason: x.reason,
    //                         createdTime: refund.createdTime,
    //                         processedTime:refund.processedTime,
    //                         refundAmt: x.amount? Math.abs(x.amount) : Number(0)
    //                     }

    //                     refundsArr.push(refundObj);
    //                 });                         
    //             }

    //             //line items refund
    //             if (refund.json.refund_line_items.length > 0) {
    //                 refund.json.refund_line_items.forEach(x => {
    //                     const refundObj = {
    //                         details: 'Line Items Refund',
    //                         reason: x.line_item.name,
    //                         createdTime: refund.createdTime,
    //                         processedTime:refund.processedTime,
    //                         refundAmt: x.subtotal? Math.abs(x.subtotal) : Number(0)
    //                     };

    //                     refundsArr.push(refundObj)
    //                 });                                  
    //             }

    //             //others
    //             if (refund.json.refund_line_items.length === 0 && refund.json.order_adjustments.length === 0) {
    //                 //not yet implemented. tested so far no needs for transaction[].
    //                 //might be in the future bugs?
    //             }
                
    //         });
    //         setRefunds(refundsArr);
    //         setTotalRefunds(refundsArr.reduce((prev,next) => {return prev + next.refundAmt}, Number(0)))
    //     }
            
    //         //setTotalRefunds(r.reduce((prev,next) =>{return prev + Math.abs(next.adjAmount)},Number(0)));
    //     });
        

    //     setIsLoading(false);
    // };
    // function shallowEqualityCheck(obj1, obj2) {
    //     if (obj1 == obj2) {
    //         return true;
    //     }
    //   }

    const init = async () => {
        state = JSON.parse(localStorage.getItem("createPurchaseData"));
        // setIsLoading(true);
        console.log("STATE", state);
        setOrders(state.orders || null);
        const items = [];
        if (state.orders) {
            const idsArr = state.orders.map((o) => {           
                return {id: o.id, orderNumber: o.orderNumber};
            });

            //update lineitems
            state.orders.map(o => { 
            
               o.lineItems.map(async (l, ind) => {
                // items.push({...l, orderNumber: o.orderNumber, key: o.id + "_" + o.productVariantId, ...l});
                l.orderNumber = o.orderNumber;
                l.key = o.id + "_" + l.productVariantId
                let checker = 0;
                if (l.customProductName?.toLowerCase().includes("bunjang") || l.productTitle?.toLowerCase().includes("bunjang") ) {
                    checker = 1
                }
                if (l.customProductName?.toLowerCase().includes("domestic shipping") || l.productTitle?.toLowerCase().includes("domestic shipping")) {
                    checker = 1
                }
                if (l.customProductName?.toLowerCase().includes("korea shopping agent") ||  l.productTitle?.toLowerCase().includes("korea shopping agent")) {
                    checker = 1
                }
                if (l.customProductName?.toLowerCase().includes("top-up shipping fee") ||  l.productTitle?.toLowerCase().includes("top-up shipping fee") ) {
                    checker = 1
                }
                if (checker == 0) {
                    let indexx = items.findIndex(x => x.productVariantId  === l.productVariantId);
                    if (indexx >= 0) {
                        items[indexx].pending = items[indexx].pending + l.pending
                        items[indexx].quantity = items[indexx].quantity + l.quantity
                    }
                    else {
                        items.push({...l}) 
                    }
                    
                }
                // items.push({...l})     
                });
            });
            setLineItems(items);
            setOrderIds(idsArr);
            
            setPurchaseItem(items.map(x => {
                x.supplierPrice =  0;//x.supplierPrice || 1;//x.price * 1000
                x.margin = 0;//((x.price * 1000) - x.supplierPrice).toFixed(2)
                console.log('x',x)
                return x;
            }));            
        }

        else {
            let ord = [];
            let items = [];
            let orderIdList = []
            
            await state.map(async (item) => {
                console.log('------', item);
                
                let data = {
                    key : item.productVariantId,
                    // productTitle : item.variantTitle ? item.productTitle + ' - ' + item.variantTitle : item.productTitle,
                    productTitle : item.productTitle ? item.productTitle : item.customProductName ? item.customProductName : null,
                    productVariantTitle : item.variantTitle ? item.variantTitle : "",
                    vendor : item.supplier,
                    images : item?.variantImage? [item.variantImage[0]] : null,
                    orderNumber : item.orders.map(order => order.orderNumber),
                    pending : item.pending,
                    supplierPrice : 0, 
                    price : item.sellingPrice,
                    currency : item.currency,
                    productVariantId : item.productVariantId,
                    quantity : item.pending
                }
            
               
                await items.push(data);
                await item.orders.map(async (order) => {
                    let indexx = await orderIdList.findIndex(x => x == order.orderNumber);
                    if (indexx < 0) {
                        await orderIdList.push(order.orderNumber);
                    }
                    await ord.push(order);
                });
            });
            setOrders(ord);
            setOrderIds(ord);
            setLineItems(items);
            
            setPurchaseItem(items.map(x => {
                x.supplierPrice = 0;//x.supplierPrice || 1;//x.price * 1000
                x.margin = 0;//((x.price * 1000) - 1).toFixed(2)
                console.log('x',x);
                return x;
            }));
          
            console.log(ord);
            console.log('ITEMS',lineItems);
            console.log('orderIds',orderIds);
        }
    };

    const handleDomesticFeeChange = async (e) => {
        if (!isNaN(e)) {
            await setDomesticFee(e);
        }    
      };

    const getOrderDetails = () => {
        // let detailsArr = [];
        // orderIds.map(async id => {
        //     await ordersApi.getOrderById(id)
        //     .then(async res => {
        //         await detailsArr.push(res)
        //     })
        // })

        // setDisplay(detailsArr);
        // console.log('aaaaaaaa',display)
        // console.log(display.order);
    }

    const markOutOfStock = (item) => {
    
        console.log("ITEM",item);
        const payload = {
          purchaseId: null,
          orderNumber: item.orderNumber,
          variantId: item.productVariantId,      
        };
    
            purchaseApi.setOutOfStock(payload)
            .then(r => {
                console.log("OOS R", r);
                message.success('Variant ' + item.productTitle + ' - ' + item.productVariantTitle + 'set to out of stock');
            
            })
            .catch(err => console.log("OOS ERR", err));
        
            // if (process.env.REACT_APP_DEVELOPMENT) {
            //   console.log("ORDER NUMBER", item.orderNumber, "ORDER SOURCE",  item.source);
            //   if (item.source === "custom" || item.source === "cop") {
            //     const webhookPayload = {
            //       orderNo: item.orderNumber,
            //       items: [{
            //         item_id: item.productVariantId
            //       }]
            //     };
                
            //     purchaseApi.notifyOutOfStock(webhookPayload)
            //     .then(r => console.log("COP ITEM OOS WEBHOOK RESPONSE",r));
            //   }
            // }
      };

    const parseNote = (noteStr) => {                   
        const httpsLinkRegex2 = /https?:\/\/[\S\d\w]+\s+.*/g;          
        const linkArr = noteStr.match(httpsLinkRegex2) || [];          
        const txtArr = noteStr.split(httpsLinkRegex2) || [];        

        const linkObjArr = [];
        for(let i=0; i < linkArr.length; i++){                
            let tmpArr = linkArr[i].split('\n');           
            
            if(!tmpArr[1]){
                //split by newline not going right. probably link separated by whitespace                
                const temp = linkArr[i].split(' ');               
                tmpArr[0] = temp[0];      
                const desc =  (temp.slice(1,temp.length-1)).reduce((prev,next) => {return prev + ' ' + next},'');   
                //console.log('DESC', desc, 'LEN', desc?.length);       
                tmpArr[1] = desc?.trim();
            }
            
            const obj = {link: tmpArr[0]?.trim(), desc:(tmpArr[1]?.length > 0)? tmpArr[1] : 'link'};           
            linkObjArr.push(obj);
        }
        
        const tmpArr = txtArr.map((t,index) => {
            let element = t;
            if(index < linkObjArr.length){
                const linkStr = `<a href='${linkObjArr[index].link}' target="_blank">${linkObjArr[index].desc}</a>`;
                element = element + linkStr;
            }

            return element;
        });
        console.log('tmpArr', tmpArr);
        const finalStr = tmpArr.toString().replace(/\n/g, '<br/>').replace(/,/g, ' ');
        setPrettyNote(finalStr);                 
    };

    // selected rows
    const rowSelection = {
        onChange: (selectedRowKeys) => {
            console.log("ACTIVE PURCHASE ITEM", selectedRowKeys);
            
            setSelectedRowKeys(selectedRowKeys);
        },

    };

    const orderColumns = [
        {
            title: "Order Number",
            render: (record,index) => {
                return <div>
                 <Tag color="#108ee9"><a style={{ cursor:"pointer"}} href={`/orders/order-details/${record.id}`}>{record.orderNumber}</a></Tag>
                </div>
            }

        },
        {
            title: "Email",
            render: (record,index) => {
                return <div>
                {record.customerEmail}
                </div>
            }
        },
        {
            title: "Total Price",
            render: (record,index) => {
                return <div>
                {record.totalPrice}
                </div>
            }
        },
            {
                title: "Status",
                render: (record,index) => {
                    return <div>
                    {record.status}
                    </div>
                }
            },
            {
                title: "Financial Status",
                render: (record,index) => {
                    
                    return <div>
                    <div></div>
                    {record?.financialStatus==="paid" ? <div className="data uppercase" >{record?.financialStatus}
                            </div> :
                                <div className="data uppercase" style={{ color: "red",fontSize: "1.2em"}}>
                                {record?.totalOutstanding}
                            </div>}
                </div>
                           
            },
        },
        {
            title: "Created At",
            render: (record,index) => {
                return <div>
                {record.createdAt}
                </div>
            }
        },
        {
            title: "Processed At",
            render: (record,index) => {
                return <div>
                {record.updatedAt}
                </div>
            }
        },
        {
            title: "Risk",
            render: (record,index) => {
                return <div>
                {record.risk == "0.5" ? <div className="data bold" style={{ color: "red",fontSize: "1.2em"}}>MEDIUM</div> :
                record.risk == "1.0" ? <div className="data bold" style={{ color: "red",fontSize: "1.2em"}}>HIGH</div> :
                <div className="data bold" style={{fontSize: "1.2em"}}>LOW</div>
            }
                </div>
            }
        },

        {
            title: "Order Notes",
            render : (record, index) => {
                record?.note ? parseNote(record.note) :  setPrettyNote('Not Available')
                return <div>
                    <div className="data" dangerouslySetInnerHTML={{__html: prettyNote}}></div>                                             
                </div>       
            }
        },
        {
            title: "Order Issues",
            render : (record, index) => {
                console.log(record)
                return (
                    <div>
                        {record?.alerts?.length > 0 ?
                            <div className="data">{record?.alerts[0].alert}</div> 
                            :
                            <div className="data">None</div> 
                        }
                        {/* <div className="data">{record?.alerts[0]?.remark}</div>                                              */}
                            
                    </div>); 
            }
        }


    ]
    const columns = [
        {
            title: "Product",
            render: (record,index) => {
                return <div>
                {record.productVariantTitle && record.productTitle ? record.productTitle + ' - ' + record.productVariantTitle : record.productTitle  && !record.productVariantTitle ? record.productTitle : record.customProductName && record.productVariantTitle ? record.customProductName + ' - ' + record.productVariantTitle : record.productTitle ?  record.productTitle : record.customProductName}
                </div>
            }

        },
        {
            title: "Supplier",
            render: (record,index) => {
                return <div>
                {record.vendor || ""}
                </div>
            }
        },
        {
            title: "Image",
            render: (record,index) => {
                return (
                    <div>
                       
                    {record?.images?.at(0)?
                        <img
                            style={{ objectFit: "cover" }}
                            width={100}
                            height={100}
                            alt="productImage"
                            src={record.images[0]}
                        /> 
                        : <img 
                            src = "/no-image.png"
                            style={{ objectFit: "cover" }}
                            width={100}
                            height={100}
                            alt="noImage"
                        
                        /> }
                    </div>
                );
            }
        },
,
        {
            title: "Order Number",
            dataIndex: "orderNumber",
            render: (orderNumber,index) => {
            return <div>
            {orders ? 
                <div>
                {orderNumber + '\n'}
                 </div>
                   :
                <div style={{height:"150px",width: "100px",overflow:"scroll"}}>                                                
                <ul>
                {orderNumber.map((o) => {
                      return <li>{o}</li>
                   })}
                </ul>
               </div>
                }
            </div>
            }

        },
        // {
        //     title: "Quantity",
        //     dataIndex: "",
        //     render: (text,record,index) => <Input
        //     name="quantity"
        //     addonAfter="PCS"
        //     step={1}
        //     type="number"
        //     value={record.pending}
        //     onChange={(event) => numberChangeHandler(event, index)}
        //   />            
        // },
        {
            title: "Quantity",
            dataIndex: "quantity",
            render: (text,record,index) => <Input
            name="quantity"
            addonAfter="PCS"
            step={1}
            type="number"
            value={text}
            onChange={(event) => numberChangeHandler(event, index)}
          />            
        },
        {
            title: "Pending Qty",
            dataIndex: "pending",
        },
        {
            title: "Selling Price",
            dataIndex: "price",
            render: (price, record, index) => 
            <Input
                name="sellingPrice"
                addonBefore={record.currency}
                value={price}
                // onChange={(event) => numberChangeHandler(event, index)}
            />
        },
        {
            title: "Supplier Price",
            dataIndex: "supplierPrice",
            render: (supplierPrice,record,index) => 
            <Input
                name="supplierPrice"
                addonBefore="KRW"
                step={0.01}
                type="number"
                value={supplierPrice}
                onChange={(event) => numberChangeHandler(event, index)}
            />

        },
        {
            title: "Margin",
            key: "margin",
            render: (margin,record, index) => {
            // let a = Object.keys(item)[0]
            return (
                <Input
                name="margin"
                addonBefore="KRW"
                value={
                    ((record.price * 1000) - (record.supplierPrice || 1)).toFixed(2) 
                }
                // onChange={(event) => numberChangeHandler(event, index)}
            />
            )
                
            }
        },
        {
            title:"Action",
            dataIndex:"productVariantId",
            key:"productVariantId",
            render:(value, record, index) => {
                return(<>
                    <Space direction="horizontal">
                        <Button type="primary" onClick={() => markOutOfStock(record,index)}>
                            Out of Stock
                        </Button>
                        <Button type="primary" onClick={()=>{
                            window.open(`/products/adjustment/${record.productVariantId}`, "_blank");
                        }}>
                            Adjust Stock
                        </Button>
                    </Space>
                    
                </>);
            }}
        
    ]

    //remove duplicates
    function removeDuplicates(arr) {
        return [...new Set(arr)];
    }

    const createPurchOrd = () => {

        // console.log('purchases', purchaseItem);
        const selectedItems = purchaseItem.filter(l => {
            if(selectedRowKeys.includes(l.key))
                return l;
        });

        // console.log('selected', selectedItems);

        // let orderIdArray = orderIds.map(o => o.orderNumber);

        let idArray = [];

        selectedItems.map(vari => {
            idArray.push(vari.orderNumber);
        })
        
        const purchPayload = {
            purchaseDate: moment().format("MM/DD/YYYY"),
            purchaseTime: moment().format("HH:mm:ss"),
            purchaser: LocalStorage.get("username","n/a"),
            linkedOrder: removeDuplicates(idArray.flat()), //orderIds.map(o => o.orderNumber)
            domesticShipFee : domesticFee ? domesticFee.toFixed(2) : 0.00,
            // linkedSource: order.source,
            
            purchaseItem: selectedItems.map(variant => {

                // console.log('varrrr', variant)
                
                const result = {
                    productName: variant?.productTitle ? variant?.productTitle : variant?.customProductName ? variant?.customProductName : "",
                    variantName: variant?.productVariantTitle ? variant?.productVariantTitle : "",
                    variantId: variant?.productVariantId ? variant?.productVariantId : "",
                    supplier: variant?.variants?.product?.vendor? variant?.variants?.product?.vendor : variant?.vendor ? variant.vendor : "",
                    purchaseLink: variant.supplierURL ? variant.supplierURL : "",
                    pendingQty: variant?.pending ? variant?.pending : 0,
                    purchasedQty: variant?.quantity ? variant?.quantity : 0,
                    supplierPrice: variant?.supplierPrice,
                    unitPrice: variant?.price? Number(variant?.price) : 0,
                    supplierCurrency: "KRW",
                    paidFrom: "Credit Card - 8876982268752",
                    exchangeRate: 1180.62,
                    orderNumber: Array.isArray(variant?.orderNumber) ? variant?.orderNumber.toString() : variant?.orderNumber,
                    domesticShipFee: domesticFee
                    };

                    console.log('varant', result);
                
                return result;
            })       
        };

        console.log('pur', purchPayload);

        purchaseApi.postMakePurchaseOrder(JSON.stringify(purchPayload))
        .then(( payload ) => {
          let purchaseOrderId = [];
          console.log('payload',payload);
          purchaseOrderId.push(payload.purchase.id);
          message.success("Successfully Create Purchase Order", 3);
          history.push("/purchases/purchase-order-details/" + purchaseOrderId);
        })
        .catch((error) => {
          message.error(error.message, 3);
          console.log('error', error);
        });

        // purchaseApi.postMakePurchaseOrder(JSON.stringify(purchPayload))
        // .then(({ payload }) => {
        //   let purchaseOrderId = [];
        //   purchaseOrderId.push(payload.id);

        //   Promise.all(
        //     selectedItems.map((item) => {
        //         console.log('item', item);
        //       const params = {
        //         purchasedQty: Number(item?.quantity),
        //         supplierPrice: Number(item?.supplierPrice),
        //         paidFrom: item?.purchaseItem?.paidFrom,
        //       };
      
        //       console.log("NEW PURCHASE ITEM", queryString.stringify(params));
      
        //       return purchaseApi.putPurchaseItem(
        //         item.id,
        //         queryString.stringify(params)
        //       );
        //     })
        //   )
        //     .then((res) => {
        //       console.log("PURCHASE ITEM RESPONSE!", res);
        //       setIsLoading(false);
        //       res.map(() => {
        //         message.success("Successful Update!", 5);          
        //         /*
        //         setTimeout(() => {
        //           window.location = "/purchases/purchase-orders";
        //         }, 1000);
        //         */
        //       });

        //   message.success("Successfully Create Purchase Order", 3);
        //   history.push("/purchases/purchase-order-details/" + purchaseOrderId);
        // })
        // .catch((error) => {
        //   message.error(error.message, 3);
        // });
    };

    useEffect(() => {
        console.log("SELECTED ORDERS", orders);
    }, [orders]);
    
    useEffect(() => {
        console.log("LINE ITEMS", lineItems)
    }, [lineItems]);
        
    useEffect(() => {
        init();               
    },[]);
   
    const numberChangeHandler = async (event, index) => {
        console.log('purchaseItem',purchaseItem);
        console.log('event', event.target.name);
        console.log('index', index)
        console.log('val', event.target.value)
        const key = event.target.name;
        const value = event.target.value;
        const newParams = await produce(purchaseItem, (draft) => {
          draft[index][key] = Number(value);
        });
        setLineItems(newParams);
        console.log('newParams', newParams)
        return setPurchaseItem(newParams);
    };

    return(<>
        <div>
            <TitleBox className="title-page">
                <Title>{"Create Purchase Order"}</Title>
                <Breadcrumbs
                items={[
                    { name: "Home", path: "/" },
                    {
                    name: Path.PENDING_PURCHASE.name,
                    path: Path.PENDING_PURCHASE.pathname,
                    },
                    { name: Path.CREATE_PURCH_ORDER.name },
                ]}
                />
            </TitleBox>
            <Container>
                {/* <Card 
                    title="Involved Orders"
                    style={{marginBottom:"20px"}}
                >                    
                    {orderIds?.map(o => <Tag color="#108ee9"><a style={{ cursor:"pointer"}} href={`/orders/order-details/${o.id}`}>{o.orderNumber}</a></Tag>)}                    
                </Card> */}
                {orders?.length > 0?
                 <Card title="Orders">
                 <Table
                     loading={isLoading}
                     columns={orderColumns}
                     // dataSource={lineItems}
                     dataSource={orders}
                     pagination={false}
                 />
              </Card> : <></> }
               
                <Card title="Products">
                    <Table
                        loading={isLoading}
                        columns={columns}
                        // dataSource={lineItems}
                        dataSource={purchaseItem}
                        selectedRowKeys={selectedRowKeys}
                        rowSelection={{
                          ...rowSelection, 
                            
                        }}

                        pagination={false}
                    />
                </Card>
               
                <Card>
                    <Col xs={24} md={24} className="box-data">      
                        <Space direction="horizontal" style={{float:"right"}}>
                            <h1>Domestic Shipping Fee</h1>
                            <InputNumber style={{width:"250px"}} onChange={(e) => handleDomesticFeeChange(e)}/>                     
                            
                        </Space>
                            
                        
                    </Col>
                </Card>
                 
                
                {/* <Card title="Proof of Purchase">
                <Col md={14} style={{ paddingRight: "30px" }}>
                    <DragDropFile
                    selectedRowKeys={selectedRowKeys}
                    purchaseItem={purchaseItem}
                    setUploaded={setUploaded}
                    // orderNumber="1122"
                    // orderSource="shopify"
                    />
                </Col>
                </Card> */}
                
                <Button type="primary" onClick={createPurchOrd} style={{marginTop:"15px",marginBottom:"30px", float:"right"}}>Create a Purchase Order</Button>
            </Container>
        </div>
    </>);
};

export default CreatePurchaseOrder;