import { Col, Input, Row, Space, Tabs, DatePicker, Switch } from "antd";
import moment from "moment";
import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "../../../components/select/Select";
import { SearchPanelStyled } from "./styles";
import {
  ORDER_FILTER_FINANCIAL_STATUS,
  ORDER_FILTER_STATUS,
  ORDER_SORT,
  ORDER_RISK_STATUS,
} from "./util";

const { TabPane } = Tabs;
const { Search } = Input;
const { RangePicker } = DatePicker;

function SearchPanel({ params, setParams, handleGetList, selectRowKey }) {
  const { t } = useTranslation();
  const [localParams, setLocalParams] = useState({
    orderNumber: "",
    customerEmail: "",
    productTitle: "",
    productVariantTitle: "",
    shippingAddress: "",
    customerName: "",
  });

  const handleChangeField = (e) => {
    setParams({ ...params, [e.target.name]: e.target.value, page: 1 });
  };

  const handleChangeStatus = (activeKey) => {
    setParams({ ...params, source: activeKey, page: 1 });
  };

  const handleChangeDate = (value) => {
    setParams({ ...params, createdAt: value, page: 1 });
  };

  const debounceCallbackSearch = useCallback(
    debounce(({ name, value }) => {
      setParams((prev) => ({ ...prev, [name]: value, page: 1 }));
    }, 500),
    []
  );
  const handleChangeQuery = (e) => {
    const { name, value } = e.target;
    setLocalParams((prev) => ({ ...prev, [name]: value }));
    debounceCallbackSearch({ name, value });
    // if (value && `${value}`.length >= 3) {
    //   debounceCallbackSearch({ name, value });
    // }
  };
  const onSearch = () => {
    handleGetList();
  };
  useEffect(() => {
    if (params.orderNumber !== localParams.orderNumber) {
      setLocalParams((prev) => ({ ...prev, orderNumber: params.orderNumber }));
    }
  }, [params.orderNumber]);
  useEffect(() => {
    if (params.productTitle !== localParams.productTitle) {
      setLocalParams((prev) => ({
        ...prev,
        productTitle: params.productTitle,
      }));
    }
  }, [params.productTitle]);
  useEffect(() => {
    if (params.productVariantTitle !== localParams.productVariantTitle) {
      setLocalParams((prev) => ({
        ...prev,
        productVariantTitle: params.productVariantTitle,
      }));
    }
  }, [params.productVariantTitle]);

  return (
    <SearchPanelStyled noValidate>
      <Row gutter={[20, 10]}>
        <Col xs="auto">
          <Tabs
            type="card"
            onChange={handleChangeStatus}
            activeKey={params.source}
          >
            <TabPane tab="All" key="" />
            <TabPane tab="Shopify" key="shopify" />
            <TabPane tab="Poizon" key="poizon" />
            <TabPane tab="Custom" key="custom" />
            <TabPane tab="Whatsapp" key="whatsapp" />
          </Tabs>
        </Col>
        <Col xs="auto">
          <Space>
            <div>{t("Sort")}</div>
            <Select
              name="sort"
              onChange={handleChangeField}
              value={params.sort}
              placeholder=""
              options={Object.values(ORDER_SORT)}
              size="large"
              className="sort-select"
              isTranslate={false}
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Space>
        </Col>
        {/* <Col xs="auto">
          <LinkButton size="large" to="/orders/create-order">
            Create Order
          </LinkButton>
        </Col> */}
        {/* <Col xs='auto'>
          <LinkButton type="primary" size='large' to={`/orders/create-order?ids=${Object.values(selectRowKey).join(',')}`}>
            Create Bulk Shipment
          </LinkButton>
        </Col> */}
      </Row>
      <Row className="filter-box" gutter={[10, 10]} style={{ marginTop: 10 }}>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Space direction="vertical">
            <div>{t("Order Number")}:</div>
            <Input
              placeholder="Order Number"
              onPressEnter={() => onSearch()}
              name="orderNumber"
              value={localParams.orderNumber}
              size="large"
              onChange={handleChangeQuery}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Space direction="vertical">
            <div>{t("Product title")}:</div>
            <Input
              placeholder="Product Title"
              onPressEnter={() => onSearch()}
              name="productTitle"
              value={localParams.productTitle}
              size="large"
              onChange={handleChangeQuery}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Space direction="vertical">
            <div>{t("Product Variant title")}:</div>
            <Input
              placeholder="Product Variant Title"
              onPressEnter={() => onSearch()}
              name="productVariantTitle"
              value={localParams.productVariantTitle}
              size="large"
              onChange={handleChangeQuery}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Space direction="vertical">
            <div>{t("Status")}:</div>
            <Select
              name="status"
              onChange={handleChangeField}
              value={params.status}
              placeholder="All status"
              options={Object.values(ORDER_FILTER_STATUS)}
              size="large"
              className="status-select"
              isTranslate={false}
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Space direction="vertical">
            <div>{t("Financial status")}:</div>
            <Select
              name="financialStatus"
              onChange={handleChangeField}
              value={params.financialStatus}
              placeholder="All status"
              options={Object.values(ORDER_FILTER_FINANCIAL_STATUS)}
              size="large"
              className="financial-status-select"
              isTranslate={false}
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Space>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
          <Space direction="vertical">
            <div>{t("Fulfillment status")}:</div>
            <Select
              name="fulfillmentStatus"
              onChange={handleChangeField}
              value={params.fulfillmentStatus}
              placeholder="All status"
              options={[
                { name: "Fulfilled", value: "fulfilled" },
                { name: "Not fulfilled", value: "notFullfilled" },
              ]}
              size="large"
              isTranslate={false}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={24} sm={24} md={16} lg={12} xl={8} xxl={4}>
          <Space direction="vertical">
            <div>{t("Date")}:</div>
            <RangePicker
              name="createdAt"
              value={params.createdAt}
              onChange={handleChangeDate}
              ranges={{
                Today: [moment(), moment()],
                Yesterday: [
                  moment().subtract(1, "day"),
                  moment().subtract(1, "day"),
                ],
                "Last 7 days": [
                  moment().startOf("week"),
                  moment().endOf("week"),
                ],
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "This Month": [
                  moment().startOf("month"),
                  moment().endOf("month"),
                ],
              }}
              size="large"
              style={{ width: "100%" }}
            />
          </Space>
        </Col>
        <Col xs={26} sm={14} md={10} lg={8} xl={6} xxl={4}>
          <Space direction="vertical">
            <div>{t("Customer Name")}:</div>
            <Input
              placeholder="Customer Name"
              onPressEnter={() => onSearch()}
              name="customerName"
              value={localParams.customerName}
              size="large"
              onChange={handleChangeQuery}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={26} sm={14} md={10} lg={8} xl={6} xxl={4}>
          <Space direction="vertical">
            <div>{t("Customer Email Address")}:</div>
            <Input
              placeholder="Email Address"
              onPressEnter={() => onSearch()}
              name="customerEmail"
              value={localParams.customerEmail}
              size="large"
              onChange={handleChangeQuery}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={26} sm={14} md={10} lg={8} xl={6} xxl={4}>
          <Space direction="vertical">
            <div>{t("Shipping Address")}:</div>
            <Input
              placeholder="Shipping Addresss"
              onPressEnter={() => onSearch()}
              name="shippingAddress"
              value={localParams.shippingAddress}
              size="large"
              onChange={handleChangeQuery}
              allowClear
            />
          </Space>
        </Col>
        <Col xs={26} sm={14} md={10} lg={8} xl={6} xxl={4}>
          <Space direction="vertical">
            <div>{t("Risk Status")}:</div>
            <Select
              name="risk"
              onChange={handleChangeField}
              value={params.risk}
              placeholder="All Risks"
              options={Object.values(ORDER_RISK_STATUS)}
              size="large"
              className="status-select"
              isTranslate={false}
              showSearch
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Space>
        </Col>
      </Row>
    </SearchPanelStyled>
  );
}

SearchPanel.propTypes = {
  handleGetList: PropTypes.func,
};

export default SearchPanel;
