import { Button, Carousel, Col, Divider, List, message, Modal, Row, Space, Table, Card, Descriptions } from "antd";
import purchaseApi from "apis/purchaseApi";
import React, { useEffect, useState } from "react";

const PopDetails = ({file, display, onClosePOP}) => {
   console.log('file.proofOfPurchase[0].fileUrl',file)
//    console.log('isSame',isSame)
let isSame = 0;
const allEqual = arr => arr.every(val => val.id === arr[0].id);
if (file?.proofOfPurchase?.length) {
    console.log('HAS')
    let result = allEqual(file.proofOfPurchase);
    if (result) {
        isSame = 1;
    }
}       
console.log('result',isSame);
    return(<>
        <Modal
            visible={display}
            onCancel={() =>
                onClosePOP()}
            footer={[
                <Button type="primary" onClick={onClosePOP}>
                    Close
                </Button>
            ]}
        >
            {isSame ?
                 <Card title="Check-In Details" bordered={false}>
                 <div style={{display: "block"}}>
                   <iframe width="100%" height={500} frameBorder="0" src={`${process.env.REACT_APP_API_URL == "http://localhost:7000" ? process.env.REACT_APP_API_URL  + '/api/media/tmp/' + file.proofOfPurchase[0]?.fileUrl : process.env.REACT_APP_API_URL  + '/media/' + file.proofOfPurchase[0]?.fileUrl}`} title="proof-of-purchase"></iframe> 
                 </div>      
             </Card>
                
            : 
            <Card title="Check-In Details" bordered={false}>
                <div style={{display: "block"}}>
                {file?.proofOfPurchase?.map(pop => {
                return <iframe width="100%" height={500} frameBorder="0" src={`${process.env.REACT_APP_API_URL == "http://localhost:7000" ? process.env.REACT_APP_API_URL  + '/api/media/tmp/' + pop?.fileUrl : process.env.REACT_APP_API_URL  + '/media/' + pop?.fileUrl}`} title="proof-of-purchase"></iframe>  
            })}
            </div>    
             </Card>
            }
           
        </Modal>
    </>);
};

export default PopDetails;
