import { ArrowLeftOutlined } from "@ant-design/icons";
import { Card, Divider, Spin, Typography } from "antd";
import ordersApi from "apis/ordersApi";
import shipmentsApi from "apis/shipmentsApi";
import Box from "components/box/Box";
import Container from "components/container/Container";
import LinkButton from "components/linkButton/LinkButton";
import TitleHeader from "components/title-header";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Path from "../../commons/breadcrumbs";
import queryString from "query-string";

const { Title, Paragraph } = Typography;

const CreatedShipment = ({ location }) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [imgURL, setImgURL] = useState("");

    const { trackingNumber, orderId, shopifyNotification, etd } = queryString.parse(location.search);

    console.log("ETD", etd);

    const requestLabel = async (zplString) => {
        setIsLoading(true);
        const response = await fetch(`https://api.labelary.com/v1/printers/8dpmm/labels/6x12/0/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: JSON.stringify(zplString),
        });

        const responseBlob = await response.blob();
        const imgURL = URL.createObjectURL(responseBlob);
        console.log("this is img", imgURL);
        setImgURL(imgURL);
        setIsLoading(false);
    };

    const getShipment = async () => {
        try {
            const params = {
                page: 1,
                pageSize: 1,
                search: trackingNumber,
                fields: ["id", "labelBase64", "invoice_base64"],
            };
            const { shipment } = await shipmentsApi.getShipments(params);
            if (etd === false) {
                try {
                    const payload = {
                        id: shipment[0]?.id,
                        documentType: ["invoice_base64", "label_base64"],
                    };
                    const resPrint = await shipmentsApi.printShipmentDocuments(payload);
                    window.location = "/shipments";
                    console.log(resPrint);
                } catch (error) {
                    console.log(error);
                }
            }
            try {
                const payload = {
                    id: shipment[0]?.id,
                    documentType: ["label_base64"],
                };
                const resPrint = await shipmentsApi.printShipmentDocuments(payload);
                console.log(resPrint);
                return (window.location = "/shipments");
            } catch (error) {
                console.log(error);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const postFullFillOrder = async () => {
        try {
            const payload = {
                orderId,
            };

            const data = {
                shopifyNotification: true,
            };
            const res = await ordersApi.postFullFillOrder(payload, queryString.stringify(data));
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        requestLabel(location.state.label[0]);
        if (process.env.REACT_APP_DEVELOPMENT === "false") {
            getShipment();
            postFullFillOrder();
        }
    }, []);

    return (
        <>
            <TitleHeader
                listOfBreadcrumb={[
                    { name: "Home", path: "/" },
                    {
                        name: Path.CREATED_SHIPMENT.name,
                    },
                ]}
                title={t(Path.CREATED_SHIPMENT.name)}
            />
            <Box style={{ height: "100%" }}>
                <Container>
                    <LinkButton to="/shipments">
                        <ArrowLeftOutlined /> Back To All Shipment
                    </LinkButton>
                    <Divider />
                    <Title>
                        Tracking Number : {location.state.trackingNumber} | {location.state.courier.toUpperCase()}
                    </Title>
                    <Paragraph>Update Status: {location.state.orderUpdateStatus}</Paragraph>
                    <Paragraph>Account Number: {location.state.accountNumber}</Paragraph>
                    <Card>
                        <a
                            href={`data:application/pdf;base64,${location.state.invoice}`}
                            title="invoice"
                            style={{
                                flexGrow: 1,
                                margin: 0,
                                padding: 0,
                                height: "100%",
                                width: "100%",
                            }}
                            download={`${location.state.courier.toUpperCase()} -${location.state.trackingNumber}`}
                        >
                            Download Invoice
                        </a>
                    </Card>
                    <Card
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "10px",
                        }}
                    >
                        {isLoading ? <Spin /> : <img src={imgURL} width={500} alt="zplLabel" />}
                    </Card>
                </Container>
            </Box>
        </>
    );
};

export default CreatedShipment;
