import { Table, Typography, Image, Avatar, Spin,Modal,Button,message } from "antd";
import produce from "immer";
import { debounce } from "lodash";
import { TableContainer } from "./index.style";
import productsApi from "apis/productsApi";

const { Column } = Table;
const { Text } = Typography;

const AllTable = ({
  params,
  setParams,
  setSelectedRows,
  selectedRows,
  dataList,
  setDataList,
  gettingProductExportList,
  isLoading,
  setIsLoading,
  setDeleteExport,
  isDeleteExport,
  isDeleteLoading,
  setIsDeleteLoading
}) => {

  const pageSizeOnChange = debounce((page, pageSize) => {
    const newParam = produce(params, (draft) => {
      draft.page = page;
      draft.pageSize = pageSize;
    });
    setParams(newParam);
    gettingProductExportList(newParam);
  }, 500);

  // removeExport();

  const onCancelRemove = (event) => {
    setDeleteExport(false);
  };

  const removeExport = async () => {
    setIsDeleteLoading(true);
    try {
      const list = dataList.filter((obj) => selectedRows.includes(obj.key))
      let c=0;
      for( var a = 0; a < list.length; a++) {
                let id = list[a].id;
                await productsApi.removeProductToExport({id})
                .then(() => {
                  c++;
                  if(c==list.length) {
                    message.success("Succesfully removed", 10);
                    setTimeout(
                      () =>
                      setIsDeleteLoading(false),
                      setDeleteExport(false),
                      gettingProductExportList(params),
                      500
                    );
                   
                   }
                })
         
      }

    } catch (error) {
      message.error(error.message);
      setIsDeleteLoading(false);
    }
  }

  const removeExportModal = async (id) => {
    if (!selectedRows.length) {
      return message.warning("Please Select One Of The Products", 5);
    }
    try {
      // console.log("remove modal", id);
      // console.log("delete export", isDeleteExport);
      // setActiveExportVariant(draftExport);
    } catch (error) {
      console.log(error.message);
    } finally {
      setDeleteExport(true);
    }
  };


  return (
    <>
    
    <Modal
        title="Remove Product"
        visible={isDeleteExport}
        onOk={removeExport}
        okText="Yes"
        onCancel={onCancelRemove}
        width={700}
      >
        <div style={{ marginTop: "26px" }}>Are you sure you want to remove selected product/s?</div>
        {isDeleteLoading ? (
        <div style={{ textAlign: "center" }}>
              <Spin />
        </div> ) : null }
      </Modal>
      <TableContainer
        dataSource={dataList}
        rowSelection={{
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys);
            // console.log('datalist',dataList)
            // console.log('selectedRowKeys',selectedRowKeys)
          },
        }}
        loading={isLoading}
        pagination={{
          pageSizeOptions: ["10", "20", "30"],
          showSizeChanger: true,
          defaultPageSize: 10,
          onChange: pageSizeOnChange,
          current: params.page,
          pageSize: params.pageSize,
          total: params.totalPage,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
        }}
      >
        <Column
          title="Product"
          key="productId"
          className="product-title-cell"
          render={(record) => (
            <Text style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}>
              {`${record.products.title || ''} ${record.variants.title || ''}`}
            </Text>
          )}
        />
        <Column
          title="Image"
          key="image"
          render={(value) => {
            let v = value.variants;
            if (v.images.length == 0 && value.image == null) {
              return (
                <Avatar
                  size={82}
                  shape="square"
                  src={"/no-image.png"}
                />
              );
            }
            return (
              <Image
                width={80}
                src={v.images[0] ? v.images[0] : value.image}
              />
            );
          }}
        />
        <Column
          title="Action"
          key="delete"
          render={(value) => {
            return (
              <div>
                  <Button
                    key={value.id}
                    onClick={event => {
                      removeExportModal();
                    }}
                    size="small"
                    style={{ margin: "3px" }}
                    type="primary"
                    // to={Path.ORDER_DETAIL.pathParam(ord.orderId)}
                  >
                    {/* {value.id} */}
                    Delete
                  </Button>
              </div>
            );
          }}
        />
         {/* <Column
          title="Export Shop"
          key="exportShopId"
          render={(value) => value.export_shops.name}
       /> */}
      </TableContainer>
    </>
  );
};

export default AllTable;
