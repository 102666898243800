import {
  Button,
  Calendar,
  Card,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Typography,
  message,
  Select,
  Checkbox,
  Descriptions,
  Upload
} from "antd";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill from "react-quill";
import Path from "../../commons/breadcrumbs";
import productsApi from "../../apis/productsApi";
import produce from "immer";
import { MinusCircleOutlined, PlusCircleOutlined,InboxOutlined } from '@ant-design/icons';
import { PRODUCT_OPTIONS } from "./utils";
import { toUpper } from "lodash";
import { mediaApi } from "apis/mediaApi";
const { Title, Paragraph } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;



const CreateProduct = ({ history }) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    onlineStatusAvailabilityModal: false,
    editSeo: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isContinue, setIsContinue] = useState(true);
  const [createNewProduct, setCreateNewProduct] = useState({
    title: "",
    vendor: "",
    type: "",
    tags: [],
    note: "",
    options: [], //this is the main array, the main options
    optionOneChoices: [''], //these are the choices
    optionTwoChoices: [''], //these are the choices
    optionThreeChoices: [''], //these are the choices
    optionFourChoices: [''], //these are the choices
    optionFiveChoices: [''], //these are the choices
    publishOnShopify:"", 
    bodyHtml : "", 
    createShopifyProduct : "", 
    source : "",
    seoTitle : "", 
    seoDescription : "", 
    seoUrl : "",
    status : "",
    language : "",
    genre : "",
    name : "",
    month:0,
    year: 0,
    purchaseLink : "",
    preOrderShipDateText : "",
    preOrderEndDate: "",
    preOrderShipDate : "",
    shopeeSgLink : "",
    shopeeMyLink : "",
    shopeeMxLink : "",
    productTopBanner : "",
    brandDescription : "", 
    addOnInfo1 : "",
    addOnInfo2 : "",
    handle: "",
    source:"custom",
    variants: [
      {
        title: "",
        sku: "",
        sourceUrl: "",
        options: [],
        price: 0,
        weight:0,
        hsCode:"",
        barCode:"",
        compareAtPrice:0,
        costPrice:0,
        trackQuantity:"",
        continueSellingOos:false,
        inventoryStock:false,
        physicalProduct:false,
        preOrderShipDateText:"",
        preOrderStartDate:"",
        preOrderEndDate:"",
        materials:[],
        size:"",
        month:0,
        year:0,
        images:[],
        image:[]
      },
    ],
    publishOnShopify: false,
    bodyHtml:
      "<h1>Title Here</h1><br><h2>Sub-Title Here</h2><p>Please tell us more about this product</p>",
  });

  const booleanOptions = [
    { label: "Continue selling", value: "continueSellingOos" },
    { label: "Inventory stock", value: "inventoryStock" },
    { label: "Physical Product", value: "physicalProduct" },
  ]

  const OnlineStatusAvailability = () => {
    const handleOk = () => {
      setModal((prev) => {
        return { ...prev, onlineStatusAvailabilityModal: false };
      });
    };
    const handleCancel = () => {
      // what you want for cancel
      setModal((prev) => {
        return { ...prev, onlineStatusAvailabilityModal: false };
      });
    };
    return (
      <Modal
        title={t("Schedule Online Store availability")}
        onOk={handleOk}
        onCancel={handleCancel}
        visible={modal.onlineStatusAvailabilityModal}
        width={1000}
      >
        <Form>
          <Row>
            <Space>
              <DatePicker />
            </Space>
          </Row>
          <Row>
            <Calendar fullscreen={false} />
            <Calendar fullscreen={false} mode="month" />
          </Row>
        </Form>
      </Modal>
    );
  };


  const createProductBtn = async () => {
    setIsLoading(true);
    try {
      //
      if (!isContinue || createNewProduct.options.length < 1 || createNewProduct.tags.length < 1 ) {
        message.error('Please fill up required values');
        setIsLoading(false);
        return;
      }
      let mainCtr = 0;
      await createNewProduct.variants.map(async (variant,index1) => {
        let imgArray = [];
        if(variant.image.length > 0) {
          let ctr = 0;
          for (let a=0; a<variant.image.length;a++) {
            const formData = new FormData();
            formData.append("file", variant.image[a].originFileObj);
            await mediaApi.uploadImage(formData)
            .then(async (uploadedIMage) => {
              console.log("RESPONSE FROM UPLOADED IMAGE!!!", uploadedIMage);
              await imgArray.push(uploadedIMage.url);
              ctr++;
            });
            if (ctr==variant.image.length) {
              const prod = await produce(createNewProduct, async (draft) => {
                             draft.variants[index1]['images']= imgArray;
                          });
               await setCreateNewProduct(prod);
               mainCtr++;
               console.log('increment mainCtr', mainCtr);
            }
          }
          if (mainCtr == createNewProduct.variants.length){
            console.log('mainCtr', mainCtr);
            const res = await productsApi.createProduct(
            JSON.stringify(createNewProduct)
          );
          console.log('res',res);
          message.success("Product has been created", 3);
          setIsLoading(false);
          
          }
        }
        else
        {
          console.log('I AM CALLED');
          const res = await productsApi.createProduct(
            JSON.stringify(createNewProduct)
          );
          console.log('res',res);
          message.success("Product has been created", 3);
          setIsLoading(false);
        }
      })
    } catch (error) {
      message.error(error, 3);
      setIsLoading(false);
    }
  };

  const inputChangeHandler = (event) => {
    console.log('event', event)
    setCreateNewProduct((prev) => {
      return { ...prev, [event.target.name]: event.target.value };
    });
  };

  const variantsInputHandler = (event, indexVar) => {
    console.log('event', event);
    const prod = produce(createNewProduct, (draft) => {
      draft.variants[indexVar][event.target.name] = event.target.value;
    });
    return setCreateNewProduct(prod);
  };

  const variantsImageHandler = (event, indexVar) => {
    console.log('event', event.fileList);
    const prod = produce(createNewProduct, (draft) => {
      draft.variants[indexVar]['image'] = event.fileList;
    });
    return setCreateNewProduct(prod);
  };

  const variantsAddOptHandler = (event, indexVar) => {

    if (event.length > 0) {
      const prod = produce(createNewProduct, (draft) => {
        booleanOptions.map((boolOpt) => {
          if(event.includes(boolOpt.value)) {
            draft.variants[indexVar][boolOpt.value] = true;
          } 
          else {
            draft.variants[indexVar][boolOpt.value] = false;
          }
        })
      });
      return setCreateNewProduct(prod);
    }
  };

  const addVariantHandler = () => {
    const newVariantObj = {
      ...createNewProduct.variants[0],
    };
    message.success("Variant Added", 1);
    return setCreateNewProduct((prev) => {
      return { ...prev, variants: [...prev.variants, newVariantObj] };
    });
  };

  const removeVariantHandler = (varIndexCard) => {
    // this function for add another card of create variant
    message.info("Variant Deleted", 1);
    return setCreateNewProduct((prev) => {
      return {
        ...prev,
        variants: prev.variants.filter(
          (varItem, index) => index !== varIndexCard
        ),
      };
    });
  };

  const variantOptionsHandler = (event, varIndexCard, optionIndex) => {
    const value = event.target.value;
    switch (optionIndex) {
      case 0:
        if(!createNewProduct.optionOneChoices.includes(value)) {
          message.error('Option must be one of the option choices');
          event.target.style.border = "solid red 2px";
          setIsContinue(false);

        } else {
          event.target.style.border = "";
          setIsContinue(true);
        }
        break;
      case 1:
        if(!createNewProduct.optionTwoChoices.includes(value)) {
          message.error('Option must be one of the option choices');
          event.target.style.border = "solid red 2px";
          setIsContinue(false);
        } else {event.target.style.border = ""; setIsContinue(false);}
        break;
      case 2:
        if(!createNewProduct.optionThreeChoices.includes(value)) {
          message.error('Option must be one of the option choices');
          event.target.style.border = "solid red 2px";
          setIsContinue(false);
        } else {event.target.style.border = ""; setIsContinue(false);}
        break;
      case 3:
        if(!createNewProduct.optionFourChoices.includes(value)) {
          message.error('Option must be one of the option choices');
          event.target.style.border = "solid red 2px";
          setIsContinue(false);
        } else {event.target.style.border = "";setIsContinue(false);}
        break;
      case 4:
        if(!createNewProduct.optionFiveChoices.includes(value)) {
          message.error('Option must be one of the option choices');
          event.target.style.border = "solid red 2px";
          setIsContinue(false);
        } else {event.target.style.border = ""; setIsContinue(false);}
        break;
    
      default:
        break;
    }
    const newValue = produce(createNewProduct, (draft) => {
      draft.variants[varIndexCard].options[optionIndex] = value;
    });
    console.log('VAROPTS', newValue)
    return setCreateNewProduct(newValue);
  };

  const optionsHandler = async (event, optionIndex, optionChoices) => {
    const value = event.target.value;
    const newValue = produce(createNewProduct, (draft) => {
      draft[optionChoices][optionIndex]= value;
    });
    return setCreateNewProduct(newValue);
  };

  const addTextBox = (optionChoices, index, optionIndex) => {
    if (createNewProduct[optionChoices][optionIndex] !== '') {
      const value ='';
      const newValue = produce(createNewProduct, (draft) => {
        draft[optionChoices].push(value);
      });
      return setCreateNewProduct(newValue);
      }
    else{
      message.error('Add option value.',2);
    }
  };

  const removeOption = (optionChoices, index, optionIndex) => {
    if (createNewProduct[optionChoices][optionIndex] !== '') {
      const value ='';
      const newValue = produce(createNewProduct, (draft) => {
        draft[optionChoices].splice(optionIndex,1);
      });
      return setCreateNewProduct(newValue);
      }
  };
  console.log(createNewProduct);

  const optionDisplay = (index,item,optionChoices) => {
      return <Form.Item required="true"><Input.Group required="true">
      <br></br>
      <Descriptions title={toUpper(item) + ' options'}  layout="vertical"></Descriptions>
      { createNewProduct[optionChoices].map((opt,ind)=> (
            <Input.Group>
                <Input style={{ width: '50%', margin: "1px", }} 
                  // required="true"
                  value={
                  opt ? opt : ""
                    }
                  placeholder="Add option value"
                  onChange={(event) =>
                      optionsHandler(
                        event,
                        ind,
                        optionChoices
                      )}
                    />
               {ind == createNewProduct[optionChoices].length-1 ? 
                  <PlusCircleOutlined
                        style={{position:"relative",top: "4px",
                        margin: "0 8px",
                        color: "#999",
                        fontSize: "24px",
                        cursor: "pointer",
                        transition: "all 0.3s"}}
                        onClick={(event) =>
                        addTextBox(
                          optionChoices,
                          index,
                          ind
                        )}
                      />
                    :
                    <MinusCircleOutlined
                    style={{position:"relative",top: "4px",
                    margin: "0 8px",
                    color: "#999",
                    fontSize: "24px",
                    cursor: "pointer",
                    transition: "all 0.3s"}}
                    onClick={() => removeOption( optionChoices,
                      index,
                      ind)}
                  />  }
            </Input.Group>         
  ))}
  {/* <Button>Done</Button> */}
   </Input.Group></Form.Item>
  }

  return (
    <>
      <OnlineStatusAvailability />
      <TitleHeader
        listOfBreadcrumb={[
          { name: "Home", path: "/" },
          { name: Path.PRODUCTS.name, path: Path.PRODUCTS.pathname },
          {
            name: Path.CREATE_PRODUCT.name,
          },
        ]}
        title={t("Create Product")}
        currentHistory={history}
      />
      <Box>
        <Title level={3}>{t("Create product")}</Title>
        <Paragraph style={{ marginBottom: "24px" }}>
          {t(
            "The details displayed often include size, color, price, shipping information, reviews, and other relevant information customers may want to know before making a purchase."
          )}
        </Paragraph>
        <Form 
           layout="vertical"
           onFinish={createProductBtn}
          //  onFinishFailed={onFinishFailed}
        >
          <Row gutter={24}>
            <Col className="left-row" md={16}>
              <Container>
                {/* Title Card  */}
                <Card style={{ marginBottom: "24px" }}>
                  <Title level={5}>{t("Product")}</Title>
                  <Row gutter={16}>
                    <Col md={8}>
                      <Form.Item label={t("Title")} name="title" rules={[
                          {
                            required: true,
                          },
                        ]}
                        hasFeedback>
                        <Input
                          name="title"
                          value={createNewProduct.title}
                          onChange={inputChangeHandler}
                          placeholder="Product name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("Vendor")} name="vendor" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                        <Input
                          name="vendor"
                          value={createNewProduct.vendor}
                          onChange={inputChangeHandler}
                          placeholder="Vendor name"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("Product Type")}  name="type" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                        <Input
                          name="type"
                          value={createNewProduct.type}
                          onChange={inputChangeHandler}
                          placeholder="Eg. Mensware"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  
                  <Row gutter={16}>
                  <Col md={24}>
                      <Form.Item label={t("Tags")}  name="tags" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                      <Select
                          
                          mode="tags"
                          name="tags"
                          style={{ width: "100%" }}
                          placeholder="Type tag values then click it to add"
                          onChange={(value) =>
                               setCreateNewProduct((prev) => {
                                 return { ...prev, tags: value};
                               })
                              }
                          required="true"
                        > 
                        </Select>
                      </Form.Item>
                    
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={24}>
                      <Form.Item label={t("Note")} name="note" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                        <TextArea
                          name="note"
                          rows={4}
                          value={createNewProduct.note}
                          onChange={inputChangeHandler}
                          placeholder="You can leave any note to admin or courier here..."
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                   <Col md={8}>
                      <Form.Item label={t("Status")} name="status" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                      <Select
                          name="status"
                          required="true"
                          placeholder="Active or draft"
                          onChange={(value) =>
                               setCreateNewProduct((prev) => {
                                 return { ...prev, status: value};
                               })
                              }
                          options={[
                            {
                              value: "true",
                              label: "active",
                            },
                            {
                              value: "false",
                              label: "draft",
                            }
                        ]}
                        > 

                        </Select>
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                    <Form.Item
                    name="genre"
                    label="Genre"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback
                  >
                        <Input
                          name="genre"
                          value={createNewProduct.genre}
                          onChange={inputChangeHandler}
                          placeholder="Product Genre"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("Language")}>
                        <Input
                          name="language"
                          value={createNewProduct.language}
                          onChange={inputChangeHandler}
                          placeholder="Language"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                   <Col md={8}>
                      <Form.Item label={t("SEO Title")}>
                        <Input
                          name="seoTitle"
                          value={createNewProduct.seoTitle}
                          onChange={inputChangeHandler}
                          placeholder="Product Seo"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("SEO URL")}>
                        <Input
                          name="seoUrl"
                          value={createNewProduct.seoUrl}
                          onChange={inputChangeHandler}
                          placeholder="Product SEO Url"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("SEO Description")}>
                        <TextArea
                          name="seoDescription"
                          rows={1}
                          value={createNewProduct.seoDescription}
                          onChange={inputChangeHandler}
                          placeholder="SEO Description"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                   <Col md={8}>
                      <Form.Item label={t("Purchase Link")}>
                        <Input
                          name="purchaseLink"
                          value={createNewProduct.purchaseLink}
                          onChange={inputChangeHandler}
                          placeholder="Product Purchase Link"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("Pre Order End Date")} name="preOrderEndDate" rules={[
                            {
                              required: true,
                            },
                          ]}
                          hasFeedback>
                        <DatePicker      
                          name="preOrderEndDate"         
                          value={createNewProduct.preOrderEndDate}
                          onChange={(date) =>
                            setCreateNewProduct((prev) => {
                              return { ...prev, preOrderEndDate: date};
                            })
                           }
                          placeholder="Product Pre Order End Date"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                      <Form.Item label={t("Pre Order Ship Date")} name="preOrderShipDate" rules={[
                            {
                              required: true,
                            },
                          ]}
                          hasFeedback>
                        <DatePicker 
                        name="preOrderShipDate"
                         value={createNewProduct.preOrderShipDate}
                         placeholder="Product Pre Order Ship Date"
                         onChange={(date) =>
                          setCreateNewProduct((prev) => {
                            return { ...prev, preOrderShipDate: date};
                          })
                         }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                   <Col md={8}>
                      <Form.Item label={t("Shopee MY Link")}>
                        <Input
                          name="shopeeMyLink"
                          value={createNewProduct.shopeeMyLink}
                          onChange={inputChangeHandler}
                          placeholder="Shopee MY Link"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                    <Form.Item label={t("Shopee SG Link")}>
                        <Input
                          name="shopeeSgLink"
                          value={createNewProduct.shopeeSgLink}
                          onChange={inputChangeHandler}
                          placeholder="Shopee SG Link"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                    <Form.Item label={t("Shopee MX Link")}>
                        <Input
                          name="shopeeMxLink"
                          value={createNewProduct.shopeeMxLink}
                          onChange={inputChangeHandler}
                          placeholder="Shopee Mx Link"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                   <Col md={8}>
                      <Form.Item label={t("Product Top Banner URL")}>
                        <Input
                          name="productTopBanner"
                          value={createNewProduct.productTopBanner}
                          onChange={inputChangeHandler}
                          placeholder="File URL for banner in product page"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                    <Form.Item label={t("Brand Description")} name="brandDescription" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                        <Input
                        name="brandDescription"
                          value={createNewProduct.brandDescription}
                          onChange={inputChangeHandler}
                          placeholder="Product Brand Description"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={8}>
                    <Form.Item label={t("Additional Information")} name="addOnInfo1" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                        <Input
                        name="addOnInfo1"
                          value={createNewProduct.addOnInfo1}
                          onChange={inputChangeHandler}
                          placeholder="Other information"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col md={24}>
                      <Form.Item
                        label={t("Description")}
                        // style={{ marginBottom: 0 }}
                      >
                      <ReactQuill
                        theme="snow"
                        value={createNewProduct.bodyHtml}
                        onChange={(content) => {
                          return setCreateNewProduct((prev) => {
                            return { ...prev, bodyHtml: content };
                          });
                        }}
                      />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col md={18}>
                      <Form.Item label={t("Options")} name="options" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                        <Select
                          name="options"
                          mode="tags"
                          style={{ width: "100%" }}
                          placeholder="Eg. Size, Pattern, Color"
                          onChange={(value) =>
                             createNewProduct.options.length < 1 ? 
                                setCreateNewProduct((prev) => {
                                  return { ...prev, options: value};
                                })
                              :
                               createNewProduct.options.map((recentOpt, index) => {
                                if(!value.includes(recentOpt)) {
                                  switch(index){
                                    case 0:
                                    setCreateNewProduct((prev) => {
                                      return { ...prev, options: value, optionOneChoices: [""] };
                                    })
                                    break;
                                    case 1:
                                    setCreateNewProduct((prev) => {
                                      return { ...prev, options: value, optionTwoChoices: [""]};
                                    })
                                    break;
                                    case 2:
                                    setCreateNewProduct((prev) => {
                                      return { ...prev, options: value, optionThreeChoices: [""] };
                                    })
                                    break;
                                    case 3:
                                    setCreateNewProduct((prev) => {
                                      return { ...prev, options: value, optionFourChoices: [""] };
                                    })
                                    break;
                                    case 4:
                                    setCreateNewProduct((prev) => {
                                      return { ...prev, options: value, optionFiveChoices: [""] };
                                    })
                                    break;
                                  }
                                }
                                else {
                                  setCreateNewProduct((prev) => {
                                    return { ...prev, options: value};
                                  })
                                }
                              })
                              
                          }
                          options={PRODUCT_OPTIONS}
                        > 
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Col md={20}> 
                  {createNewProduct.options.map((item,index) =>{

                   switch (index) {
                    case 0:
                      return optionDisplay(index,item,'optionOneChoices');
                    case 1:
                      return optionDisplay(index,item,'optionTwoChoices');
                    case 2:
                      return optionDisplay(index,item,'optionThreeChoices');
                    case 3:
                      return optionDisplay(index,item,'optionFourChoices');
                    case 4:
                      return optionDisplay(index,item,'optionFiveChoices');
                    default:
                      break;
                   }         
})}
            
                  </Col>
                </Card>

                <Divider />
                <Space align="center" direction="horizontal">
                  <Button
                    loading={isLoading}
                    // onClick={createProductBtn}
                    type="primary"
                    htmlType="submit" 
                  >
                    Create Product
                  </Button>
                  <Button type="danger">Discard</Button>
                </Space>
              </Container>
            </Col>

            {/* Right column side start here */}
            <Col className="right-row" md={8}>
              <Container>
                {/* variants  */}
                {createNewProduct.variants.map((cardVariant, varIndexCard) => (
                  <Card key={varIndexCard} style={{ marginBottom: "24px" }}>
                    <Title level={5}>{t(`Variant ${varIndexCard + 1}`)}</Title>
                    <Row gutter={16}>
                      <Col md={24}>
                        <Form.Item
                          label={t("Title")}
                          extra="Eg. With Flower Pattern"
                          required="required"
                        >
                          <Input
                            required="true"
                            name="title"
                            value={
                              createNewProduct.variants[varIndexCard].title
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={12}>
                        <Form.Item
                          label={t("SKU")}
                          extra="Each product has their own unique id"
                          name="sku" rules={[
                            {
                              required: true,
                            },
                          ]}
                          hasFeedback>
                          <Input
                          name="sku"
                            value={createNewProduct.variants[varIndexCard].sku}
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item
                          label={t("HS CODE")}
                          extra="Product HS code"
                        >
                          <Input
                            name="hsCode"
                            value={createNewProduct.variants[varIndexCard].hsCode}
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={24}>
                        <Form.Item
                          label={t("Barcode")}
                          extra="Product's Bar Code"
                        >
                          <Input
                            name="barCode"
                            value={
                              createNewProduct.variants[varIndexCard].barCode
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>

                      <Col md={24}>
                        <Form.Item
                          label={t("Source URL")}
                          extra="Product's Link"
                          name="sourceUrl" rules={[
                            {
                              required: true,
                            },
                          ]}
                          hasFeedback>
                          <Input
                            name="sourceUrl"
                            value={
                              createNewProduct.variants[varIndexCard].sourceUrl
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col md={12}>
                        <Form.Item label={t("Selling Price")} name="price" rules={[
                            {
                              required: true,
                            },
                          ]}
                          hasFeedback>
                          <Input
                          name="price"
                            type="number"
                            step={0.05}
                            addonBefore={"MYR"}
                            value={
                              createNewProduct.variants[varIndexCard].price
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item label={t("Discounted Price")}  name="compareAtPrice" rules={[
                      {
                        required: true,
                      },
                    ]}
                    hasFeedback>
                          <Input
                            name="compareAtPrice" 
                            type="number"
                            step={0.05}
                            addonBefore={"MYR"}
                            value={
                              createNewProduct.variants[varIndexCard].compareAtPrice
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item label={t("Weight")} name="weight" rules={[
                          {
                            required: true,
                          },
                        ]}
                        hasFeedback>
                          <Input
                            name="weight"
                            type="number"
                            step={0.01}
                            addonAfter="KG"
                            value={
                              createNewProduct.variants[varIndexCard].weight
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12}>
                        <Form.Item label={t("Quantity")}  name="trackQuantity" rules={[
                          {
                            required: true,
                          },
                        ]}
                        hasFeedback>
                          <Input
                            name="trackQuantity"
                            type="number"
                            step={0.05}
                            value={
                              createNewProduct.variants[varIndexCard].trackQuantity
                            }
                            onChange={(event) => {
                              variantsInputHandler(event, varIndexCard);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={24}>
                        <Form.Item label={t("Materials")} name="materials" rules={[
                            {
                              required: true,
                            },
                          ]}
                          hasFeedback>
                          <Select
                            name="materials"
                            mode="tags"
                            // style={{ width: "100%" }}
                            placeholder="Type material values then click it to add"
                            onChange={(value) =>
                              setCreateNewProduct((prev) => {
                                return { ...prev, materials: value};
                              })
                             }
                          > 
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={24}>
                      <Form.Item
                            name="additionalCourierOptions"
                            label="Additional Options"
                        >
                            <Checkbox.Group
                                name="optionBool"
                                layout={{ span: 8 }}
                                options={booleanOptions}
                                onChange={(event) => {
                                  variantsAddOptHandler(event, varIndexCard);
                                }}
                            >
                                <Checkbox />
                                
                            </Checkbox.Group>
                        </Form.Item>
                        <Form.Item name="image"
                            label="Variant Images">
                          <Dragger 
                            name="variantImage"
                            required="true"
                            multiple={true}
                            showUploadList={true}
                            value={
                              createNewProduct.variants[varIndexCard].image
                            }
                            customRequest={({onSuccess, onError, file}) => {
                              if (file) {
                                console.log('success');
                                onSuccess("Ok");
                              }
                            }}
                            onChange={(event) => {
                              variantsImageHandler(event, varIndexCard);
                            }}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                          </Dragger>
                        </Form.Item>
                      </Col>

                      {createNewProduct.options.map((item, index) => (
                            <Col md={24} key={index}>
                              <Form.Item label={t(`${item} Options`)} name="variant options"
                                hasFeedback>
                                <Input
                                  key={index + "choice"}
                                  value={
                                    createNewProduct.variants[varIndexCard].options
                                      ? createNewProduct.variants[varIndexCard]
                                          .options[index]
                                      : "Empty"
                                  }
                                  onChange={(event) =>
                                    variantOptionsHandler(
                                      event,
                                      varIndexCard,
                                      index
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                          ))}
                    </Row>
                    
                    {varIndexCard + 1 === createNewProduct.variants.length && (
                      <Space align="end">
                        <Button type="primary" onClick={addVariantHandler}>
                          Add Variant
                        </Button>
                        <Button
                          type="danger"
                          disabled={createNewProduct.variants.length < 2}
                          onClick={() => removeVariantHandler(varIndexCard)}
                        >
                          Delete
                        </Button>
                      </Space>
                    )}
                  </Card>
                ))}
              </Container>
            </Col>
          </Row>
        </Form>
      </Box>
    </>
  );
};

export default CreateProduct;
