import { Button, Card, Col, Row, Space, Table, Typography, Collapse, Spin, Modal, Divider,message, Steps,Input,Select } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { Link, useParams, useHistory } from "react-router-dom";
import ordersApi from "../../apis/ordersApi";
import Path from "../../commons/breadcrumbs";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Container from "../../components/container/Container";
import { moment } from "../../utils/funcs";
import { OrderDetailPageStyled, TitleBox } from "./index.styles";
import { ORDER_FILTER_STATUS } from "../orders/SearchPanel/util";
import LinkButton from "components/linkButton/LinkButton";
import queryString from "query-string";
import shipmentsApi from "apis/shipmentsApi";
import commentApi from "apis/commentApi";
import CombineModal from "pages/orders/CombineModal";
import useModal from "../../hooks/useModal";
import LocalStorage from "../../utils/storage";
import TextArea from "antd/lib/input/TextArea";
import URL from 'url';

const { Title } = Typography;
const { Panel } = Collapse;
const { Step } = Steps;

function sleep(ms) { 
    return new Promise(resolve => setTimeout(resolve, ms)); 
}

function OrderDetail() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState();
    const [orders, setOrders] = useState([]);
    const [combinedOrder, setCombinedOrder] = useState();
    const { id } = useParams();
    const history = useHistory();
    const [isModal, setIsModal] = useState(false);
    const [isModalCreateShipment, setIsModalCreateShipment] = useState(false);
    const [isPaymentModal, setIsPaymentModal] = useState(false);
    const [shipInfo, setShipInfo] = useState();
    const [orderInfoArr, setOrderInfoArr] = useState([]);
    const [shipping, setShipping] = useState([]);
    const [lineItems, setLineItems] = useState([]);
    const [otherCharges, setOtherCharges] = useState([]);
    const [createShipBtnTitle, setCreateShipBtnTitle] = useState("Create Shipment");
    const [openCombineModal, onCloseCombineModal, onOpenCombineModal] = useModal();
    const [shippedCombos, setShippedCombos] = useState([]);
    const [prettyNote, setPrettyNote] = useState();
    const [shipmentUrl, setShipmentUrl] = useState('');
    const [isFulfilled, setIsFulfilled] = useState(false);
    const [events, setEvents] = useState();
    const [isFulfillmentCancellable, setIsFulfillmentCancellable] = useState(false);
    const [refunds, setRefunds] = useState();
    const [totalRefunds, setTotalRefunds] = useState();
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [isCommentsLoading, setIsCommentsLoading] = useState(false);
    const [isPaymentsLoading, setIsPaymentsLoading] = useState(false);
    const [payment, setPayment] = useState({
        amount : "",
        gateway : "",
        transactionId : "",
        orderNumber : "",
        notes : ""
    });
    const [payments, setPayments] = useState([]);


    let note = "";

    const fullFillOrder = async (fullFill) => {
        // fullFill: Bool
        setIsLoading(true);
        try {
            const payload = {
                orderId: order.id,
            };

            const data = {
                shopifyNotification: fullFill,
            };
            const res = await ordersApi.postFullFillOrder(payload, queryString.stringify(data));
            setIsLoading(false);
            setIsModal(false);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setIsModal(false);
        }
    };

    const cancelFulfillment = async(returnMessage) => {
        // fullFill: Bool
        setIsLoading(true);
        try {
            const payload = {
                orderId: order.id,
            };
            const res = await ordersApi.postCancelFulfillment(payload);
            setIsFulfilled(false);
            setIsLoading(false);
            setIsModal(false);
            message.info(res.returnMessage.message);
            setIsFulfillmentCancellable(false);

        } catch (error) {
            console.log(error);
            setIsLoading(false);
            setIsModal(false);
            message.info(error);
        }
    };

    const nonShipmentItems = [
        "Korea Shopping Agent",
        "Courier ",
        "Signature ",
        "Domestic Shipping",
        "Bunjang",
        "Top-up",
        "Top up",
        "Topup",
        "Keyword Search",
        "Change Of",
        "Shipping top up",
        "Shipping topup",
        "Shipping top-up",
        "Service Fee",
    ].map((item) => item.toLowerCase());

    const separateLineItemsVsShipping = (line) => {      
        const items = [];
        const others = [];
       
        line.forEach(i => {                  
            const productName = i.productTitle ? i.productTitle : i.customProductName;              
            if(nonShipmentItems.some(s => productName.toLowerCase().includes(s)))
                others.push(i);
            // else
            //     items.push(i);
            else {
                //Remove refunded items from line items listing
                if (i.status !== "refunded")
                    items.push(i);
            }
            
        });        
        console.log('LINE ITEMS', items);
        console.log('OTHERS', others);
        return {items, others};
    };
    
    const buildShippingLine = (ord) => {       
        if(ord){
            let weight = Number(0); //based on grams
            
            for(let j=0; j < ord.lineItems.length; j++){
                weight = Number(weight) + Number(ord.lineItems[j].weight);
            }
            
            ord.shippingLines["weight"] = weight;
            ord.shippingLines["weightUnit"] = "KG";
            
            setShipping(ord.shippingLines);            
        }   
    }  

    const calculateDiscount = (items) => {        
        //get discount applications & index
        items.forEach(i => {            
            const discountApps = i.discountApplications;
            const discountAllocs = i.discountAllocations;
            let price = Number(i.price);            
            
            if(discountAllocs){              
                for(let j=0; j < discountAllocs.length; j++){
                    const index = discountAllocs[j].discount_application_index;
                    const discountApp = discountApps[index];
              
                    if( discountApp.value_type === 'fixed_amount' && discountApp.target_selection !== 'all'){
                        price = price - Number( discountApp.value);
                        console.log(price);
                    } else if (discountApp.value_type === 'percentage' && discountApp.target_selection !== 'all'){
                        const percentage = (100 - Number( discountApp.value))/100;
                        price = price * percentage;
                        console.log(price);
                    }      
                }
            }
            i["discountedPrice"] = price.toFixed(2);
        }); 
        
        setLineItems(items);
    };

    const getPossibleCombos = async () => {
        const combo = await ordersApi.getPossibleCombo(id);
        // if(order){
        //     console.log('ADD POSSIBLE TO ORDER');
        //     setOrder({...order, possibleCombinationOrders: combo})
        // }
            
        //setPossibleCombo(combo);
        return combo;        
    }

    const savePayment = async () => {
        console.log('order', order);
        console.log('opayments', payment);
        payment.orderNumber = order?.orderNumber;
        await ordersApi.createManualPayment(payment)
        .then((res) => {
            console.log(res);
            message.success('Payment successfully recorded');
            getManualPayments();
            setIsPaymentModal(false);
        });
    }

    const getManualPayments = async () => {
        setIsPaymentsLoading(true)
        let encoded = await encodeURIComponent(order?.orderNumber);
        let payments = await ordersApi.getManualPayment(encoded);
        
        if (payments) {
            setPayments(payments);
            console.log('payments',payments)
            setIsPaymentsLoading(false);
        }
    }

    const valueChangeHandler = async (event) => {
        console.log('event', event.target.name);
        console.log('val', event.target.value)
        const key = event.target.name;
        const value = event.target.value;
        payment[key] = value
        // const newParams = await produce(payment, (draft) => {
        //   draft[index] = Number(value);
        // });
        // console.log('newParams', newParams)
        setPayment(payment);
      };

      const handleSelectChange = (value: string) => {
        payment.gateway = value;
        setPayment(payment);
        console.log(`selected ${value}`, payment);
      };

    const checkCreateShipmentBtn = () => {
        //combinedorders can be 1 if we remove everything but
        //the main order itself
        if(order?.combinedOrders?.length > 1){
            setCreateShipBtnTitle("Create Combined Shipment");
        } 
        else {
            setCreateShipBtnTitle("Create Shipment");
        }
    };

    const handleSuccessCombine = () => {
        onCloseCombineModal();
        getDetail();

        checkCreateShipmentBtn();
    };
    
    const handleCreateShipment = () => {
        const url = `/create-shipment?source=${order?.source}&orderNumber=${order?.orderNumber[0] === "#" ? order?.orderNumber.substring(1) : order?.orderNumber}`;
        switch(createShipBtnTitle){
            case("Create Shipment"):
                history.push(url);
                break;
            case("Create Combined Shipment"):
                if(window.confirm("Are you sure to ship this combined orders?")){
                    history.push(url);
                }
                break;
        }
    };

    const checkShippedCombo = () => {
        const possibleCombos = order?.possibleCombinedOrders || []; 
        if (possibleCombos.length > 0) {
            const shipArr = shipInfo?.sourceId?.split(',');
            const tempArr = [];
            shipArr?.map((s) => {
                const ord = possibleCombos.find(p => p.orderNumber === s);
                console.log('HAIKALFIX ord', ord);
                if(s !== order.orderNumber){
                    tempArr.push({id:ord.id, orderNumber: s});
                }
            });
            console.log('Shipped Combo', tempArr);
            setShippedCombos(tempArr);
        }        
    }

    const parseNote = (noteStr) => {                   
        const httpsLinkRegex2 = /https?:\/\/[\S\d\w]+\s+.*/g;          
        const linkArr = noteStr.match(httpsLinkRegex2) || [];          
        const txtArr = noteStr.split(httpsLinkRegex2) || [];        

        const linkObjArr = [];
        for(let i=0; i < linkArr.length; i++){                
            let tmpArr = linkArr[i].split('\n');           
            
            if(!tmpArr[1]){
                //split by newline not going right. probably link separated by whitespace                
                const temp = linkArr[i].split(' ');               
                tmpArr[0] = temp[0];      
                const desc =  (temp.slice(1,temp.length-1)).reduce((prev,next) => {return prev + ' ' + next},'');   
                //console.log('DESC', desc, 'LEN', desc?.length);       
                tmpArr[1] = desc?.trim();
            }
            
            const obj = {link: tmpArr[0]?.trim(), desc:(tmpArr[1]?.length > 0)? tmpArr[1] : 'link'};           
            linkObjArr.push(obj);
        }
        
        const tmpArr = txtArr.map((t,index) => {
            let element = t;
            if(index < linkObjArr.length){  
                let linkk = linkObjArr[index].link
                const domainnn = linkk.match(/^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im)[1];  
                const linkStr = `<a href='${linkObjArr[index].link}' target="_blank">${domainnn + '/' +linkObjArr[index].desc}</a>`;
                element = element + linkStr;
            }

            return element;
        });
        
        const finalStr = tmpArr.toString().replace(/\n/g, '<br/>').replace(/,/g, ' ');
        setPrettyNote(finalStr);                 
    };   

    // const forceUpdate = async () => {
    //     if (!id) {
    //         return;
    //     }
    //     if (!qs.parse(history.location.search)?.combine) {
    //     if(process.env.REACT_APP_AUTO_UPDATE_ORDERS){        
    //         setIsLoading(true);           
    //         //Force fetching orders
    //         const resOrderNum = await ordersApi.getOrderById({
    //             id,
    //         });

    //         if (!resOrderNum?.order?.orderNumber) {
    //             return;
    //         }

    //         let rawOrderNum = resOrderNum.order.orderNumber
    //         if(resOrderNum.order.orderNumber.includes('#')) {
    //             rawOrderNum = resOrderNum.order.orderNumber.split('#')[1];
    //         }
            
    //         message.loading("Updating the order");
    //         await ordersApi.refetchOrderByNo(rawOrderNum)
    //         .then(async (res) => {
    //             message.success("Order updated");                          
    //         })
    //         .catch((err) => {                        
    //             message.error(err);
    //         });
    //         //end of force fetching
    //     }
    // }
    // }

    const getDetail = async () => {
        if (!id) {
            return;
        }
        try {
            setIsLoading(true);
            let resCombineOrder = null;
            let orderItem = null;
            let combinedOrders = null;
            
            if (!qs.parse(history.location.search)?.combine) {

                if(process.env.REACT_APP_AUTO_UPDATE_ORDERS){        
                    // setIsLoading(true);           
                    //Force fetching orders
                    const resOrderNum = await ordersApi.getOrderById({
                        id,
                    });
        
                    if (!resOrderNum?.order?.orderNumber) {
                        return;
                    }
        
                    let rawOrderNum = resOrderNum.order.orderNumber
                    if(resOrderNum.order.orderNumber.includes('#')) {
                        rawOrderNum = resOrderNum.order.orderNumber.split('#')[1];
                    }
                    
                    message.loading("Updating the order");
                    // await ordersApi.refetchOrderByNo(rawOrderNum)
                    // .then(async (res) => {
                    //     message.success("Order updated"); 
                        
                    //     //Retrieve refund for this order if applicable
                    //     ordersApi.getRefunds(id)
                    //     .then((r) => {
                    //         console.log('REFUNDS', r);
                    //         const refundsArr = [];
                    //         if (r.length > 0) {
                    //         r.forEach(refund => {                                
                    //             //order adj refund
                    //             if (refund.json.order_adjustments.length > 0) {                                                                        
                    //                 refund.json.order_adjustments.forEach(x => {                                        
                    //                     const refundObj = {
                    //                         details: 'Order Adjustment Refund',
                    //                         reason: x.reason,
                    //                         createdTime: refund.createdTime,
                    //                         processedTime:refund.processedTime,
                    //                         refundAmt: x.amount? Math.abs(x.amount) : Number(0)
                    //                     }

                    //                     refundsArr.push(refundObj);
                    //                 });                         
                    //             }

                    //             //line items refund
                    //             if (refund.json.refund_line_items.length > 0) {
                    //                 refund.json.refund_line_items.forEach(x => {
                    //                     const refundObj = {
                    //                         details: 'Line Items Refund',
                    //                         reason: x.line_item.name,
                    //                         createdTime: refund.createdTime,
                    //                         processedTime:refund.processedTime,
                    //                         refundAmt: x.subtotal? Math.abs(x.subtotal) : Number(0)
                    //                     };

                    //                     refundsArr.push(refundObj)
                    //                 });                                  
                    //             }

                    //             //others
                    //             if (refund.json.refund_line_items.length === 0 && refund.json.order_adjustments.length === 0) {
                    //                 //not yet implemented. tested so far no needs for transaction[].
                    //                 //might be in the future bugs?
                    //             }
                                
                    //         });
                    //         setRefunds(refundsArr);
                    //         setTotalRefunds(refundsArr.reduce((prev,next) => {return prev + next.refundAmt}, Number(0)))
                    //     }
                            
                    //         //setTotalRefunds(r.reduce((prev,next) =>{return prev + Math.abs(next.adjAmount)},Number(0)));
                    //     });
                    // })                   
                    // .catch((err) => {                        
                    //     message.error(err);
                    // });
                    //end of force fetching
                }
              
                await sleep(500);

                const resOrder = await ordersApi.getOrderById({
                    id,
                    //params: { listCombinedOrders: true },
                });

                if (!resOrder?.order?.id) {
                    return;
                }

                orderItem = await resOrder.order;   
         
                const {items, others} = await separateLineItemsVsShipping(orderItem.lineItems);
                
                calculateDiscount(items);
                setOtherCharges(others);
                buildShippingLine(orderItem);
                
                if(orderItem.note){
                    parseNote(orderItem.note);                    
                } else {
                    setPrettyNote("Not Available");
                }                    

                const possibleCombos = await getPossibleCombos();
                orderItem["possibleCombinedOrders"] = possibleCombos;               

                orderItem.fulfillmentStatus != 'fulfilled'? setIsFulfillmentCancellable(false) : setIsFulfillmentCancellable(true)
                
                setOrder(orderItem);     

                console.log('RES ORDER', orderItem)
                
                if(orderItem.trackingNumber){
                    const info = await shipmentsApi.getOrderShipmentInfo(orderItem.trackingNumber);
                    setShipInfo(info);
                    setIsFulfilled(true);
                }
                
                //reset combined order array each time getDetails is run.
                //else it will append to previous order's details
                setOrderInfoArr([]);

                combinedOrders = orderItem.combinedOrders;   
                
                combinedOrders.map((c) => {
                    const payload = { id: c };
                    ordersApi.getOrderById(payload)
                    .then((r) => {
                        console.log('ORDER RETURNED',r);
                        if(r.order.id.toString() !== id){
                            const info = { id:c, number:r.order.orderNumber };                        
                            setOrderInfoArr(old => [...old, info]);
                        }
                        
                    });
                });             
                
                if (!Array.isArray(combinedOrders) || combinedOrders.length === 0) {
                    calculateDiscount(orderItem.lineItems);
                    setOrder(orderItem);
                    return;
                }
                
                resCombineOrder = await ordersApi.getCombineOrders({
                    filter: `subOrders_eq=${JSON.stringify(combinedOrders)}`,
                });

                if (!Array.isArray(resCombineOrder?.combinedOrders) || resCombineOrder.combinedOrders?.length === 0) {
                    return;
                }

                calculateDiscount(orderItem.lineItems);
                setOrder(orderItem);
                
                setCombinedOrder(resCombineOrder.combinedOrders[0]);

                const resOrders = await Promise.all(
                    combinedOrders.map((item) => {
                        const payload = {
                            id: item,
                            params: {
                                listCombinedOrders: true,
                            },
                        };
                        ordersApi.getOrderById(payload);
                    })
                );
                setOrders(resOrders.map((item) => item.order));
            } else {
                
                resCombineOrder = await ordersApi.getCombineOrders({
                    filter: `id_eq=${id}`,
                });
                // console.log('ORDERRITEMMMMMMMMSSSM',resCombineOrder)
                setCombinedOrder(resCombineOrder.combinedOrders[0]);
                combinedOrders = resCombineOrder.combinedOrders[0].subOrders;
                // console.log('combine',combinedOrders)
                const resOrders = await Promise.all(
                    combinedOrders.map((item) => {
                        const payload = {
                            id: item,
                            params: { listCombinedOrders: true },
                        };
                        return ordersApi.getOrderById(payload);
                    })
                );
                // console.log('REScombine',resOrders)
                setOrders(Array(resOrders?.map((item) => item.order)[0]));
                // console.log('setOrder',resOrders[0]?.order)
                setOrder(resOrders[0]?.order);
                // console.log('ORDER DOWN',order)
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    const createPurchOrder = async () => {

    console.log('orders', order);
   
    if(order) {
        console.log('orders')
            order.lineItems.forEach(async (lineItem,index) => {
                    console.log(',================================',lineItem);
                    lineItem.images = lineItem.variant.images
            })
        const data = {
          orders: await [order]
        }
        console.log("selected orders", order);
        console.log("data", data);
        await history.push({ pathname:"/purchases/create", state: data });
    }
    // if (order.length > 0) {
    //     console.log('order')
    //     await orders.forEach((order, ind) => {
    //         order.lineItems.forEach(async (lineItem,index) => {
    //                 console.log(',================================',lineItem);
    //                 lineItem.images = lineItem.variant.images
    //                 itemList.push(lineItem);
    //         })
    //       })
       
    //     const data = {
    //       orders: await order
    //     }
    //     console.log("selected orders", order);
    //     console.log("data", data);
    //     // await history.push({ pathname:"/purchases/create", state: data });
    // }
   
    //history.push("/purchases/create?ordersList=" + ordersIds.toString());
  }

    const getEvents = async () => {
        ordersApi.getEvents(id)
        .then(r => {
            console.log('Events',r)
            setEvents(r.events);
        })
        .catch(e => {
            console.log('EVENTS ERROR', e);
        })
    };

    const getComments = async () => {
        setIsCommentsLoading(true);

        const orderNumber = order?.orderNumber || "";

        const payload = {
            module: "order",
            op: "details",
            ref: orderNumber
        }
        const commentList = await commentApi.getCommentsSafely(payload);
        
        if (commentList) {
            setComments(commentList);
            setIsCommentsLoading(false);
        }
    };

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const submitComment = () => {
        if (comment.length === 0) {
            message.error("Cannot create comment for empty comment");
            return;
        }

        const orderNumber = order?.orderNumber || "";
        console.log('LocalStorage   ',LocalStorage);  
        const payload = {
            userId: LocalStorage.get("userid",''),
            userName: LocalStorage.get("username",''),
            module: "order",
            operation: "details",
            reference: orderNumber,
            content: comment,
            tag:["order-details", orderNumber]
        };

        commentApi.createComment(payload)
        .then(r => {
            console.log('COMMENT RES',r)
            //refresh comment
            getComments();
        })
        .catch(err => message.error("Comment registration error: " + err));        
    };

    useEffect(() => {
        getDetail();
        //getPossibleCombos();
        getEvents();
        console.log('ORDER AFTER ID CHANGE',order);
    }, [id]);

    
    useEffect(() => {       
        console.log('CHANGE TO ORDER',order);
        checkCreateShipmentBtn();    
           
    },[order]);

    useEffect(() => {
        checkShippedCombo();
        const url = "/shipments/details/" + shipInfo?.id;
        setShipmentUrl(url);
        
        getComments();
        getManualPayments(); 
        
    },[shipInfo, order]);

    const columnsLineItems = [
        {
            title: "DETAILS",
            dataIndex: "name",
            key: "name",
            render: (_, record) => (
                <>
                    <div className="bold">{record.productTitle || record.customProductName}</div>
                    <div className="paragraph">{record.productVariantTitle}</div>
                    <Space className="actions">
                        <Button size="small" className="btn-success">
                            Add to purchase
                        </Button>
                        <Button size="small" className="btn-info">
                            Receive stock
                        </Button>
                        <Button size="small" className="btn-warning">
                            Mark as fulfilled
                        </Button>
                    </Space>
                </>
            ),
        },
        {
            title: "IMAGE",
            dataIndex: "id",
            key: "id",
            width: 120,
            render: (value, record) => {
                return <img src={record.image ? record.image : record.variant?.images?.length > 0 && record.variant?.images[0] != 'null' ? record.variant.images[0] :  "/no-image.png" } width="120"></img>;
            },
        },
        {
            title: "VARIANT ID",
            dataIndex: "productVariantId",
            key: "productVariantId",   
        },
        {
            title: "ORIGINAL PRICE",
            dataIndex: "price",
            key: "price",
            width: 160,
            align: "right",            
        },
        {
            title: "DISCOUNTED PRICE",
            dataIndex: "discountedPrice",
            key: "discountedPrice",
            width: 160,
            align: "right", 
        },
        {
            title: "QUANTITY",
            dataIndex: "quantity",
            key: "quantity",
            width: 120,
            align: "center",
        },
        {
            title: "ALLOCATED",
            dataIndex: "allocatedQuantity",
            key: "allocatedQuantity",
            width: 120,
            align: "center",
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            width: 120,
            render: (value, record) => {
                //kaye , show REFUNDED if quantity is o
                return <strong>{record.quantity == 0 ? 'REFUNDED': value.toUpperCase()}</strong>;
            },
        },
        {
            title: "PRODUCT DETAIL",
            dataIndex: "id",
            key: "id",
            width: 160,
            render: (_, record) => {
                return (
                    <LinkButton
                        to={{
                            pathname: "/products/product-detail",
                            search: `?id=${record.productId}`,
                        }}
                        type="primary"
                    >
                        Product Details
                    </LinkButton>
                );
            },
        },
    ];
    const columnsShipping = [
        {
            title: "DETAILS",
            dataIndex: "productTitle",
            key: "productTitle",            
            align: "left",           
            render: (_, record) => (
                <>
                    <div className="bold">{record.title}</div>
                </>
            ), 
        },
        /*
        {
            title: "WEIGHT",
            dataIndex: "weight",
            key: "weight",
            width: 160,
            align: "center",    
            render: (_, record) => (
                <>
                    <p>{record.weight || "0"}</p>
                </>
            ),         
        },
        {
            title: "UNIT",
            dataIndex: "weightUnit",
            key: "weightUnit",
            width: 160,
            align: "center",    
            render: (_, record) => (
                <>
                    <p>{record.weightUnit || "KG"}</p>
                </>
            ),         
        },      
        */
        {
            title: "PRICE",
            dataIndex: "price",
            key: "price",
            width: 160,
            align: "center",    
            render: (_, record) => (
                <>
                    <p>${record.price || "0.00"}</p>
                </>
            ),         
        },    
        
    ];
    const columnsOthers = [
        {
            title: "DETAILS",
            dataIndex: "productTitle",
            key: "productTitle",            
            align: "left",           
            render: (_, record) => (
                <>
                    <div className="bold">{record.productTitle || record.customProductName}</div>
                </>
            ), 
        },
        {
            title: "QUANTITY",
            dataIndex: "quantity",
            key: "quantity",
            width: 160,
            align: "center",    
            render: (_, record) => (
                <>
                    <p>{record.quantity || "0"}</p>
                </>
            ),         
        },       
        {
            title: "PRICE",
            dataIndex: "weight",
            key: "weight",
            width: 160,
            align: "center",    
            render: (_, record) => (
                <>
                    <p>${record.price || "0"}</p>
                </>
            ),         
        },       
    ] 

    const columnsRefunds = [
        {
            title: "DETAILS",
            dataIndex: "json",
            key: "json",
            //width: 160,
            align: "left",    
            render: (_, record) => (
                <>
                    <p>{record?.details || "N/A"}</p>
                </>
            ),         
        },  
        {
            title: "REASON/ITEM",
            dataIndex: "json",
            key: "json",
            //width: 160,
            align: "left",    
            render: (_, record) => (
                <>
                    <p>{record?.reason || "N/A"}</p>
                </>
            ),         
        },       
        {
            title: "CREATED AT",
            dataIndex: "createdTime",
            key: "createdTime",
            //width: 160,
            align: "left",    
            render: (_, record) => (
                <>
                    <p>{record.createdTime? moment(record.createdTime).format("DD-MM-YYYY, hh:mm a") : "N/A"}</p>
                </>
            ),         
        },
        {
            title: "PROCESSED AT",
            dataIndex: "processedTime",
            key: "processedTime",
            //width: 160,
            align: "left",    
            render: (_, record) => (
                <>
                    <p>{record.processedTime? moment(record.processedTime).format("DD-MM-YYYY, hh:mm a") : "N/A"}</p>
                </>
            ),         
        },
        {
            title: "REFUND AMOUNT",
            dataIndex: "adjAmount",
            key: "adjAmount",
            //width: 160,
            align: "right",    
            render: (_, record) => (
                <>
                    <p>${record.refundAmt.toFixed(2) || "0.00"}</p>
                </>
            ),         
        },
             
    ]

    const columnsPayment = [
        {
            title: "Date",
            dataIndex: "createdAt",
            render: (date,record,index) => <Input
            name="date"
            value={date}
            ></Input>
        },
        
             
    ]

    const columns = [
        {
            title: "Amount",
            dataIndex: "amount",
            render: (text,record,index) => <Input
            name="amount"
            step={1}
            type="number"
            value={text}
            onChange={(event) => valueChangeHandler(event, index)}
            ></Input>
        },
        {
            title: "Gateway",
            dataIndex: "gateway",
            render: (text,record,index) => <Input
            name="gateway"
            step={1}
            type="number"
            value={text}
            onChange={(event) => valueChangeHandler(event, index)}
            ></Input>
        },
        {
            title: "Transaction ID",
            dataIndex: "transactionId",
            render: (text,record,index) => <Input
            name="transactionId"
            step={1}
            type="number"
            value={text}
            onChange={(event) => valueChangeHandler(event, index)}
            ></Input>
        },
        {
            title: "Notes",
            dataIndex: "notes",
            render: (text,record,index) => <Input
            name="notes"
            step={1}
            type="number"
            value={text}
            onChange={(event) => valueChangeHandler(event, index)}
            ></Input>
        },
        {
            title: "Quantity",
            dataIndex: "pendingHolder",
            render: (text,record,index) => <Input
            name="pendingHolder"
            addonAfter="PCS"
            step={1}
            type="number"
            value={text}
            onChange={(event) => valueChangeHandler(event, index)}
          />            
        },
        {
            title: "Order Number",
            key: "orderNumber",
            render: (orderNumber,record, index) => {
            // let a = Object.keys(item)[0]
            return (
                <Input
                name="orderNumber"
                value={orderNumber}
                // onChange={(event) => valueChangeHandler(event, index)}
            />
            )}
        }
    ]

    let totalPrice = order?.totalPrice;
    note = order?.note ?? "----";

    if (combinedOrder?.id && Array.isArray(orders) && orders?.length > 0) {
        totalPrice = 0;
        orders.forEach((item) => {
            totalPrice += parseFloat(item.totalPrice);
        });
        note = orders.map((itemOrder) => (itemOrder.note ? <div key={itemOrder.id}>{itemOrder.note}</div> : ""));
        if (!note.join("")) {
            note = "----";
        }
    }

    return (
        <>
            <Modal
                visible={isModalCreateShipment}
                title="Create New Shipment"
                onCancel={() => setIsModalCreateShipment(false)}
                footer={[
                    <Button
                        type="primary"
                        onClick={() => {
                            window.open(
                                `/create-shipment?orderNumber=${order?.orderNumber[0] === "#" ? order?.orderNumber.substring(1) : order?.orderNumber}`,
                                "_blank"
                            );
                            setIsModalCreateShipment(false);
                        }}
                    >
                        New Tab
                    </Button>,
                    <LinkButton
                        type="primary"
                        to={{
                            ////uncomment this
                            // pathname: `/create-shipment`,
                            // search: `?orderNumber=${order?.orderNumber[0] === "#" ? order?.orderNumber.substring(1) : order?.orderNumber}`,
                        }}
                    >
                        Current Tab
                    </LinkButton>,
                ]}
            >
                Where do you want Create Shipment Page will be open?
            </Modal>
            <Modal
                visible={isModal}
                title="Confirmation"
                onCancel={() => setIsModal(false)}
                okText="Yes"
                footer={[
                    <Button onClick={() => cancelFulfillment()} type="primary">
                        YES
                    </Button>,
                    <Button onClick={() => setIsModal(false)} type="danger">
                        NO
                    </Button>,
                ]}
            >
                <div style={{ marginTop: "20px" }}>Are you sure you want to cancel fulfillment?</div>
            </Modal>   
            <Modal
                visible={isPaymentModal}
                title="Manual Payment"
                onCancel={() => setIsPaymentModal(false)}
                onOk={() => savePayment()}
                // footer={[
                //     <Button onClick={() => cancelFulfillment()} type="primary">
                //         YES
                //     </Button>,
                //     <Button onClick={() => setIsModal(false)} type="danger">
                //         NO
                //     </Button>,
                // ]}
            >
                <div style={{ marginTop: "20px" }}>
                {/* <Table
                        loading={isLoading}
                        columns={columns}
                        // dataSource={lineItems}
                        dataSource={payment}
                        pagination={false}
                    /> */}
                    <h1>Amount</h1>
                    <Input type="number" name="amount" onChange={(event) => valueChangeHandler(event)} ></Input>
                    <h1>Gateway</h1>
                    <Select
                        name="gateway"
                        onChange={handleSelectChange}
                        style={{ width: 400 }}
                        defaultValue={payment?.gateway}
                        options={[
                            { value: "Paypal", label: "Paypal"},
                            { value: "Wise", label: "Wise"},
                            { value: "Bank In", label: "Bank In"},
                            { value: "Other", label: "Other"}
                                    ]}
                                />
                    <h1>Transaction ID</h1>
                    <Input name="transactionId" onChange={(event) => valueChangeHandler(event)}></Input>
                    <h1>Notes</h1>
                    <Input name= "notes" onChange={(event) => valueChangeHandler(event)} ></Input>
                    <h1>Order Number</h1>

                </div>
            </Modal>            
            
            <TitleBox className="title-page">
                <Title>
                    {t("Order")} {order?.orderNumber}
                </Title>
                <Breadcrumb items={[{ name: "Home", path: "/" }, Path.ORDERS, { name: `${t("Order")} ${order?.orderNumber || ""}` }]} />
            </TitleBox>
          

            <OrderDetailPageStyled title={t("Order detail")}>
                <Container>
                    <Spin spinning={isLoading}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={18}>
                                <Card className="order-info">
                                    <div className="order-details">
       
                                        {order?.trackingNumber ? 
                                        (<Row className="detail-row" hidden={!isFulfilled}>
                                            <Col xs={24} md={24} className="warn">
                                                <div className="data">
                                                    Shipment has been made for this order. Tracking number: <a className="data" href={shipmentUrl}>{order.trackingNumber}</a>
                                                </div>
                                            </Col>
                                        </Row>) : order?.totalOutstanding > "0.00"? 
                                         (<Row className="detail-row">
                                         <Col xs={24} md={24} className="redwarn">
                                             <div className="data">
                                                PAYMENT OUTSTANDING: ${order?.totalOutstanding}
                                             </div>
                                         </Col>
                                     </Row>) : order?.risk == "0.5"? 
                                         (<Row className="detail-row">
                                         <Col xs={24} md={24} className="riskMedWarn">
                                             <div className="data">
                                                FLAGGED AS MEDIUM-RISK!
                                             </div>
                                         </Col>
                                     </Row>) : order?.risk == "1.0"? 
                                         (<Row className="detail-row">
                                         <Col xs={24} md={24} className="riskHighWarn">
                                             <div className="data">
                                                FLAGGED AS HIGH-RISK!
                                             </div>
                                         </Col>
                                     </Row>) : undefined
                                        }                                      
                                        <Row className="detail-row">
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Order Number</div>
                                                <div className="data bold">                                           
                                                    <Title level={4}>
                                                        {t("Order")} {order?.orderNumber}
                                                    </Title>                                               
                                                </div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Email</div>
                                                <div className="data">{order?.customerEmail}</div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Total Price</div>
                                                <div className="data">{totalRefunds? (totalPrice - totalRefunds).toFixed(2) : totalPrice}</div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">{order?.financialStatus === "paid" ? "Financial Status" : "Total outstanding"}</div>
                                                <div className="data uppercase">
                                                    {order?.financialStatus === "paid" ? order?.financialStatus : order?.totalOutstanding}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="detail-row">
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Status</div>
                                                <div className="data uppercase">{order?.status}</div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Shipping Date</div>
                                                {shipInfo ? (
                                                    <div className="data">{moment(shipInfo?.createdAt).format("DD MMM YYYY, hh:mm a")}</div>                                                  
                                                ):(                                                   
                                                    <div className="data">No Shipping Info</div>                                                    
                                                )}
                                                
                                                {/*<div className="data">{order?.shopifyShippingLine}</div>*/}
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Created At</div>
                                                <div className="data data-sm">{moment(order?.createdAt).format("DD MMM YYYY, hh:mm a")}</div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Processed At</div>
                                                <div className="data data-sm">{moment(order?.updatedAt).format("DD MMM YYYY, hh:mm a")}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <Row className="billing-shipping-info">
                                        <Col xs={24} md={12} className="box-data">
                                            <div className="label">Billing Info:</div>
                                            <div className="data address">
                                                <span className="bold">{`${order?.billingFirstName || ""} ${order?.billingLastName || ""}`}</span>
                                                <br />
                                                Phone: {order?.billingPhone}
                                                <br />
                                                Zip: {order?.billingAddressZip}
                                                <br />
                                                {[
                                                    order?.billingAddressLineOne,
                                                    order?.billingAddressLineTwo,
                                                    order?.billingAddressCity,
                                                    order?.billingAddressProvince,
                                                    order?.billingAddressCountry,
                                                ]
                                                    .filter((item) => item)
                                                    .join(", ")}
                                            </div>
                                        </Col>
                                        <Col xs={24} md={12} className="box-data">
                                            <div className="label">Shipping Info:</div>
                                            <div className="data address">
                                                <span className="bold">{`${order?.addressFirstName || ""} ${order?.addressLastName || ""}`}</span>
                                                <br />
                                                Phone: {order?.addressPhone}
                                                <br />
                                                Zip: {order?.addressZip}
                                                <br />
                                                {[
                                                    order?.companyName? order.companyName : undefined, 
                                                    order?.addressLineOne,
                                                    order?.addressLineTwo,
                                                    order?.addressCity,
                                                    order?.addressProvince,
                                                    order?.addressCountry,
                                                ]
                                                    .filter((item) => item)
                                                    .join(", ")}
                                            </div>
                                        </Col>
                                       
                                    </Row>
                                    <Row className="billing-shipping-info">                                       
                                        <Col xs={24} md={12} className="box-data">
                                         <div className="label">Discount:</div>
                                            <div>                                                
                                                <div className="data address" >
                                                    <ul>
                                                    {order?.discountCodes? (
                                                       order.discountCodes.map((d) => {
                                                            return (<div>
                                                                <li>Code: {d.code}</li>
                                                                <li>Amount: {d.amount}</li>
                                                            </div>);
                                                       })
                                                    ):(
                                                        <li>Not Available</li>
                                                    )}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </Col>
                                        <Col xs={24} md={12} className="box-data">
                                            <div className="label">Order Issues:</div>
                                            <div style={{height:"150px",overflow:"scroll"}}>                                                
                                               <span> {order?.alerts[0]?.alert}</span>
                                            </div>                                                                                                        
                                        </Col>
                                    </Row>
                                    <Row className="billing-shipping-info">                                       
                                        <Col xs={24} md={12} className="box-data">
                                         <div className="label">Note Attributes:</div>
                                            <div style={{height:"150px",overflow:"scroll"}}>                                                
                                                <div className="data" >
                                                    <ul>
                                                    {order?.noteAttributes? (
                                                       order.noteAttributes.map((o) => {
                                                            return (<li>{o.name} : {o.value}</li>);
                                                       })
                                                    ):(
                                                        <li>Not Available</li>
                                                    )}
                                                    </ul>
                                                </div> 
                                            </div>
                                        </Col>
                                        <Col xs={24} md={12} className="box-data">
                                            <div className="label">Order Notes:</div>
                                            <div style={{height:"150px",overflow:"scroll"}}>                                                
                                                <div className="data" dangerouslySetInnerHTML={{__html: prettyNote}}></div> 
                                            </div>                                                                                                        
                                        </Col>
                                        
                                    </Row>
                                </Card>
                            </Col>

                            <Col xs={24} md={6}>
                                <Space direction="vertical" className="order-actions">
                                    {/*
                                    {order?.trackingNumber ? (
                                        <Row gutter={[6, 6]}>
                                            <Col xs={24}>
                                                <div className="label">Airway Bill Number:</div>
                                                <div className="data">{order?.trackingNumber}</div>
                                            </Col>
                                            { <Button
                                                type="primary"
                                                size="large"
                                                className="download-invoice"
                                            >
                                                Download PDF invoice
                                            </Button>
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="download-label"
                                            >
                                                Download PDF label
                                            </Button>
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="preview-label"
                                            >
                                                Preview Label
                                            </Button>
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="reprint-label"
                                                disabled
                                            >
                                                Reprint Label
                                            </Button>
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="view-shipment"
                                            >
                                                View Shipment Label Photos
                                            </Button>
                                            <Button
                                                type="primary"
                                                size="large"
                                                className="clone-shipment border-warning bg-warning"
                                                disabled
                                            >
                                                Clone Shipment details
                                            </Button> }
                                        </Row>
                                    ) : (
                                        (order?.status === ORDER_FILTER_STATUS[`ready to pack`].value ||
                                            order?.status === ORDER_FILTER_STATUS.allocated.value) && (
                                            <Button href="#" type="primary" size="large" className="create-shipment">
                                                Create Shipment
                                            </Button>
                                        )
                                            )} 
                                    */}                                  
                                    <form method="post" action="#">
                                        <input
                                            type="hidden"
                                            name="csrfmiddlewaretoken"
                                            value="2EA46FwyXggMXkM93cCbD411kNymoQvJ7Pl67a9K55a3Nawc7J1gX0udEcHUjNKp"
                                        />
                                        {/* <Button className="close-order" htmlType="submit" danger type="primary" size="large">
                                            Close Order
                                        </Button> */}
                                          {isFulfillmentCancellable ? 
                                        <Button onClick={() => setIsModal(true)} danger type="primary" size="large" style={{ marginTop: "15px" }}>
                                            Cancel Fulfillment
                                        </Button> : null
                                        }
                                        {order?.status == 'refunded' ? null : 
                                        <Link
                                            to={{
                                                ////uncomment this
                                                pathname: `/create-shipment`,
                                                search: `?source=${order?.source}&orderNumber=${
                                                    order?.orderNumber[0] === "#" ? order?.orderNumber.substring(1) : order?.orderNumber
                                                }`,
                                            }}
                                            style={{
                                                marginTop: "15px",
                                                padding: "11px",
                                                background: "#2BABE3",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "2px",
                                                color: "white",
                                            }}
                                        >
                                            {createShipBtnTitle}
                                        </Link>
                                        }
                                        {order?.status == 'refunded' ? null : 
                                         <Button onClick={() => createPurchOrder()} type="primary" size="large" style={{ marginTop: "15px" }}>
                                            Make Purchase
                                        </Button>
                                        }

                                        {order?.status == 'refunded' ? null : 
                                        <Button onClick={() => setIsPaymentModal(true)} type="primary" size="large" style={{ marginTop: "15px" }}>
                                            Record Payment
                                        </Button>
                                        }

                                        <Link
                                            to={{
                                                pathname : `/purchases/purchase-orders`,
                                                search: `?orderNumber=${order?.orderNumber[0] === "#" ? order?.orderNumber.substring(1) : order?.orderNumber
                                                }`,  
                                            }}
                                            target="_blank"
                                            style={{
                                                marginTop: "15px",
                                                padding: "11px",
                                                background: "#2babe3",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "2px",
                                                color: "white",
                                            }}
                                        >
                                            Check In
                                        </Link>
                                        <Link
                                            to={{
                                                ////uncomment this
                                                // pathname: `/create-shipment`,
                                                // search: `https://harumio.myshopify.com/admin/orders/${order?.source}`,
                                                pathname : `https://harumio.myshopify.com/admin/orders/${order?.shopifyId}`
                                                // pathname: `https://harumio.myshopify.com/admin/orders/${order?.orderNumber[0]`
                                                
                                            }}
                                            target="_blank"
                                            style={{
                                                marginTop: "15px",
                                                padding: "11px",
                                                background: "#87cf31",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "2px",
                                                color: "white",
                                            }}
                                        >
                                            Go To Shopify 
                                        </Link>
                                    </form>  
                                    

                                    {order?.possibleCombinedOrders.length > 0  ? (
                                        <Card title="Orders Combined With">
                                            <Row>
                                                <Col xs={24} md={14}>
                                                    {orderInfoArr?.map((o) => {   
                                                                                      
                                                        const url = "/orders/order-details/" + o.id;
                                                        return (<><Link to={url}>{o.number}</Link><br/></>);
                                                    })}
                                                </Col>
                                                <Col xs={24} md={10}>
                                                    {order? (<>
                                                        <Button type="primary" 
                                                                onClick={() => {
                                                                    console.log('VEESEEBLE',order);
                                                                    onOpenCombineModal(order);
                                                                }}
                                                        >
                                                            Possible Combine
                                                        </Button>
                                                    </>):(<></>)}
                                                </Col>
                                            </Row>
                                          
                                        </Card>
                                    ) : (
                                        <>                                      
                                        </>
                                    )}

                                    {shippedCombos?.length > 0? (
                                        <Card title="Orders Shipped With">
                                            <Row>
                                                <Col xs={24} md={14}>
                                                    {shippedCombos?.map((s) => {
                                                        const url = "/orders/order-details/" + s.id;
                                                        return (<><Link to={url}>{s.orderNumber}</Link><br/></>);
                                                    })}
                                                </Col>
                                            </Row>
                                        </Card>
                                    ):(<>
                                    
                                    </>)}                                    
                                </Space>                    
                            </Col>
                            <Col xs={24} md={20}>                                
                                <Col xs={24}>                               
                                    <Card title="Line Items" className="line-items">
                                        <Table
                                            pagination={false}
                                            columns={columnsLineItems}
                                            dataSource={lineItems}
                                            rowKey="id"
                                            size="small"
                                            scroll={{
                                                x: "max-content",
                                            }}
                                        />
                                    </Card>                               
                                </Col>                          
                                <Col xs={24}>
                                    <Card title="Other Charges" className="line-items">
                                        <Table
                                            pagination={false}
                                            columns={columnsOthers}
                                            dataSource={otherCharges}
                                            rowKey="id"
                                            size="small"
                                            scroll={{
                                                x: "max-content",
                                            }}
                                            />
                                    </Card>
                                </Col>
                                <Col xs={24}>
                                    <Card title="Shipping" className="line-items">
                                        <Table
                                            pagination={false}
                                            columns={columnsShipping}
                                            dataSource={shipping}
                                            rowKey="id"
                                            size="small"
                                            scroll={{
                                                x: "max-content",
                                            }}
                                            />
                                    </Card>
                                </Col>
                                {refunds?.length > 0? (
                                <Col xs={24}>
                                    <Card title="Refunds" className="line-items">
                                        <Table 
                                            pagination={false}
                                            columns={columnsRefunds}
                                            dataSource={refunds}
                                            rowKey="id"
                                            size="small"
                                            scroll={{
                                                x: "max-content",
                                            }}
                                        />
                                    </Card>
                                </Col>
                                ):(<></>)}
                                 <Col xs={24}>
                                    <Card title="Manual Payments" className="line-items">
                                        {/* <Table
                                            pagination={false}
                                            columns={columnsPayment}
                                            dataSource={payments}
                                            scroll={{
                                                x: "max-content",
                                            }}
                                            /> */}
                                            <Spin spinning={isPaymentsLoading}>
                                                {payments.length > 0 ? (
                                                    <div style={{height:"150px",overflow:"scroll"}}>
                                                    {payments.map(p => <>
                                                        <div style={{margin:"15px 0px 0px 20px"}}>
                                                            <span style={{fontSize:"85%", fontWeight:"bold"}}>
                                                                {moment(p.createdAt).format("DD-MM-YYYY, hh:mm a")}
                                                            </span> 
                                                            &nbsp; &nbsp;
                                                            <span style={{fontSize:"85%", fontStyle:"italic"}}>    
                                                                {p.gateway}
                                                            </span>
                                                            <br/>
                                                            <span style={{fontSize:"85%"}}>
                                                                Transaction Id :  {p.transactionId}
                                                            </span> 
                                                            <br/>
                                                            <span style={{fontSize:"85%"}}>
                                                                Amount :  {p.amount}
                                                            </span> 
                                                            <br/>
                                                            <span style={{fontSize:"85%"}}>
                                                                Notes :  {p.notes}
                                                            </span> 
                                                            
                                                        </div>
                                                        <br/>
                                                   </>)}    
                                                   </div>):(<>
                                                    <div style={{margin:"15px 0px 0px 20px"}}>
                                                            <span style={{fontSize:"85%", fontWeight:"bold"}}>
                                                              None
                                                            </span>
                                                            <br/>
                                                    </div>
                                                    <br/>
                                                </>)}
                                            </Spin>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card title="Comments" className="line-items">
                                        <Row>
                                            <Col md={12}>
                                                <Spin spinning={isCommentsLoading}>
                                                {comments?.length > 0? (<>
                                                    {comments.map(c => <>
                                                        <div style={{margin:"15px 0px 0px 20px"}}>
                                                            <span style={{fontSize:"85%", fontWeight:"bold"}}>
                                                                {c.userName}
                                                            </span> 
                                                            &nbsp; &nbsp;
                                                            <span style={{fontSize:"85%", fontStyle:"italic"}}>    
                                                                {moment(c.createdAt).format("DD-MM-YYYY, hh:mm a")}
                                                            </span>
                                                            <br/>
                                                            {c.content}
                                                        </div>
                                                        <br/>
                                                    </>)}
                                                </>):(<></>)}
                                                </Spin>
                                            </Col>
                                            <Col md={1} style={{backgroundColor:"whitesmoke"}}>
                                            </Col>
                                            <Col md={10} style={{backgroundColor:"whitesmoke"}}>
                                                <Row gutter={[16,16]}>
                                                    <Col md={24}>&nbsp;</Col>
                                                </Row>
                                                <Row gutter={[16,16]}>
                                                    <Col md={24} style={{backgroundColor:"white"}}>
                                                        <br/>
                                                        Add New Comment
                                                        <br/><br/>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16,16]}>
                                                    <Col md={24} style={{backgroundColor:"white"}}>
                                                        <TextArea rows={8} placeholder="Comment here..." 
                                                            style={{marginRight:"25px"}}
                                                            onChange={handleCommentChange} value={comment}/>  
                                                        <br/><br/>                                                                                        
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16,24]}>
                                                    <Col md={16} style={{backgroundColor:"white"}}></Col>
                                                    <Col md={8} style={{backgroundColor:"white"}}>
                                                        <Space direction="horizontal">
                                                            <Button type="secondary" onClick={() => setComment("")}>Clear</Button>                                                            
                                                            <Button type="primary" onClick={submitComment}>Submit</Button>
                                                        </Space>
                                                        <br/><br/>
                                                    </Col>
                                                </Row>                                       
                                            </Col >
                                            <Col md={1} style={{backgroundColor:"whitesmoke"}}>
                                            </Col>
                                        </Row>                                        
                                    </Card>
                                </Col>                                
                            </Col>
                            <Col xs={24} md={4}>
                                <Card title="Events Timeline">
                                    <Steps 
                                        direction="vertical" 
                                        size="small" 
                                        progressDot
                                        current={events? events.length:1}
                                    >
                                        {events? (
                                            events.slice(0).reverse().map(e => {
                                                return (<Step   title={e.subject_type + ', ' + e.verb} 
                                                                description={'[' + moment(e.created_at).format("DD MMM YYYY, hh:mm a") + '] ' + e.description}/>)
                                            })
                                        ):(<>
                                            <Step title="Not available" description="No timeline events found" />                                    
                                        </>)
                                        }                                 
                                    </Steps>      
                                </Card>                                                       
                            </Col>                            
                        </Row>
                    </Spin>
                </Container>
                {order? (<>
                    <CombineModal visible={openCombineModal} onCancel={onCloseCombineModal} onSuccess={handleSuccessCombine} parentPage="order-detail"/>
                </>):(<></>)}
                
            </OrderDetailPageStyled>
        </>
    );
}

export default OrderDetail;
