import React from "react";
import { Button, Card, Col, Input, Row, Space, Form, Select } from "antd";
import kodNegara from "kod-negara";
import { Provinces } from "country-and-province";
import { useState } from "react";

const { Option } = Select;

const CheckRateCard = ({ onFinish, form }) => {
    const [activeProvinces, setActiveProvinces] = useState([]);
    const onSelect = (value) => {
        const countryCode = kodNegara.findOne("countryNameEn", value).countryCode;
        const provinces = new Provinces(countryCode);
        if (!provinces.data.length || provinces.data[0].code === undefined) {
            setActiveProvinces([]);
        } else if (provinces !== undefined && provinces.data[0].code !== undefined) {
            setActiveProvinces(provinces.data);
        }
        
        return form.setFieldsValue({ shipperCountryCode: countryCode });
    };

    const onSelectProvince = (value) => {
        const provinceCode = activeProvinces?.find((item) => item.name === value).code;
        return form.setFieldsValue({ provinceCode });
    };

    console.log("CAP", activeProvinces);
    return (
        <Card title="Check Rate Form" style={{ marginBottom: "10px" }}>
            <Form layout="vertical" form={form} onFinish={onFinish}>
                <Row gutter={16}>
                    <Col md={12}>
                        <Form.Item
                            name="weight"
                            label="Weight"
                            rules={[
                                {
                                    required: true,
                                    message: "Weight is required",
                                },
                            ]}
                            style={{ marginBottom: 0 }}
                            hasFeedback
                        >
                            <Input placeholder="Weight" type="number" addonAfter={"KG"} />
                        </Form.Item>
                    </Col>
                    <Col md={12}>
                        <Form.Item
                            name="postal"
                            label="Postal"
                            rules={[
                                {
                                    required: true,
                                    message: "Postal is required",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="Postal" />
                        </Form.Item>
                    </Col>
                    
                    <Col md={12}>
                        <Form.Item
                            name="city"
                            label="City"
                            rules={[
                                {
                                    required: true,
                                    message: "City is required",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="City" />
                        </Form.Item>
                    </Col>
                    
                    <Col md={12}>
                        <Form.Item
                            name="countryCode"
                            label="Country Code"
                            rules={[
                                {
                                    required: true,
                                    message: "Country Code is required",
                                },
                            ]}
                            hasFeedback
                        >
                            <Select showSearch={true} style={{ width: "100%" }} onSelect={onSelect}>
                                {kodNegara.all().map((item, index) => (
                                    <Option key={index} value={item.countryNameEn}>
                                        {item.countryNameEn} ({item.countryCode})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    
                    <Col md={24}>
                        <Form.Item
                            name="provinceCode"
                            label="Province Code"
                            rules={[
                                {
                                    required: false,
                                    message: "Country Code is required",
                                },
                            ]}
                            hasFeedback
                        >
                            {activeProvinces.length > 1 ? (
                                <Select showSearch={true} style={{ width: "100%" }} onSelect={onSelectProvince} placeholder={"Please choose..."}>
                                    {activeProvinces?.map((item, index) => (
                                        <Option key={index} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            ) : (
                                <Input placeholder="Please insert province code" />
                            )}
                        </Form.Item>
                    </Col>
                    
                </Row>
                <Space>
                    <Button type="primary" htmlType="submit">
                        Check Rate
                    </Button>
                    <Button type="ghost" htmlType="reset">
                        Reset
                    </Button>
                </Space>
            </Form>
        </Card>
    );
};

export default CheckRateCard;
