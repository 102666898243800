import { message, Tabs, Typography } from "antd";
import productsApi from "apis/productsApi";
import purchaseApi from "apis/purchaseApi";
import produce from "immer";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { removeBlankNull } from "utils/funcs";
import Path from "../../commons/breadcrumbs";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import AllPendingPurchases from "./AllPendingPurchase";
import CustomPendingPurchases from "./Custom";
import { CardContainer, TitleBox } from "./index.style";
import { moment } from "utils/funcs";
import ShopifyPendingPurchases from "./Shopify";
const { Title } = Typography;

const { TabPane } = Tabs;

const PendingPurchases = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(false);
  const [loadingVariantImage, setLoadingVariantImage] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [dataListOrders, setDataListOrders] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    totalPage: null,
    sort: [],
    productFilter: '',
    variantFilter: '',
    orderFilter: [],
  });

  const [paramsOrder, setParamsOrder] = useState({
    page: 1,
    pageSize: 10,
    totalPage: null,
    sort: [],
    productFilter: '',
    variantFilter: '',
    orderFilter: [moment().subtract(1, "month"), moment()],
  });

  const gettingListPending = async (newParam) => {
    const param = removeBlankNull({ ...params, ...newParam });
    delete param.totalPage;
    if (param.productFilter) {
      param.productFilter = `title_iLike=%${param.productFilter}%`;
    }
    if (param.variantFilter) {
      param.variantFilter = `title_iLike=%${param.variantFilter}%`;
    }
    const { orderFilter } = param;
    if (Array.isArray(orderFilter) && orderFilter.some((item) => item)) {
      param.orderFilter = [];
      if (orderFilter[0]) {
        // param.orderFilter.push(`createdAt_gte=${orderFilter[0].utc().startOf('date').format()}`)
        param.orderFilter.push(`createdAt_gte=${orderFilter[0].utc().startOf('date').format()}`);
        //param.orderFilter.push(`createdAt_gte=${moment().subtract(1, "month")}`)
      }
      if (orderFilter[1]) {
        param.orderFilter.push(`createdAt_lte=${orderFilter[1].utc().endOf('date').format()}`)
        // param.orderFilter.push('');
        //param.orderFilter.push(`createdAt_lte=${moment()}`)
      }
    }
    setIsLoading(true);
    try {
      const res = await purchaseApi.getListPendingVariants(param);
      if (res === undefined) {
        return alert("Response undefined");
      }

      const { pendingProducts, totalCount } = res;
      
      const reformPendingProds = pendingProducts.map((obj, index) => {
        return { ...obj, key: index };
      });
      setDataList(reformPendingProds);
      // console.log('DATALIST', reformPendingProds)
      setParams((prev) => {
        return {
          ...prev,
          totalPage: totalCount,
        };
      });
      // message.success("Succesfully fetch all pending", 5);
      setIsLoading(false);

    } catch (error) {
      message.error(error.message);
      setIsLoading(false);
      setLoadingVariantImage(false);
    }   
  };

  const gettingListPendingOrders = async (newParam) => {
    const param = removeBlankNull({ ...paramsOrder, ...newParam });
    delete param.totalPage;
    let filter = [];
    if (param.productFilter) {
      param.productFilter = `title_iLike=%${param.productFilter}%`;
    }
    if (param.variantFilter) {
      param.variantFilter = `title_iLike=%${param.variantFilter}%`;
    }
    if (param.orderNumberFilter) {
      filter.push(`orderNumber_iLike=%${param.orderNumberFilter}%`);
    }
   
    const { orderFilter } = newParam;
    // if (Array.isArray(orderFilter) && orderFilter.some((item) => item))
    if (Array.isArray(orderFilter)) {
      console.log('ARRAY ORDER FILER')
      if (orderFilter[0]) {
        filter.push(`createdAt_gte=${orderFilter[0].utc().startOf('date').format()}`)
      }
      if (orderFilter[1]) {
        filter.push(`createdAt_lte=${orderFilter[1].utc().endOf('date').format()}`)
      }
     
    }
    param.filter = filter;
    setIsLoadingOrders(true);    
    message.success("Please wait a few minutes, fetching data..", 5);   
    
    try {      
      const res = await purchaseApi.getListOrders(param);
      if (res === undefined) {
        return alert("Response undefined");
      }
      
      console.log('res getlistorders  ',res);
      const { orders, totalCount } = res;

      setParamsOrder((prev) => {
        return {
          ...prev,
          totalPage: totalCount,
        };
      });

      const reformPendingProds = orders.map((obj, index) => {
        return { ...obj, key: index };
      });
      setDataListOrders(reformPendingProds);
      message.success("Succesfully fetch all pending products by orders", 5);
      setIsLoadingOrders(false);

      // setLoadingVariantImage(true);
     
      // const productShopify = await Promise.all(reformPendingProds.map((purchase) => productsApi.getProductShopify(purchase.productId)));
      // setDataList((prev) => produce(prev, (draft) => {
      //   productShopify.forEach((product, index) => {
      //     const variantImage = product.product?.images?.find((image) => image.variant_ids.includes(parseFloat(draft[index].productVariantShopifyId)))?.src;
      //     const productImage = product.product?.image?.src;
      //     draft[index].variantImage = variantImage || productImage;
      //   });
      // }));
      // setLoadingVariantImage(false);
    } catch (error) {
      message.error(error.message);
      setIsLoadingOrders(false);
      setLoadingVariantImage(false);
    }
  };

  useEffect(() => {
    //gettingListPending(params);
  }, []);

  useEffect(() => {
    gettingListPendingOrders(paramsOrder);
  }, []);

  const onTabChanged = (e) => {
    console.log("ON TAB CHANGED", e);
    switch(e) {
      case("2"):
        console.log("PRODUX");
        gettingListPending(params);
        break;
      case("1"):
        console.log("ORDEX");
        gettingListPendingOrders(paramsOrder);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <TitleBox className="title-page">
        <Title>{t("Pending Purchase")}</Title>
        <Breadcrumbs
          items={[
            { name: "Home", path: "/" },
            { name: Path.PENDING_PURCHASE.name },
          ]}
        />
      </TitleBox>
      <CardContainer>
        <Tabs type="card" onChange={onTabChanged}>
        <TabPane tab="By Orders" key="1">
            <CustomPendingPurchases 
              params={paramsOrder}
              setParams={setParamsOrder}
              isLoadingOrders={isLoadingOrders}
              setIsLoadingOrders={setIsLoadingOrders}
              dataListOrders={dataListOrders}
              setDataList={setDataListOrders}
              gettingListPendingOrders={gettingListPendingOrders}
              loadingVariantImage={loadingVariantImage}
            />
          </TabPane>
          <TabPane tab="By Products" key="2">
            <AllPendingPurchases
              params={params}
              setParams={setParams}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              dataList={dataList}
              setDataList={setDataList}
              gettingListPending={gettingListPending}
              loadingVariantImage={loadingVariantImage}
            />
          </TabPane>
          {/* <TabPane tab="Shopify" key="2">
            <ShopifyPendingPurchases />
          </TabPane> */}
        </Tabs>
      </CardContainer>
    </>
  );
};

export default PendingPurchases;
