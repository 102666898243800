import axios from "axios";
import React, { useState, useEffect } from "react";
import Path from "../../commons/breadcrumbs";
import { Avatar, Button, Row, Col, Typography, message, Spin, DatePicker, Modal, Input, Space } from "antd";
import TitleHeader from "components/title-header";
import Box from "components/box/Box";
import Container from "components/container/Container";
import shipmentsApi from "apis/shipmentsApi";

const { Title, Paragraph, Text } = Typography;

const ShipFeesTable = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState();

    const getFeeTable = () => {
        if (!email) {
            message.error("Email cannot be empty");
            //setOpen(false);
            return;
        }

        if (!validateEmail(email)) {
            message.error("Invalid email format");
            //setOpen(false);
            return;
        }

        const payload = {
            recipientEmail: email
        }

        shipmentsApi.getShipFeesTable(payload)
        .then(r => {
            console.log("R",r);
            message.info(r.message, 6);
            setOpen(false);
        })
        .catch(err => {
            console.log("ERR",err);
            message.error("Cannot process the request. Check the backend", 6);
        });
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (incEmail) => {
        return incEmail.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    return(<>
        <TitleHeader
            listOfBreadcrumb={[
                { name: "Home", path: "/" },
                { name: Path.SHIPMENTS.name, path: Path.SHIPMENTS.pathname },
                {
                    name: Path.SHIP_FEES_TABLE.name,
                },
            ]}
            title={Path.SHIP_FEES_TABLE.name}
        />
        <Box>
            <Container>
                <Row gutter={[16, 16]}>
                    <Spin spinning={isLoading}>
                        <Col xs={24} md={24}>                            
                            <Button style={{backgroundColor:'#34c25a',color: 'white'}} onClick={() => setOpen(true)}>Get Shipment Fees Table</Button>                         
                        </Col>
                    </Spin>
                </Row>
            </Container>
        </Box>

        <Modal
            title="Get Shipment Fees Table"
            centered
            visible={open}
            onOk={getFeeTable}
            onCancel={() => setOpen(false)}
            width={1000}
        >           
            <br/>
            <Space direction="vertical">
                Destination Email 
                <Input placeholder="your@email.com" onChange={handleEmailChange} value={email}/>                        
            </Space> 
            <br/><br/>
            <Text italic>
                *The shipment fees table file will be sent to destination email when the process is complete
            </Text>           
        </Modal>     
    </>);
};

export default ShipFeesTable;
