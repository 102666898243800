import axios from "axios";
import axiosClient from "./axiosClient";

const productsApi = {
  getProducts: (payload) =>
    axiosClient.get("/api/products", { params: payload }),
  getProductVariant: (id) => axiosClient.get(`/api/products/variant/${id}`),
  getDailyStatusProducts: (payload) =>
    axiosClient.get("/api/products", { params: payload }),
  getProductShopify: (id) => axiosClient.get(`/api/products/get/get-shopify/${id}`),
  getOrdersVariant: (id) =>
    axiosClient.get(`/api/orders/list-orders-containing-variant/${id}`),
  getProductById: (id) => axiosClient.get(`/api/products/${id}`),
  createProduct: (payload) => axiosClient.post("/api/products/", payload),
  bulkFetchProducts: (minDate, maxDate) => axiosClient.get(`/api/products/bulk-fetch/date/${minDate}/${maxDate}`),
  bulkFetchAllProducts: () => axiosClient.get('/api/products/bulk-fetch/all'),
  updateVariantWeight: (payload) => axiosClient.put('/api/products/update-variant-weight', payload),
  addProductToExport: (payload) =>  axiosClient.post('/api/products/add-to-product-export', payload),
  removeProductToExport : (payload) => axiosClient.post('/api/products/remove-to-product-export',payload),
  listExportShops : () => axiosClient.get(`/api/products/get/list-export-shops`),
  getListProductExports: (payload) =>
    axiosClient.get("/api/products/get/list-product-exports", { params: payload,}),
  getLatestFileName : (id) => axiosClient.get(`/api/products/get/latest-export/${id}`),
  flushProductStock: (payload) => axiosClient.post(`/api/products/flush-variant`, payload),
  adjustStock: (payload) => axiosClient.post(`/api/products/adjustment`, payload),
  adjustHistory: (id) => axiosClient.get(`/api/products/adjustment-history/${id}`),
  bulkExport : (payload) => axiosClient.post(`/api/products/bulk-export`, payload)
};

export default productsApi;
