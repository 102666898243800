import { Button, Checkbox, Col, DatePicker, Form, Input, message, Row, Space } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FilterContainer } from "./index.style";
import queryString from "query-string";
import purchaseApi from "apis/purchaseApi";
import { moment } from "utils/funcs";
import productsApi from "apis/productsApi";
import Select from "../../../../components/select/Select";
import axios from "axios";

const { RangePicker } = DatePicker;

const Filter = ({
  filter,
  setFilter,
  selectedRows,
  dataList,
  setIsLoading,
  gettingProductExportList,
  params,
  setParams
}) => {

  const history = useHistory();
  const [selectedProductVariant, setSelectedProductVariant] = useState([]); // for unitPrice
  const [localParams, setLocalParams] = useState({
    productFilter: params.productFilter,
    variantFilter: params.variantFilter,
    orderFilter: params.orderFilter,
    sort: params.sort
  })

  const PURCHASE_SORT = {
    SupplierDesc: { name: 'Vendor (Asc)', value: 'product_vendor_asc' },
    SupplierAsc: { name: 'Vendor (Desc)', value: 'product_vendor_desc' },
    OrderDesc: { name: 'Order (Asc)', value: `orders_order_createdAt_asc` },
    OrderAsc: { name: 'Order (Desc)', value: 'orders_order_createdAt_desc' },
    ProductTitleDesc: { name: 'Product Title (Asc)', value: 'product_title_asc' },
    ProductTitleAsc: { name: 'Product Title (Desc)', value: 'product_title_desc' },
  }

  const handleChangeField = async (e) => {
    setLocalParams((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  // const handleChangeDate = (date) => {
  //   setLocalParams((prev) => ({ ...prev, orderFilter: date }));
  // };
  const onClickFilter = () => {
    setParams((prev) => ({ ...prev, ...localParams, page: 1 }))
    gettingProductExportList({ ...localParams, page: 1 })
  };

  const onDownload = async () => {
    const param = {
        // startDate: range ? range[0].toISOString().split( "T" )[0] + 'T00:00:00.000Z' : moment().subtract(90, "days"),
        // endDate: range ? range[1].toISOString().split( "T" )[0] + 'T23:59:59.999Z' : moment(),  //this results to enddate -1 day
        // firstTimeDownload: false,
        // fromLastItem : isLatest,
        // isDHLOnly : isDHLonly,         
    };

    axios({
        url: `${process.env.REACT_APP_API_URL}/api/products/get/export-snappy`,
        method: "GET",
        responseType: "blob", // important
        headers: {
            "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
        },
    })
    .then((response) => {
            console.log('getExportListSnappy res', response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Snappy Export ${moment()}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
    .catch((err) => {            
        if(err.response?.status === 404)
            alert('No valid exports found');            
    });
};

  // const makePurchaseHandler = () => {
  //   if (!selectedRows.length) {
  //     return message.warning("Please Select One Of The Pending Item", 5);
  //   }
  //   setIsLoading(true);
  //   try {
  //     const makePurchase = {
  //       purchaseDate: moment().format("MM/DD/YYYY"),
  //       purchaseTime: moment().format("HH:mm:ss"),
  //       purchaser: "Kaamesh",
  //       purchaseItem: dataList
  //         .filter((obj) => selectedRows.includes(obj.key))
  //         .map((obj) => {
  //           const result = {
  //             productName: obj?.productTitle ? obj?.productTitle : "",
  //             variantName: obj?.variantTitle ? obj?.variantTitle : "",
  //             variantId: obj?.productVariantId ? obj?.productVariantId : 0,
  //             supplier: obj?.supplier ? obj?.supplier : "",
  //             purchaseLink: obj.supplierURL ? obj.supplierURL : "",
  //             pendingQty: obj?.pending ? obj?.pending : 0,
  //             purchasedQty: obj?.quantity ? obj?.quantity : 0,
  //             supplierPrice: 1,
  //             unitPrice: Number(
  //               selectedProductVariant.find(
  //                 (prod) => prod?.id === obj.productVariantId
  //               )?.price
  //             ),
  //             supplierCurrency: obj?.currency,
  //             paidFrom: "Credit Card - 8876982268752",
  //             exchangeRate: 1180.62,
  //           };
  //           return result;
  //         }),
  //     };

  //     // const makePurchase = {
  //     //   purchaseDate: "12/22/2021",
  //     //   purchaseTime: "8:00:00",
  //     //   purchaser: "Kaamesh",
  //     //   purchaseItem: dataList
  //     //     .filter((obj) => selectedRows.includes(obj.key))
  //     //     .map((obj) => {
  //     //       return {
  //     //         productName: "Shirt w",
  //     //         variantName: "S / RED",
  //     //         variantId: obj?.productVariantId ? obj?.productVariantId : 0,
  //     //         supplier: "HM",
  //     //         purchaseLink: "www.sampleurlsuplier.com",
  //     //         pendingQty: obj?.pending ? obj?.pending : 0,
  //     //         purchasedQty: obj?.quantity ? obj?.quantity : 0,
  //     //         supplierPrice: 120,
  //     //         unitPrice: 19800,
  //     //         supplierCurrency: "USD",
  //     //         paidFrom: "Credit Card - 8876982268752",
  //     //         exchangeRate: 1180.62,
  //     //       };
  //     //     }),
  //     // };

  //     console.log("MAKE PURCHASE", makePurchase);

  //     purchaseApi
  //       .postMakePurchaseOrder(JSON.stringify(makePurchase))
  //       .then(({ payload }) => {
  //         let purchaseOrderId = [];
  //         purchaseOrderId.push(payload.id);
  //         message.success("Successfully Create Draft Purchase", 5);
  //         history.push({
  //           pathname: "/purchases/pending-purchases/make-purchase",
  //           search: queryString.stringify({
  //             purchaseOrderId,
  //           }),
  //         });
  //       })
  //       .catch((error) => {
  //         message.error(error.message, 3);
  //       });

  //     setIsLoading(false);
  //   } catch (error) {
  //     setIsLoading(false);
  //     return message.error(error.message, 5);
  //   }
  // };

  // fetch product to get unit price
  const getProductBySelectedId = () => {
    setSelectedProductVariant([]);
    Promise.all(
     dataList
        .filter((obj) => selectedRows.includes(obj.key))
        // .map((item) => productsApi.getProductVariant(item.productVariantId))
    )
    return dataList;
    // return [...prev, dataList];
      // .then((res) => {
      //   res.map(({ variant }) => {
      //     return setSelectedProductVariant((prev) => {
      //       return [...prev, variant];
      //     });
      //   });
      // })
      // .catch((error) => console.log(error));
  };

  useEffect(() => {
    getProductBySelectedId();
  }, [selectedRows]);

  return (
    <FilterContainer layout="vertical">
      <Row gutter={[8, 8]} align="bottom">
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item label="Product title" style={{ marginBottom: 0 }}>
            <Input
              placeholder="Product title"
              name="productFilter"
              value={localParams.productFilter}
              onChange={handleChangeField}
              onPressEnter={onClickFilter}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item label="Variant title" style={{ marginBottom: 0 }}>
            <Input
              placeholder="Variant title"
              name="variantFilter"
              value={localParams.variantFilter}
              onChange={handleChangeField}
              onPressEnter={onClickFilter}
            />
          </Form.Item>
        </Col>
        {/* <Col xs={24} sm={12} md={8} lg={4}>
          <Form.Item label="Order created date" style={{ marginBottom: 0 }}>
            <RangePicker
              format="MM/DD/YYYY"
              onChange={handleChangeDate}
              value={localParams.orderFilter}
              style={{ width: '100%' }}
              allowEmpty={[true, true]}
              onPressEnter={onClickFilter}
            />
          </Form.Item>
        </Col>
        <Col xs={8} sm={6} md={3} lg={3}>
        <Form.Item label="Sort by:" style={{ marginBottom: 0 }}>
            <Select
              name="sort"
              value={localParams.sort}
              placeholder=""
              options={Object.values(PURCHASE_SORT)}
              size="medium"
              className="sort-select"
              isTranslate={false}
              showSearch={true}
              allowClear={true}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handleChangeField}
              onPressEnter={onClickFilter}
            />
          </Form.Item>
        </Col> */}
        <Col>
          <Space direction="vertical">
            <Button type="primary" onClick={onClickFilter}>Filter</Button>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Button type="primary" onClick={onDownload}>Download Export File</Button>
          </Space>
        </Col>
        {/* <Col xs={24} sm={12} md={8} lg={6}>
          <Checkbox>Show all pending purchases</Checkbox>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Checkbox>Show pending purchases for orders placed</Checkbox>
        </Col> */}
      </Row>
    </FilterContainer>
  );
};

Filter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
};

export default Filter;
