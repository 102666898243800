import { Avatar, Button, Row, Col, List, Typography, message, Spin, Upload, Card, Space, Checkbox, Table, Select, DatePicker } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Path from "../../commons/breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import Box from "components/box/Box";
import { useTranslation } from "react-i18next";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import { Icon } from "@iconify/react";
import accountingApi from "apis/accountingApi";
import moment from "moment";

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

const DbBackup = () => {
    const [data, setData] = useState([]);

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (item, record, index) => {
                return (<>{index+1}</>);
            }
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'File Location',
            dataIndex: 'fileLocation',
            key: 'fileLocation',
            render: (item) => {
                const arr = item.split('/');
                const fileName = arr[arr.length-1];
                return(<a href={"http://34.28.115.9/dbBackup/" + fileName}>{item}</a>)
            }
        },
        {
            title: 'Backup Time (KST)',
            dataIndex: 'backupDate',
            key: 'backupDate',
            render: (item) => {
                return(<>{item? moment(item).utcOffset("+0900").format("YYYY-MM-DD HH:mm"): ''}</>)
            }
        },
        /*
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (item, record, index) => {
                return (<><Button type="primary">Download</Button></>);
            }
        },
        */
    ];

    const backupNow = () => {
        alert("Feature not yet implemented!");
    }

    useEffect(async () => {
        const backupData = await accountingApi.getDbBackup();
        setData(backupData);
    },[])
    return (<>
         <TitleHeader
                listOfBreadcrumb={[
                    { name: "Home", path: "/" },
                    { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
                    {
                        name: Path.DB_BACKUP.name,
                    },
                ]}
                title={Path.DB_BACKUP.name}
            />
            <Box>
                <Container>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={16}>
                            <Space direction="vertical">
                                <Text>Backup Files</Text>
                                <Text>Server: 34.28.115.9</Text>
                                <Table dataSource={data} columns={columns} pagination={false} size="large"/>
                            </Space>
                        </Col>
                        <Col xs={24} md={6}>
                            <Space direction="vertical" style={{backgroundColor:"red"}}>
                                <Button type="primary" onClick={backupNow}>Backup Now</Button>
                            </Space>                          
                        </Col>
                    </Row> 
                </Container>
            </Box>
    </>);
};

export default DbBackup;