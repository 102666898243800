import PropTypes from "prop-types";
import React, { Component } from "react";
// import IdleTimer from "react-idle-timer";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
// import { logout } from "store/slices/accountSlice";
import LocalStorage, { SessionStorage } from "utils/storage";
import { routes, routesSpecialLogged } from "../../commons/routes";
import Header from "../../components/header/Header";
import Route from "../../components/router-custom/route";
import SideBar from "../../components/sidebar/SideBar";
import NotFound from "../../pages/not-found";
import NotAllowed from "pages/not-authorized";
import { check2ArrayPermission } from "../../utils/funcs";
import { v4 as uuidv4 } from "uuid";


const ConditionRouter = ({ component: Component, condition, redirect, ...rest }) => (
    <Route {...rest} key={uuidv4} render={(props) => (condition() ? <Component {...props} /> : <Redirect to={redirect} />)} />
);

ConditionRouter.propTypes = {
    component: PropTypes.elementType,
    condition: PropTypes.func.isRequired,
    redirect: PropTypes.string,
};

const isModuleAllowed = async (route) => {
    console.log('CHECK MODULE AUTHORIZATION');
    //const route = props.path;
    const permissions = LocalStorage.get("permissions");
    console.log('Permissions1', permissions);
    console.log('Route Permisson1', route)

    if(!permissions)
        return false;

    if(!route)
        return true;
    console.log('Permissions', permissions);
    console.log('Route Permisson', route);
    
    //I avoided using .map, .forEach as theyre asyncs, might not
    //behave as we expected. Below block of code is to find if a user
    //has permission to route/module    
    for(let i=0; i<permissions.length; i++){
        for(let j=0; j<route.length; j++){
            if(permissions[i] === route[j]){
                console.log('PERMISSION FOUND');
                return true;
            }
        }
    }

    console.log('NO PERMISSION FOUND');
    //no matching permissions
    return false;
};

const isAuthenticated = () => {    
    const accessToken = LocalStorage.get("access_token",null);
    console.log('CHECK AUTHENTICATION',accessToken);
    if(accessToken){
        return true;
    }
    return false;
}

const Routes = () => {
    const { permissionKey, token } = useSelector((state) => state.account);
    console.log("TOKEN STATUS", token);
    console.log("PERMISSION KEYS", permissionKey);
    // const dispatch = useDispatch();
    // const idleTime = useRef(null);

    const renderAuthRoutes = () => (
        <div className="box__body">
            <div className="header-box">
                <Header />
            </div>
            <div className="box__content">
                <SideBar />
                <div className="content-box">
                    <Switch>
                        {/**
                        {routes
                            .filter((route) => check2ArrayPermission(permissionKey, route.permission))                            
                            .map((router, i) => (
                                console.log('ROUTER ', router),
                                <Route key={i} {...router} />
                            ))                            
                        }
                         */}
                        {routes
                            //.filter((route) => check2ArrayPermission(permissionKey, route.permission))                            
                            .map((router, i) => {
                                if(!check2ArrayPermission(permissionKey, router.permission)){
                                    router.component = NotAllowed;
                                }
                                return (<Route key={i} {...router} />);
                            })                             
                        }
                      
                        <Route exact path="/">
                            <Redirect to="/orders" />
                        </Route>

                        {/**
                        <Route exact path="*">
                            <Redirect to="/orders" />
                        </Route>

                        <Route exact path="*" component={NotFound} />
                         */}
                    </Switch>
                </div>
            </div>
        </div>
    );

    // const onIdle = () => {
    //     dispatch(logout());
    //     SessionStorage.remove("token");
    // };  

    return (
        // <IdleTimer ref={idleTime} timeout={2700 * 1000} onIdle={onIdle}>
        <Router>
            <Switch>
                {routesSpecialLogged.map((router, i) => (
                    <ConditionRouter
                        {...router}
                        key={i}
                        //condition={() => !SessionStorage.has("token")} // <- no token in redux (not auth)
                        condition={() => !LocalStorage.has("access_token")} //<-- authenticated user shall have access_token
                        component={router.component} // <- go here if satisfied with condition
                        redirect="/orders" // <- else if not satisfied with condition
                    />
                ))}                             
               
                {
                <ConditionRouter
                    condition={isAuthenticated} // <- got token in redux (auth)
                    component={renderAuthRoutes} // <- go here if satisfied with condition
                    redirect="/login" // <- else if not satisfied with condition
                />
                }            
                
                <Route path="*" exact component={NotFound} />                
            </Switch>
        </Router>
        // </IdleTimer>
    );
};

export default Routes;
