// if no zero, do run this
export const restructureWeight = (itemArray, courierType) => {
    const listOfZeroWeight = itemArray.filter((item) => Number(item.weight) === 0);

    if (!!listOfZeroWeight.length) {
        const firstNonZeroObj = itemArray.find((item) => Number(item.weight) !== 0);
        const newWeight = courierType === "dhl"? Number(firstNonZeroObj.weight) - 0.1 * listOfZeroWeight.length : Number(firstNonZeroObj.weight) - 0.02 * listOfZeroWeight.length;
        const newFirstNonZeroObj = { ...firstNonZeroObj, weight: newWeight, grossWeight: newWeight };
        let result = [];

        for (let index = 0; index < itemArray.length; index++) {
            if (firstNonZeroObj.id === itemArray[index].id) {
                result[index] = newFirstNonZeroObj;
            } else if (Number(itemArray[index].weight) === 0) {
                if(courierType === "dhl"){
                    result[index] = {
                        ...itemArray[index],
                        weight: 0.1,
                        grossWeight: 0.1,
                    };
                } else {
                    result[index] = {
                        ...itemArray[index],
                        weight: 0.02,
                        grossWeight: 0.02,
                    };
                }             
            } else {
                result[index] = itemArray[index];
            }
        }
        return result;
    }

    return itemArray;
};

export const companyNameChecker = (companyName, courier) => {
    if (companyName?.length <= 1 && !!courier) {
        if (courier.apiType === "dhl") {
            return "-";
        } else if (courier.apiType === "fedex") {
            return " ";
        } else {
            return "-";
        }
    }
    return companyName;
};

export const getInsuranceParams = (activeCourier, formValue, currency) => {
    if (activeCourier?.apiType !== "dhl" || !formValue.insuranceRequested || !formValue.insuranceCost) {
        return {
            insuranceCost: "",
            insuranceRequested: false,
            insuranceCurrency: "",
        };
    }
    return {
        insuranceCost: formValue.insuranceCost,
        insuranceRequested: true,
        insuranceCurrency: currency,
    };
};

export const ifNullThenUndefined = (input) => {
    if (input === null) {
        return undefined;
    } else {
        return input;
    }
};

export const detectBookCategory = (word) => {
    if (!word) {
        return null;
    }
    
    if (word.includes(`Pocket Watch Only`)) {
        return false;
    }

    //Removed ["book", "books" "comic", "comics", "magazine", "magazines"], from the list
    const bookCat = ["manhwa",  "manga", "novel", "wadiz"];
    word = word.replace(/[^a-zA-Z0-9 ]/g, '');
    const splitterWord = word?.toLowerCase().split(" ");
    return bookCat.some((cat) => splitterWord?.some((word) => word === cat));
};
