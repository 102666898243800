import { Avatar, Button, Row, Col, Typography, message, Spin, DatePicker, Modal, Input, Space } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Path from "../../commons/breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import Box from "components/box/Box";
import { useTranslation } from "react-i18next";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import { Icon } from "@iconify/react";
import moment from "moment";
import accountingApi from "apis/accountingApi";
import ordersApi from "apis/ordersApi";
import axios from "axios";
import { Header } from "antd/lib/layout/layout";

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

const BookExport = () => {
    const [dateRange, setDateRange] = useState([moment().subtract(1, "days"), moment()]);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [fileSize, setFileSize] = useState();
    const [opResult, setOpResult] = useState();
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState();
    const [fileInfo, setFileInfo] = useState();

    const getBookExport = () => {
        const payload = {
            startDate: moment(dateRange[0]).format('YYYYMMDD'),
            endDate: moment(dateRange[1]).format('YYYYMMDD')
        };
        
        setIsLoading(true);        
        axios.post(process.env.REACT_APP_API_URL+`/api/accounting/book-export`, payload, {
            headers: {              
                "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
            },           
            responseType: "blob"
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `BookExport_${moment().format("YYYYMMDD-HHmmss")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((err) => {
            if(err.response?.status === 404)
                alert('No book export found');  
        })
        .finally(() => {
            setIsLoading(false);
        });     
    
    };

    const processBookExport = () => {       
        const startDate = moment(dateRange[0]).format('YYYYMMDD');
        const endDate = moment(dateRange[1]).format('YYYYMMDD');
        
        const input = document.createElement('input');
        input.type = 'file';
        
        input.onchange = e => {     
            message.info("Please wait until the whole process completes with a document download. Do not close this tab",0);

            const file = e.target.files[0];
            setSelectedFile(file.name); 
            setFileSize(file.size);

            // setting up the reader
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            // here we tell the reader what to do when it's done reading...
            reader.onload = async readerEvent => {
                setIsLoading(true);
                const content = readerEvent.target.result; // this is the content!             

                const form = new FormData();
                //form.append('courier', selectedCourier);
                form.append('file', file);                
                form.append('startDate', startDate);
                form.append('endDate', endDate);

                //shipmentsApi.updateFromXls(payload);
                axios.post(process.env.REACT_APP_API_URL + "/api/accounting/process-edn", form, {
                    headers: {                      
                        "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                        "Content-Type": "multipart/form-data"
                    },   
                    responseType: "blob",
                    timeout: 0                 
                })
                .then(response => {                    
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `BookExport_${moment().format("YYYYMMDD-HHmmss")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {                  
                    if(err.response?.status === 404)
                        alert('No book export found');  
                })
                .finally(() => {
                    setIsLoading(false);
                    message.success("Book export process is complete.");
                });
            }
        }
        
        input.click();
    };
    
    const processBookExport2 = () => {        
        const startDate = moment(dateRange[0]).format('YYYYMMDD');
        const endDate = moment(dateRange[1]).format('YYYYMMDD');
        
        //Check email validity
        if (!email) {
            message.error("Email cannot be empty");
            //setOpen(false);
            return;
        }

        if (!validateEmail(email)) {
            message.error("Invalid email format");
            //setOpen(false);
            return;
        }

        if (!selectedFile) {
            message.error("Must select a file");
            //setOpen(false);
            return;
        }
        
        // setting up the reader
        const reader = new FileReader();
        reader.readAsBinaryString(fileInfo);

        // here we tell the reader what to do when it's done reading...
        reader.onload = async readerEvent => {
            setIsLoading(true);
            const content = readerEvent.target.result; // this is the content!             

            const form = new FormData();
            //form.append('courier', selectedCourier);
            form.append('file', fileInfo);                
            form.append('startDate', startDate);
            form.append('endDate', endDate);
            form.append('email', email);

            axios.post(process.env.REACT_APP_API_URL+ "/api/accounting/process-edn", form, {
                headers: {                      
                    "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                    "Content-Type": "multipart/form-data"
                },   
                          
            })
            .then(response => {                
                //console.log('RESP', response);
            })
            .catch(err => {                  
                if(err.response?.status === 404)
                    alert('Error in processing book export: ' + err);  
            })
            .finally(() => {
                setIsLoading(false);
                setOpen(false);
                message.success("Book export is being processed. The output file will be sent to the email address");
            });
        }    
    };

    const chooseFile = () => {      
        const input = document.createElement('input');
        input.type = 'file';

        input.onchange = e => {  
            const file = e.target.files[0];            
            setSelectedFile(file.name); 
            setFileSize(file.size);
            setFileInfo(file);
        }

        input.click();
    };
    
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const validateEmail = (incEmail) => {
        return incEmail.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    
    return (<>
        <TitleHeader
            listOfBreadcrumb={[
                { name: "Home", path: "/" },
                { name: Path.ACCOUNTING.name, path: Path.ACCOUNTING.pathname },
                {
                    name: Path.BOOK_EXPORT.name,
                },
            ]}
            title={Path.BOOK_EXPORT.name}
        />
        <Box>
            <Container>                      
                <Row gutter={[16, 16]}>
                    <Spin spinning={isLoading}>
                        <Col xs={24} md={24}>
                            Date Range &nbsp;
                            <RangePicker defaultValue={dateRange} onChange={setDateRange} />                       
                            &nbsp;&nbsp;
                            <Button type="primary">Show Data</Button>
                            &nbsp;
                            <Button style={{backgroundColor:'#34c25a',color: 'white'}} onClick={getBookExport}>Get Book Export</Button>
                            &nbsp;
                            <Button style={{backgroundColor:'#34c25a',color: 'white'}} onClick={() => setOpen(true)}>Upload & Process Book Export</Button>                         
                        </Col>
                    </Spin>
                </Row>
            </Container>
        </Box>
        
        <Modal
            title="Upload & Process Book Export"
            centered
            visible={open}
            onOk={processBookExport2}
            onCancel={() => setOpen(false)}
            width={1000}
        >           
            <br/>
            <Space direction="vertical">
                Destination Email 
                <Input placeholder="your@email.com" onChange={handleEmailChange} value={email}/>
                EDN File
                <Space direction="horizontal">
                    <Input placeholder="EDN file" value={selectedFile}/>
                    <Button type="primary" onClick={chooseFile}>Select EDN File</Button>
                </Space>               
            </Space> 
            <br/><br/>
            <Text italic>
                *Book export file will be sent to destination email when the process is complete
            </Text>           
        </Modal>             
    </>)
}

export default BookExport;