const etd = [
        { countryCode: "AD", inBound: false, outBound: false },
        { countryCode: "AE", inBound: false, outBound: true },
        { countryCode: "AF", inBound: true, outBound: true },
        { countryCode: "AG", inBound: false, outBound: false },
        { countryCode: "AI", inBound: false, outBound: false },
        { countryCode: "AL", inBound: true, outBound: false },
        { countryCode: "AM", inBound: false, outBound: false },
        { countryCode: "AN", inBound: false, outBound: true },
        { countryCode: "AO", inBound: true, outBound: true },
        { countryCode: "AR", inBound: false, outBound: true },
        { countryCode: "AS", inBound: false, outBound: false },
        { countryCode: "AT", inBound: true, outBound: true },
        { countryCode: "AU", inBound: true, outBound: true },
        { countryCode: "AW", inBound: false, outBound: true },
        { countryCode: "AX", inBound: false, outBound: false },
        { countryCode: "AZ", inBound: false, outBound: false },
        { countryCode: "BA", inBound: false, outBound: true },
        { countryCode: "BB", inBound: true, outBound: true },
        { countryCode: "BE", inBound: true, outBound: true },
        { countryCode: "BF", inBound: false, outBound: false },
        { countryCode: "BG", inBound: true, outBound: true },
        { countryCode: "BH", inBound: true, outBound: true },
        { countryCode: "BI", inBound: false, outBound: false },
        { countryCode: "BJ", inBound: false, outBound: false },
        { countryCode: "BM", inBound: true, outBound: true },
        { countryCode: "BN", inBound: true, outBound: true },
        { countryCode: "BQ", inBound: false, outBound: true },    
        { countryCode: "BR", inBound: false, outBound: false },
        { countryCode: "BS", inBound: false, outBound: true },
        { countryCode: "BT", inBound: false, outBound: false },
        { countryCode: "BV", inBound: false, outBound: false },
        { countryCode: "BW", inBound: false, outBound: false },
        { countryCode: "BY", inBound: false, outBound: false },
        { countryCode: "BZ", inBound: false, outBound: false },
        { countryCode: "CA", inBound: true, outBound: true },
        { countryCode: "CC", inBound: false, outBound: false },
        { countryCode: "CD", inBound: false, outBound: false },
        { countryCode: "CG", inBound: false, outBound: false },
        { countryCode: "CH", inBound: true, outBound: true },
        { countryCode: "CI", inBound: true, outBound: true },
        { countryCode: "CK", inBound: false, outBound: false },
        { countryCode: "CL", inBound: true, outBound: true },
        { countryCode: "CM", inBound: false, outBound: false },
        { countryCode: "CN", inBound: true, outBound: true },
        { countryCode: "CO", inBound: true, outBound: true },
        { countryCode: "CR", inBound: false, outBound: true },
        { countryCode: "CV", inBound: false, outBound: false },
        { countryCode: "CW", inBound: false, outBound: true },
        { countryCode: "CY", inBound: true, outBound: true },
        { countryCode: "CZ", inBound: true, outBound: true },
        { countryCode: "DE", inBound: true, outBound: true },
        { countryCode: "DJ", inBound: false, outBound: true },
        { countryCode: "DK", inBound: true, outBound: true },
        { countryCode: "DM", inBound: false, outBound: false },
        { countryCode: "DO", inBound: false, outBound: true },
        { countryCode: "DZ", inBound: false, outBound: false },
        { countryCode: "EC", inBound: true, outBound: true },
        { countryCode: "EE", inBound: true, outBound: true },
        { countryCode: "EG", inBound: false, outBound: true },
        { countryCode: "EH", inBound: false, outBound: false },
        { countryCode: "ER", inBound: false, outBound: false },
        { countryCode: "ES", inBound: true, outBound: true },
        { countryCode: "ET", inBound: false, outBound: false },
        { countryCode: "FI", inBound: true, outBound: true },
        { countryCode: "FJ", inBound: false, outBound: false },
        { countryCode: "FK", inBound: false, outBound: false },
        { countryCode: "FM", inBound: true, outBound: false },
        { countryCode: "FO", inBound: false, outBound: false },
        { countryCode: "FR", inBound: true, outBound: true },
        { countryCode: "GA", inBound: false, outBound: false },
        { countryCode: "GB", inBound: true, outBound: true },
        { countryCode: "GD", inBound: false, outBound: false },
        { countryCode: "GE", inBound: false, outBound: false },
        { countryCode: "GF", inBound: false, outBound: false },
        { countryCode: "GH", inBound: false, outBound: true },
        { countryCode: "GI", inBound: false, outBound: false },
        { countryCode: "GL", inBound: false, outBound: false },
        { countryCode: "GM", inBound: false, outBound: false },
        { countryCode: "GN", inBound: false, outBound: false },
        { countryCode: "GP", inBound: false, outBound: true },
        { countryCode: "GR", inBound: false, outBound: true },
        { countryCode: "GT", inBound: false, outBound: true },
        { countryCode: "GU", inBound: true, outBound: true },
        { countryCode: "GY", inBound: false, outBound: false },
        { countryCode: "GZ", inBound: false, outBound: false },
        { countryCode: "HK", inBound: true, outBound: true },
        { countryCode: "HM", inBound: false, outBound: false },
        { countryCode: "HN", inBound: false, outBound: true },
        { countryCode: "HR", inBound: true, outBound: true },
        { countryCode: "HT", inBound: false, outBound: false },
        { countryCode: "HU", inBound: true, outBound: true },
        { countryCode: "ID", inBound: true, outBound: true },
        { countryCode: "IE", inBound: true, outBound: true },
        { countryCode: "IL", inBound: true, outBound: true },
        { countryCode: "IN", inBound: true, outBound: false },
        { countryCode: "IO", inBound: false, outBound: false },
        { countryCode: "IQ", inBound: false, outBound: false },
        { countryCode: "IS", inBound: true, outBound: true },
        { countryCode: "IT", inBound: true, outBound: true },
        { countryCode: "JE", inBound: true, outBound: true },
        { countryCode: "JM", inBound: true, outBound: false },
        { countryCode: "JO", inBound: false, outBound: true },
        { countryCode: "JP", inBound: true, outBound: true },
        { countryCode: "KE", inBound: false, outBound: true },
        { countryCode: "KG", inBound: true, outBound: true },
        { countryCode: "KH", inBound: false, outBound: true },
        { countryCode: "KN", inBound: false, outBound: true },
        { countryCode: "KR", inBound: true, outBound: true },
        { countryCode: "KV", inBound: false, outBound: false },
        { countryCode: "KW", inBound: false, outBound: true },
        { countryCode: "KY", inBound: false, outBound: true },
        { countryCode: "KZ", inBound: false, outBound: true },
        { countryCode: "LA", inBound: false, outBound: true },
        { countryCode: "LC", inBound: false, outBound: true },
        { countryCode: "LI", inBound: true, outBound: true },
        { countryCode: "LK", inBound: true, outBound: true },
        { countryCode: "LS", inBound: false, outBound: true },
        { countryCode: "LT", inBound: true, outBound: true },
        { countryCode: "LU", inBound: true, outBound: true },
        { countryCode: "LV", inBound: true, outBound: true },
        { countryCode: "LY", inBound: false, outBound: false },
        { countryCode: "MA", inBound: false, outBound: true },
        { countryCode: "MC", inBound: true, outBound: true },
        { countryCode: "MD", inBound: false, outBound: false },
        { countryCode: "ME", inBound: false, outBound: false },
        { countryCode: "MF", inBound: false, outBound: true },
        { countryCode: "MG", inBound: false, outBound: true },
        { countryCode: "MH", inBound: true, outBound: false },
        { countryCode: "MK", inBound: false, outBound: false },
        { countryCode: "ML", inBound: false, outBound: false },
        { countryCode: "MN", inBound: false, outBound: false },
        { countryCode: "MO", inBound: true, outBound: true },
        { countryCode: "MP", inBound: true, outBound: false },
        { countryCode: "MQ", inBound: false, outBound: false },
        { countryCode: "MR", inBound: false, outBound: false },
        { countryCode: "MS", inBound: false, outBound: true },
        { countryCode: "MT", inBound: true, outBound: true },
        { countryCode: "MU", inBound: true, outBound: true },
        { countryCode: "MV", inBound: false, outBound: false },
        { countryCode: "MW", inBound: false, outBound: false },
        { countryCode: "MX", inBound: true, outBound: true },
        { countryCode: "MY", inBound: true, outBound: true },
        { countryCode: "MZ", inBound: false, outBound: false },
        { countryCode: "NA", inBound: false, outBound: false },
        { countryCode: "NC", inBound: false, outBound: false },
        { countryCode: "NE", inBound: false, outBound: false },
        { countryCode: "NF", inBound: false, outBound: false },
        { countryCode: "NG", inBound: false, outBound: false },
        { countryCode: "NI", inBound: false, outBound: true },
        { countryCode: "NL", inBound: true, outBound: true },
        { countryCode: "NO", inBound: true, outBound: true },
        { countryCode: "NZ", inBound: true, outBound: true },
        { countryCode: "OM", inBound: false, outBound: true },
        { countryCode: "PA", inBound: false, outBound: true },
        { countryCode: "PE", inBound: true, outBound: true },
        { countryCode: "PF", inBound: false, outBound: false },
        { countryCode: "PG", inBound: false, outBound: false },
        { countryCode: "PH", inBound: true, outBound: true },
        { countryCode: "PK", inBound: true, outBound: false },
        { countryCode: "PL", inBound: true, outBound: true },
        { countryCode: "PN", inBound: false, outBound: false },
        { countryCode: "PR", inBound: true, outBound: true },
        { countryCode: "PS", inBound: true, outBound: false },
        { countryCode: "PT", inBound: true, outBound: true },
        { countryCode: "PW", inBound: true, outBound: false },
        { countryCode: "PY", inBound: true, outBound: true },
        { countryCode: "QA", inBound: false, outBound: false },
        { countryCode: "RE", inBound: false, outBound: false },
        { countryCode: "RO", inBound: true, outBound: true },
        { countryCode: "RS", inBound: false, outBound: false },
        { countryCode: "RW", inBound: false, outBound: false },
        { countryCode: "SA", inBound: true, outBound: true },
        { countryCode: "SC", inBound: false, outBound: false },    
        { countryCode: "SE", inBound: true, outBound: true },
        { countryCode: "SG", inBound: true, outBound: true },
        { countryCode: "SI", inBound: true, outBound: true },
        { countryCode: "SJ", inBound: false, outBound: false },
        { countryCode: "SK", inBound: true, outBound: true },
        { countryCode: "SM", inBound: true, outBound: false },
        { countryCode: "SN", inBound: false, outBound: false },
        { countryCode: "SR", inBound: false, outBound: false },
        { countryCode: "SV", inBound: false, outBound: true },
        { countryCode: "SX", inBound: false, outBound: true },
        { countryCode: "SZ", inBound: false, outBound: false },
        { countryCode: "TC", inBound: false, outBound: true },
        { countryCode: "TD", inBound: false, outBound: false },
        { countryCode: "TF", inBound: false, outBound: false },
        { countryCode: "TG", inBound: true, outBound: true },
        { countryCode: "TH", inBound: true, outBound: true },
        { countryCode: "TL", inBound: false, outBound: true },
        { countryCode: "TN", inBound: true, outBound: true },
        { countryCode: "TO", inBound: false, outBound: false },
        { countryCode: "TR", inBound: true, outBound: true },
        { countryCode: "TT", inBound: false, outBound: true },
        { countryCode: "TW", inBound: true, outBound: true },
        { countryCode: "TZ", inBound: false, outBound: false },
        { countryCode: "UG", inBound: false, outBound: false },
        { countryCode: "UM", inBound: false, outBound: false },
        { countryCode: "US", inBound: true, outBound: true },
        { countryCode: "UY", inBound: true, outBound: true },
        { countryCode: "UZ", inBound: false, outBound: false },
        { countryCode: "VA", inBound: true, outBound: false },
        { countryCode: "VC", inBound: false, outBound: false },
        { countryCode: "VE", inBound: false, outBound: false },
        { countryCode: "VG", inBound: false, outBound: true },
        { countryCode: "VI", inBound: false, outBound: true },
        { countryCode: "VN", inBound: true, outBound: true },
        { countryCode: "VU", inBound: false, outBound: false },
        { countryCode: "WF", inBound: false, outBound: false },
        { countryCode: "WS", inBound: false, outBound: false },
        { countryCode: "ZA", inBound: true, outBound: true },
        { countryCode: "ZM", inBound: false, outBound: false },
        { countryCode: "ZW", inBound: false, outBound: false },
        ]
    
const plt = [
            { countryCode: "AD", inBound: true, outBound: true },
            { countryCode: "AE", inBound: true, outBound: true },
            { countryCode: "AF", inBound: false, outBound: false },
            { countryCode: "AG", inBound: true, outBound: false },
            { countryCode: "AI", inBound: true, outBound: false },
            { countryCode: "AL", inBound: true, outBound: true },
            { countryCode: "AM", inBound: false, outBound: false },
            { countryCode: "AO", inBound: true, outBound: true },
            { countryCode: "AR", inBound: true, outBound: true },
            { countryCode: "AS", inBound: true, outBound: true },
            { countryCode: "AT", inBound: true, outBound: true },
            { countryCode: "AU", inBound: true, outBound: true },
            { countryCode: "AW", inBound: true, outBound: false },
            { countryCode: "AZ", inBound: false, outBound: false },
            { countryCode: "BA", inBound: true, outBound: true },
            { countryCode: "BB", inBound: true, outBound: false },
            { countryCode: "BD", inBound: false, outBound: false },
            { countryCode: "BE", inBound: true, outBound: true },
            { countryCode: "BF", inBound: true, outBound: true },
            { countryCode: "BG", inBound: true, outBound: true },
            { countryCode: "BH", inBound: true, outBound: true },
            { countryCode: "BI", inBound: true, outBound: true },
            { countryCode: "BJ", inBound: true, outBound: true },
            { countryCode: "BL", inBound: true, outBound: false },
            { countryCode: "BM", inBound: true, outBound: false },
            { countryCode: "BN", inBound: true, outBound: true },
            { countryCode: "BO", inBound: false, outBound: true },
            { countryCode: "BQ", inBound: true, outBound: false },
            { countryCode: "BR", inBound: false, outBound: false },
            { countryCode: "BS", inBound: true, outBound: false },
            { countryCode: "BT", inBound: true, outBound: true },
            { countryCode: "BW", inBound: true, outBound: true },
            { countryCode: "BY", inBound: false, outBound: false },
            { countryCode: "BZ", inBound: true, outBound: false },
            { countryCode: "CA", inBound: true, outBound: true },
            { countryCode: "CD", inBound: true, outBound: true },
            { countryCode: "CF", inBound: true, outBound: true },
            { countryCode: "CG", inBound: true, outBound: true },
            { countryCode: "CH", inBound: true, outBound: true },
            { countryCode: "CI", inBound: true, outBound: true },
            { countryCode: "CK", inBound: true, outBound: true },
            { countryCode: "CL", inBound: true, outBound: true },
            { countryCode: "CM", inBound: true, outBound: true },
            { countryCode: "CN", inBound: true, outBound: true },
            { countryCode: "CO", inBound: true, outBound: true },
            { countryCode: "CR", inBound: false, outBound: true },
            { countryCode: "CU", inBound: false, outBound: false },
            { countryCode: "CV", inBound: true, outBound: true },
            { countryCode: "CW", inBound: true, outBound: false },
            { countryCode: "CY", inBound: true, outBound: true },
            { countryCode: "CZ", inBound: true, outBound: true },
            { countryCode: "DE", inBound: true, outBound: true },
            { countryCode: "DJ", inBound: true, outBound: true },
            { countryCode: "DK", inBound: true, outBound: true },
            { countryCode: "DM", inBound: true, outBound: false },
            { countryCode: "DO", inBound: true, outBound: true },
            { countryCode: "DZ", inBound: false, outBound: false },
            { countryCode: "EC", inBound: false, outBound: true },
            { countryCode: "EE", inBound: true, outBound: true },
            { countryCode: "EG", inBound: false, outBound: true },
            { countryCode: "ER", inBound: true, outBound: true },
            { countryCode: "ES", inBound: true, outBound: true },
            { countryCode: "ET", inBound: true, outBound: true },
            { countryCode: "FI", inBound: true, outBound: true },
            { countryCode: "FJ", inBound: true, outBound: true },
            { countryCode: "FK", inBound: true, outBound: true },
            { countryCode: "FM", inBound: true, outBound: true },
            { countryCode: "FO", inBound: true, outBound: true },
            { countryCode: "FR", inBound: true, outBound: true },
            { countryCode: "GA", inBound: true, outBound: true },
            { countryCode: "GB", inBound: true, outBound: true },
            { countryCode: "GD", inBound: true, outBound: false },
            { countryCode: "GE", inBound: false, outBound: false },
            { countryCode: "GF", inBound: true, outBound: true },
            { countryCode: "GG", inBound: true, outBound: true },
            { countryCode: "GH", inBound: true, outBound: true },
            { countryCode: "GI", inBound: true, outBound: true },
            { countryCode: "GL", inBound: false, outBound: true },
            { countryCode: "GM", inBound: true, outBound: true },
            { countryCode: "GN", inBound: true, outBound: true },
            { countryCode: "GP", inBound: true, outBound: true },
            { countryCode: "GQ", inBound: true, outBound: true },
            { countryCode: "GR", inBound: true, outBound: true },
            { countryCode: "GT", inBound: false, outBound: true },
            { countryCode: "GU", inBound: true, outBound: true },
            { countryCode: "GW", inBound: true, outBound: true },
            { countryCode: "GY", inBound: true, outBound: false },
            { countryCode: "HK", inBound: true, outBound: true },
            { countryCode: "HN", inBound: false, outBound: true },
            { countryCode: "HR", inBound: true, outBound: true },
            { countryCode: "HT", inBound: true, outBound: false },
            { countryCode: "HU", inBound: true, outBound: true },
            { countryCode: "IC", inBound: true, outBound: true },
            { countryCode: "ID", inBound: true, outBound: true },
            { countryCode: "IE", inBound: true, outBound: true },
            { countryCode: "IL", inBound: true, outBound: true },
            { countryCode: "IN", inBound: false, outBound: false },
            { countryCode: "IN", inBound: false, outBound: true },
            { countryCode: "IQ", inBound: false, outBound: false },
            { countryCode: "IR", inBound: false, outBound: false },
            { countryCode: "IS", inBound: true, outBound: true },
            { countryCode: "IT", inBound: true, outBound: true },
            { countryCode: "JE", inBound: true, outBound: true },
            { countryCode: "JM", inBound: true, outBound: false },
            { countryCode: "JO", inBound: true, outBound: true },
            { countryCode: "JP", inBound: true, outBound: true },
            { countryCode: "KE", inBound: true, outBound: true },
            { countryCode: "KG", inBound: true, outBound: true },
            { countryCode: "KH", inBound: true, outBound: true },
            { countryCode: "KI", inBound: true, outBound: true },
            { countryCode: "KM", inBound: true, outBound: true },
            { countryCode: "KN", inBound: true, outBound: false },
            { countryCode: "KP", inBound: false, outBound: false },
            { countryCode: "KR", inBound: true, outBound: true },
            { countryCode: "KV", inBound: false, outBound: false },
            { countryCode: "KW", inBound: true, outBound: true },
            { countryCode: "KY", inBound: false, outBound: false },
            { countryCode: "KZ", inBound: false, outBound: true },
            { countryCode: "LA", inBound: true, outBound: true },
            { countryCode: "LB", inBound: false, outBound: true },
            { countryCode: "LC", inBound: true, outBound: false },
            { countryCode: "LI", inBound: true, outBound: true },
            { countryCode: "LK", inBound: true, outBound: true },
            { countryCode: "LR", inBound: true, outBound: false },
            { countryCode: "LS", inBound: false, outBound: false },
            { countryCode: "LT", inBound: true, outBound: true },
            { countryCode: "LU", inBound: true, outBound: true },
            { countryCode: "LV", inBound: true, outBound: true },
            { countryCode: "LY", inBound: false, outBound: true },
            { countryCode: "MA", inBound: false, outBound: true },
            { countryCode: "MC", inBound: true, outBound: true },
            { countryCode: "MD", inBound: false, outBound: false },
            { countryCode: "ME", inBound: false, outBound: false },
            { countryCode: "MG", inBound: true, outBound: true },
            { countryCode: "MH", inBound: true, outBound: true },
            { countryCode: "MK", inBound: false, outBound: false },
            { countryCode: "ML", inBound: true, outBound: false },
            { countryCode: "MM", inBound: false, outBound: false },
            { countryCode: "MN", inBound: true, outBound: true },
            { countryCode: "MO", inBound: true, outBound: true },
            { countryCode: "MP", inBound: true, outBound: true },
            { countryCode: "MQ", inBound: true, outBound: false },
            { countryCode: "MR", inBound: true, outBound: true },
            { countryCode: "MS", inBound: true, outBound: false },
            { countryCode: "MT", inBound: true, outBound: false },
            { countryCode: "MU", inBound: true, outBound: true },
            { countryCode: "MV", inBound: true, outBound: true },
            { countryCode: "MW", inBound: true, outBound: true },
            { countryCode: "MX", inBound: true, outBound: true },
            { countryCode: "MY", inBound: true, outBound: true },
            { countryCode: "MZ", inBound: true, outBound: true },
            { countryCode: "NA", inBound: true, outBound: true },
            { countryCode: "NC", inBound: true, outBound: false },
            { countryCode: "NE", inBound: true, outBound: true },
            { countryCode: "NG", inBound: false, outBound: true },
            { countryCode: "NI", inBound: false, outBound: false },
            { countryCode: "NL", inBound: true, outBound: true },
            { countryCode: "NO", inBound: true, outBound: true },
            { countryCode: "NP", inBound: true, outBound: false },
            { countryCode: "NR", inBound: true, outBound: false },
            { countryCode: "NU", inBound: true, outBound: false },
            { countryCode: "NZ", inBound: true, outBound: true },
            { countryCode: "OM", inBound: true, outBound: true },
            { countryCode: "PA", inBound: true, outBound: true },
            { countryCode: "PE", inBound: true, outBound: true },
            { countryCode: "PF", inBound: true, outBound: true },
            { countryCode: "PG", inBound: true, outBound: true },
            { countryCode: "PH", inBound: true, outBound: true },
            { countryCode: "PK", inBound: false, outBound: false },
            { countryCode: "PL", inBound: true, outBound: true },
            { countryCode: "PN", inBound: true, outBound: true },
            { countryCode: "PR", inBound: true, outBound: true },
            { countryCode: "PT", inBound: true, outBound: true },
            { countryCode: "PW", inBound: true, outBound: false },
            { countryCode: "PY", inBound: true, outBound: false },
            { countryCode: "QA", inBound: true, outBound: true },
            { countryCode: "RE", inBound: true, outBound: true },
            { countryCode: "RO", inBound: true, outBound: true },
            { countryCode: "RS", inBound: false, outBound: false },
            { countryCode: "RU", inBound: false, outBound: true },
            { countryCode: "RW", inBound: true, outBound: false },
            { countryCode: "SA", inBound: true, outBound: true },
            { countryCode: "SB", inBound: true, outBound: false },
            { countryCode: "SC", inBound: true, outBound: false },
            { countryCode: "SD", inBound: false, outBound: false },
            { countryCode: "SE", inBound: true, outBound: true },
            { countryCode: "SG", inBound: true, outBound: true },
            { countryCode: "SH", inBound: true, outBound: false },
            { countryCode: "SI", inBound: true, outBound: true },
            { countryCode: "SK", inBound: true, outBound: true },
            { countryCode: "SL", inBound: true, outBound: false },
            { countryCode: "SM", inBound: false, outBound: false },
            { countryCode: "SN", inBound: true, outBound: false },
            { countryCode: "SO", inBound: false, outBound: false },
            { countryCode: "SR", inBound: true, outBound: false },
            { countryCode: "SS", inBound: false, outBound: false },
            { countryCode: "ST", inBound: true, outBound: false },
            { countryCode: "SV", inBound: false, outBound: true },
            { countryCode: "SX", inBound: true, outBound: false },
            { countryCode: "SY", inBound: false, outBound: true },
            { countryCode: "SZ", inBound: true, outBound: false },
            { countryCode: "TC", inBound: true, outBound: false },
            { countryCode: "TC", inBound: true, outBound: false },
            { countryCode: "TD", inBound: true, outBound: false },
            { countryCode: "TG", inBound: true, outBound: false },
            { countryCode: "TH", inBound: true, outBound: true },
            { countryCode: "TJ", inBound: false, outBound: false },
            { countryCode: "TL", inBound: true, outBound: false },
            { countryCode: "TM", inBound: true, outBound: true },
            { countryCode: "TN", inBound: false, outBound: false },
            { countryCode: "TO", inBound: true, outBound: false },
            { countryCode: "TR", inBound: false, outBound: true },
            { countryCode: "TT", inBound: true, outBound: false },
            { countryCode: "TV", inBound: true, outBound: false },
            { countryCode: "TW", inBound: true, outBound: true },
            { countryCode: "TZ", inBound: true, outBound: true },
            { countryCode: "UA", inBound: false, outBound: false },
            { countryCode: "UG", inBound: true, outBound: false },
            { countryCode: "US", inBound: true, outBound: true },
            { countryCode: "UY", inBound: true, outBound: true },
            { countryCode: "VA", inBound: true, outBound: false },
            { countryCode: "VC", inBound: true, outBound: false },
            { countryCode: "VE", inBound: true, outBound: false },
            { countryCode: "VG", inBound: true, outBound: false },
            { countryCode: "VI", inBound: true, outBound: true },
            { countryCode: "VN", inBound: false, outBound: true },
            { countryCode: "VU", inBound: true, outBound: true },
            { countryCode: "WS", inBound: true, outBound: true },
            { countryCode: "XB", inBound: true, outBound: false },
            { countryCode: "XC", inBound: true, outBound: false },
            { countryCode: "XE", inBound: true, outBound: false },
            { countryCode: "XM", inBound: true, outBound: false },
            { countryCode: "XN", inBound: true, outBound: false },
            { countryCode: "XS", inBound: false, outBound: false },
            { countryCode: "XY", inBound: true, outBound: false },
            { countryCode: "YE", inBound: false, outBound: false },
            { countryCode: "YT", inBound: true, outBound: false },
            { countryCode: "ZA", inBound: true, outBound: true },
            { countryCode: "ZM", inBound: true, outBound: true },
            { countryCode: "ZW", inBound: true, outBound: false },
        ];

const edi = [
    { countryCode: "FI", inBound: true, outBound:true },
    { countryCode: "AG", inBound: true, outBound:false },
    { countryCode: "AR", inBound: true, outBound:true },
    { countryCode: "AU", inBound: true, outBound:true },
    { countryCode: "AT", inBound: true, outBound:true },
    { countryCode: "PT", inBound: true, outBound:true },
    { countryCode: "BS", inBound: true, outBound:false },
    { countryCode: "BH", inBound: true, outBound:true },
    { countryCode: "BD", inBound: true, outBound:true },
    { countryCode: "BB", inBound: true, outBound:true },
    { countryCode: "BE", inBound: true, outBound:true },
    { countryCode: "BM", inBound: true, outBound:false },
    { countryCode: "BQ", inBound: true, outBound:false },
    { countryCode: "BA", inBound: true, outBound:true },
    { countryCode: "BN", inBound: true, outBound:true },
    { countryCode: "BG", inBound: true, outBound:true },
    { countryCode: "KH", inBound: true, outBound:true },
    { countryCode: "CA", inBound: true, outBound:true },
    { countryCode: "KY", inBound: true, outBound:false },
    { countryCode: "CL", inBound: true, outBound:true },
    { countryCode: "CN", inBound: true, outBound:true },
    { countryCode: "CO", inBound: true, outBound:true },
    { countryCode: "CR", inBound: true, outBound:true },
    { countryCode: "HR", inBound: true, outBound:true },
    { countryCode: "CW", inBound: true, outBound:false },
    { countryCode: "CY", inBound: true, outBound:true },
    { countryCode: "CZ", inBound: true, outBound:true },
    { countryCode: "DK", inBound: true, outBound:true },
    { countryCode: "EC", inBound: true, outBound:true },
    { countryCode: "SV", inBound: true, outBound:true },
    { countryCode: "GB", inBound: true, outBound:true },
    { countryCode: "EE", inBound: true, outBound:true },
    { countryCode: "FJ", inBound: true, outBound:true },
    { countryCode: "FR", inBound: true, outBound:true },
    { countryCode: "GE", inBound: true, outBound:true },
    { countryCode: "DE", inBound: true, outBound:true },
    { countryCode: "GI", inBound: true, outBound:true },
    { countryCode: "GU", inBound: true, outBound:true },
    { countryCode: "GT", inBound: true, outBound:true },
    { countryCode: "GG", inBound: true, outBound:true },
    { countryCode: "HT", inBound: true, outBound:true },
    { countryCode: "NL", inBound: true, outBound:true },
    { countryCode: "HN", inBound: true, outBound:true },
    { countryCode: "HK", inBound: true, outBound:true },
    { countryCode: "HU", inBound: true, outBound:true },
    { countryCode: "IS", inBound: true, outBound:true },
    { countryCode: "IN", inBound: true, outBound:true },
    { countryCode: "ID", inBound: true, outBound:true },
    { countryCode: "IE", inBound: true, outBound:true },
    { countryCode: "IL", inBound: true, outBound:true },
    { countryCode: "IT", inBound: true, outBound:true },
    { countryCode: "JM", inBound: true, outBound:true },
    { countryCode: "JP", inBound: true, outBound:true },
    { countryCode: "JE", inBound: true, outBound:true },
    { countryCode: "KE", inBound: true, outBound:true },
    { countryCode: "KR", inBound: true, outBound:true },
    { countryCode: "KV", inBound: true, outBound:true },
    { countryCode: "LA", inBound: true, outBound:true },
    { countryCode: "LV", inBound: true, outBound:true },
    { countryCode: "LI", inBound: true, outBound:true },
    { countryCode: "LT", inBound: true, outBound:true },
    { countryCode: "LU", inBound: true, outBound:true },
    { countryCode: "MO", inBound: true, outBound:true },
    { countryCode: "MY", inBound: true, outBound:true },
    { countryCode: "MT", inBound: true, outBound:true },
    { countryCode: "MX", inBound: true, outBound:true },
    { countryCode: "MC", inBound: true, outBound:true },
    { countryCode: "MA", inBound: true, outBound:true },
    { countryCode: "NZ", inBound: true, outBound:true },
    { countryCode: "NG", inBound: true, outBound:true },
    { countryCode: "NO", inBound: true, outBound:true },
    { countryCode: "PE", inBound: true, outBound:true },
    { countryCode: "PH", inBound: true, outBound:true },
    { countryCode: "PL", inBound: true, outBound:true },
    { countryCode: "PR", inBound: true, outBound:true },
    { countryCode: "RO", inBound: true, outBound:true },
    { countryCode: "SA", inBound: true, outBound:true },
    { countryCode: "RS", inBound: true, outBound:true },
    { countryCode: "SG", inBound: true, outBound:true },
    { countryCode: "SK", inBound: true, outBound:true },
    { countryCode: "SI", inBound: true, outBound:true },
    { countryCode: "ZA", inBound: true, outBound:true },
    { countryCode: "ES", inBound: true, outBound:true },
    { countryCode: "VI", inBound: true, outBound:false },
    { countryCode: "KN", inBound: true, outBound:true },
    { countryCode: "LC", inBound: true, outBound:false },
    { countryCode: "SX", inBound: true, outBound:false },
    { countryCode: "SE", inBound: true, outBound:true },
    { countryCode: "CH", inBound: true, outBound:true },
    { countryCode: "TW", inBound: true, outBound:true },
    { countryCode: "TH", inBound: true, outBound:true },
    { countryCode: "TT", inBound: true, outBound:false },
    { countryCode: "TR", inBound: true, outBound:true },
    { countryCode: "AE", inBound: true, outBound:true },
    { countryCode: "US", inBound: true, outBound:true },
    { countryCode: "UY", inBound: true, outBound:true },
    { countryCode: "VA", inBound: true, outBound:true },
    { countryCode: "VN", inBound: true, outBound:true },
    { countryCode: "AX", inBound: false, outBound:true },
    { countryCode: "AS", inBound: false, outBound:true },
    { countryCode: "AD", inBound: false, outBound:true },
    { countryCode: "A2", inBound: false, outBound:true },
    { countryCode: "BY", inBound: false, outBound:true },
    { countryCode: "BT", inBound: false, outBound:true },
    { countryCode: "IC", inBound: false, outBound:true },
    { countryCode: "CK", inBound: false, outBound:true },
    { countryCode: "EN", inBound: false, outBound:true },
    { countryCode: "FO", inBound: false, outBound:true },
    { countryCode: "PF", inBound: false, outBound:true },
    { countryCode: "GR", inBound: false, outBound:true },
    { countryCode: "GL", inBound: false, outBound:true },
    { countryCode: "GY", inBound: false, outBound:true },
    { countryCode: "HO", inBound: false, outBound:true },
    { countryCode: "KI", inBound: false, outBound:true },
    { countryCode: "KO", inBound: false, outBound:true },
    { countryCode: "M3", inBound: false, outBound:true },
    { countryCode: "MH", inBound: false, outBound:true },
    { countryCode: "FM", inBound: false, outBound:true },
    { countryCode: "MN", inBound: false, outBound:true },
    { countryCode: "NC", inBound: false, outBound:true },
    { countryCode: "NF", inBound: false, outBound:true },
    { countryCode: "NB", inBound: false, outBound:true },
    { countryCode: "MP", inBound: false, outBound:true },
    { countryCode: "PW", inBound: false, outBound:true },
    { countryCode: "PG", inBound: false, outBound:true },
    { countryCode: "PO", inBound: false, outBound:true },
    { countryCode: "RT", inBound: false, outBound:true },
    { countryCode: "RU", inBound: false, outBound:true },
    { countryCode: "SP", inBound: false, outBound:true },
    { countryCode: "WS", inBound: false, outBound:true },
    { countryCode: "SM", inBound: false, outBound:true },
    { countryCode: "SF", inBound: false, outBound:true },
    { countryCode: "SB", inBound: false, outBound:true },
    { countryCode: "C3", inBound: false, outBound:true },
    { countryCode: "UV", inBound: false, outBound:true },
    { countryCode: "VL", inBound: false, outBound:true },
    { countryCode: "SR", inBound: false, outBound:true },
    { countryCode: "TA", inBound: false, outBound:true },
    { countryCode: "TL", inBound: false, outBound:true },
    { countryCode: "TI", inBound: false, outBound:true },
    { countryCode: "TO", inBound: false, outBound:true },
    { countryCode: "TU", inBound: false, outBound:true },
    { countryCode: "TV", inBound: false, outBound:true },
    { countryCode: "VU", inBound: false, outBound:true },
    { countryCode: "WL", inBound: false, outBound:true },
    { countryCode: "WF", inBound: false, outBound:true },
    { countryCode: "YA", inBound: false, outBound:true },
];

const countryList =  {plt: plt, etd: etd, edi: edi};

const etdPlt = {
    findByCountryCode: (countryCode, service) => {
        if (service === "plt") {
            const result = countryList.plt.find(
                (item) => item.countryCode === countryCode
            );
            return result !== undefined
                ? result
                : { info: `The ${countryCode} is not available for DHL` };
        } else if (service === "etd") {
            const result = countryList.etd.find(
                (item) => item.countryCode === countryCode
            );
            return result !== undefined
                ? result
                : { info: `The ${countryCode} is not available for FEDEX` };
        } else if (service === "edi") {
            const result = countryList.edi.find(
                (item) => item.countryCode === countryCode
            );
            return result !== undefined
                ? result
                : { info: `The ${countryCode} is not available for UPS EDI` };
        }
    }
}
export default etdPlt;