import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  Table,
  Input,
  message,
} from "antd";
import productsApi from "../../apis/productsApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Container from "../../components/container/Container";
import ProductVariantCard from "./Card/ProductVariantCard";
import produce from "immer";
import {
  ProductDetailPageStyled,
  ProductInfoCardStyled,
  TitleBox,
} from "./index.styles";
import queryString from "query-string";
import purchaseApi from "apis/purchaseApi";
import inventoryApi from "apis/inventoryApi";
const { Title } = Typography;

function ProductDetail({ location }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState();
  const [productShopify, setProductShopify] = useState();
  const productId = queryString.parse(location.search).id;
  const [pendingProducts, setPendingProducts] = useState([]);
  const [activeVariant, setActiveVariant] = useState([
    { key: "", id: "", variantTitle: "", in_stock: 0, addQuantity: 0 },
  ]);
  const [activeExportVariant, setActiveExportVariant] = useState([
    { key: "", id: "", name: "",productId: "", variantId : ""},
  ]);
  const [isModal, setIsModal] = useState(false);
  const [isExportModal, setIsExportModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedExportRows, setSelectedExportRows] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [variantList, setVariantList] = useState([]);

  console.log("PRODUCT!", productId);

  const getProduct = async () => {
    setIsLoading(true);
    try {
      const { product } = await productsApi.getProductById(productId);
      setProduct(product);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }

    const resShopify = await productsApi.getProductShopify(productId);
    const productShopifyDetail = resShopify?.product;

    if (!productShopifyDetail?.id) {
      return;
    }

    const variants = productShopifyDetail?.variants.map((item) => ({
      ...item,
      image: productShopifyDetail?.images?.find(
        (image) => item.image_id === image.id
        )?.src || resShopify.product.image.src
    }));
    setProductShopify({
      ...productShopifyDetail,
      variants,
    });
  };

  const getPendingVariant = async () => {
    setIsLoading(true);
    try {
      const allPendingVariantList = await Promise.all(
        product?.variants?.map(async (item) => {
          const param = {
            page: 1,
            pageSize: 10,
            variantId: item.id,
          };
          const res = await purchaseApi.getListPendingVariants(param);
          return res;
        })
      );
      const availablePending = allPendingVariantList.filter(
        (item) => !!item.totalCount
      );

      const pendingProducts = availablePending.map(
        (item) => item.pendingProducts[0]
      );
      setPendingProducts(pendingProducts);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getVariantList = async () => {
    try {
      const res = await productsApi
          .getProductById(productId)
          .then((product) => 
             product.product.variants
             );
      console.log('res',res);
      let vList = [];
      await res.map(async (variants) => {
        vList.push(variants.id);
      });
      await setVariantList(vList);
    } 
    catch (error) {
      console.log(error.message);
    } finally {
    }

  };

  const openExportModal = async () => {
    try {
    const res = await productsApi
        .getProductById(productId)
        .then((product) => 
           product.product.variants
           );
     let ress = await productsApi.listExportShops()
        .then(({shops}) => {
          return shops
        }) 
      // console.log("DELETE TERUS LEPAS PAKAI", res);
      let draftExport = [];
      console.log('export List', exportList);
      await ress.forEach(async(shops) => {
          console.log('shops',shops.name)
          let b = {
            key: shops.id,
            id: shops.id,
            name: shops.name.toUpperCase(),
            variantId:await variantList,
            productId : res[0].productId,
          }
          draftExport.push(b);
          console.log('draft',draftExport);
    })
      // console.log("draftExport TERUS LEPAS PAKAI", draftExport);
      setActiveExportVariant(draftExport);
    } catch (error) {
      console.log(error.message);
    } finally {
      // setExportButtonLoad(false);
      setIsExportModal(true);
    }
  };

  const handleAddSet = () => {};

  useEffect(() => {
    getProduct();
  }, []);

  useEffect(() => {
    if (product?.variants?.length) {
      getPendingVariant();
    }
  }, [product]);

  useEffect(() => {
    getVariantList();
  }, []);

  const onOk = () => {
    Promise.all(
      activeVariant
        .filter((item) => selectedRows.includes(item.key))
        .map((variant) => {
          const payload = {
            quantity: variant.quantity,
          };
          return inventoryApi.addVariantInventory(
            variant.id,
            JSON.stringify(payload)
          );
        })
    )
      .then((res) => {
        message.success("Succesfully update product inventory", 5);
        setIsLoading(false);
        // updateNewValue
        // const param = {
        //   page: 1,
        //   pageSize: 10,
        //   variantId: item.id,
        // };
        // const res = await purchaseApi.getListPendingVariants(param);
        setIsModal(false);
        setTimeout(
          () =>
            window.location.reload(),
          700
        );
      })
      .catch((error) => {
        message.error(error, 5);
        setIsModal(false);
      });
    //reset active variant
  };

  const onOkExport = (event) => {
    if (activeExportVariant.length < 1) {
      setIsExportModal(false);
    } 
    else {
      Promise.all(
        activeExportVariant
          .filter((item) => selectedExportRows.includes(item.key))
          .map((shops) => {
            const payload = {
              productId : shops.productId,
              variantId : shops.variantId,
              exportShopId : shops.id
            };
            console.log('payload', payload);
            return productsApi.addProductToExport(
              JSON.stringify(payload)
            );
          })
      )
        .then((res) => {
          console.log(res);
          if (res[0].success) {
            message.success("Succesfully exported", 3);
            setIsLoading(false);
            setIsExportModal(false);
            setTimeout(
              () =>
                window.location.reload(),
              700
            );
          } else {
            message.error('Product already exported',3)
          }
        })
        .catch((error) => {
          message.error(error, 5);
          setIsModal(false);
        });
    }
    // reset active variant
  };

  const onCancel = (event) => {
    setIsModal(false);
  };

  const onCancelExport = (event) => {
    setIsExportModal(false);
  };

  return (
    <>
      <Modal
        title="Receive Stock"
        visible={isModal}
        onOk={onOk}
        onCancel={onCancel}
        width={700}
      >
        <Table
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRows(selectedRowKeys);
            },
          }}
          dataSource={activeVariant}
          pagination={false}
          style={{ marginTop: "26px" }}
        >
          <Table.Column title="Id" key="id" dataIndex="id" />
          <Table.Column title="Title" key="title" dataIndex="title" />
          <Table.Column title="In Stock" key="in_stock" dataIndex="in_stock" />
          <Table.Column
            title="Add Quantity"
            key="quantity"
            dataIndex="quantity"
            render={(value, record, index) => {
              return (
                <Input
                  name="quantity"
                  placeholder="Quantity"
                  onChange={(event) => {
                    const result = produce(activeVariant, (draft) => {
                      draft[index].quantity = Number(event.target.value);
                    });
                    setActiveVariant(result);
                  }}
                  type="number"
                  addonAfter="PCS"
                  value={value}
                />
              );
            }}
          />
        </Table>
      </Modal>
      <Modal
        title="Add to Export"
        visible={isExportModal}
        onOk={onOkExport}
        onCancel={onCancelExport}
        width={700}
      >
         {activeExportVariant.length > 0 ?
        <Table
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedExportRows(selectedRowKeys);
            },
          }}
          dataSource={activeExportVariant}
          pagination={false}
          style={{ marginTop: "26px" }}
        >
          <Table.Column title="Name" key="name" dataIndex="name" />
        </Table>
        :  <div style={{ marginTop: "26px" }}>Product already exported</div>}
      </Modal>
      <TitleBox className="title-page">
        <Title>Product Detail</Title>
        <Breadcrumb
          items={[
            { name: "Home", path: "/" },
            { name: "Products", path: "/products" },
            { name: `${t("Product")} #${product?.id}` },
          ]}
        />
      </TitleBox>

      <ProductDetailPageStyled title={t("Product detail")}>
        <Container>
          {isLoading ? (
            <div style={{ textAlign: "center" }}>
              <Spin />
            </div>
          ) : (
            <Row gutter={[16, 16]}>
              <Col xs={24} md={18}>
                <ProductInfoCardStyled
                  className="product-info"
                  title={product?.title}
                >
                  <Row gutter={[20]}>
                    <Col xs={24} md={16} className="source-info">
                      <div className="box-data">
                        <div className="label">Source: </div>
                        <div className="data">{product?.source}</div>
                        {/* <div className="data">{`SHOPIFY`}</div> */}
                      </div>
                      <div className="box-data">
                        <div className="label">Description: </div>
                        <div
                          className="data description"
                          dangerouslySetInnerHTML={{
                            __html: productShopify?.body_html,
                          }}
                        />
                      </div>
                      <div className="box-data">
                        <div className="label">Source URL: </div>
                        <div className="data">{product?.sourceUrl}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Shopify ID: </div>
                        <div className="data">{product?.shopifyId}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Tags: </div>
                        <div className="data">
                          {product?.tags?.map((item, index) => (
                            <Tag color="#2CD07E" key={item}>
                              {item}
                            </Tag>
                          ))}
                        </div>
                      </div>
                      <div className="box-data">
                        <div className="label">Product Type:</div>
                        <div className="data">{product?.type}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Vendor:</div>
                        <div className="data">{product?.vendor}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Variants:</div>
                        <div className="data">{`${
                          product?.variants?.length || 0
                        } Variants`}</div>
                      </div>
                    </Col>
                    <Col xs={24} md={8} className="featured-images">
                      <div className="box-data">
                        <div className="label">Featured Images</div>
                        <div className="data">
                          <Row gutter={[8, 8]}>
                            {productShopify?.images?.map((item, index) => (
                              <Col key={index}>
                                <img src={item?.src} width={82} height={82} />
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </ProductInfoCardStyled>
                <div className="variants-container">
                  <h3>Variants</h3>
                  <Button type="primary" onClick={openExportModal}>
                    Add all variants to Export 
                  </Button> 
                 
                  <div className="variants">
                  <br></br>
                    {product?.variants?.map((item, index) => (
                      <ProductVariantCard
                        {...item}
                        shopify={productShopify?.variants?.find((singleV) => item.shopifyId == singleV.id)}
                        key={item.id}
                        setIsLoading={setIsLoading}
                        productName={product?.title}
                        supplier={product?.vendor}
                        pendingProducts={pendingProducts}
                        setActiveVariant={setActiveVariant}
                        setActiveExportVariant = {setActiveExportVariant}
                        setIsModal={setIsModal}
                        setIsExportModal={setIsExportModal}
                      />
                    ))}
                  </div>
                </div>
              </Col>

              <Col xs={24} md={6}>
                <ProductInfoCardStyled
                  title="Set Details"
                  className="set-details"
                >
                  <Space gutter={[8, 8]}>
                    <div className="note">
                      This item is not part of any bundle sets
                    </div>
                    <Button type="primary" onClick={handleAddSet}>
                      Add Set
                    </Button>
                  </Space>
                </ProductInfoCardStyled>
                <ProductInfoCardStyled
                  title="Statistics"
                  className="statistics"
                >
                  <ul>
                    <li>
                      <div className="statistic-title">Sold</div>
                      <div className="box-data">
                        <div className="label">All Time: </div>
                        <div className="data">{``}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Week: </div>
                        <div className="data">{``}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Month: </div>
                        <div className="data">{``}</div>
                      </div>
                    </li>
                    <li>
                      <div className="statistic-title">Refunded</div>
                      <div className="box-data">
                        <div className="label">All Time: </div>
                        <div className="data">{``}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Week: </div>
                        <div className="data">{``}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Month: </div>
                        <div className="data">{``}</div>
                      </div>
                    </li>
                    <li>
                      <div className="statistic-title">Purchased</div>
                      <div className="box-data">
                        <div className="label">All Time: </div>
                        <div className="data">{``}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Week: </div>
                        <div className="data">{``}</div>
                      </div>
                      <div className="box-data">
                        <div className="label">Month: </div>
                        <div className="data">{``}</div>
                      </div>
                    </li>
                  </ul>
                </ProductInfoCardStyled>
              </Col>
            </Row>
          )}
        </Container>
      </ProductDetailPageStyled>
    </>
  );
}

export default ProductDetail;
