import { Col, Form, Input, Row, Select, Button, Modal, notification, Alert } from "antd";
import kodNegara from "kod-negara";
import { FormBody, FormHeader, FormWrapper } from "./index.style";
import NewShipperForm from "./NewShipperForm";
import { useState } from "react";
import _ from "lodash";

const { Option } = Select;

const ShipperDetail = ({ form, shipper, activeShipper, setActiveShipper, shipmentsApi, setShipper }) => {
  const [openShipperForm, setOpenShipperForm] = useState(false);
  const [shipperUpdatable, setShipperUpdatable] = useState(false);

  const onSelect = (value) => {
    checkAnyChanges();
    const countryCode = value.match("[a-zA-Z]+")[0];
    return form.setFieldsValue({ shipperCountryCode: countryCode });
  };

  const showShipperForm = () => {
    setOpenShipperForm(true);    
  };

  const shipperChanged = (value) => {
    if(Number(value) === -1){
      setOpenShipperForm(true);
      return;
    }

    //selected is id of shipper
    const selectedId = form.getFieldValue('shipperName');
    const selectedShipper = shipper.find(s => {
        if(s.id === Number(selectedId))
          return s; 
    });
   
    if(selectedShipper){
      setActiveShipper(selectedShipper);          
    }
      
  }
  const checkAnyChanges = () => {
    /*
     shipper: {
                            name: value.shipperName,
                            phonenumber: value.shipperPhoneNumber,
                            utcOffset: shipper[value.shipper - 1]?.utcOffset,
                            email: value.shipperEmail,
                            address1: value.shipperAddress1,
                            address2: value.shipperAddress2,
                            city: value.shipperCity,
                            postal: value.shipperPostal,
                            provinceCode: value.shipperProvinceCode,
                            countryCode: value.shipperCountryCode,
                            country: value.shipperCountryCode,
                        },
    */
    //Brute-force checking
    if(activeShipper.email !== form.getFieldValue('shipperEmail') ||
        activeShipper.phonenumber !== form.getFieldValue('shipperPhoneNumber') ||
        activeShipper.companyName !== form.getFieldValue('shipperCompanyName') ||
        activeShipper.address1 !== form.getFieldValue('shipperAddress1') ||
        activeShipper.address2 !== form.getFieldValue('shipperAddress2') ||
        activeShipper.city !== form.getFieldValue('shipperCity') ||
        activeShipper.postal !== form.getFieldValue('shipperPostal') ||
        activeShipper.provinceCode !== form.getFieldValue('shipperProvinceCode') ||
        activeShipper.countryCode !== form.getFieldValue('shipperCountryCode')){

      setShipperUpdatable(true);

    } else {
      setShipperUpdatable(false);
    }      
  }

  const openNotificationWithIcon = (type, msg, desc) => {
    notification[type]({
      message: msg,
      description:desc        
    });
  };

  const updateShipper = () => {
    const updatedShipper = {
      id: activeShipper.id,
      email: form.getFieldValue('shipperEmail'), 
      phonenumber: form.getFieldValue('shipperPhoneNumber'), 
      companyName: form.getFieldValue('shipperCompanyName'), 
      address1: form.getFieldValue('shipperAddress1'), 
      address2: form.getFieldValue('shipperAddress2'), 
      city: form.getFieldValue('shipperCity'),
      postal: form.getFieldValue('shipperPostal'), 
      provinceCode: form.getFieldValue('shipperProvinceCode'), 
      countryCode: form.getFieldValue('shipperCountryCode')
    }

    shipmentsApi.updateShipperDetails(updatedShipper)
      .then((res) => {
        openNotificationWithIcon('success', 'Success', 'Shipper updated successfully');
        setShipperUpdatable(false);

        //refetch shippers
        const params = {
          page: 1,
          pageSize: 10,
        };
      
        shipmentsApi.getListShipper(params)
          .then(({ shipper_details, errors }) => {
            setShipper(shipper_details);
          });
      })      
      .catch(err =>{
        console.log(err)
        openNotificationWithIcon('error', 'Error', 'Shipper update failed. Error: ' + err);
      });
  };

  return (    
    <FormWrapper>      
      <FormHeader className="greenBoldTitle">Shipper Details</FormHeader>
      <FormBody>
        <Row gutter={16}>
          <Col lg={6}>
            {/* 
            <Form.Item
              name="shipperName"
              label="Shipper Name"
              rules={[
                {
                  required: true,
                  message: "Receiver name is required",
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Shipper Name" />
            </Form.Item>
            */}
            <Form.Item
                label="Shipper Name"
                name="shipperName"
                rules={[
                  {
                    required: true,
                    message: "Receiver name is required",
                  },
                ]}
                hasFeedback
            >
              <Select onChange={shipperChanged}>
                  <Option key='test' value='-1'>[Add New Shipper]</Option>
                  {shipper?.map((ship, index) => {
                      return (
                          <Option
                              key={index}
                              value={ship.id}
                          >
                              {ship.name}
                          </Option>
                      );
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="shipperEmail"
              label="Email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: "Please insert consignee email",
                },
              ]}
              hasFeedback
              onChange={checkAnyChanges}
            >
              <Input placeholder="Email" type="email" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="shipperPhoneNumber"
              label="Phone Number"
              rules={[
                {
                  type: "string",
                  required: true,
                  message: "Consignee's contact number",
                },
              ]}
              hasFeedback
              onChange={checkAnyChanges}
            >
              <Input placeholder="Office Number" />
            </Form.Item>
          </Col>
          <Col lg={6}>
            <Form.Item
              name="shipperCompanyName"
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Company name is required",
                },
              ]}
              hasFeedback
              onChange={checkAnyChanges}
            >
              <Input placeholder="Company Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={8}>
            <Form.Item
              name="shipperAddress1"
              label="Address Line 1"
              rules={[
                {
                  required: true,
                  message: "Please insert consignee email",
                },
              ]}
              hasFeedback
              onChange={checkAnyChanges}
            >
              <Input placeholder="Address 1" disabled={true}/>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="shipperAddress2"
              label="Address Line 2"
              rules={[
                {
                  required: false,
                },
              ]}
              hasFeedback
              onChange={checkAnyChanges}
            >
              <Input placeholder="Address 2" disabled={true}/>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="shipperCity"
              label="City"
              rules={[
                {
                  required: true,
                  message: "City is required",
                },
              ]}
              hasFeedback
              onChange={checkAnyChanges}
            >
              <Input placeholder="City" disabled={true}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col lg={8}>
            <Form.Item
              name="shipperPostal"
              label="Postal Code"
              rules={[
                {
                  required: true,
                  message: "Please insert consignee email",
                },
              ]}
              hasFeedback
              onChange={checkAnyChanges}
            >
              <Input placeholder="Postal Code" disabled={true}/>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="shipperProvinceCode"
              label="Province Code"
              rules={[
                {
                  required: false,
                },
              ]}
              hasFeedback
              onChange={checkAnyChanges}
            >
              <Input placeholder="Province Code" disabled={true}/>
            </Form.Item>
          </Col>
          <Col lg={8}>
            <Form.Item
              name="shipperCountryCode"
              label="Country Code"
              rules={[
                {
                  required: true,
                  message: "Country Code is required",
                },
              ]}
              hasFeedback              
            >
              <Select showSearch style={{ width: "100%" }} onSelect={onSelect} disabled={true}>
                {kodNegara.all().map((item, index) => (
                  <Option key={index} value={item.countryCode}>
                    {item.countryNameEn} ({item.countryCode})
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {shipperUpdatable? 
        (<Row gutter={24}>
          <Col lg={16}></Col>
          <Col lg={8}>
            <Alert 
              message="Shipper information changed" 
              type="info" 
              showIcon
              action={
                <Button type="primary" block onClick={updateShipper}>
                  Update Shipper
                </Button>
              }
            />                      
          </Col>
        </Row>)
        : undefined}
      </FormBody>
                  
      <NewShipperForm 
        openShipperForm={openShipperForm} 
        setOpenShipperForm={setOpenShipperForm}
        shipmentsApi={shipmentsApi}
        setShipper={setShipper} 
        activeShipper={activeShipper}
        setActiveShipper={setActiveShipper}         
        form={form} 
        openNotificationWithIcon={openNotificationWithIcon}  
      />
        
    </FormWrapper>
  );
};

export default ShipperDetail;
