import { Button, Checkbox, Col, DatePicker, Form, Input, message, Row, Space } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FilterContainer } from "./index.style";
import queryString from "query-string";
import purchaseApi from "apis/purchaseApi";
import { moment } from "utils/funcs";
import productsApi from "apis/productsApi";
import Select from "../../../../components/select/Select";
import axios from "axios";

const Filter = ({
  filter,
  setFilter,
  selectedRows,
  dataList,
  setIsLoading,
  isLoading,
  gettingProductExportList,
  params,
  setParams
}) => {

  const history = useHistory();
  const [selectedProductVariant, setSelectedProductVariant] = useState([]); // for unitPrice
  const [localParams, setLocalParams] = useState({
    productFilter: params.productFilter,
    variantFilter: params.variantFilter,
    orderFilter: params.orderFilter,
    sort: params.sort
  });
  const [isWaiting,setIsWaiting] = useState(false);
  const [fileName, setFileName] = useState(''); 

  const PURCHASE_SORT = {
    SupplierDesc: { name: 'Vendor (Asc)', value: 'product_vendor_asc' },
    SupplierAsc: { name: 'Vendor (Desc)', value: 'product_vendor_desc' },
    OrderDesc: { name: 'Order (Asc)', value: `orders_order_createdAt_asc` },
    OrderAsc: { name: 'Order (Desc)', value: 'orders_order_createdAt_desc' },
    ProductTitleDesc: { name: 'Product Title (Asc)', value: 'product_title_asc' },
    ProductTitleAsc: { name: 'Product Title (Desc)', value: 'product_title_desc' },
  }

  const handleChangeField = async (e) => {
    setLocalParams((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const onClickFilter = () => {
    setParams((prev) => ({ ...prev, ...localParams, page: 1 }))
    gettingProductExportList({ ...localParams, page: 1 })
  };

  const onDownload = async () => {
    setIsWaiting(true);
    axios({
        url: `${process.env.REACT_APP_API_URL}/api/products/get/export-mercari`,
        method: "GET",
        headers: {
            "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
        },
    })
    .then(async(response) => {
            // setFileName(response.data.fileName);
            if (response.status == '200') {
              message.success('Preparing export file. Please wait.', 4);
            }
        })
    .catch((err) => {       
      console.log('error',err);
        if(err.response?.status === 404)
            alert('No valid exports found');            
    });
};

const getFile = async () => {
  await setIsWaiting(true);
  axios({
      url: `${process.env.REACT_APP_API_URL}/api/products/get/export-file/1`,
      method: "GET",
      responseType: "blob", // important
      headers: {
          "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
      },
  })
  .then(async (response) => {
          const theFile = await productsApi.getLatestFileName(1);
          await setFileName(theFile);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            await link.setAttribute("download", theFile);
            await document.body.appendChild(link);
            await link.click();
            await setIsWaiting  (false);
      })
  .catch((err) => {       
    console.log('error',err);
      if(err.response?.status === 404)
          alert('No valid exports found');            
  });
};

  // fetch product to get unit price
  const getProductBySelectedId = () => {
    setSelectedProductVariant([]);
    Promise.all(
     dataList
        .filter((obj) => selectedRows.includes(obj.key))
    )
    return dataList;
  };

  useEffect(() => {
    getProductBySelectedId();
  }, [selectedRows]);

  // useEffect(() => {
  //   getLatest();
  // });

  useEffect(() => {
    const interval = setInterval(async() => {
      const theFile = await productsApi.getLatestFileName(1);
      await setFileName(theFile);
    if ((fileName) && (fileName != theFile)) {
      message.info('File is now ready.', 3);
      await setIsWaiting(false);
    }
    }, 30000);
    return () => clearInterval(interval);
  }, [fileName]);

  return (
    <FilterContainer layout="vertical">
      <Row gutter={[8, 8]} align="bottom">
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item label="Product title" style={{ marginBottom: 0 }}>
            <Input
              placeholder="Product title"
              name="productFilter"
              value={localParams.productFilter}
              onChange={handleChangeField}
              onPressEnter={onClickFilter}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={8} lg={6}>
          <Form.Item label="Variant title" style={{ marginBottom: 0 }}>
            <Input
              placeholder="Variant title"
              name="variantFilter"
              value={localParams.variantFilter}
              onChange={handleChangeField}
              onPressEnter={onClickFilter}
            />
          </Form.Item>
        </Col>
        <Col>
          <Space direction="vertical">
            <Button type="primary" onClick={onClickFilter} loading={isLoading}>Filter</Button>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Button type="primary" onClick={onDownload} disabled={isLoading} loading={isWaiting}>Export File</Button>
          </Space>
        </Col>
        <Col>
          <Space direction="vertical">
            <Button type="primary" onClick={getFile} disabled={isLoading} loading={isWaiting}>{fileName != "" ? fileName : "Download Latest Export File"}</Button>
          </Space>
        </Col>
      </Row>
    </FilterContainer>
  );
};

Filter.propTypes = {
  filter: PropTypes.object,
  setFilter: PropTypes.func,
};

export default Filter;
