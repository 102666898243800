import axios from "axios";
import axiosClient from "./axiosClient";

const accountApi = {    
    login: (payload) => axiosClient.post(`/api/auth/oauth2/token`, payload),
    //TBD: Send signout to IDM
    logout: (payload) => axiosClient.post("/api/admin/sign-out", payload),
    validate: (config) => axiosClient.get("https://dev-backend.harumdev.xyz/api/auth/profile", config),
    getListUsers: (payload) => axiosClient.get("/api/auth/users", payload),
    getUsers: (payload) => axiosClient.get("/api/auth/list-users", payload),
    getAdmins: (payload) => axiosClient.get("/api/auth/list-admins", payload),
    addNewStaff: (payload, headers) => axiosClient.post("/api/auth/create-user", payload, headers),
    oktaLogin: (payload) => axiosClient.post("/api/auth/login", payload),
    oktaLogout: (payload) => axiosClient.post("/api/auth/login", payload),
    resetPassword: (payload, headers) => axiosClient.post("/api/auth/reset-password", payload, headers),
    suspendUser: (userId) => axiosClient.get("/api/auth/suspend-user/" + userId),
    unsuspendUser: (userId) => axiosClient.get("/api/auth/unsuspend-user/" + userId),
    removeUser: (userId) => axiosClient.get("/api/auth/delete-user/" + userId),
    unlockUser: (userId) => axiosClient.get("/api/auth/unlock-user/" + userId),
    getUser: (userId) => axiosClient.get("/api/auth/get-app-user/" + userId),
    updatePermissions: (payload) => axiosClient.post("/api/auth/update-permissions", payload),
};

export default accountApi;
