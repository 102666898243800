export const product_type  = [
{name :  'Pet Accesories' ,value :  'Pet Accesories' },
{name :  'Bouncy Ball' ,value :  'Bouncy Ball' },
{name :  'Sports' ,value :  'Sports' },
{name :  'Files' ,value :  'Files' },
{name :  'Hooded Blankets' ,value :  'Hooded Blankets' },
{name :  'Dress' ,value :  'Dress' },
{name :  'Hoodie' ,value :  'Hoodie' },
{name :  'Polaroid' ,value :  'Polaroid' },
{name :  'Cable Protect Cap' ,value :  'Cable Protect Cap' },
{name :  'Decorates' ,value :  'Decorates' },
{name :  'Tablet Pouch' ,value :  'Tablet Pouch' },
{name :  'Pin Buttons' ,value :  'Pin Buttons' },
{name :  'Eyeshadow Paletea' ,value :  'Eyeshadow Paletea' },
{name :  'Cutlery Box' ,value :  'Cutlery Box' },
{name :  'Sticky note' ,value :  'Sticky note' },
{name :  'Steamed bowls' ,value :  'Steamed bowls' },
{name :  'Wallets' ,value :  'Wallets' },
{name :  'Fabric Posters' ,value :  'Fabric Posters' },
{name :  'Padding' ,value :  'Padding' },
{name :  'Food Container' ,value :  'Food Container' },
{name :  'Hoodie Set' ,value :  'Hoodie Set' },
{name :  'Table Clock' ,value :  'Table Clock' },
{name :  'Pouch' ,value :  'Pouch' },
{name :  'Acrylic Charms' ,value :  'Acrylic Charms' },
{name :  'Birthday Box' ,value :  'Birthday Box' },
{name :  'Backpacks' ,value :  'Backpacks' },
{name :  'Skincare,Lotion' ,value :  'Skincare,Lotion' },
{name :  'Blouse' ,value :  'Blouse' },
{name :  'Pet Toys' ,value :  'Pet Toys' },
{name :  'Sun Cream' ,value :  'Sun Cream' },
{name :  'Liner' ,value :  'Liner' },
{name :  'Drain Cover' ,value :  'Drain Cover' },
{name :  'Photo Album' ,value :  'Photo Album' },
{name :  'Purse' ,value :  'Purse' },
{name :  'Print Books' ,value :  'Print Books' },
{name :  'Stationery, Toys' ,value :  'Stationery, Toys' },
{name :  'Kitchen Appliances' ,value :  'Kitchen Appliances' },
{name :  'Skirts' ,value :  'Skirts' },
{name :  'Coloring & Sticker Book' ,value :  'Coloring & Sticker Book' },
{name :  'Bavaria Glass' ,value :  'Bavaria Glass' },
{name :  'Eco Bag' ,value :  'Eco Bag' },
{name :  'Face Wash Headband' ,value :  'Face Wash Headband' },
{name :  'Electronics' ,value :  'Electronics' },
{name :  'stationery' ,value :  'stationery' },
{name :  'Cereal Cup' ,value :  'Cereal Cup' },
{name :  'Bag/Pouch' ,value :  'Bag/Pouch' },
{name :  'Travel Pack Set' ,value :  'Travel Pack Set' },
{name :  'Arts & Entertainment' ,value :  'Arts & Entertainment' },
{name :  'Mouse' ,value :  'Mouse' },
{name :  'Photo File' ,value :  'Photo File' },
{name :  'Mask, Skin, Skincare' ,value :  'Mask, Skin, Skincare' },
{name :  'Pajamas' ,value :  'Pajamas' },
{name :  'Face Lotion' ,value :  'Face Lotion' },
{name :  'Primer' ,value :  'Primer' },
{name :  'Accessories, Toys' ,value :  'Accessories, Toys' },
{name :  'Makeup Remover' ,value :  'Makeup Remover' },
{name :  'Photo Card Holder' ,value :  'Photo Card Holder' },
{name :  'Season Greetings' ,value :  'Season Greetings' },
{name :  'Sweaters' ,value :  'Sweaters' },
{name :  'Tank Tops' ,value :  'Tank Tops' },
{name :  'Crocs Accessories' ,value :  'Crocs Accessories' },
{name :  'Rings' ,value :  'Rings' },
{name :  'Dispenser' ,value :  'Dispenser' },
{name :  'Treat Bag' ,value :  'Treat Bag' },
{name :  'Balm' ,value :  'Balm' },
{name :  'Pencil Case' ,value :  'Pencil Case' },
{name :  'Earphone Pouch' ,value :  'Earphone Pouch' },
{name :  'Slogan' ,value :  'Slogan' },
{name :  'Squeeze Ball' ,value :  'Squeeze Ball' },
{name :  'Parka' ,value :  'Parka' },
{name :  'Baby Spoon' ,value :  'Baby Spoon' },
{name :  'Body Pillow' ,value :  'Body Pillow' },
{name :  'Sticker Board' ,value :  'Sticker Board' },
{name :  'Jacket and Pants' ,value :  'Jacket and Pants' },
{name :  'Curved Monitor' ,value :  'Curved Monitor' },
{name :  'Face Makeup' ,value :  'Face Makeup' },
{name :  'Beverage' ,value :  'Beverage' },
{name :  'Humidifiers' ,value :  'Humidifiers' },
{name :  'Disc' ,value :  'Disc' },
{name :  'Binder' ,value :  'Binder' },
{name :  'Pillows' ,value :  'Pillows' },
{name :  'Bottle Socks' ,value :  'Bottle Socks' },
{name :  'Turntable' ,value :  'Turntable' },
{name :  'Buds Cases' ,value :  'Buds Cases' },
{name :  'Pen Case' ,value :  'Pen Case' },
{name :  'Letter Pad' ,value :  'Letter Pad' },
{name :  'Clipboard' ,value :  'Clipboard' },
{name :  'Moisturizer' ,value :  'Moisturizer' },
{name :  'Brow Fixer' ,value :  'Brow Fixer' },
{name :  'Sacoche Bag' ,value :  'Sacoche Bag' },
{name :  'Photo' ,value :  'Photo' },
{name :  'Golf Driver Cover' ,value :  'Golf Driver Cover' },
{name :  'Soju Glass' ,value :  'Soju Glass' },
{name :  'Pillow Cover' ,value :  'Pillow Cover' },
{name :  'Watch' ,value :  'Watch' },
{name :  'Storage Box' ,value :  'Storage Box' },
{name :  'Pens' ,value :  'Pens' },
{name :  'Pin Badges' ,value :  'Pin Badges' },
{name :  'Skincare, Sleeping Pack, Skin' ,value :  'Skincare, Sleeping Pack, Skin' },
{name :  'Hairband' ,value :  'Hairband' },
{name :  'Makeup Spatula' ,value :  'Makeup Spatula' },
{name :  'Eco Bags' ,value :  'Eco Bags' },
{name :  'Eyewear' ,value :  'Eyewear' },
{name :  'Hipsack' ,value :  'Hipsack' },
{name :  'Book cushion' ,value :  'Book cushion' },
{name :  'Bar Soap' ,value :  'Bar Soap' },
{name :  '2023 Wall Calendar' ,value :  '2023 Wall Calendar' },
{name :  'Bowl' ,value :  'Bowl' },
{name :  'Necklace' ,value :  'Necklace' },
{name :  'leggings' ,value :  'leggings' },
{name :  'Clip' ,value :  'Clip' },
{name :  'Headbands' ,value :  'Headbands' },
{name :  'CHAdeMO Adapter Case' ,value :  'CHAdeMO Adapter Case' },
{name :  'Skincare,Mask Sheet' ,value :  'Skincare,Mask Sheet' },
{name :  'Frying pan' ,value :  'Frying pan' },
{name :  'Pants' ,value :  'Pants' },
{name :  'Pet Clothes' ,value :  'Pet Clothes' },
{name :  'AirPods Case' ,value :  'AirPods Case' },
{name :  'Bracelets' ,value :  'Bracelets' },
{name :  'Board game' ,value :  'Board game' },
{name :  'Phone Cards' ,value :  'Phone Cards' },
{name :  'Cutlery' ,value :  'Cutlery' },
{name :  'Slipper' ,value :  'Slipper' },
{name :  'Buds/Buds Plus Cases' ,value :  'Buds/Buds Plus Cases' },
{name :  'Story Book' ,value :  'Story Book' },
{name :  'Pet Grooming Supplies' ,value :  'Pet Grooming Supplies' },
{name :  'Clothes' ,value :  'Clothes' },
{name :  'LP Vinyl' ,value :  'LP Vinyl' },
{name :  'Multi Balm' ,value :  'Multi Balm' },
{name :  'Photocard inlay' ,value :  'Photocard inlay' },
{name :  'Books' ,value :  'Books' },
{name :  'Home & Living, Accessories' ,value :  'Home & Living, Accessories' },
{name :  'Sweatpants' ,value :  'Sweatpants' },
{name :  'Skincare, Repair Pack, Skin' ,value :  'Skincare, Repair Pack, Skin' },
{name :  'Autographed Paper' ,value :  'Autographed Paper' },
{name :  'Charging Hub' ,value :  'Charging Hub' },
{name :  'Wallet' ,value :  'Wallet' },
{name :  'Socks' ,value :  'Socks' },
{name :  'Cropped Sweater' ,value :  'Cropped Sweater' },
{name :  'Beauty' ,value :  'Beauty' },
{name :  'Galaxy Buds' ,value :  'Galaxy Buds' },
{name :  'Mirror' ,value :  'Mirror' },
{name :  'Flippers' ,value :  'Flippers' },
{name :  'Bed Textiles' ,value :  'Bed Textiles' },
{name :  'OST' ,value :  'OST' },
{name :  'Long Straight Pants' ,value :  'Long Straight Pants' },
{name :  'Phone Strap' ,value :  'Phone Strap' },
{name :  'Jackets' ,value :  'Jackets' },
{name :  'Mobile Accessories' ,value :  'Mobile Accessories' },
{name :  'Gel Mask Sheet' ,value :  'Gel Mask Sheet' },
{name :  'Air Freshener' ,value :  'Air Freshener' },
{name :  'Tray' ,value :  'Tray' },
{name :  'Eyelash Serum' ,value :  'Eyelash Serum' },
{name :  'Hooded T-shirt' ,value :  'Hooded T-shirt' },
{name :  'Collectible Trading Cards' ,value :  'Collectible Trading Cards' },
{name :  'Toner Pad' ,value :  'Toner Pad' },
{name :  'Cup' ,value :  'Cup' },
{name :  'Rug & Carpet' ,value :  'Rug & Carpet' },
{name :  'Acrylic Keyring' ,value :  'Acrylic Keyring' },
{name :  'Low Pot' ,value :  'Low Pot' },
{name :  'Underpants' ,value :  'Underpants' },
{name :  'Cross Stitch' ,value :  'Cross Stitch' },
{name :  'Charging Pad' ,value :  'Charging Pad' },
{name :  'Wok Pan' ,value :  'Wok Pan' },
{name :  'Crossbody bag' ,value :  'Crossbody bag' },
{name :  'Camera Pouch' ,value :  'Camera Pouch' },
{name :  'Perfume' ,value :  'Perfume' },
{name :  'T-Shirt' ,value :  'T-Shirt' },
{name :  'Magnets' ,value :  'Magnets' },
{name :  'Planner Pad' ,value :  'Planner Pad' },
{name :  'Photo Binder' ,value :  'Photo Binder' },
{name :  'Sling Bags' ,value :  'Sling Bags' },
{name :  'Cotton Doll' ,value :  'Cotton Doll' },
{name :  'Makeup' ,value :  'Makeup' },
{name :  'Collect binder' ,value :  'Collect binder' },
{name :  'Laptop Bags' ,value :  'Laptop Bags' },
{name :  'Rug' ,value :  'Rug' },
{name :  'Caps' ,value :  'Caps' },
{name :  'Jeans' ,value :  'Jeans' },
{name :  'Phone Accessories' ,value :  'Phone Accessories' },
{name :  'Acrylic Frame' ,value :  'Acrylic Frame' },
{name :  'Plush keychain' ,value :  'Plush keychain' },
{name :  'Lucky Box' ,value :  'Lucky Box' },
{name :  'Air Wall' ,value :  'Air Wall' },
{name :  'Dog Car Seat' ,value :  'Dog Car Seat' },
{name :  'Book Lamp' ,value :  'Book Lamp' },
{name :  'iPad Pouch' ,value :  'iPad Pouch' },
{name :  'Cutlery Holder' ,value :  'Cutlery Holder' },
{name :  'Keycap' ,value :  'Keycap' },
{name :  'Jumper' ,value :  'Jumper' },
{name :  'Collectibles' ,value :  'Collectibles' },
{name :  'Clear Bag' ,value :  'Clear Bag' },
{name :  'Hair Treatment' ,value :  'Hair Treatment' },
{name :  'Jigsaw Puzzles' ,value :  'Jigsaw Puzzles' },
{name :  'Pencil Pouch' ,value :  'Pencil Pouch' },
{name :  'Crossbody Bag' ,value :  'Crossbody Bag' },
{name :  'Towel' ,value :  'Towel' },
{name :  'Brooches & Lapel Pins' ,value :  'Brooches & Lapel Pins' },
{name :  'Dog Toy' ,value :  'Dog Toy' },
{name :  'Towels' ,value :  'Towels' },
{name :  'Shorts' ,value :  'Shorts' },
{name :  'Earthenware' ,value :  'Earthenware' },
{name :  'Light Stick Strap' ,value :  'Light Stick Strap' },
{name :  'Facial Cleansers' ,value :  'Facial Cleansers' },
{name :  'Light Stick Cover' ,value :  'Light Stick Cover' },
{name :  'Disposable Mask' ,value :  'Disposable Mask' },
{name :  'Poetry' ,value :  'Poetry' },
{name :  'Tapestry' ,value :  'Tapestry' },
{name :  'Kids Clothes' ,value :  'Kids Clothes' },
{name :  'Skincare,Toner&Lotion' ,value :  'Skincare,Toner&Lotion' },
{name :  'Grill Pan' ,value :  'Grill Pan' },
{name :  'Spatula' ,value :  'Spatula' },
{name :  'Pregnancy Test Kit' ,value :  'Pregnancy Test Kit' },
{name :  'Kitchen Towels' ,value :  'Kitchen Towels' },
{name :  'Measuring Tape' ,value :  'Measuring Tape' },
{name :  'Seasons Greetings' ,value :  'Seasons Greetings' },
{name :  'Eco bag' ,value :  'Eco bag' },
{name :  'Eyelash Curlers' ,value :  'Eyelash Curlers' },
{name :  'Lip Care' ,value :  'Lip Care' },
{name :  'Accessories, Bag' ,value :  'Accessories, Bag' },
{name :  'Buds Case' ,value :  'Buds Case' },
{name :  'Jugs' ,value :  'Jugs' },
{name :  'Mascara' ,value :  'Mascara' },
{name :  'Letter Paper' ,value :  'Letter Paper' },
{name :  'Photo Set' ,value :  'Photo Set' },
{name :  'Bookmarks' ,value :  'Bookmarks' },
{name :  'Cookware sets' ,value :  'Cookware sets' },
{name :  'Back Pack' ,value :  'Back Pack' },
{name :  'Posters, Prints, & Visual Artwork' ,value :  'Posters, Prints, & Visual Artwork' },
{name :  'Folding Cards' ,value :  'Folding Cards' },
{name :  'Student ID Cards' ,value :  'Student ID Cards' },
{name :  'Lenticular Photo Cards' ,value :  'Lenticular Photo Cards' },
{name :  'Face Cover' ,value :  'Face Cover' },
{name :  'Accesories' ,value :  'Accesories' },
{name :  'Tote bag' ,value :  'Tote bag' },
{name :  'PS5 Console' ,value :  'PS5 Console' },
{name :  'collect binder' ,value :  'collect binder' },
{name :  'Skincare,Mask' ,value :  'Skincare,Mask' },
{name :  'Blindbox' ,value :  'Blindbox' },
{name :  'Wappen' ,value :  'Wappen' },
{name :  'Crocs' ,value :  'Crocs' },
{name :  'Pancake Pan' ,value :  'Pancake Pan' },
{name :  'Dessert Bowl' ,value :  'Dessert Bowl' },
{name :  'Skincare, Skin' ,value :  'Skincare, Skin' },
{name :  'File + Photo Card' ,value :  'File + Photo Card' },
{name :  'Merchandise' ,value :  'Merchandise' },
{name :  'Fruit & Vegetable Wash' ,value :  'Fruit & Vegetable Wash' },
{name :  'Airline Bag' ,value :  'Airline Bag' },
{name :  'Clothings' ,value :  'Clothings' },
{name :  'Wireless Keyboard' ,value :  'Wireless Keyboard' },
{name :  'Illustration Paper' ,value :  'Illustration Paper' },
{name :  'Mini Beam Projector' ,value :  'Mini Beam Projector' },
{name :  'Makeup,BB' ,value :  'Makeup,BB' },
{name :  'Hair Shampoo' ,value :  'Hair Shampoo' },
{name :  'Standing Figurine' ,value :  'Standing Figurine' },
{name :  'Slide' ,value :  'Slide' },
{name :  'Travel Tag' ,value :  'Travel Tag' },
{name :  'Serum Toner' ,value :  'Serum Toner' },
{name :  'Makeup Pouch' ,value :  'Makeup Pouch' },
{name :  'Baby Food Pot' ,value :  'Baby Food Pot' },
{name :  'Lipstick' ,value :  'Lipstick' },
{name :  'Tote Bag' ,value :  'Tote Bag' },
{name :  'Blushes & Bronzers' ,value :  'Blushes & Bronzers' },
{name :  'Postcard' ,value :  'Postcard' },
{name :  'Kitchen Wear' ,value :  'Kitchen Wear' },
{name :  'Clothing' ,value :  'Clothing' },
{name :  'Rain Coat' ,value :  'Rain Coat' },
{name :  'Insulated Bags' ,value :  'Insulated Bags' },
{name :  'Keychain' ,value :  'Keychain' },
{name :  'Apron' ,value :  'Apron' },
{name :  'Drawstring Pouch' ,value :  'Drawstring Pouch' },
{name :  'Poster & Stickers' ,value :  'Poster & Stickers' },
{name :  'Skincare,Essence' ,value :  'Skincare,Essence' },
{name :  'Games & Hobbies' ,value :  'Games & Hobbies' },
{name :  'AirPods & AirPods Pro cases' ,value :  'AirPods & AirPods Pro cases' },
{name :  'Mini Fan' ,value :  'Mini Fan' },
{name :  'Baume' ,value :  'Baume' },
{name :  'Handkerchief' ,value :  'Handkerchief' },
{name :  'Hair Oil' ,value :  'Hair Oil' },
{name :  'Plushie' ,value :  'Plushie' },
{name :  'Collect Books & Albums' ,value :  'Collect Books & Albums' },
{name :  'Knives Set' ,value :  'Knives Set' },
{name :  'Smartphone Sterilizer' ,value :  'Smartphone Sterilizer' },
{name :  'Track Set-Up' ,value :  'Track Set-Up' },
{name :  'Hair Styling Tools' ,value :  'Hair Styling Tools' },
{name :  'Aroma Diffuser' ,value :  'Aroma Diffuser' },
{name :  'Finish Powder' ,value :  'Finish Powder' },
{name :  'Jibbitz' ,value :  'Jibbitz' },
{name :  'Skincare,Emulsion' ,value :  'Skincare,Emulsion' },
{name :  'Knits/Sweaters' ,value :  'Knits/Sweaters' },
{name :  'Apple Pencil Case' ,value :  'Apple Pencil Case' },
{name :  'Hot Pot' ,value :  'Hot Pot' },
{name :  'Pen & Pencil Cases' ,value :  'Pen & Pencil Cases' },
{name :  'Supplements' ,value :  'Supplements' },
{name :  'Sunscreen' ,value :  'Sunscreen' },
{name :  'Solo Album' ,value :  'Solo Album' },
{name :  'Panty' ,value :  'Panty' },
{name :  'SD Figure' ,value :  'SD Figure' },
{name :  'Passport Case' ,value :  'Passport Case' },
{name :  'Diary Refill' ,value :  'Diary Refill' },
{name :  'Skirt' ,value :  'Skirt' },
{name :  'Magnet clip' ,value :  'Magnet clip' },
{name :  'Dry Bag' ,value :  'Dry Bag' },
{name :  'Paper Toy' ,value :  'Paper Toy' },
{name :  'Milk Pan' ,value :  'Milk Pan' },
{name :  'Utensils' ,value :  'Utensils' },
{name :  'Blanket Poster' ,value :  'Blanket Poster' },
{name :  'Bedding Set' ,value :  'Bedding Set' },
{name :  'Binder Inlays' ,value :  'Binder Inlays' },
{name :  'Masks' ,value :  'Masks' },
{name :  'Bowls' ,value :  'Bowls' },
{name :  'Accessories, Stationery' ,value :  'Accessories, Stationery' },
{name :  'Cropped T-shirts' ,value :  'Cropped T-shirts' },
{name :  'Illustrated Art Paper' ,value :  'Illustrated Art Paper' },
{name :  'Tableware' ,value :  'Tableware' },
{name :  'Mobile Phone Charms & Straps' ,value :  'Mobile Phone Charms & Straps' },
{name :  'Knives' ,value :  'Knives' },
{name :  'Sticky Notes' ,value :  'Sticky Notes' },
{name :  'Soap & Lotion Dispensers' ,value :  'Soap & Lotion Dispensers' },
{name :  'Sweatband' ,value :  'Sweatband' },
{name :  'Keyring/Student ID Set' ,value :  'Keyring/Student ID Set' },
{name :  'Notebooks' ,value :  'Notebooks' },
{name :  'Boots' ,value :  'Boots' },
{name :  'Holder' ,value :  'Holder' },
{name :  'Wall Calendar' ,value :  'Wall Calendar' },
{name :  'Hair Shampoo & Body Wash' ,value :  'Hair Shampoo & Body Wash' },
{name :  'Lamp' ,value :  'Lamp' },
{name :  'Home Slippers' ,value :  'Home Slippers' },
{name :  'Base Makeup, Base' ,value :  'Base Makeup, Base' },
{name :  'Keyboard Parts' ,value :  'Keyboard Parts' },
{name :  'kitchenware' ,value :  'kitchenware' },
{name :  'Plush Toys' ,value :  'Plush Toys' },
{name :  'Scroll' ,value :  'Scroll' },
{name :  'Sweater' ,value :  'Sweater' },
{name :  'Memo Pad' ,value :  'Memo Pad' },
{name :  'Cooler' ,value :  'Cooler' },
{name :  'Ring' ,value :  'Ring' },
{name :  'CD Players & Recorders' ,value :  'CD Players & Recorders' },
{name :  'Teapot' ,value :  'Teapot' },
{name :  'Luggage' ,value :  'Luggage' },
{name :  'Decorative Stickers' ,value :  'Decorative Stickers' },
{name :  'Book Reader' ,value :  'Book Reader' },
{name :  'Garland' ,value :  'Garland' },
{name :  'Makeup Fixer' ,value :  'Makeup Fixer' },
{name :  'Bedding Quilt' ,value :  'Bedding Quilt' },
{name :  'Keyboard Switches' ,value :  'Keyboard Switches' },
{name :  'Pop Tok' ,value :  'Pop Tok' },
{name :  'Bath Bomb' ,value :  'Bath Bomb' },
{name :  'Kitchen tools' ,value :  'Kitchen tools' },
{name :  'Frying Pan' ,value :  'Frying Pan' },
{name :  'Shopping Bag' ,value :  'Shopping Bag' },
{name :  'Sleep Masks' ,value :  'Sleep Masks' },
{name :  'Magazine' ,value :  'Magazine' },
{name :  'Glass Cup' ,value :  'Glass Cup' },
{name :  'Makeup,Primer' ,value :  'Makeup,Primer' },
{name :  'Tent' ,value :  'Tent' },
{name :  'Home & Living Accessories' ,value :  'Home & Living Accessories' },
{name :  '144Hz Monitor' ,value :  '144Hz Monitor' },
{name :  'Mug Warmer' ,value :  'Mug Warmer' },
{name :  'Acne & Spot Care' ,value :  'Acne & Spot Care' },
{name :  'Choker' ,value :  'Choker' },
{name :  'Pet Cushion' ,value :  'Pet Cushion' },
{name :  'File Storage Inlay' ,value :  'File Storage Inlay' },
{name :  'Floor Mat' ,value :  'Floor Mat' },
{name :  'USB Hub' ,value :  'USB Hub' },
{name :  'Day Planner' ,value :  'Day Planner' },
{name :  'Artboard' ,value :  'Artboard' },
{name :  'Chopsticks' ,value :  'Chopsticks' },
{name :  'Face wash band' ,value :  'Face wash band' },
{name :  'ID Photo' ,value :  'ID Photo' },
{name :  'Posters' ,value :  'Posters' },
{name :  'Package' ,value :  'Package' },
{name :  'Vase' ,value :  'Vase' },
{name :  'Backpack' ,value :  'Backpack' },
{name :  'Name Tag Pouch' ,value :  'Name Tag Pouch' },
{name :  'Photo cards' ,value :  'Photo cards' },
{name :  'Calendars' ,value :  'Calendars' },
{name :  'Mug Tumbler' ,value :  'Mug Tumbler' },
{name :  'Music' ,value :  'Music' },
{name :  'Music Box' ,value :  'Music Box' },
{name :  'Id Photo' ,value :  'Id Photo' },
{name :  'String Bags' ,value :  'String Bags' },
{name :  'Sling Bag' ,value :  'Sling Bag' },
{name :  'Keyring pocket' ,value :  'Keyring pocket' },
{name :  'Cropped Long Sleeve Tee' ,value :  'Cropped Long Sleeve Tee' },
{name :  'Accessories, Clutch' ,value :  'Accessories, Clutch' },
{name :  'Drawing & Painting Paper' ,value :  'Drawing & Painting Paper' },
{name :  'Illustration Colored Paper' ,value :  'Illustration Colored Paper' },
{name :  'Audio Webtoon' ,value :  'Audio Webtoon' },
{name :  'Collection Binder' ,value :  'Collection Binder' },
{name :  'Eyeliner' ,value :  'Eyeliner' },
{name :  'Double Pot' ,value :  'Double Pot' },
{name :  'Headphone & Headset Accessories' ,value :  'Headphone & Headset Accessories' },
{name :  'Cleaning Supplies' ,value :  'Cleaning Supplies' },
{name :  'Mini Skirts' ,value :  'Mini Skirts' },
{name :  'Photo Card Holders' ,value :  'Photo Card Holders' },
{name :  'Kitchen Tools & Utensils' ,value :  'Kitchen Tools & Utensils' },
{name :  'Masking Tapes' ,value :  'Masking Tapes' },
{name :  'Skincare,Serum' ,value :  'Skincare,Serum' },
{name :  'Charm' ,value :  'Charm' },
{name :  'Shower Wrap' ,value :  'Shower Wrap' },
{name :  'Keyrings' ,value :  'Keyrings' },
{name :  'Tape' ,value :  'Tape' },
{name :  'Reusable Bag' ,value :  'Reusable Bag' },
{name :  'File' ,value :  'File' },
{name :  'Jogger Pants' ,value :  'Jogger Pants' },
{name :  'Can Badge' ,value :  'Can Badge' },
{name :  'T-shirt' ,value :  'T-shirt' },
{name :  'Jacket Set' ,value :  'Jacket Set' },
{name :  'Laminating Film, Pouches & Sheets' ,value :  'Laminating Film, Pouches & Sheets' },
{name :  'Car Accessories' ,value :  'Car Accessories' },
{name :  'Photo File Refill' ,value :  'Photo File Refill' },
{name :  'Earbuds Case' ,value :  'Earbuds Case' },
{name :  'Homeware' ,value :  'Homeware' },
{name :  'Knit Vest' ,value :  'Knit Vest' },
{name :  'Photo Slogan' ,value :  'Photo Slogan' },
{name :  'Makeup,Eyebrow' ,value :  'Makeup,Eyebrow' },
{name :  'Passport Cover' ,value :  'Passport Cover' },
{name :  'Makeup,Lip Oil' ,value :  'Makeup,Lip Oil' },
{name :  'Coaster' ,value :  'Coaster' },
{name :  'Lotion & Moisturizer' ,value :  'Lotion & Moisturizer' },
{name :  'Face Mists' ,value :  'Face Mists' },
{name :  'Highlighter' ,value :  'Highlighter' },
{name :  '3D Magnet' ,value :  '3D Magnet' },
{name :  'Cases' ,value :  'Cases' },
{name :  'Pin Button' ,value :  'Pin Button' },
{name :  'Diaries' ,value :  'Diaries' },
{name :  'Pins' ,value :  'Pins' },
{name :  'Container' ,value :  'Container' },
{name :  'Home Slipper' ,value :  'Home Slipper' },
{name :  'Cup & Saucer Set' ,value :  'Cup & Saucer Set' },
{name :  'Photo Cards' ,value :  'Photo Cards' },
{name :  'Snack' ,value :  'Snack' },
{name :  'Fashion' ,value :  'Fashion' },
{name :  'Steel Badge' ,value :  'Steel Badge' },
{name :  'LED Calendar' ,value :  'LED Calendar' },
{name :  'Polaroid Photo' ,value :  'Polaroid Photo' },
{name :  'Shower' ,value :  'Shower' },
{name :  'ID Card' ,value :  'ID Card' },
{name :  'Keyboard Pouch' ,value :  'Keyboard Pouch' },
{name :  'Coasters' ,value :  'Coasters' },
{name :  'Scheduler' ,value :  'Scheduler' },
{name :  'Wine Bag' ,value :  'Wine Bag' },
{name :  'Pet Sling Bag' ,value :  'Pet Sling Bag' },
{name :  'Brow Pencil' ,value :  'Brow Pencil' },
{name :  'Moisturizing Lotion' ,value :  'Moisturizing Lotion' },
{name :  'Mini Notebook' ,value :  'Mini Notebook' },
{name :  'Skincare, Sunscreen' ,value :  'Skincare, Sunscreen' },
{name :  'Toy' ,value :  'Toy' },
{name :  'Gift' ,value :  'Gift' },
{name :  'Picnic Chair' ,value :  'Picnic Chair' },
{name :  'Memo Book' ,value :  'Memo Book' },
{name :  'Artbook' ,value :  'Artbook' },
{name :  'Humidifier' ,value :  'Humidifier' },
{name :  'Stickers' ,value :  'Stickers' },
{name :  'Hammocks' ,value :  'Hammocks' },
{name :  'File Folders' ,value :  'File Folders' },
{name :  'Mobile Phone Accessories' ,value :  'Mobile Phone Accessories' },
{name :  'Lip Creamer' ,value :  'Lip Creamer' },
{name :  'Cropped Shirts' ,value :  'Cropped Shirts' },
{name :  'Makeup Sets' ,value :  'Makeup Sets' },
{name :  'Long Padding' ,value :  'Long Padding' },
{name :  'Nail Art Kits & Accessories' ,value :  'Nail Art Kits & Accessories' },
{name :  'Shoulder Pad' ,value :  'Shoulder Pad' },
{name :  'Hand Bell' ,value :  'Hand Bell' },
{name :  'iPhone case' ,value :  'iPhone case' },
{name :  'Golf Accessories' ,value :  'Golf Accessories' },
{name :  'test' ,value :  'test' },
{name :  'Serum' ,value :  'Serum' },
{name :  'Hand Mirrors' ,value :  'Hand Mirrors' },
{name :  'Notebook' ,value :  'Notebook' },
{name :  'Jewelry Box' ,value :  'Jewelry Box' },
{name :  'Craft Magnets' ,value :  'Craft Magnets' },
{name :  'Pet Supplies' ,value :  'Pet Supplies' },
{name :  'Doll Accessories' ,value :  'Doll Accessories' },
{name :  'Accessories, Wallet' ,value :  'Accessories, Wallet' },
{name :  'Grip talk' ,value :  'Grip talk' },
{name :  'Piggy Banks' ,value :  'Piggy Banks' },
{name :  'Memo Boards' ,value :  'Memo Boards' },
{name :  'Acrylic Mood Light' ,value :  'Acrylic Mood Light' },
{name :  'Magnet' ,value :  'Magnet' },
{name :  'Travel Pouches' ,value :  'Travel Pouches' },
{name :  'Fashion & Accesories' ,value :  'Fashion & Accesories' },
{name :  'Hoodies' ,value :  'Hoodies' },
{name :  'Wrist Cushion' ,value :  'Wrist Cushion' },
{name :  'Vests' ,value :  'Vests' },
{name :  'Travel name tag' ,value :  'Travel name tag' },
{name :  'Tank Top' ,value :  'Tank Top' },
{name :  'Notebook Pouches' ,value :  'Notebook Pouches' },
{name :  'Cosmetics' ,value :  'Cosmetics' },
{name :  'Greeting & Note Cards' ,value :  'Greeting & Note Cards' },
{name :  'Seed Stick Kit' ,value :  'Seed Stick Kit' },
{name :  'Makeup,Sponge' ,value :  'Makeup,Sponge' },
{name :  'Earmuffs' ,value :  'Earmuffs' },
{name :  'Hologram Sticker' ,value :  'Hologram Sticker' },
{name :  'Hand Towels' ,value :  'Hand Towels' },
{name :  'Phone Stand' ,value :  'Phone Stand' },
{name :  'Apparel & Accessories' ,value :  'Apparel & Accessories' },
{name :  'Hand Mirror' ,value :  'Hand Mirror' },
{name :  'Plates' ,value :  'Plates' },
{name :  'Hair accessories' ,value :  'Hair accessories' },
{name :  'Watches' ,value :  'Watches' },
{name :  'Makeup,Pact' ,value :  'Makeup,Pact' },
{name :  'Marche Bag' ,value :  'Marche Bag' },
{name :  'Driving Kit Dog Car Seat' ,value :  'Driving Kit Dog Car Seat' },
{name :  'Badges' ,value :  'Badges' },
{name :  'Cap' ,value :  'Cap' },
{name :  'Buds & Buds Plus/Live Case' ,value :  'Buds & Buds Plus/Live Case' },
{name :  'Kitchen Gloves' ,value :  'Kitchen Gloves' },
{name :  'BB Cream' ,value :  'BB Cream' },
{name :  'Speaker' ,value :  'Speaker' },
{name :  'SSD Drive' ,value :  'SSD Drive' },
{name :  'UHD Monitor' ,value :  'UHD Monitor' },
{name :  'Food' ,value :  'Food' },
{name :  'Keyring' ,value :  'Keyring' },
{name :  'Mask Sheet' ,value :  'Mask Sheet' },
{name :  'Kettle' ,value :  'Kettle' },
{name :  'Crossbody Bags' ,value :  'Crossbody Bags' },
{name :  'Hand & Nail Cream' ,value :  'Hand & Nail Cream' },
{name :  'Cooking utensils set' ,value :  'Cooking utensils set' },
{name :  'Photocard Mounts' ,value :  'Photocard Mounts' },
{name :  'Decorations' ,value :  'Decorations' },
{name :  'Hand Warmer' ,value :  'Hand Warmer' },
{name :  'Skincare, Gel, Skin' ,value :  'Skincare, Gel, Skin' },
{name :  'Albums' ,value :  'Albums' },
{name :  'Hobbies' ,value :  'Hobbies' },
{name :  'Lenticular Cards' ,value :  'Lenticular Cards' },
{name :  'Half Zip Up' ,value :  'Half Zip Up' },
{name :  'Accessories, Pouch' ,value :  'Accessories, Pouch' },
{name :  'Cleanser' ,value :  'Cleanser' },
{name :  'Facial Tissues' ,value :  'Facial Tissues' },
{name :  'T-Shirts' ,value :  'T-Shirts' },
{name :  'Bookmark' ,value :  'Bookmark' },
{name :  'Pencils' ,value :  'Pencils' },
{name :  'Mini Colored Paper' ,value :  'Mini Colored Paper' },
{name :  'Standing Doll' ,value :  'Standing Doll' },
{name :  'Bag' ,value :  'Bag' },
{name :  'Camping Chair' ,value :  'Camping Chair' },
{name :  'Hair Conditioner' ,value :  'Hair Conditioner' },
{name :  'Hand Lotion' ,value :  'Hand Lotion' },
{name :  'Sweatshirt' ,value :  'Sweatshirt' },
{name :  'Bucket Bag' ,value :  'Bucket Bag' },
{name :  'Cooler Bag' ,value :  'Cooler Bag' },
{name :  'Toys' ,value :  'Toys' },
{name :  'Handbags' ,value :  'Handbags' },
{name :  'cup' ,value :  'cup' },
{name :  'Knit Sweatshirt' ,value :  'Knit Sweatshirt' },
{name :  'Script Book' ,value :  'Script Book' },
{name :  'Knit Long Sleeve' ,value :  'Knit Long Sleeve' },
{name :  'Ramkin' ,value :  'Ramkin' },
{name :  'Intimate Gel' ,value :  'Intimate Gel' },
{name :  'Hand Towel' ,value :  'Hand Towel' },
{name :  'Cards' ,value :  'Cards' },
{name :  'Coin Purse' ,value :  'Coin Purse' },
{name :  'Compost Pail' ,value :  'Compost Pail' },
{name :  '2023 Photocard Calendar' ,value :  '2023 Photocard Calendar' },
{name :  'AirPods Pro Case' ,value :  'AirPods Pro Case' },
{name :  'Crepe Pan' ,value :  'Crepe Pan' },
{name :  'Beverage Cooler' ,value :  'Beverage Cooler' },
{name :  'Photo Package' ,value :  'Photo Package' },
{name :  'Acrylic stand' ,value :  'Acrylic stand' },
{name :  'Skincare, Cream, Skin' ,value :  'Skincare, Cream, Skin' },
{name :  'Kitchen Scissors' ,value :  'Kitchen Scissors' },
{name :  'Film Photo' ,value :  'Film Photo' },
{name :  'Dress Up Kit' ,value :  'Dress Up Kit' },
{name :  'Journals' ,value :  'Journals' },
{name :  'Pencil Holder' ,value :  'Pencil Holder' },
{name :  'Laptop Bag' ,value :  'Laptop Bag' },
{name :  'Illustrated Colored Paper' ,value :  'Illustrated Colored Paper' },
{name :  'Eye Cream' ,value :  'Eye Cream' },
{name :  'Photo Card Stand' ,value :  'Photo Card Stand' },
{name :  'Cushion' ,value :  'Cushion' },
{name :  'Body,BB Balm' ,value :  'Body,BB Balm' },
{name :  'doll' ,value :  'doll' },
{name :  'DVD' ,value :  'DVD' },
{name :  'Doll Magnets' ,value :  'Doll Magnets' },
{name :  'Goblet Glass' ,value :  'Goblet Glass' },
{name :  'Picnic Mat' ,value :  'Picnic Mat' },
{name :  'Body Wash' ,value :  'Body Wash' },
{name :  'Sink Mats' ,value :  'Sink Mats' },
{name :  'Pillow' ,value :  'Pillow' },
{name :  'Popup Photo' ,value :  'Popup Photo' },
{name :  'Travel Container' ,value :  'Travel Container' },
{name :  'Cereal Mug' ,value :  'Cereal Mug' },
{name :  'Bullet Opener' ,value :  'Bullet Opener' },
{name :  'AirPods Pouch' ,value :  'AirPods Pouch' },
{name :  'Autographed Colored Paper' ,value :  'Autographed Colored Paper' },
{name :  'Pen Pouch' ,value :  'Pen Pouch' },
{name :  'Health & Beauty' ,value :  'Health & Beauty' },
{name :  'Calendar' ,value :  'Calendar' },
{name :  'Anti-Aging Skin Care Kits' ,value :  'Anti-Aging Skin Care Kits' },
{name :  'Long Sleeve Tee' ,value :  'Long Sleeve Tee' },
{name :  'Coats & Jackets' ,value :  'Coats & Jackets' },
{name :  'Photo Frame' ,value :  'Photo Frame' },
{name :  'Track Set' ,value :  'Track Set' },
{name :  'Picnic Mats' ,value :  'Picnic Mats' },
{name :  'Novel' ,value :  'Novel' },
{name :  'Necklaces' ,value :  'Necklaces' },
{name :  'Inner Pants' ,value :  'Inner Pants' },
{name :  'neck strap' ,value :  'neck strap' },
{name :  'Soothing Cream' ,value :  'Soothing Cream' },
{name :  'Others' ,value :  'Others' },
{name :  'Eyepatch' ,value :  'Eyepatch' },
{name :  'Car wireless charger' ,value :  'Car wireless charger' },
{name :  'Outerwear' ,value :  'Outerwear' },
{name :  'Earphone Winder' ,value :  'Earphone Winder' },
{name :  'Magazines' ,value :  'Magazines' },
{name :  'Tamagotchi' ,value :  'Tamagotchi' },
{name :  'Wireless Mouse' ,value :  'Wireless Mouse' },
{name :  'Hologram Glass' ,value :  'Hologram Glass' },
{name :  'Blu Ray' ,value :  'Blu Ray' },
{name :  'Album Photos' ,value :  'Album Photos' },
{name :  'Camisole' ,value :  'Camisole' },
{name :  'Photo Card Frame' ,value :  'Photo Card Frame' },
{name :  'Stationery, Accessories' ,value :  'Stationery, Accessories' },
{name :  'Sleeveless Top' ,value :  'Sleeveless Top' },
{name :  'Skincare, Toner, Skin' ,value :  'Skincare, Toner, Skin' },
{name :  'Kitchen Glove' ,value :  'Kitchen Glove' },
{name :  'Key Holder' ,value :  'Key Holder' },
{name :  'Acrylic Photo Frames' ,value :  'Acrylic Photo Frames' },
{name :  'Messenger Bag' ,value :  'Messenger Bag' },
{name :  'Scarf' ,value :  'Scarf' },
{name :  'Nail,Nail Color' ,value :  'Nail,Nail Color' },
{name :  'Collectible' ,value :  'Collectible' },
{name :  'Photo Essay' ,value :  'Photo Essay' },
{name :  'Sauce Pan' ,value :  'Sauce Pan' },
{name :  'Roll Stickers' ,value :  'Roll Stickers' },
{name :  'Toners' ,value :  'Toners' },
{name :  'Sleep Eyepatch' ,value :  'Sleep Eyepatch' },
{name :  'Felt Mat' ,value :  'Felt Mat' },
{name :  'Tops' ,value :  'Tops' },
{name :  'Water Glitter' ,value :  'Water Glitter' },
{name :  'Dinnerware' ,value :  'Dinnerware' },
{name :  'Grip' ,value :  'Grip' },
{name :  'Body Oil' ,value :  'Body Oil' },
{name :  'Eco Back' ,value :  'Eco Back' },
{name :  'Beg Pet Travel Case' ,value :  'Beg Pet Travel Case' },
{name :  'Hobo bag' ,value :  'Hobo bag' },
{name :  'Loose Powder' ,value :  'Loose Powder' },
{name :  'sticker' ,value :  'sticker' },
{name :  'Skincare, Nose strip' ,value :  'Skincare, Nose strip' },
{name :  'Air Kit' ,value :  'Air Kit' },
{name :  'Knit' ,value :  'Knit' },
{name :  'Face Mask' ,value :  'Face Mask' },
{name :  'Pouches' ,value :  'Pouches' },
{name :  'Footwear' ,value :  'Footwear' },
{name :  'Chair' ,value :  'Chair' },
{name :  'Binder Note' ,value :  'Binder Note' },
{name :  'Dinning Mat' ,value :  'Dinning Mat' },
{name :  'Makeup Sponge' ,value :  'Makeup Sponge' },
{name :  'Short Pants' ,value :  'Short Pants' },
{name :  'Paper Toy Book' ,value :  'Paper Toy Book' },
{name :  'Pot Stand' ,value :  'Pot Stand' },
{name :  'Binder Pockets' ,value :  'Binder Pockets' },
{name :  'Illustration Card' ,value :  'Illustration Card' },
{name :  'Room Slipper' ,value :  'Room Slipper' },
{name :  'Home & Living' ,value :  'Home & Living' },
{name :  'Book' ,value :  'Book' },
{name :  'Cool pack' ,value :  'Cool pack' },
{name :  'Silica Gel Case' ,value :  'Silica Gel Case' },
{name :  'Acrylic Holder' ,value :  'Acrylic Holder' },
{name :  'Food Storage Bags' ,value :  'Food Storage Bags' },
{name :  'Statue Figure' ,value :  'Statue Figure' },
{name :  'Beach Towels' ,value :  'Beach Towels' },
{name :  'Desk Pad' ,value :  'Desk Pad' },
{name :  'Two-piece sets' ,value :  'Two-piece sets' },
{name :  'Accessories, Jewelry' ,value :  'Accessories, Jewelry' },
{name :  'Dinnerware sets' ,value :  'Dinnerware sets' },
{name :  'Mouse Pad' ,value :  'Mouse Pad' },
{name :  'Top' ,value :  'Top' },
{name :  'Badge & Keyring' ,value :  'Badge & Keyring' },
{name :  'Ball Cap' ,value :  'Ball Cap' },
{name :  'Skincare,Eye Cream' ,value :  'Skincare,Eye Cream' },
{name :  'Tee' ,value :  'Tee' },
{name :  'Coat' ,value :  'Coat' },
{name :  'Textiles' ,value :  'Textiles' },
{name :  'Makeup,Foundation' ,value :  'Makeup,Foundation' },
{name :  'bag' ,value :  'bag' },
{name :  'Swimwear' ,value :  'Swimwear' },
{name :  'Photocard Holder' ,value :  'Photocard Holder' },
{name :  'Shower Cap' ,value :  'Shower Cap' },
{name :  'Candy' ,value :  'Candy' },
{name :  'Griptok' ,value :  'Griptok' },
{name :  'Eye Cream For Face' ,value :  'Eye Cream For Face' },
{name :  'Acrylic Plate' ,value :  'Acrylic Plate' },
{name :  'Blazer' ,value :  'Blazer' },
{name :  'Innerwear' ,value :  'Innerwear' },
{name :  'Neck pillow' ,value :  'Neck pillow' },
{name :  'Buds and AirPods Case' ,value :  'Buds and AirPods Case' },
{name :  'Table mat' ,value :  'Table mat' },
{name :  'Watch strap' ,value :  'Watch strap' },
{name :  'Eye Liner' ,value :  'Eye Liner' },
{name :  'homeware' ,value :  'homeware' },
{name :  'Buds & Buzz Live cases' ,value :  'Buds & Buzz Live cases' },
{name :  'Sleeping Bag' ,value :  'Sleeping Bag' },
{name :  'Kitchen Cloth' ,value :  'Kitchen Cloth' },
{name :  'Bags' ,value :  'Bags' },
{name :  'Yoga Mat' ,value :  'Yoga Mat' },
{name :  'Album' ,value :  'Album' },
{name :  'Wok' ,value :  'Wok' },
{name :  'Grip Talk' ,value :  'Grip Talk' },
{name :  'Water Glitters' ,value :  'Water Glitters' },
{name :  'Oral Care' ,value :  'Oral Care' },
{name :  'Makeup,Lip Crayon' ,value :  'Makeup,Lip Crayon' },
{name :  'Cleanser, Cleanser Blam' ,value :  'Cleanser, Cleanser Blam' },
{name :  'Fabric Poster' ,value :  'Fabric Poster' },
{name :  'Kitchenware' ,value :  'Kitchenware' },
{name :  'diary' ,value :  'diary' },
{name :  'Organizer' ,value :  'Organizer' },
{name :  'Body Mist' ,value :  'Body Mist' },
{name :  'Top and Bottom' ,value :  'Top and Bottom' },
{name :  'Pet' ,value :  'Pet' },
{name :  'Griptoks' ,value :  'Griptoks' },
{name :  'Bread Box' ,value :  'Bread Box' },
{name :  'Grip Stand' ,value :  'Grip Stand' },
{name :  'Planner' ,value :  'Planner' },
{name :  'Laptop' ,value :  'Laptop' },
{name :  'Tablet Bag' ,value :  'Tablet Bag' },
{name :  'Slogan Towel' ,value :  'Slogan Towel' },
{name :  'Pajama Set' ,value :  'Pajama Set' },
{name :  'Thermos' ,value :  'Thermos' },
{name :  'iPhone Case' ,value :  'iPhone Case' },
{name :  'Turntables & Record Players' ,value :  'Turntables & Record Players' },
{name :  'Photobook' ,value :  'Photobook' },
{name :  'Fanbook' ,value :  'Fanbook' },
{name :  'Memo Board' ,value :  'Memo Board' },
{name :  'Induction Cooker' ,value :  'Induction Cooker' },
{name :  'Cutting Boards' ,value :  'Cutting Boards' },
{name :  'Massage Candle' ,value :  'Massage Candle' },
{name :  'Clock' ,value :  'Clock' },
{name :  'Gel Cream' ,value :  'Gel Cream' },
{name :  'Water Globe' ,value :  'Water Globe' },
{name :  'Grilling Pan' ,value :  'Grilling Pan' },
{name :  'Bandana' ,value :  'Bandana' },
{name :  'Popcorn Makers' ,value :  'Popcorn Makers' },
{name :  'Cookware Sets' ,value :  'Cookware Sets' },
{name :  'Pillow Case' ,value :  'Pillow Case' },
{name :  'Rattan Cover' ,value :  'Rattan Cover' },
{name :  'Lip Gloss' ,value :  'Lip Gloss' },
{name :  'Laptop Pouches' ,value :  'Laptop Pouches' },
{name :  'Collage' ,value :  'Collage' },
{name :  'Muffler' ,value :  'Muffler' },
{name :  'Skincare, Hand cream' ,value :  'Skincare, Hand cream' },
{name :  'Laptop Pouch' ,value :  'Laptop Pouch' },
{name :  'Care Kit' ,value :  'Care Kit' },
{name :  'Perfume & Cologne' ,value :  'Perfume & Cologne' },
{name :  'Airpods Case' ,value :  'Airpods Case' },
{name :  'Pin' ,value :  'Pin' },
{name :  'Womens Swimsuit' ,value :  'Womens Swimsuit' },
{name :  'Sneakers' ,value :  'Sneakers' },
{name :  'Neck Strap' ,value :  'Neck Strap' },
{name :  'Metal Frame' ,value :  'Metal Frame' },
{name :  'Pan Sets' ,value :  'Pan Sets' },
{name :  'Tablewear' ,value :  'Tablewear' },
{name :  'Smart Talk' ,value :  'Smart Talk' },
{name :  'Baby Food Container' ,value :  'Baby Food Container' },
{name :  'Topper' ,value :  'Topper' },
{name :  'Acrylic Stands' ,value :  'Acrylic Stands' },
{name :  'Graphic Short Sleeve Tee' ,value :  'Graphic Short Sleeve Tee' },
{name :  'plush' ,value :  'plush' },
{name :  'Skin Care' ,value :  'Skin Care' },
{name :  'Stamp' ,value :  'Stamp' },
{name :  'Poncho Liners' ,value :  'Poncho Liners' },
{name :  'Variety Pack' ,value :  'Variety Pack' },
{name :  'Whisk' ,value :  'Whisk' },
{name :  'Hair,Hair Mist' ,value :  'Hair,Hair Mist' },
{name :  'Action Figures' ,value :  'Action Figures' },
{name :  'Skincare, Essence, Skin' ,value :  'Skincare, Essence, Skin' },
{name :  'Hip Sack' ,value :  'Hip Sack' },
{name :  'Sleeveless Shirt' ,value :  'Sleeveless Shirt' },
{name :  'Figures' ,value :  'Figures' },
{name :  'Yogurt Maker' ,value :  'Yogurt Maker' },
{name :  'Multi Pouch' ,value :  'Multi Pouch' },
{name :  'Makeup,Powder' ,value :  'Makeup,Powder' },
{name :  'Collection' ,value :  'Collection' },
{name :  'Watch Charger Stand' ,value :  'Watch Charger Stand' },
{name :  'Plush' ,value :  'Plush' },
{name :  'Sandwich Makers' ,value :  'Sandwich Makers' },
{name :  'Sack' ,value :  'Sack' },
{name :  'Pot' ,value :  'Pot' },
{name :  'Blusher' ,value :  'Blusher' },
{name :  'Dolls' ,value :  'Dolls' },
{name :  'Face Cushion' ,value :  'Face Cushion' },
{name :  'Tissue Cover' ,value :  'Tissue Cover' },
{name :  'Condom' ,value :  'Condom' },
{name :  'hand strap' ,value :  'hand strap' },
{name :  'Plushy' ,value :  'Plushy' },
{name :  'Face Primer' ,value :  'Face Primer' },
{name :  'Foundation' ,value :  'Foundation' },
{name :  'PhotoBook' ,value :  'PhotoBook' },
{name :  'Coloring Book' ,value :  'Coloring Book' },
{name :  'Storage & Organization' ,value :  'Storage & Organization' },
{name :  'Airpods/Airpods Pro Cases' ,value :  'Airpods/Airpods Pro Cases' },
{name :  'Shirt' ,value :  'Shirt' },
{name :  'Muddler Stick' ,value :  'Muddler Stick' },
{name :  'Lip Tint' ,value :  'Lip Tint' },
{name :  'Photo Prop' ,value :  'Photo Prop' },
{name :  'Cash Book' ,value :  'Cash Book' },
{name :  'Pocket Folders' ,value :  'Pocket Folders' },
{name :  'Toppers' ,value :  'Toppers' },
{name :  'Cushion Compact' ,value :  'Cushion Compact' },
{name :  'Body Lotion' ,value :  'Body Lotion' },
{name :  'AirPods Cases' ,value :  'AirPods Cases' },
{name :  'Skin Care Masks & Peels' ,value :  'Skin Care Masks & Peels' },
{name :  'Photo Albums' ,value :  'Photo Albums' },
{name :  'Earphone Cases' ,value :  'Earphone Cases' },
{name :  'Name Tags' ,value :  'Name Tags' },
{name :  'Glass' ,value :  'Glass' },
{name :  'Acrylic Figure' ,value :  'Acrylic Figure' },
{name :  'Phone Casing' ,value :  'Phone Casing' },
{name :  'Toy Playsets' ,value :  'Toy Playsets' },
{name :  'beauty' ,value :  'beauty' },
{name :  'Pot and Frying Pan' ,value :  'Pot and Frying Pan' },
{name :  'Puffer Bag' ,value :  'Puffer Bag' },
{name :  'Buds Hard Case' ,value :  'Buds Hard Case' },
{name :  'Console Pouch' ,value :  'Console Pouch' },
{name :  'Gloves' ,value :  'Gloves' },
{name :  'Season greeting' ,value :  'Season greeting' },
{name :  'Stew Pot' ,value :  'Stew Pot' },
{name :  'Stationery & Office Supplies' ,value :  'Stationery & Office Supplies' },
{name :  'Sweatshirts' ,value :  'Sweatshirts' },
{name :  'Hair Styling Products' ,value :  'Hair Styling Products' },
{name :  'Playing Cards' ,value :  'Playing Cards' },
{name :  'Tumbler' ,value :  'Tumbler' },
{name :  'Planners' ,value :  'Planners' },
{name :  'Dessert knife' ,value :  'Dessert knife' },
{name :  'Pen' ,value :  'Pen' },
{name :  'Makeup Brushes' ,value :  'Makeup Brushes' },
{name :  'tumbler' ,value :  'tumbler' },
{name :  'Glass cup' ,value :  'Glass cup' },
{name :  'Photoalbum' ,value :  'Photoalbum' },
{name :  'Repairing Ointment' ,value :  'Repairing Ointment' },
{name :  'Badge' ,value :  'Badge' },
{name :  'Puffer' ,value :  'Puffer' },
{name :  'Eyes, Gel' ,value :  'Eyes, Gel' },
{name :  'Raincoat' ,value :  'Raincoat' },
{name :  'Figure' ,value :  'Figure' },
{name :  'Folding Bag' ,value :  'Folding Bag' },
{name :  'Makeup,CC' ,value :  'Makeup,CC' },
{name :  'Toys & Games' ,value :  'Toys & Games' },
{name :  'Ceramic & Pottery Glazes' ,value :  'Ceramic & Pottery Glazes' },
{name :  'Set' ,value :  'Set' },
{name :  'Ampoules' ,value :  'Ampoules' },
{name :  'Sticky Memo Pads' ,value :  'Sticky Memo Pads' },
{name :  'Scrap Kit' ,value :  'Scrap Kit' },
{name :  'Folding Cart' ,value :  'Folding Cart' },
{name :  'Eye Makeup' ,value :  'Eye Makeup' },
{name :  'Card Pocket' ,value :  'Card Pocket' },
{name :  'Picnic Bag' ,value :  'Picnic Bag' },
{name :  'Hand Cream' ,value :  'Hand Cream' },
{name :  'Metal Badge' ,value :  'Metal Badge' },
{name :  'Anorak Jacket' ,value :  'Anorak Jacket' },
{name :  'Headband' ,value :  'Headband' },
{name :  'Wireless Earbuds' ,value :  'Wireless Earbuds' },
{name :  'Blanket Set' ,value :  'Blanket Set' },
{name :  'Keyboard' ,value :  'Keyboard' },
{name :  'iPad/Laptop Pouch' ,value :  'iPad/Laptop Pouch' },
{name :  'Cereal Bowl' ,value :  'Cereal Bowl' },
{name :  'Illustration Papers' ,value :  'Illustration Papers' },
{name :  'Cups' ,value :  'Cups' },
{name :  'Neck Cushion' ,value :  'Neck Cushion' },
{name :  'Weight Management' ,value :  'Weight Management' },
{name :  'Anti-Aging' ,value :  'Anti-Aging' },
{name :  'Eraser' ,value :  'Eraser' },
{name :  'Message Cards' ,value :  'Message Cards' },
{name :  'Shopper Bag' ,value :  'Shopper Bag' },
{name :  'Soothing Gel' ,value :  'Soothing Gel' },
{name :  'Calender' ,value :  'Calender' },
{name :  'Long Sleeve Shirts' ,value :  'Long Sleeve Shirts' },
{name :  'Knitted Bag' ,value :  'Knitted Bag' },
{name :  'Facial Essence' ,value :  'Facial Essence' },
{name :  'Diary' ,value :  'Diary' },
{name :  'Folding Chair' ,value :  'Folding Chair' },
{name :  'Snack Set' ,value :  'Snack Set' },
{name :  'Earrings' ,value :  'Earrings' },
{name :  'Makeup,Concealer' ,value :  'Makeup,Concealer' },
{name :  'Cropped Hoodie' ,value :  'Cropped Hoodie' },
{name :  'Makeup Removers' ,value :  'Makeup Removers' },
{name :  'Mouse Pads' ,value :  'Mouse Pads' },
{name :  'Ballpoint Pen' ,value :  'Ballpoint Pen' },
{name :  'Skincare Set' ,value :  'Skincare Set' },
{name :  'Adhesives' ,value :  'Adhesives' },
{name :  'Soap Tray' ,value :  'Soap Tray' },
{name :  'Photo Frames' ,value :  'Photo Frames' },
{name :  'Mobile & Gadgets' ,value :  'Mobile & Gadgets' },
{name :  'Cooking Utensils Set' ,value :  'Cooking Utensils Set' },
{name :  'Lenticular Photo Card' ,value :  'Lenticular Photo Card' },
{name :  'Patch' ,value :  'Patch' },
{name :  'Accessory' ,value :  'Accessory' },
{name :  'Short Sleeve Tee' ,value :  'Short Sleeve Tee' },
{name :  'Hair Removal Cream' ,value :  'Hair Removal Cream' },
{name :  'Palette' ,value :  'Palette' },
{name :  'Cereal Bowl Set' ,value :  'Cereal Bowl Set' },
{name :  'Blush and Eyeshadow Palette' ,value :  'Blush and Eyeshadow Palette' },
{name :  'Pet Accessories' ,value :  'Pet Accessories' },
{name :  'Phone holder' ,value :  'Phone holder' },
{name :  'Cooking tool rack' ,value :  'Cooking tool rack' },
{name :  'Binder Refill' ,value :  'Binder Refill' },
{name :  'Sweatshirt Set' ,value :  'Sweatshirt Set' },
{name :  'Skin Care Set' ,value :  'Skin Care Set' },
{name :  'Long Pants' ,value :  'Long Pants' },
{name :  'Photo Ticket Album' ,value :  'Photo Ticket Album' },
{name :  'Robe' ,value :  'Robe' },
{name :  'Card' ,value :  'Card' },
{name :  'Shoulder Bag' ,value :  'Shoulder Bag' },
{name :  'Hat' ,value :  'Hat' },
{name :  'Eye Color Palette' ,value :  'Eye Color Palette' },
{name :  'Cross bag' ,value :  'Cross bag' },
{name :  'Mood Light' ,value :  'Mood Light' },
{name :  'Keyboard Pad' ,value :  'Keyboard Pad' },
{name :  'Makeup,Eyeshadow' ,value :  'Makeup,Eyeshadow' },
{name :  'Phone Holder' ,value :  'Phone Holder' },
{name :  'Hair Serum' ,value :  'Hair Serum' },
{name :  'Official Goods' ,value :  'Official Goods' },
{name :  'Photo File Cover' ,value :  'Photo File Cover' },
{name :  'Windbreaker' ,value :  'Windbreaker' },
{name :  'Bucket Hat' ,value :  'Bucket Hat' },
{name :  'Wireless Charging Pad' ,value :  'Wireless Charging Pad' },
{name :  'Emulsion' ,value :  'Emulsion' },
{name :  'Image Picket' ,value :  'Image Picket' },
{name :  'Pencil Case Set' ,value :  'Pencil Case Set' },
{name :  'Jersey' ,value :  'Jersey' },
{name :  'Note set' ,value :  'Note set' },
{name :  'Acrylic Stand' ,value :  'Acrylic Stand' },
{name :  'Calendars, Organizers & Planners' ,value :  'Calendars, Organizers & Planners' },
{name :  'Shirt/Blouse' ,value :  'Shirt/Blouse' },
{name :  'Pillowcases & Shams' ,value :  'Pillowcases & Shams' },
{name :  'Hangers' ,value :  'Hangers' },
{name :  'Golf Bag' ,value :  'Golf Bag' },
{name :  'Face Mirrors' ,value :  'Face Mirrors' },
{name :  'OST Book' ,value :  'OST Book' },
{name :  'Colored Paper' ,value :  'Colored Paper' },
{name :  'Robes' ,value :  'Robes' },
{name :  'Waist Bag' ,value :  'Waist Bag' },
{name :  'HotPot' ,value :  'HotPot' },
{name :  'Makeup,Lip Balm' ,value :  'Makeup,Lip Balm' },
{name :  'Corner Guard' ,value :  'Corner Guard' },
{name :  'Photocard Album' ,value :  'Photocard Album' },
{name :  'CLOTH' ,value :  'CLOTH' },
{name :  'Paper Clips' ,value :  'Paper Clips' },
{name :  'Single' ,value :  'Single' },
{name :  'Mini Card' ,value :  'Mini Card' },
{name :  'Strap' ,value :  'Strap' },
{name :  'Wireless Charger' ,value :  'Wireless Charger' },
{name :  'Plush Doll' ,value :  'Plush Doll' },
{name :  'Key ring' ,value :  'Key ring' },
{name :  'Pouch Bag' ,value :  'Pouch Bag' },
{name :  'Inflatable Sofa' ,value :  'Inflatable Sofa' },
{name :  'Band Aid' ,value :  'Band Aid' },
{name :  'Buds Live Cases' ,value :  'Buds Live Cases' },
{name :  'Multi Charger' ,value :  'Multi Charger' },
{name :  'Wash Bag' ,value :  'Wash Bag' },
{name :  'Cardigan' ,value :  'Cardigan' },
{name :  'Mobile' ,value :  'Mobile' },
{name :  'Makeup,Eyeliner' ,value :  'Makeup,Eyeliner' },
{name :  'Collect Binder' ,value :  'Collect Binder' },
{name :  'Ice Tray' ,value :  'Ice Tray' },
{name :  'Manhwa' ,value :  'Manhwa' },
{name :  'Bath Sponges & Loofahs' ,value :  'Bath Sponges & Loofahs' },
{name :  'Card Holder' ,value :  'Card Holder' },
{name :  'Diamond Painting' ,value :  'Diamond Painting' },
{name :  'Shirts' ,value :  'Shirts' },
{name :  'Airpods case' ,value :  'Airpods case' },
{name :  'Metal Badges' ,value :  'Metal Badges' },
{name :  'Long Sleeve T-shirts' ,value :  'Long Sleeve T-shirts' },
{name :  'Memo pad' ,value :  'Memo pad' },
{name :  'Pajama' ,value :  'Pajama' },
{name :  'Wok sets' ,value :  'Wok sets' },
{name :  'Lip Makeup' ,value :  'Lip Makeup' },
{name :  'Stationary' ,value :  'Stationary' },
{name :  'Hair Mist' ,value :  'Hair Mist' },
{name :  'Mechanical Keyboard' ,value :  'Mechanical Keyboard' },
{name :  'Steel badge' ,value :  'Steel badge' },
{name :  'Cutting Board Holder' ,value :  'Cutting Board Holder' },
{name :  'OST Album' ,value :  'OST Album' },
{name :  'Short Sleeve' ,value :  'Short Sleeve' },
{name :  'Eyeshadow' ,value :  'Eyeshadow' },
{name :  'Plush magnet' ,value :  'Plush magnet' },
{name :  'Mug' ,value :  'Mug' },
{name :  'Accessories' ,value :  'Accessories' },
{name :  'Cleansing Oil' ,value :  'Cleansing Oil' },
{name :  'LG Case' ,value :  'LG Case' },
{name :  'Buds/Buds Live Cases' ,value :  'Buds/Buds Live Cases' },
{name :  'Sweat Set' ,value :  'Sweat Set' },
{name :  'Gag' ,value :  'Gag' },
{name :  'Stamp Blocks' ,value :  'Stamp Blocks' },
{name :  'Apple AirPods Case' ,value :  'Apple AirPods Case' },
{name :  'Turtle Neck' ,value :  'Turtle Neck' },
{name :  'Mugs' ,value :  'Mugs' },
{name :  'Headwear' ,value :  'Headwear' },
{name :  'Skincare,Tone' ,value :  'Skincare,Tone' },
{name :  'Neck Cream' ,value :  'Neck Cream' },
{name :  'Application Form + ID Photo' ,value :  'Application Form + ID Photo' },
{name :  'Makeup Accessories' ,value :  'Makeup Accessories' },
{name :  'Cookware' ,value :  'Cookware' },
{name :  'Postcard Book' ,value :  'Postcard Book' },
{name :  'Tin Case' ,value :  'Tin Case' },
{name :  'Manga' ,value :  'Manga' },
{name :  'Hair Pin' ,value :  'Hair Pin' },
{name :  'Chain Charm' ,value :  'Chain Charm' },
{name :  'Watch Strap' ,value :  'Watch Strap' },
{name :  'Blu-Ray' ,value :  'Blu-Ray' },
{name :  'Tableware Set' ,value :  'Tableware Set' },
{name :  'Canister' ,value :  'Canister' },
{name :  'Stationary, Accessories' ,value :  'Stationary, Accessories' },
{name :  'Keyboard Set' ,value :  'Keyboard Set' },
{name :  'ID Photos' ,value :  'ID Photos' },
{name :  'Choker & Leash' ,value :  'Choker & Leash' },
{name :  'Apple Watch Strap Band' ,value :  'Apple Watch Strap Band' },
{name :  'Beanie' ,value :  'Beanie' },
{name :  'Handwarmers' ,value :  'Handwarmers' },
{name :  'Phone Tab' ,value :  'Phone Tab' },
{name :  'Travel Bag' ,value :  'Travel Bag' },
{name :  't-shirt' ,value :  't-shirt' },
{name :  'Phone Cases' ,value :  'Phone Cases' },
{name :  'Pot & Pan' ,value :  'Pot & Pan' },
{name :  'Sticker File' ,value :  'Sticker File' },
{name :  'Camping Tools' ,value :  'Camping Tools' },
{name :  'Stationeries' ,value :  'Stationeries' },
{name :  'AirPods/AirPods Pro Case' ,value :  'AirPods/AirPods Pro Case' },
{name :  'Photocard' ,value :  'Photocard' },
{name :  'Plush Toy' ,value :  'Plush Toy' },
{name :  'Flag' ,value :  'Flag' },
{name :  'Bottle Sleeve' ,value :  'Bottle Sleeve' },
{name :  'Mirrors' ,value :  'Mirrors' },
{name :  'Buds Live Case' ,value :  'Buds Live Case' },
{name :  'Table Mat' ,value :  'Table Mat' },
{name :  'Galaxy Buds Case' ,value :  'Galaxy Buds Case' },
{name :  'Skincare, Skin, Sleeping Pack' ,value :  'Skincare, Skin, Sleeping Pack' },
{name :  'Stockings' ,value :  'Stockings' },
{name :  'Lunch Bag' ,value :  'Lunch Bag' },
{name :  'Mini Wallet' ,value :  'Mini Wallet' },
{name :  'ID Cards' ,value :  'ID Cards' },
{name :  'Cushion Cover' ,value :  'Cushion Cover' },
{name :  'Mobile Phone Cases' ,value :  'Mobile Phone Cases' },
{name :  'Skincare,Cleansing Foam' ,value :  'Skincare,Cleansing Foam' },
{name :  'Jigsaw Puzzle' ,value :  'Jigsaw Puzzle' },
{name :  'Pen Holder' ,value :  'Pen Holder' },
{name :  'Photo Keyring' ,value :  'Photo Keyring' },
{name :  'Photo Card' ,value :  'Photo Card' },
{name :  'Memo Pads' ,value :  'Memo Pads' },
{name :  'Facial Cream' ,value :  'Facial Cream' },
{name :  'Desk Calendar' ,value :  'Desk Calendar' },
{name :  'Paper' ,value :  'Paper' },
{name :  'Musical Hand Washer' ,value :  'Musical Hand Washer' },
{name :  'Slippers' ,value :  'Slippers' },
{name :  'Photos' ,value :  'Photos' },
{name :  'Hotpot' ,value :  'Hotpot' },
{name :  'Long pillow' ,value :  'Long pillow' },
{name :  'Postcards' ,value :  'Postcards' },
{name :  'Korea Disinfection Mask' ,value :  'Korea Disinfection Mask' },
{name :  'Bottle' ,value :  'Bottle' },
{name :  'Laptop bag' ,value :  'Laptop bag' },
{name :  'Hats' ,value :  'Hats' },
{name :  'Fans' ,value :  'Fans' },
{name :  'Water Stickers' ,value :  'Water Stickers' },
{name :  'Rash Guard' ,value :  'Rash Guard' },
{name :  'Earring' ,value :  'Earring' },
{name :  'Transportation Card' ,value :  'Transportation Card' },
{name :  'Polaroids' ,value :  'Polaroids' },
{name :  'Skincare, Sunblock' ,value :  'Skincare, Sunblock' },
{name :  'Chopstick Rests' ,value :  'Chopstick Rests' },
{name :  'Figurine' ,value :  'Figurine' },
{name :  'Mini Album' ,value :  'Mini Album' },
{name :  'Nose Pack' ,value :  'Nose Pack' },
{name :  'Memo Paper' ,value :  'Memo Paper' },
{name :  'Power Bank' ,value :  'Power Bank' },
{name :  'Measuring cup' ,value :  'Measuring cup' },
{name :  'Toning Lotion' ,value :  'Toning Lotion' },
{name :  'Badge & Pass Holders' ,value :  'Badge & Pass Holders' },
{name :  'Hair & Body Care' ,value :  'Hair & Body Care' },
{name :  'Acrylic frame' ,value :  'Acrylic frame' },
{name :  'Drawing Books' ,value :  'Drawing Books' },
{name :  'Lunch Box' ,value :  'Lunch Box' },
{name :  'Movie' ,value :  'Movie' },
{name :  'Rugs' ,value :  'Rugs' },
{name :  'Aipods Case / Buds Case' ,value :  'Aipods Case / Buds Case' },
{name :  'Tablet Pouches' ,value :  'Tablet Pouches' },
{name :  'Lanyard' ,value :  'Lanyard' },
{name :  'Skincare,Cream' ,value :  'Skincare,Cream' },
{name :  'Sticker' ,value :  'Sticker' },
{name :  'Crewneck' ,value :  'Crewneck' },
{name :  'Feminine Wash' ,value :  'Feminine Wash' },
{name :  'Zipper Bag' ,value :  'Zipper Bag' },
{name :  'Office Supplies' ,value :  'Office Supplies' },
{name :  'Pin Badge' ,value :  'Pin Badge' },
{name :  'Blankets' ,value :  'Blankets' },
{name :  'Skin Repair' ,value :  'Skin Repair' },
{name :  'Glasses Cleaner' ,value :  'Glasses Cleaner' },
{name :  'Resume Package' ,value :  'Resume Package' },
{name :  'Mule' ,value :  'Mule' },
{name :  'Mini Folding Screen' ,value :  'Mini Folding Screen' },
{name :  'Employee ID' ,value :  'Employee ID' },
{name :  'CCS1 Combo Adapter' ,value :  'CCS1 Combo Adapter' },
{name :  'Belts' ,value :  'Belts' },
{name :  'Travel Kit' ,value :  'Travel Kit' },
{name :  'Notebooks & Notepads' ,value :  'Notebooks & Notepads' },
{name :  'Pet Slim Bag' ,value :  'Pet Slim Bag' },
{name :  'Umbrella' ,value :  'Umbrella' },
{name :  'Animal Supplies' ,value :  'Animal Supplies' },
{name :  'Paper Pack' ,value :  'Paper Pack' },
{name :  'Light Novel' ,value :  'Light Novel' },
{name :  'Pen Stand' ,value :  'Pen Stand' },
{name :  'Hair Vinegar' ,value :  'Hair Vinegar' },
{name :  'Mini Pouch' ,value :  'Mini Pouch' },
{name :  'Hand Fans' ,value :  'Hand Fans' },
{name :  'Wine Glass' ,value :  'Wine Glass' },
{name :  'Camera' ,value :  'Camera' },
{name :  'Lucky Draw' ,value :  'Lucky Draw' },
{name :  'Deco Kit' ,value :  'Deco Kit' },
{name :  'Facial Lotion' ,value :  'Facial Lotion' },
{name :  'Photo Holder' ,value :  'Photo Holder' },
{name :  'Sport Goods' ,value :  'Sport Goods' },
{name :  '2023 Desk Calendar' ,value :  '2023 Desk Calendar' },
{name :  'Cleansing Pad' ,value :  'Cleansing Pad' },
{name :  'Foundations & Concealers' ,value :  'Foundations & Concealers' },
{name :  'Box' ,value :  'Box' },
{name :  'Lighting' ,value :  'Lighting' },
{name :  'Lip Mask' ,value :  'Lip Mask' },
{name :  'Heels' ,value :  'Heels' },
{name :  'Mid Padding' ,value :  'Mid Padding' },
{name :  'Base Makeup,Concealer' ,value :  'Base Makeup,Concealer' },
{name :  'Insulated Tumbler' ,value :  'Insulated Tumbler' },
{name :  'Detective File' ,value :  'Detective File' },
{name :  'Masking Tape' ,value :  'Masking Tape' },
{name :  'Cicapair Cream' ,value :  'Cicapair Cream' },
{name :  'Buds Plus Hard Case' ,value :  'Buds Plus Hard Case' },
{name :  'Galaxy Case' ,value :  'Galaxy Case' },
{name :  'Keyboard Accessories' ,value :  'Keyboard Accessories' },
{name :  'calendars' ,value :  'calendars' },
{name :  'Gel Pen' ,value :  'Gel Pen' },
{name :  'Denim Pants' ,value :  'Denim Pants' },
{name :  'Aprons' ,value :  'Aprons' },
{name :  'Long Skirts' ,value :  'Long Skirts' },
{name :  'Bedding' ,value :  'Bedding' },
{name :  'Equipment' ,value :  'Equipment' },
{name :  'Slogan Set' ,value :  'Slogan Set' },
{name :  'hidden' ,value :  'hidden' },
{name :  'Illustrated Cards' ,value :  'Illustrated Cards' },
{name :  'iPad Case' ,value :  'iPad Case' },
{name :  'Serving Trays' ,value :  'Serving Trays' },
{name :  'Stock Pot' ,value :  'Stock Pot' },
{name :  'Glass Dish Set' ,value :  'Glass Dish Set' },
{name :  'Pot Sets' ,value :  'Pot Sets' },
{name :  'Multi Cleaner' ,value :  'Multi Cleaner' },
{name :  'Desk Multi-purpose Holder' ,value :  'Desk Multi-purpose Holder' },
{name :  'Strap Keyrings' ,value :  'Strap Keyrings' },
{name :  'Waste Bin' ,value :  'Waste Bin' },
{name :  'Multidock' ,value :  'Multidock' },
{name :  'Phone Case' ,value :  'Phone Case' },
{name :  'Lip Balm' ,value :  'Lip Balm' },
{name :  'Keyboard DIY Kit' ,value :  'Keyboard DIY Kit' },
{name :  'Pet Mat' ,value :  'Pet Mat' },
{name :  'Stationery' ,value :  'Stationery' },
{name :  'ID photos' ,value :  'ID photos' },
{name :  'Tinted Primer' ,value :  'Tinted Primer' },
{name :  'Slides' ,value :  'Slides' },
{name :  'Doll' ,value :  'Doll' },
{name :  'Golf Ball Pouch' ,value :  'Golf Ball Pouch' },
{name :  'Chair & Sofa Cushions' ,value :  'Chair & Sofa Cushions' },
{name :  'iPhone Accessory' ,value :  'iPhone Accessory' },
{name :  'Skincare, Cleanser, Skin' ,value :  'Skincare, Cleanser, Skin' },
{name :  'Egg Pan' ,value :  'Egg Pan' },
{name :  'File Storage' ,value :  'File Storage' },
{name :  'Hair Accessories' ,value :  'Hair Accessories' },
{name :  'Duo Pot' ,value :  'Duo Pot' },
{name :  'Makeup Kit' ,value :  'Makeup Kit' },
{name :  'Plush doll' ,value :  'Plush doll' },
{name :  'Massage Gun' ,value :  'Massage Gun' },
{name :  'Face Powder' ,value :  'Face Powder' },
{name :  'Hooded sweatshirt' ,value :  'Hooded sweatshirt' },
{name :  '2023 Calendars' ,value :  '2023 Calendars' },
{name :  'Frame Deck' ,value :  'Frame Deck' },
{name :  'Transparent Postcard' ,value :  'Transparent Postcard' },
{name :  'Vest' ,value :  'Vest' },
{name :  'Tumblers' ,value :  'Tumblers' },
{name :  'Cushions' ,value :  'Cushions' },
{name :  'Keychains' ,value :  'Keychains' },
{name :  'Tarot Card' ,value :  'Tarot Card' },
{name :  'Vitamins' ,value :  'Vitamins' },
{name :  'Doll keyring' ,value :  'Doll keyring' },
{name :  'Concealer' ,value :  'Concealer' },
{name :  'Skincare,Foam' ,value :  'Skincare,Foam' },
{name :  'Mini Table' ,value :  'Mini Table' },
{name :  'iPad Pouches' ,value :  'iPad Pouches' },
{name :  'Roulette Game' ,value :  'Roulette Game' },
{name :  'Makeup Set' ,value :  'Makeup Set' },
{name :  'Flip Flops' ,value :  'Flip Flops' },
{name :  'Scene Film Pack' ,value :  'Scene Film Pack' },
{name :  'Party Pack' ,value :  'Party Pack' },
{name :  'Knife' ,value :  'Knife' },
{name :  'Light Stick' ,value :  'Light Stick' },
{name :  'Light Stick Deco Band' ,value :  'Light Stick Deco Band' },
{name :  'Lenticular Card' ,value :  'Lenticular Card' },
{name :  'Blanket' ,value :  'Blanket' },
{name :  'Shoes' ,value :  'Shoes' },
{name :  'Fragrance' ,value :  'Fragrance' },
{name :  'Makeup Powder' ,value :  'Makeup Powder' },
{name :  'Skincare,Toner' ,value :  'Skincare,Toner' },
{name :  'Tools' ,value :  'Tools' },
{name :  'Pouch Set' ,value :  'Pouch Set' },
{name :  'Keyboard Bags' ,value :  'Keyboard Bags' },
{name :  'Candle' ,value :  'Candle' },
{name :  'Knife Block' ,value :  'Knife Block' },
{name :  'Candles' ,value :  'Candles' },
{name :  'Waffle Maker' ,value :  'Waffle Maker' },
{name :  'Jewelry' ,value :  'Jewelry' },
{name :  'T-shirts' ,value :  'T-shirts' },
{name :  'Ball' ,value :  'Ball' },
{name :  'Skincare, mask' ,value :  'Skincare, mask' },
{name :  'Tote Bags' ,value :  'Tote Bags' },
{name :  'Poster' ,value :  'Poster' },
{name :  'Outdoor Recreation' ,value :  'Outdoor Recreation' },
{name :  'Foam Cleanser' ,value :  'Foam Cleanser' },
{name :  'Snowball Maker' ,value :  'Snowball Maker' },
{name :  'Face Pads' ,value :  'Face Pads' },
{name :  'Jacket' ,value :  'Jacket' },
{name :  'Table Fan' ,value :  'Table Fan' },
{name :  'Handle Pot' ,value :  'Handle Pot' },
{name :  'Water Bottle' ,value :  'Water Bottle' },
{name :  'Desk Mat' ,value :  'Desk Mat' },
{name :  'Hobo Bag' ,value :  'Hobo Bag' },
]