import { Avatar, Button, Row, Col, List, Typography, message, Spin, Upload, Card, Space, Checkbox, Table, Select, DatePicker } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Path from "../../commons/breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import Box from "components/box/Box";
import { useTranslation } from "react-i18next";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import { Icon } from "@iconify/react";
import moment from "moment";
import ordersApi from "apis/ordersApi";
import axios from "axios";

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

const ShiprateCheck = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [filter, setFilter] = useState("norates");
    const [currPage, setCurrPage] = useState(0);
    const [data, setData] = useState([]);

    const changeFilter = (value) => {
        setFilter(value);
    };

    const fetchData = async () => {
        const r = await ordersApi.getShiprateCheck(filter, currPage);
        if (r.rows) {
            setData(r.rows);
        }
        console.log('R',r);
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            render: (item, record, index) => {
                return (<>{index+1}</>);
            }
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Order No',
            dataIndex: 'orderNo',
            key: 'orderNo',
            render: (item, record) => {
                return(<a href={`/orders/order-details/${record.orderId}`}>{item}</a>)
            }
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation'
        },
        /*
        {
            title: 'Ship Rate Ok?',
            dataIndex: 'shipRateOk',
            key: 'shipRateOk',
            render: (item) => {
                return(<p>{item? 'YES':'NO'}</p>)
            }            
        },
        */
        {
            title: 'Error List',
            dataIndex: 'shipRateResponse',
            key: 'shipRateResponse',
            render: (item) => {
                let list = [];
                item.rates.forEach(i => {
                    const errors = [];
                    console.log("ERROZ LIST", i.errorsList);
                    if (i.errorsList) {
                        if (Array.isArray(i.errorsList)) {
                            i.errorsList.forEach(i => errors.push(i));
                        } else {
                            errors.push(i.errorsList);
                        }
                       
                        list.push({
                            courier: i.courier,
                            errors: errors
                        });
                    }
                });

                const jsxObj =
                    <ul>
                    {list.map(l =>                        
                        <li>{l.courier} - {JSON.stringify(l.errors)}</li>)                      
                    }
                    </ul>
                return jsxObj;
            }
        },
        /*
        {
            title: 'Response JSON',
            dataIndex: 'shipRateResponse',
            key: 'shipRateResponse',
            render: (item) => {
                return(<p style={{fontSize:10}}>{JSON.stringify(item)}</p>)
            }
        },
        */
        {
            title: 'Created At (KST)',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item) => {
                return(<>{item? moment(item).utcOffset("+0900").format("YYYY-MM-DD HH:mm"): ''}</>)
            }
        },
    ];

    return(
        <>
            <TitleHeader
                listOfBreadcrumb={[
                    { name: "Home", path: "/" },
                    { name: Path.CUST_SERVICES.name, path: Path.CUST_SERVICES.pathname },
                    {
                        name: Path.ORDER_SHIP_RATE_CHECK.name,
                    },
                ]}
                title={Path.ORDER_SHIP_RATE_CHECK.name}
            />
            <Box>
                <Container>                      
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={24}>
                                <Title level={4}>Orders Shipment Rate Preliminary Check</Title>
                                <Paragraph>
                                    Orders will go through shipment rate tests during creations or updates.<br/> 
                                    The results can be viewed here.
                                </Paragraph>
                                <Space direction="horizontal">
                                    Test Filter
                                    <Select 
                                        style={{
                                            width: 250,
                                        }}
                                        onChange={changeFilter}
                                        defaultValue="norates"
                                        options={[
                                            {
                                                value: "all",
                                                label: "All"
                                            },
                                            /*
                                            {
                                                value: "failed",
                                                label: "Failed"
                                            },
                                            */
                                            {
                                                value: "norates",
                                                label: "No Rates"
                                            }
                                        ]}                          
                                    />  
                                    <Button type="primary" onClick={fetchData}>Get</Button>
                                </Space>
                            </Col>
                        </Row>
                        
                        <Row gutter={[16, 16]}>
                            &nbsp;
                        </Row>    
                        <Row gutter={[16, 16]}>                            
                            <Table dataSource={data} columns={columns}/>
                        </Row>
                </Container>
            </Box>
        </>
    );
}

export default ShiprateCheck;