import { Button, Card, Col, Row, Space, Table, Typography, Collapse, Spin, Modal, Divider, Image } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import ordersApi from "../../apis/ordersApi";
import Path from "../../commons/breadcrumbs";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Container from "../../components/container/Container";
import { OrderDetailPageStyled, TitleBox } from "./index.styles";
import queryString from "query-string";
import shipmentsApi from "apis/shipmentsApi";
import useModal from "../../hooks/useModal";
import moment from "moment";
import axios from "axios";

const { Title, Text } = Typography;

function ShipmentDetails(){
    const { id } = useParams();
    const [shipment, setShipment] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [invoice, setInvoice] = useState();
    const [showLabel, setShowLabel] = useState(false);
    const [showInvoice, setShowInvoice] = useState(false);

    //{ name: `${t("Order")} ${order?.orderNumber || ""}` }]
    const getShipment = async () => {
        setIsLoading(true);
        if(id){
            const res = await shipmentsApi.getShipmentById(id);
            console.log('SHIPMENT', res);
            
            const invoice64 = "data:application/pdf;base64," + res.invoiceBase64;
            console.log('IMG 64', invoice64);
                        
            if(res.labelZpl) {
                console.log('ZPL', res.labelZpl)
                getLabel(res.courier, res.labelZpl);
            }
            setInvoice(invoice64);
            setShipment(res);
            let params = {
                filter: [`id_eq=${res.shippingAccountId}`],
                fields: ['accountName','accountNumber']
            }
            const shippingAccount = await shipmentsApi.getListShippingAccounts({ params: params }) || null;
            res.payload = {accountName : shippingAccount.shipping_account[0].accountName,accountNumber : shippingAccount.shipping_account[0].accountNumber, ...res.payload} 
            setShipment(res)
            // console.log('SHIPMENT', shipment)
        }       
        setIsLoading(false);
    };      
    const getLabel = async(cour, zplStr) => {
        let width, height;
        const courier = cour;  

        if (courier === "dhl") {
            width = 4;
            height = 8;
        } else if (courier === "rincos") {            
            width = 6;
            height = 12;
        } else if (courier === "fedex") {          
            width = 4;
            height = 7;
        }

        const res = await axios({
            url: `https://api.labelary.com/v1/printers/8dpmm/labels/${width}x${height}/0/`,
            method: "post",
            headers: {
                // prettier-ignore
                //"Accept": "image/png",
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: JSON.stringify(zplStr),
            responseType: "blob",
        });

        const responseBlob = res.data; 
        const img = document.createElement('img');
        img.src = URL.createObjectURL(responseBlob);
        document.querySelector(`#imgContainer`).innerHTML = '';
        document.querySelector(`#imgContainer`).append(img);
    };

    const toggleLabel = () => {
        setShowLabel(prev => !prev);
    };

    const toggleInvoice = () => {
        setShowInvoice(prev => !prev);
    };

    const backToShipments = () => {
        history.push("/shipments");
    }
    //Initiator
    useEffect(() => {       
        getShipment();       
    },[]);
    return(
        <>
            <TitleBox className="title-page">
                <Title>
                    Shipment #{shipment?.airwayBillNumber}
                </Title>
                <Breadcrumb items={[{ name: "Home", path: "/" }, Path.SHIPMENTS, {name: shipment?.airwayBillNumber}]} />
            </TitleBox>
            
            <OrderDetailPageStyled title="SHIPMENTS">
                <Container>
                    <Spin spinning={isLoading}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={18}>
                                <Card className="order-info">
                                    <div className="order-details">
                                        <Row className="detail-row">
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Airway Bill Number</div>
                                                <div className="data bold">                                           
                                                    <Title level={4}>
                                                        #{shipment?.airwayBillNumber}
                                                    </Title>                                               
                                                </div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Source Order</div>
                                                <div className="data">{shipment?.sourceId? shipment.sourceId : "N/A"}</div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Courier</div>
                                                <div className="data">{shipment?.courier.toUpperCase()}</div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Status</div>
                                                <div className="data">{shipment?.status.toUpperCase()}</div>
                                            </Col>
                                        </Row>
                                        <Row className="detail-row">
                                            <Col xs={24} md={12} className="box-data">
                                                <div className="label">Account Name</div>
                                                <div className="data bold">                                           
                                                    <Title level={4}>
                                                        #{shipment?.payload?.accountName}
                                                    </Title>                                               
                                                </div>
                                            </Col>
                                            <Col xs={24} md={12} className="box-data">
                                                <div className="label">Account Number</div>
                                                <div className="data">{shipment?.payload?.accountNumber}</div>
                                            </Col>
                                          
                                        </Row>
                                        <Row className="detail-row">
                                            <Col xs={24} md={3} className="box-data">
                                                <div className="label">Currency</div>
                                                <div className="data">{shipment?.feeCurrency? shipment.feeCurrency.toUpperCase() : "N/A"}</div>
                                            </Col>
                                            <Col xs={24} md={3} className="box-data">
                                                <div className="label">Shipping Fee</div>
                                                <div className="data">{shipment?.shippingFee? shipment?.shippingFee : "N/A"}</div>
                                            </Col>
                                            <Col xs={24} md={3} className="box-data">
                                                <div className="label">PLT Status</div>
                                                <div className="data">{shipment?.pltStatus ? "Yes" : "No"}</div>
                                            </Col>
                                            <Col xs={24} md={3} className="box-data">
                                                <div className="label">Local Waybill No.</div>
                                                <div className="data">{shipment?.localWaybillNumber.toUpperCase()}</div>
                                            </Col>
                                            <Col xs={24} md={3} className="box-data">
                                                <div className="label">Exp. Downloaded?</div>
                                                <div className="data">{shipment?.exportDeclarationDownloaded? "Yes" : "No"}</div>
                                            </Col>
                                            <Col xs={24} md={3} className="box-data">
                                                <div className="label">Exp. No</div>
                                                <div className="data">{shipment?.expDclrNo? shipment.expDclrNo : "N/A"}</div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Created At</div>
                                                <div className="data">{moment(shipment?.createdAt).format("DD MMM YYYY, HH:mm")}</div>
                                            </Col>
                                            
                                        </Row>
                                        <Row className="detail-row">
                                            <Col xs={24} md={12} className="box-data">
                                                <div className="label">Consignee</div>
                                                <div>
                                                    <span className="data">
                                                        {shipment?.payload?.consignee?.companyName? 
                                                            shipment?.payload?.consignee?.companyName : (shipment?.payload?.consignee?.personName? 
                                                                                                            shipment?.payload?.consignee?.personName : '')}<br/>
                                                    </span>
                                                    <br />
                                                        Phone:  {shipment?.payload?.consignee?.personPhone? shipment.payload.consignee.personPhone : ''}
                                                    <br />
                                                        Postal:  {shipment?.payload?.consignee?.postal? shipment.payload.consignee.postal : ''}
                                                    <br />
                                                    {[                                                        
                                                        shipment?.payload?.consignee?.address1? shipment.payload.consignee.address1 : '',
                                                        shipment?.payload?.consignee?.address2? shipment.payload.consignee.address2 : '',
                                                        shipment?.payload?.consignee?.city? shipment.payload.consignee.city : '',
                                                        shipment?.payload?.consignee?.division? shipment.payload.consignee.division : '',
                                                        shipment?.payload?.consignee?.provinceCode? shipment.payload.consignee.provinceCode : '',
                                                        shipment?.payload?.consignee?.countryCode? shipment.payload.consignee.countryCode : '',
                                                    ]
                                                        .filter((item) => item)
                                                        .join(", ")}
                                               
                                                </div>
                                            </Col>
                                            <Col xs={24} md={12} className="box-data">
                                                <div className="label">Email</div>
                                                <div className="data">{shipment?.email}</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                                <br/>
                                <Card className="order-info">
                                    <div className="order-details">
                                        <Row className="detail-row">
                                            <Col xs={24} md={24} className="box-data">
                                                <div className="label">Payload Json</div>
                                                <div style={{
                                                        overflow:"scroll", 
                                                        minHeight:"200px",
                                                        maxHeight:"400px", 
                                                        background:"#ebebeb", 
                                                        fontFamily: "Courier New", 
                                                        fontSize: "85%"                                                    
                                                        }}>
                                                    <pre>
                                                        {JSON.stringify(shipment?.payload, null, 2)}                                                        
                                                    </pre>
                                                </div>                                               
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                                <br/>
                                <Card className="order-info">
                                    <div className="order-details">
                                        <Row className="detail-row">
                                            <Col xs={24} md={24} className="box-data">
                                                <div className="label">Invoice Type</div>
                                                <div className="data">{shipment?.invoiceType}</div>
                                            </Col>                                            
                                        </Row>
                                        <Row className="detail-row">
                                            <Col xs={24} md={24} className="box-data">
                                                <div className="label">Label</div>
                                                <div className="data">
                                                    <Button type="primary" onClick={toggleLabel}>{showLabel? "Hide Label" : "Show Label"}</Button>
                                                    <div id="imgContainer" style={{display: showLabel? "block":"none"}}></div>                                                                                                   
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="detail-row">
                                            <Col xs={24} md={24} className="box-data">
                                                <div className="label">Invoice</div>
                                                <div className="data">
                                                    <Button type="primary" onClick={toggleInvoice}>{showInvoice? "Hide Invoice" : "Show Invoice"}</Button>
                                                    <div style={{display: showInvoice? "block":"none"}}>
                                                        <iframe width="100%" height={800} frameBorder="0" src={invoice} title="invoice"></iframe> 
                                                    </div>                                                   
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            <Col xs={24} md={6}>
                                <Space direction="vertical" className="order-actions">
                                    <Button type="primary" size="large" 
                                        style={{ marginTop: "15px" }}
                                        onClick={backToShipments}      
                                        >
                                        Back to Shipments
                                    </Button>
                                </Space>
                            </Col>
                        </Row>
                    </Spin>
                </Container>
            </OrderDetailPageStyled>
        </>
    );
}

export default ShipmentDetails;