import { Card, Col, Statistic } from "antd";
import React from "react";
import { HeaderWrapper, InnerHeader } from "./index.style";

const LiveFeedsData = () => {
  return (
    <div></div>
    //TEMPORARILY REMOVED
    // <HeaderWrapper className="statistic">
    //   <InnerHeader>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="New Orders" value={91} />
    //       </Card>
    //     </Col>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="Shipments Packed" value={24} />
    //       </Card>
    //     </Col>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="Shopify Orders" value={15} />
    //       </Card>
    //     </Col>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="WhatsApp Orders" value={24} />
    //       </Card>
    //     </Col>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="Customs Orders" value={24} />
    //       </Card>
    //     </Col>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="Orders Cancelled" value={2} />
    //       </Card>
    //     </Col>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="Orders Refunded" value={7} />
    //       </Card>
    //     </Col>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="Packers Online" value={5} />
    //       </Card>
    //     </Col>
    //     <Col md={6} lg={4}>
    //       <Card bordered={false}>
    //         <Statistic title="Orders Purchased" value={35} />
    //       </Card>
    //     </Col>
    //   </InnerHeader>
    // </HeaderWrapper>
  );
};

export default LiveFeedsData;
