import axiosClient from "./axiosClient";

const shipmentsApi = {
    getExportList: (payload) => axiosClient.get("/api/shipment/get-export-list", { params: payload, responseType: "blob" }),
    addShipperDetails: (payload) => axiosClient.post("/api/shipment/add-shipper-details", payload),
    updateShipperDetails: (payload) => axiosClient.put("/api/shipment/update-shipper-details", payload),
    removeShipperDetails: (payload) => axiosClient.post("/api/shipment/remove-shipper-details", payload),
    printShipmentDocuments: (payload) => axiosClient.post("/api/shipment/print-shipment-documents", payload),
    getListShipper: (payload) => axiosClient.get("/api/shipment/list-shipper-details", { params: payload }),
    getShipments: (payload) => axiosClient.get("/api/shipment/list-shipments", { params: payload }),
    getListShippingAcc: (payload) =>
        axiosClient.get("/api/shipment/list-shipping-accounts", {
            params: payload,
        }),
    rateShipmentFedex: (payload) => axiosClient.post("/api/shipment/rate-shipment-rest-fedex", payload),
    rateShipmentDHL: (payload) => axiosClient.post("/api/shipment/rate-shipment-dhl", payload),
    createShipmentFedex: (payload) => axiosClient.post("/api/shipment/create-shipment-rest-fedex", payload),
    createShipmentDhl: (payload) => axiosClient.post("/api/shipment/create-shipment-rest-dhl", payload),
    createShipmentRincos: (payload) => axiosClient.post("/api/shipment/create-shipment-rest-rincos", payload),
    createShipmentUps: (payload) => axiosClient.post("/api/shipment/create-shipment-ups", payload),
    getRateList: (payload) => axiosClient.post("/api/shipment/get-rate-list", payload),
    getListShippingAccounts: (payload) => axiosClient.get("/api/shipment/list-shipping-accounts", payload),
    getPrinters: (payload) => axiosClient.get("/api/shipment/get-db-printers", payload),
    addShippingAccount: (payload) => axiosClient.post("/api/shipment/add-shipping-account", payload),
    updateShippingAccount: (payload) => axiosClient.put("/api/shipment/update-shipping-account", payload),
    removeShippingAccount: (payload) => axiosClient.post("/api/shipment/remove-shipping-account", payload),
    getOrderShipmentInfo: (trackId) => axiosClient.get("/api/shipment/get-order-shipment/" + trackId),
    getShipmentById: (id) => axiosClient.get("api/shipment/get-shipment-by-id/" + id),
    createShipmentOperation: (payload) => axiosClient.post("api/shipment/create-shipment-operation", payload),
    updateFromXls: (payload) => axiosClient.post("/api/shipment/update-from-xls", payload),
    notifyShipment: (payload) => axiosClient.post("/api/shipment/notify-shipment", payload),
    notifyPoBox: (payload) => axiosClient.post("/api/shipment/notify-po-box", payload),
    notifyNonLatinName: (payload) => axiosClient.post("/api/shipment/notify-non-latin-name", payload),
    getLastExportFile: () => axiosClient.get("/api/shipment/get-last-export-file"),
    emailLastExportFile: (payload) => axiosClient.post("/api/shipment/send-last-export-file", payload),
    getShipFeesTable: (payload) => axiosClient.post("/api/shipment/get-countries-zones-comparison", payload)
};

export default shipmentsApi;
