import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
} from 'antd'
import manageUsersApi from 'apis/manageUsersApi';
import accountApi from 'apis/accountApi';
import Box from 'components/box/Box'
import Container from 'components/container/Container'
import TitleHeader from 'components/title-header'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Path from '../../../commons/breadcrumbs'
import LocalStorage, { SessionStorage} from "utils/storage";

const generalOptions = [
  'orders',
  'orderDetails',
  'shipments',
  'readyToShip',
  'createShipment',
  'todayShipment',
  'allShipments',
  'courierList',
  'products',
  'productDetail',
  'settings',
  'storeDetails',
  'usersAndPermissions',
  'salesAndChannels',
  'languages',
  'policies',
  'addStaff',
  'editStaff',
]

// later change the defaultCheckedList with
// with data fetch from database
const defaultCheckedList = [
  'orders',
  'orderDetails',
  'shipments',
  'readyToShip',
  'createShipment',
  'todayShipment',
  'allShipments',
  'courierList',
  'products',
  'productDetail',
  'settings',
  'storeDetails',
  'usersAndPermissions',
  'salesAndChannels',
  'languages',
  'policies',
  'addStaff',
  'editStaff',
]

const AddStaff = ({ history, location }) => {
  const [isLoading, setIsLoading] = useState(false)
  /*
  const [addStaff, setAddStaff] = useState({
    firstName: '',
    lastName: '',
    avatar: '',
    email: '',
    bio: '',
    website: '',
    permissionKeys: defaultCheckedList,
  })
  */

  const [addStaff, setAddStaff] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword:'',
  });

  const firstName = 'FirstName' // temporary data
  const [checkAll, setCheckAll] = useState(false)
  const [indeterminate, setIndeterminate] = useState(true)
  const [isModal, setIsModal] = useState(false)

  const { t } = useTranslation()

  // onChange for Checkbox.Group produce checkedValue
  const onChangeHandler = (checkedValue) => {
    setAddStaff((prev) => {
      return { ...prev, permissionKeys: checkedValue }
    })
    setIndeterminate(
      !!checkedValue.length && checkedValue.length < generalOptions
    )
    setCheckAll(checkedValue.length === generalOptions.length)
  }

  const onChangeSelectAllHandler = (event) => {
    setAddStaff((prev) => {
      return {
        ...prev,
        permissionKeys: event.target.checked ? generalOptions : [],
      }
    })
    setIndeterminate(false)
    setCheckAll(event.target.checked)
  }

  const redirect = () => {
    const path = '/settings/users-and-permissions'
    history.push(path)
  }

  const handleOk = () => {
    // handleOk modal here
    setIsModal(false)
    message.success(`Succesfully deleted ${firstName}`, 2)
    redirect()
  }

  const handleCancel = () => {
    // handleOk modal here
    setIsModal(false)
  }

  const addStaffHandler = async () => {
    setIsLoading(true)
    try {
      const { res } = await manageUsersApi.addUser(addStaff)
      message.success(res.message, 3)
      setIsLoading(false)
      redirect()
    } catch (error) {
      message.error(error.message, 3)
      setIsLoading(false)
    }
  }

  const addNewStaff = async () => {
    setIsLoading(true);

    const access_token = LocalStorage.get("access_token");  
    
    if(addStaff.password.trim() !== addStaff.confirmPassword.trim()){
      message.error('Password and confirmation must be the same');
      setIsLoading(false);
      return;
    }
    const header = {headers: {'Authorization':  `Bearer ${access_token}`}};
    const payload = {
        firstName: addStaff.firstName.trim(),
        lastName: addStaff.lastName.trim(),
        email: addStaff.email.trim(),
        login: addStaff.email.trim(),
        password: addStaff.password.trim(),
    };
    
    accountApi.addNewStaff(payload,{
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    })
    .then((r) => {
      console.log('Response',r);
      setIsLoading(false)
      message.success('User created successfully');
      history.push('/settings/users-and-permissions');
    })
    .catch((e) => {
      console.log('Error', e);
      setIsLoading(false)
      message.error('Failed to create user. ' + e.toString());
    });
  }

  const discardHandler = () => {
    // when people click save handler
    // PUT all bio, personal website and checked permission to user-endpoint
    message.warning('Discarded', 2)
    redirect()
  }

  const inputHandler = (event) => {
    event.preventDefault()
    return setAddStaff((prev) => {
      return {
        ...prev,
        [event.target.name]: event.target.value,
      }
    })
  }

  return (
    <>
      <Modal
        title={`Remove ${firstName}`}
        visible={isModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Yes, I'm Sure"}
        okType='danger'
      >
        <p
          style={{ marginTop: '25px' }}
        >{`Are you sure want to remove ${firstName} ?`}</p>
      </Modal>
      <TitleHeader
        currentHistory={history}
        listOfBreadcrumb={[
          { name: 'Home', path: '/' },
          { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
          {
            name: Path.ADD_STAFF.name,
            path: Path.ADD_STAFF.pathname,
          },
          { name: Path.ADD_STAFF.name },
        ]}
        title={t('Add Staff')}
      />
      <Box>
        <Container>
          <Card style={{ marginBottom: '24px' }} title='Details'>
            <Form layout='vertical'>            
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item label='First Name' required>
                    <Input
                      name='firstName'
                      type='text'
                      placeholder='First Name'
                      value={addStaff.firstName}
                      onChange={inputHandler}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label='Last Name' required>
                    <Input
                      name='lastName'
                      type='text'
                      placeholder='Last Name'
                      value={addStaff.lastName}
                      onChange={inputHandler}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={24}>
                  <Form.Item label='Email' required>
                    <Input
                      name='email'
                      type='email'
                      placeholder='email'
                      value={addStaff.email}
                      onChange={inputHandler}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col md={12}>
                  <Form.Item label='Password' required>
                    <Input
                      name='password'
                      type='password'
                      placeholder='Password'
                      value={addStaff.password}
                      onChange={inputHandler}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item label='Confirm Password' required>
                    <Input
                      name='confirmPassword'
                      type='password'
                      placeholder='Last Name'
                      value={addStaff.confirmPassword}
                      onChange={inputHandler}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
          {/** 
          <Card style={{ marginBottom: '24px' }} title='Permissions'>
            <Form layout='vertical'>
              <GridCheckboxSpace>
                <Checkbox
                  checked={checkAll}
                  onChange={onChangeSelectAllHandler}
                  indeterminate={indeterminate}
                >
                  Select All
                </Checkbox>
              </GridCheckboxSpace>
              <GridCheckboxSpace>
                <Form label='General'>
                  <Checkbox.Group
                    options={generalOptions}
                    value={addStaff.permissionKeys}
                    onChange={onChangeHandler}
                  />
                </Form>
              </GridCheckboxSpace>
            </Form>
          </Card>
          */}
          <Divider />
          <Space align='end'>
            <Button
              type='primary'
              onClick={addNewStaff}
              disabled={isLoading}
            >
              Add Staff
            </Button>
            <Button type='danger' onClick={discardHandler} disabled={isLoading}>
              Discard
            </Button>
          </Space>
        </Container>
      </Box>
    </>
  )
}

export default AddStaff

const GridCheckboxSpace = styled(Row)`
  .ant-checkbox-wrapper,
  .ant-checkbox-group-item {
    /* border: 1px solid black; */
    margin-bottom: 10px;
  }
`
