import axios from "axios";
import axiosClient from "./axiosClient";

const accountingApi = {
    getBankAccounts: () => axiosClient.get("/api/accounting/get-bank-accounts"),
    getCreditCards: () => axiosClient.get("/api/accounting/get-credit-cards"),
    //getAccountLog: (accNum, baseMonth) => axiosClient.get(`/api/accounting/get-account-log/${accNum}/${baseMonth}`),
    getAccountLogEx: (accNum, bankName, baseMonth) => axiosClient.get(`/api/accounting/get-account-log-ex/${accNum}/${bankName}/${baseMonth}`),
    fetchAccountLogEx: (accNum, bankName, baseMonth) => axiosClient.get(`/api/accounting/get-account-log-ex/${accNum}/${bankName}/${baseMonth}/true`),
    //getCardLog: (cardNum, baseMonth) => axiosClient.get(`/api/accounting/get-card-log/${cardNum}/${baseMonth}`),
    getCardLogEx: (cardNum, cardName, baseMonth) => axiosClient.get(`/api/accounting/get-card-log-ex/${cardNum}/${cardName}/${baseMonth}`),
    fetchCardLogEx: (cardNum, cardName, baseMonth) => axiosClient.get(`/api/accounting/get-card-log-ex/${cardNum}/${cardName}/${baseMonth}/true`),
    updateAccountTxn: (payload) => axiosClient.put('/api/accounting/update-account-txn', payload),
    updateCardTxn: (payload) => axiosClient.put('/api/accounting/update-card-txn', payload),
    getDbBackup: () => axiosClient.get('/api/accounting/db-backup'),
    runBackup: () => axiosClient.get('/api/accounting/start-db-backup'),
    getBookExport: (payload) => axiosClient.post('/api/accounting/book-export', payload),
};

export default accountingApi