const Breadcrumbs = {
    HOME: "/",
    LOGIN: "/login",
    ORDERS: {
        pathname: "/orders",
        name: "Orders",
    },
    CREATE_ORDER: {
        pathname: "/orders/create-order",
        name: "Create Order",
    },
    ORDER_DETAIL: {
        pathname: "/orders/order-details/:id",
        pathParam: (id) => `/orders/order-details/${id}`,
        name: "Orders Detail",
    },
    SHIPMENTS: {
        pathname: "/shipments",
        name: "Shipments",
    },
    SHIPPER_DETAILS: {
        pathname: "/shipper-details",
        name: "Shipper Details",
    },
    READY_TO_SHIP: {
        pathname: "/ready-to-ship",
        name: "Ready To Ship",
    },
    CREATE_SHIPMENT: {
        pathname: "/create-shipment",
        name: "Create Shipment",
    },
    TODAYS_SHIPMENT: {
        pathname: "/todays-shipment",
        name: "Today's Shipment",
    },
    COURIER_LIST: {
        pathname: "/courier-list",
        name: "Courier List",
    },
    PRODUCTS: {
        pathname: "/products",
        name: "Products",
    },
    CREATE_PRODUCT: {
        pathname: "/products/create-product",
        name: "Create Product",
    },
    BEENOS_EXPORT: {
        pathname: "/products/export/beenos",
        name: "Buyee Export",
    },
    SNAPPY_EXPORT: {
        pathname: "/products/export/snappy",
        name: "Snappy Export",
    },
    MERCARI_EXPORT: {
        pathname: "/products/export/mercari",
        name: "Mercari Export",
    },
    PRODUCT_DETAIL: {
        pathname: "/products/product-detail/",
        pathParam: (id) => `/products/product-detail/?id=${id}`,
        name: "Product Detail",
    },
    SETTINGS: {
        pathname: "/settings/",
        name: "Settings",
    },
    COURIERS: {
        pathname: "/settings/couriers",
        name: "Courier Accounts",
    },
    COURIER_ADD: {
        pathname: "/settings/couriers/add",
        name: "Add Courier Accounts",
    },
    COURIER_UPDATE: {
        pathname: "/settings/couriers/update",
        name: "Update Courier Accounts",
    },
    STORE_DETAILS: {
        pathname: "/settings/store-details",
        name: "Store Details",
    },
    USERS_AND_PERMISIONS: {
        pathname: "/settings/users-and-permissions",
        name: "Users And Permissions",
    },
    SALES_AND_CHANNELS: {
        pathname: "/settings/sales-and-channels",
        name: "Sales And Channel",
    },
    LANGUAGES: {
        pathname: "/settings/languages",
        name: "Languages",
    },
    POLICIES: {
        pathname: "/settings/policies",
        name: "Policies",
    },
    ADD_STAFF: {
        pathname: "/settings/users-and-permissions/add-staff",
        name: "Add Staff",
    },
    EDIT_STAFF_WITH_ID: {
        pathParam: (id) => `/settings/users-and-permissions/edit-staff/${id}`,
        pathname: `/settings/users-and-permissions/edit-staff/:id`,
        name: "Edit Staff",
    },
    INVENTORY: {
        pathname: "/inventory",
        name: "Inventory",
    },
    PURCHASES: {
        pathname: "/purchases",
        name: "Purchases",
    },
    PENDING_PURCHASE: {
        pathname: "/purchases/pending-purchases",
        name: "Pending Purchases",
    },
    PURCHASE_ORDERS: {
        pathname: "/purchases/purchase-orders",
        name: "Purchase Orders",
    },
    PURCHASE_ORDER_DETAILS: {
        pathname: "/purchases/purchase-order-details/:id",
        pathParam: (id) => `/purchases/purchase-order-details/${id}`,
        name: "Purchase Order Details",
    },
    SUPPLIER_LIST: {
        pathname: "/purchases/supplier-list",
        name: "Supplier List",
    },
    PROOF_OF_PURCHASE: {
        pathname: "/purchases/proof-of-purchase",
        name: "Proof Of Purchase",
    },
    MAKE_PURCHASE: {
        pathname: "/purchases/pending-purchases/make-purchase",
        name: "Make Purchase",
    },
    PURCHASING_PARTIAL_CHECK_IN: {
        pathname: "/purchases/pending-purchases/partial-check-in",
        name: "Partial Check-In"
    },
    ORDER_PURCHASE: {
        pathname: "/purchases/order-purchase/:id",
        pathParam: (id) => `/purchases/order-purchase/${id}`,
        name: "Order Purchase",
    },
    CREATED_SHIPMENT: {
        pathname: "/created-shipment",
        name: "Created Shipment",
    },
    RATE_SHIPMENT: {
        pathname: "/rate-shipment",
        name: "Rate Shipment",
    },
    BULK_FETCH: {
        pathname: "/settings/bulk-fetch",
        name: "Bulk Fetch",
    },
    BULK_EXPORT: {
        pathname: "/settings/bulk-export",
        name: "Bulk Export",
    },
    SHIPMENT_DETAILS: {
        pathname: "/shipments/details/:id",
        pathParam: (id) => `/shipments/details/${id}`,
        name: "Shipment Details",
    },
    RESET_PASSWORD:{
        pathParam: (id) => `/settings/users-and-permissions/reset-password/${id}`,
        pathname: `/settings/users-and-permissions/reset-password/:id`,
        name: "Reset Password",
    },
    CHANGE_PERMISSIONS:{
        pathParam: (id) => `/settings/users-and-permissions/change-permissions/${id}`,
        pathname: `/settings/users-and-permissions/change-permissions/:id`,
        name: "Change Permissions"
    },
    ACCOUNTING: {
        pathname: "/accounting/",
        name: "Accounting",
    },
    TRANSACTIONS:{
        pathname: "/accounting/transactions",
        name: "Transactions",
    },
    ORDERS_TXNS: {
        pathname: "/accounting/orders-txns",
        name: "Accounting Log"
    },
    UPLOAD_SHIPMENTS: {
        pathname: "/accounting/upload-shipments",
        name: "Upload Shipments"
    },
    UPLOAD_DOMESTIC_FEES: {
        pathname: "/accounting/upload-domestic-fees",
        name: "Upload Domestic Fees"
    },
    UPLOAD_SUPPLIER_PRICES: {
        pathname: "/accounting/upload-supplier-prices",
        name: "Upload Supplier Prices"
    },
    DB_BACKUP: {
        pathname: "/database/backup",
        name: "Database Backups"
    },
    UPLOAD_EDN: {
        pathname: "/accounting/upload-edn",
        name: "Upload EDN"
    },
    BOOK_EXPORT: {
        pathname: "/accounting/book-export",
        name: "Book Export"
    },
    CUST_SERVICES: {
        pathname: "/customer-services",
        name: "Customer Services"
    },
    ORDER_SHIP_RATE_CHECK: {
        pathname: "/customer-services/shiprate-check",
        name: "Shipment Rate Check"
    },
    BULK_LABEL_PRINT: {
        pathname: "/bulk-label-print",
        name: "Bulk Label Print"  
    },
    SHIP_FEES_TABLE: {
        pathname: "/ship-fees-table",
        name: "Shipment Fees Table"
    },
    MANUAL_ORDERS_UPLOAD: {
        pathname: "/manual-orders-upload",
        name: "Manual Orders Upload"
    },
    CREATE_PURCH_ORDER: {
        pathname: "/purchases/create",
        name: "Create Purchase"
    },
    STOCK_ADJUSTMENT: {
        pathParam: (id) => `/products/adjustment/${id}`,
        pathname: "/products/adjustment/:id",
        name: "Stock Adjustment"
    },
    NOT_ALLOWED:{
        pathname: "/not-allowed",
        name: "Not Allowed",
    },
    NOT_FOUND: {
        pathname: "/not-found",
        name: "Not Found"
    },

    //DEVELOPMENT TEST
    DEV: {
        pathname: "/development",
        name: "Development"
    },
    DL_PICS_TEST: {
        pathname: "/development/download-pics",
        name: "Download Pics Test"
    }

};

export default Breadcrumbs;
