import { Input, Table, Typography, Image, Avatar, Spin } from "antd";
import LinkButton from "components/linkButton/LinkButton";
import produce from "immer";
import { debounce } from "lodash";
import React from "react";
import { moment } from "utils/funcs";
import Path from "../../../commons/breadcrumbs";
import { TableContainer } from "./index.style";
import { Link, useParams, useHistory } from "react-router-dom";

const { Column } = Table;
const { Text } = Typography;

const AllTable = ({
  params,
  setParams,
  setSelectedRows,
  dataList,
  setDataList,
  gettingListPending,
  isLoading,
  loadingVariantImage,
}) => {
  const qtyPurchaseHandler = (event, index) => {
    const newData = produce(dataList, (draft) => {
      draft[index].quantity = Number(event.target.value);
    });
    return setDataList(newData);
  };

  const pageSizeOnChange = debounce((page, pageSize) => {
    const newParam = produce(params, (draft) => {
      draft.page = page;
      draft.pageSize = pageSize;
    });
    setParams(newParam);
    gettingListPending(newParam);
  }, 500);

  return (
    <>
      <TableContainer
        dataSource={dataList}
        rowSelection={{
          onChange: (selectedRowKeys) => {
            setSelectedRows(selectedRowKeys);
          },
        }}
        loading={isLoading}
        pagination={{
          pageSizeOptions: ["10", "20", "30"],
          showSizeChanger: true,
          defaultPageSize: 10,
          onChange: pageSizeOnChange,
          current: params.page,
          pageSize: params.pageSize,
          total: params.totalPage,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
        }}
      >
        <Column
          title="Product"
          key="productTitle"
          className="product-title-cell"
          render={(record) => (
            // <Text style={{ wordBreak: 'break-word', wordWrap: 'break-word' }}>
            //   {`${record.productTitle || ''} - ${record.variantTitle || ''}`}
            // </Text>
            <Link to={`/products/product-detail/?id=${record.productId}`}>{`${record.productTitle || ''}${record.variantTitle ? ' - ' + record.variantTitle  : ''}`}</Link>
          )
        }
        />
        <Column
          title="Image"
          dataIndex="variantImage"
          key="variantImage"
          render={(value) => {
            if (loadingVariantImage) {
              return <Spin />;
            }
            if (!value || value == null) {
              return (
                <Avatar
                  size={82}
                  shape="square"
                  src={"/no-image.png"}
                />
              );
            }
            return (
              <Image
                width={82}
                src={value}
              />
            );
          }}
        />
        <Column
          title="Orders"
          dataIndex="orders"
          key="orders"
          width={200}
          render={(value) => {
            return (
              <div>
                {value.map((ord, index) => (
                  <LinkButton
                    key={index}
                    size="small"
                    style={{ margin: "3px" }}
                    type="primary"
                    to={Path.ORDER_DETAIL.pathParam(ord.orderId)}
                  >
                    {ord.orderNumber}
                  </LinkButton>
                ))}
              </div>
            );
          }}
        />
        <Column
          title="First Order"
          dataIndex="firstOrderDate"
          key="firstOrderDate"
          render={(value) => moment(value).format("MM/DD/YYYY")}
        />
        <Column
          title="Last Order"
          dataIndex="lastOrderDate"
          key="lastOrderDate"
          render={(value) => moment(value).format("MM/DD/YYYY")}
        />
        <Column title="Vendor" dataIndex="supplier" key="supplier" />
        <Column title="Pending" dataIndex="pending" key="pending" />
        {/* <Column
          title="Quantity To Purchase"
          dataIndex="quantity"
          key="quantity"
          render={(value, record, index) => (
            <Input
              value={record?.quantity}
              type="number"
              style={{ width: "100%" }}
              onChange={(event) => qtyPurchaseHandler(event, index)}
            />
          )}
        /> */}
      </TableContainer>
    </>
  );
};

export default AllTable;
