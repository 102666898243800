import React from 'react'

const NotAllowed = () => {
  return (
    <div>
      You are not allowed to access this page. Please communicate with your
      admin for more info.
    </div>
  )
}

export default NotAllowed
