import React from "react";
import { Provider } from "react-redux";
import store from "../store";
import ConfigRoutes from "./configs/RoutesComponent";
import Init from "./Init";

function MainApp() {
    return (
        <Provider store={store}>
            <Init>
                <ConfigRoutes />
            </Init>
        </Provider>
    );
}

export default MainApp;
