import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import produce from "immer";
import { debounce, some } from "lodash";
import { Fragment } from "react";
import { ButtonPlacement, FormBody, FormHeader, FormWrapper } from "./index.style";

const packagingTypeOptions = [
    "YOUR_PACKAGING",
    "FEDEX_ENVELOPE",
    "FEDEX_BOX",
    "FEDEX_SMALL_BOX",
    "FEDEX_MEDIUM_BOX",
    "FEDEX_LARGE_BOX",
    "FEDEX_EXTRA_LARGE_BOX",
    "FEDEX_10KG_BOX",
    "FEDEX_25KG_BOX",
    "FEDEX_PAK",
    "FEDEX_TUBE",
];

const PackagingInfo = ({ currency, fromOrder, form, recheckDataHandler }) => {
    const weightUnit = some(fromOrder) ? fromOrder?.lineItems[0]?.weightUnit : "kg";
    const curr = currency;

    const checkWeightZero = debounce((event) => {
        const arr = event.target.id.split("_");
        const index = Number(arr[1]);
        const value = event.target.value;
        const pieces = form.getFieldValue("pieces");
        if (Number(value) === 0 || value === undefined) {
            const result = produce(pieces, (draft) => {
                draft[index][arr[2]] = "0.5";
            });
            form.setFieldsValue({
                pieces: result,
            });
        }
        return recheckDataHandler();
    }, 3000);

    const clearInput = (field, fieldName) => {
        const pieces = form.getFieldValue("pieces");
        const newDraft = produce(pieces, (draft) => {
            draft[field.name][fieldName] = "";
        });
        return form.setFieldsValue({
            pieces: newDraft,
        });
    };

    return (
        <FormWrapper>
            <FormHeader className="greenBoldTitle">Packaging Informations</FormHeader>
            <FormBody>
                <Row gutter={16}>
                    <Col md={6} lg={6}>
                        <Form.Item
                            name="totalPackageWeight"
                            label="Total Weight"
                            extra="Total weight from all export items"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (!value || value < 0.001) {
                                            return Promise.reject(new Error("Cannot less than 0.001"));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input disabled={true} type="number" addonAfter={weightUnit.toUpperCase()} />
                        </Form.Item>
                    </Col>
                    <Col md={6} lg={6}>
                        <Form.Item name="totalPackageGrossWeight" label="Total Gross Weight" extra="Total gross weight from all export items">
                            <Input type="number" addonAfter={weightUnit.toUpperCase()} placeholder="Total Gross Weight" disabled />
                        </Form.Item>
                    </Col>
                    <Col md={6} lg={6}>
                        <Form.Item
                            name="totalPackageValue"
                            label="Total Declared Value"
                            extra="Total value from all export items"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (!value || value < 0.001) {
                                            return Promise.reject(new Error("Cannot less than 0.001"));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input addonBefore={curr} type="number" disabled={true} />
                        </Form.Item>
                    </Col>
                    <Col md={6} lg={6}>
                        <Form.Item name="totalPackagePiece" label="Total Pieces">
                            <Input addonAfter="PCS" type="number" disabled={true} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.List name="pieces">
                    {(fields, { add, remove }) => {
                        return (
                            <div>
                                <ButtonPlacement>
                                    <Button
                                        className="submit"
                                        onClick={() => {
                                            add();
                                            return recheckDataHandler();
                                        }}
                                    >
                                        Add Piece
                                    </Button>
                                </ButtonPlacement>
                                {fields.map((field) => {
                                    return (
                                        <Fragment key={field.name}>
                                            <Divider />
                                            <p className="greenBoldTitle">Pieces {field.name + 1}</p>
                                            <Row gutter={16}>
                                                <Col md={8}>
                                                    <Form.Item
                                                        name={[field.name, "weight"]}
                                                        label="Weight"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Shipper is required",
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    const weight = Number(getFieldValue("pieces")[field.name]["weight"]);
                                                                    if (!value || weight < 0) {
                                                                        return Promise.reject(new Error("Cannot less than 0"));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                        hasFeedback
                                                        extra="This is weight of single variant"
                                                        initialValue={0}
                                                    >
                                                        <Input
                                                            placeholder="Weight..."
                                                            addonBefore={weightUnit.toUpperCase()}
                                                            step={1}
                                                            onChange={checkWeightZero}
                                                            onClick={() => clearInput(field, "weight")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item
                                                        name={[field.name, "grossWeight"]}
                                                        label="Gross Weight"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Gross Weight is required",
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    const weight = Number(getFieldValue("pieces")[field.name]["weight"]);
                                                                    if (!value || weight < 0) {
                                                                        return Promise.reject(new Error("Cannot less than 0"));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                        initialValue={0}
                                                    >
                                                        <Input
                                                            placeholder="Gross Weight"
                                                            addonBefore={weightUnit.toUpperCase()}
                                                            onChange={checkWeightZero}
                                                            onClick={() => clearInput(field, "grossWeight")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={8}>
                                                    <Form.Item
                                                        name={[field.name, "value"]}
                                                        label="Declared Value"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Declared value is required",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                        initialValue={0}
                                                    >
                                                        <Input
                                                            type="number"
                                                            step={0.5}
                                                            placeholder="Declared Value..."
                                                            addonBefore={curr}
                                                            onChange={recheckDataHandler}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col md={6}>
                                                    <Form.Item
                                                        name={[field.name, "width"]}
                                                        label="Width"
                                                        initialValue="10"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Width are required",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input placeholder="Width..." type="number" step={0.5} addonBefore={"CM"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Item
                                                        name={[field.name, "height"]}
                                                        label="Height"
                                                        initialValue="10"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Height are required",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input placeholder="Height..." type="number" step={0.5} addonBefore={"CM"} />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Item
                                                        name={[field.name, "depth"]}
                                                        label="Depth"
                                                        initialValue="10"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Depth are required",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input placeholder="Depth..." type="number" step={0.5} addonBefore={"CM"} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={24}>
                                                    <Form.Item name={[field.name, "reference"]} label="Reference">
                                                        <Input placeholder="Reference..." />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <ButtonPlacement>
                                                {field.name !== 0 && (
                                                    <Button
                                                        className="reset"
                                                        onClick={() => {
                                                            remove(field.name);
                                                            return recheckDataHandler();
                                                        }}
                                                    >
                                                        Remove Item
                                                    </Button>
                                                )}
                                            </ButtonPlacement>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        );
                    }}
                </Form.List>
            </FormBody>
        </FormWrapper>
    );
};

export default PackagingInfo;
