
const shipmentCountryFilter = {
  //No International Priority  Australia, Japan, Hong Kong, Malaysia, Singapore, Thailand, Taiwan
  disallowFedexIntPrio: ['AU','JP','HK','MY','SG','TH','TW','VN','NZ'],
  disallowFedexConnectPlus: [],
  disallowDhl: [],
  disallowRinco: [],
}

export default shipmentCountryFilter;
