import React, { useState } from "react";
import AllTable from "./AllTable";
import Filter from "./Filter";

const CustomPendingPurchases = ({
  params,
  setParams,
  isLoadingOrders,
  setIsLoadingOrders,
  dataListOrders,
  setDataListOrders,
  gettingListPendingOrders,
  loadingVariantImage,
}) => {
  const [selectedRows, setSelectedRows] = useState([]);
  const [filter, setFilter] = useState({
    showAllPending: true,
    showPendingPurchasedForOrdersPlaces: true,
  });

  return (
    <>
      <Filter
        filter={filter}
        setFilter={setFilter}
        dataListOrders={dataListOrders}
        selectedRows={selectedRows}
        setIsLoadingOrders={setIsLoadingOrders}
        gettingListPendingOrders={gettingListPendingOrders}
        params={params}
        setParams={setParams}
      />
      <AllTable
        params={params}
        setParams={setParams}
        setSelectedRows={setSelectedRows}
        dataListOrders={dataListOrders}
        setDataListOrders={setDataListOrders}
        gettingListPendingOrders={gettingListPendingOrders}
        isLoadingOrders={isLoadingOrders}
        loadingVariantImage={loadingVariantImage}
      />
    </>
  );
};

export default CustomPendingPurchases;
