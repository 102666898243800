import CreatedShipment from "pages/created-shipment";
import Languages from "pages/languages";
import MakePurchase from "pages/make-purchase";
import PendingPurchases from "pages/pending-purchase";
import Policies from "pages/policies";
import RateShipment from "pages/rate-shipment";
import SalesAndChannels from "pages/sales-and-channels";
import StoreDetails from "pages/store-details";
import { Couriers, CourierDetail } from "pages/couriers";
import UsersAndPermissions from "pages/users-and-permissions";
import AddStaff from "pages/users-and-permissions/AddStaff";
import EditStaffWithId from "pages/users-and-permissions/EditStaffWithId";
import Shipments from "../pages/all-shipments";
import CreateOrder from "../pages/create-order";
import CreateProduct from "../pages/create-product";
import CreateShipment from "../pages/create-shipment";
import Login from "../pages/login/Login";
import OrderDetail from "../pages/order-detail";
import Orders from "../pages/orders";
import ProductDetail from "../pages/product-detail";
import Products from "../pages/products";
import PurchaseOrders from "../pages/purchase-order";
import Breadcrumbs from "./breadcrumbs";
import ShipperDetails from "pages/shipper-details";
import BulkFetch from "pages/data/bulk-fetch";
import BulkExport from "pages/bulk-export";
import ShipmentDetails from "../pages/shipment-details";
import ResetPassword from "../pages/reset-password";
import NotFound from "pages/not-found";
import NotAllowed from "pages/not-authorized";
import ChangePermissions from "../pages/users-and-permissions/change-permissions";
import AccountsTransactions from "pages/transactions";
import OrdersTransactions from "pages/orders-transactions";
import UploadShipmentXls from "pages/upload-shipment";
import UploadSupplierPricesXls from "pages/upload-supplier-prices";
import DbBackup from "pages/db-backup";
import Beenos from "../pages/export/beenos";
import Snappy from "pages/export/snappy";
import Mercari from "pages/export/mercari";
import UploadEdn from "pages/upload-edn";
import BookExport from "pages/book-export";
import ShiprateCheck from "pages/shiprate-check";
import OrderPurchase from "pages/order-purchase";
import UploadDomesticFees from "pages/upload-domestic-fees";
import PartialCheckIn from "pages/partial-check-in";
import BulkLabelPrinting from "pages/bulk-label-printing";
import ShipFeesTable from "pages/shipment-fees-table";
import ManualOrdersUpload from "pages/manual-orders-upload";
import PurchaseOrderDetail from "pages/purchase-order-detail";
import CreatePurchaseOrder from "pages/create-purchase-order";
import StockAdjustment from "pages/stock-adjustment";
//Dev pages
import DownloadPicsTest from "pages/dev-test/dl-pics-test";

const routes = [
    {
        exact: true,
        title: "Orders Detail",
        path: Breadcrumbs.ORDER_DETAIL.pathname,
        component: OrderDetail,
        permission: ["orders","all"],
        loginRequired: true,
    },
    {
        exact: true,
        title: "Buyee Export",
        path: Breadcrumbs.BEENOS_EXPORT.pathname,
        component: Beenos,
        permission: ["products","all"],
        loginRequired: true,
    },
    {
        exact: true,
        title: "Snappy Export",
        path: Breadcrumbs.SNAPPY_EXPORT.pathname,
        component: Snappy,
        permission: ["products","all"],
        loginRequired: true,
    },
    {
        exact: true,
        title: "Mercari Export",
        path: Breadcrumbs.MERCARI_EXPORT.pathname,
        component: Mercari,
        permission: ["products","all"],
        loginRequired: true,
    },
    {
        exact: true,
        title: "Create Order",
        path: Breadcrumbs.CREATE_ORDER.pathname,
        component: CreateOrder,
        permission: ["orders","all"],
        loginRequired: true,
    },
    {
        exact: true,
        title: "Orders Management",
        path: Breadcrumbs.ORDERS.pathname,
        component: Orders,
        permission: ["orders","all"],
        loginRequired: true,
    },
    {
        title: "Shipment Management",
        path: Breadcrumbs.SHIPMENTS.pathname,
        exact: true,
        component: Shipments,
        permission: ["shipment","all"],
        loginRequired: true,
    },
    {
        title: "Shipper Details",
        path: Breadcrumbs.SHIPPER_DETAILS.pathname,
        exact: true,
        component: ShipperDetails,
        permission: ["shipment","all"],
        loginRequired: true,
    },
    {
        title: "Create Shipment",
        path: Breadcrumbs.CREATE_SHIPMENT.pathname,
        exact: true,
        component: CreateShipment,
        permission: ["shipment","all"],
        loginRequired: true,
    },
    {
        exact: true,
        title: "Product Detail",
        path: Breadcrumbs.PRODUCT_DETAIL.pathname,
        component: ProductDetail,
        permission: ["products","all"],
    },
    {
        exact: true,
        title: "Create Product",
        path: Breadcrumbs.CREATE_PRODUCT.pathname,
        component: CreateProduct,
        permission: ["products","all"],
    },
    {
        title: "Products",
        path: Breadcrumbs.PRODUCTS.pathname,
        exact: true,
        component: Products,
        permission: ["products","all"],
        loginRequired: true,
    },
    {
        title: "Store Details",
        path: Breadcrumbs.STORE_DETAILS.pathname,
        exact: true,
        component: StoreDetails,
        //permission: ["storeDetails","all"],
        permission: ["shipment","all"],
        loginRequired: true,
    },
    {
        title: "Courier Accounts",
        path: Breadcrumbs.COURIERS.pathname,
        exact: true,
        component: Couriers,
        //permission: ["storeDetails","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Courier Accounts",
        path: Breadcrumbs.COURIER_ADD.pathname,
        exact: true,
        component: CourierDetail,
        //permission: ["storeDetails","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Courier Accounts",
        path: Breadcrumbs.COURIER_UPDATE.pathname,
        exact: true,
        component: CourierDetail,
        //permission: ["storeDetails","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Users And Permissions",
        path: Breadcrumbs.USERS_AND_PERMISIONS.pathname,
        exact: true,
        component: UsersAndPermissions,
        //permission: ["usersAndPermissions","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Sales And Channels",
        path: Breadcrumbs.SALES_AND_CHANNELS.pathname,
        exact: true,
        component: SalesAndChannels,
        //permission: ["salesAndChannels","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Languages",
        path: Breadcrumbs.LANGUAGES.pathname,
        exact: true,
        component: Languages,
        //permission: ["languages","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Policies",
        path: Breadcrumbs.POLICIES.pathname,
        exact: true,
        component: Policies,
        //permission: ["policies","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Add Staff",
        path: Breadcrumbs.ADD_STAFF.pathname,
        exact: true,
        component: AddStaff,
        //permission: ["addStaff","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Edit Staff",
        path: Breadcrumbs.EDIT_STAFF_WITH_ID.pathname,
        exact: true,
        component: EditStaffWithId,
        //permission: ["editStaff","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Reset Password",
        path: Breadcrumbs.RESET_PASSWORD.pathname,
        exact: true,
        component: ResetPassword,
        //permission: ["editStaff","all"],
        permission: ["basic","all"],
        loginRequired: true,
    },
    {
        title: "Change Permissions",
        path: Breadcrumbs.CHANGE_PERMISSIONS.pathname,
        exact: true,
        component: ChangePermissions,
        //permission: ["editStaff","all"],
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Pending Purchases",
        path: Breadcrumbs.PENDING_PURCHASE.pathname,
        exact: true,
        component: PendingPurchases,
        // permission: [],
        permission: ["all", "purchasing"],
        loginRequired: true,
    },
    {
        title: "Purchase Orders",
        path: Breadcrumbs.PURCHASE_ORDERS.pathname,
        exact: true,
        component: PurchaseOrders,
        // permission: [],
        permission: ["all", "purchasing"],
        loginRequired: true,
    },
    {
        title: "Purchase Order Details",
        path: Breadcrumbs.PURCHASE_ORDER_DETAILS.pathname,
        exact: true,
        component: PurchaseOrderDetail,
        // permission: [],
        permission: ["all", "purchasing"],
        loginRequired: true,
    },
    {
        title: "Make Purchase",
        path: Breadcrumbs.MAKE_PURCHASE.pathname,
        component: MakePurchase,
        permission: ["all", "purchasing"],
        loginRequired: true,
    },
    {
        title: "Created Shipment",
        path: Breadcrumbs.CREATED_SHIPMENT.pathname,
        component: CreatedShipment,
        permission: ["shipment","all"],
        loginRequired: true,
    },
    {
        title: "Rate Shipment",
        path: Breadcrumbs.RATE_SHIPMENT.pathname,
        component: RateShipment,
        permission: ["shipment","all"],
        loginRequired: true,
    },
    {
        title: "Not Found",
        path: Breadcrumbs.NOT_FOUND.pathname,
        component: NotFound,
        permission: [],
        loginRequired: false,
    },
    {
        title: "Bulk Fetch",
        path: Breadcrumbs.BULK_FETCH.pathname,
        component: BulkFetch,
        loginRequired: true,
        permission: ["all"],
    },
    {
        title: "Bulk Export",
        path: Breadcrumbs.BULK_EXPORT.pathname,
        component: BulkExport,
        loginRequired: true,
        permission: ["all"],
    },
    {
        title: "Transactions",
        path: Breadcrumbs.TRANSACTIONS.pathname,
        component:AccountsTransactions,
        permission: ["all","accounting"],
        loginRequired: true,
    },
    {
        title: "Orders Transactions",
        path: Breadcrumbs.ORDERS_TXNS.pathname,
        component: OrdersTransactions,
        permission: ["all","accounting"],
        loginRequired: true,
    },
    {
        title: "Shipment Details",
        path: Breadcrumbs.SHIPMENT_DETAILS.pathname,
        component: ShipmentDetails,
        permission: ["shipment","all"],
        loginRequired: true,
    },
    {
        title: "Upload Shipments",
        path: Breadcrumbs.UPLOAD_SHIPMENTS.pathname,
        component: UploadShipmentXls,
        permission: ["all","accounting"],
        loginRequired: true,
    },
    {
        title: "Upload Domestic Fees",
        path: Breadcrumbs.UPLOAD_DOMESTIC_FEES.pathname,
        component: UploadDomesticFees,
        permission: ["all","accounting"],
        loginRequired: true,
    },
    {
        title: "Upload Supplier Prices",
        path: Breadcrumbs.UPLOAD_SUPPLIER_PRICES.pathname,
        component: UploadSupplierPricesXls,
        permission: ["all","accounting"],
        loginRequired: true,
    },
    {
        title: "Upload EDN",
        path: Breadcrumbs.UPLOAD_EDN.pathname,
        component: UploadEdn,
        permission: ["all","accounting"],
        loginRequired: true,
    },
    {
        title: "Book Export",
        path: Breadcrumbs.BOOK_EXPORT.pathname,
        component: BookExport,
        permission: ["all","accounting"],
        loginRequired: true,
    },
    {
        title: "Shiprate Check",
        path: Breadcrumbs.ORDER_SHIP_RATE_CHECK.pathname,
        component: ShiprateCheck,
        permission: ["all","shipment"],
        loginRequired: true,
    },
    {
        title: "Database Backups",
        path: Breadcrumbs.DB_BACKUP.pathname,
        component: DbBackup,
        permission: ["all"],
        loginRequired: true,
    },
    {
        title: "Order Purchase",
        path: Breadcrumbs.ORDER_PURCHASE.pathname,
        component: OrderPurchase,
        permission: ["all","purchasing"],
        loginRequired: true,
    },
    {
        title: "Purchasing Partial Check-In",
        path: Breadcrumbs.PURCHASING_PARTIAL_CHECK_IN.pathname,
        component: PartialCheckIn,
        permission: ["all","purchasing"],
        loginRequired: true
    },
    {
        title: "Create Purchase Order",
        path: Breadcrumbs.CREATE_PURCH_ORDER.pathname,
        component: CreatePurchaseOrder,
        permission: ["all","purchasing"],
        loginRequired: true
    },
    {
        title: "Bulk Label Print",
        path: Breadcrumbs.BULK_LABEL_PRINT.pathname,
        component: BulkLabelPrinting,
        permission: ["all","shipment"],
        loginRequired: true
    },
    {
        title: "Shipment Fees Table",
        path: Breadcrumbs.SHIP_FEES_TABLE.pathname,
        component: ShipFeesTable,
        permission: ["all","shipment"],
        loginRequired: true
    },
    {
        title: "Manual Orders Upload",
        path: Breadcrumbs.MANUAL_ORDERS_UPLOAD.pathname,
        component: ManualOrdersUpload,
        permission: ["all","accounting"],
        loginRequired: true
    },
    {
        title: "Stock Adjustment",
        path: Breadcrumbs.STOCK_ADJUSTMENT.pathname,
        component: StockAdjustment,
        permission: ["all","products"],
        loginRequired: true
    },
    {
        title: "Not Allowed",
        path: Breadcrumbs.NOT_ALLOWED.pathname,
        component: NotAllowed,
        permission: [],
        loginRequired: false,
    },

    //DEVELOPMENT TEST PAGES
    {
        title: "Download Pics Test",
        path: Breadcrumbs.DL_PICS_TEST.pathname,
        component: DownloadPicsTest,
        permission: ["all"],
        loginRequired: true,
    },
];

// Routes will rejected when logged
const routesSpecialLogged = [
    {
        title: "Login",
        path: Breadcrumbs.LOGIN,
        exact: true,
        component: Login,
        loginRequired: false,
    },
];

export { routes, routesSpecialLogged };
