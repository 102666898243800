import { Button, Card, Col, Row, Space, Table, Typography, Collapse, Spin, Modal, Divider,message, Steps,List } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import qs from "query-string";
import { Link, useParams, useHistory } from "react-router-dom";
import ordersApi from "../../apis/ordersApi";
import purchaseApi from "apis/purchaseApi";
import Path from "../../commons/breadcrumbs";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Container from "../../components/container/Container";
import { moment } from "../../utils/funcs";
import { OrderDetailPageStyled, TitleBox } from "./index.styles";
import LinkButton from "components/linkButton/LinkButton";
import CheckInDetails from "./check-in-details";
import LocalStorage from "../../utils/storage";
import TextArea from "antd/lib/input/TextArea";
import commentApi from "apis/commentApi";
import DragDropFile from "components/drag-drop-image";
import styled from "styled-components";
import PopDetails from "./proof-of-purchase";

const { Title } = Typography;


function sleep(ms) { 
    return new Promise(resolve => setTimeout(resolve, ms)); 
}

function PurchaseOrderDetail() {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [order, setOrder] = useState();
    const { id } = useParams();
    const history = useHistory();
    const [lineItems, setLineItems] = useState([]);
    const [purchaseOrder, setPurchaseOrder] = useState({});
    const [checkInList, setCheckInList] = useState([]);
    const [isCheckInDetailsOpen, setIsCheckInDetailsOpen] = useState(false);
    const [selectedCheckIn, setSelectedCheckIn] = useState();
    const [purchaseItem, setPurchaseItem] = useState({
        itemPurchased: [],
        addItem: [],
      });
    const [purchaseItems, setPurchaseItems] = useState([]); // <-- array
    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState("");
    const [isCommentsLoading, setIsCommentsLoading] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]); // <-- array
    const [uploaded, setUploaded] = useState(false);
    const [gotProofOfPurchase, setGotProofOfPurchase] = useState([]);
    const [showPOP, setShowPOP] = useState(false);

    const toggleInvoice = (val) => {
        setShowPOP(true);
        // setShowPOP(prev => !prev);
        
    };

    const onClosePOP = () => {
        setShowPOP(false);
      }

    const onCheckInModalOk = () => {
        setIsCheckInDetailsOpen(false);
      }

    
    
    const onCheckInModalCancel = () => {
        setIsCheckInDetailsOpen(false);
      };

    const showDetailModal = (checkin) => {
        setSelectedCheckIn(checkin);
        setIsCheckInDetailsOpen(true);
      }  
    
    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };

    const submitComment = () => {
        if (comment.length === 0) {
            message.error("Cannot create comment for empty comment");
            return;
        }

        console.log(purchaseOrder);

        const poNumber = purchaseOrder?.orderNumber || "";
        console.log('LocalStorage   ',LocalStorage);  
        const payload = {
            userId: LocalStorage.get("userid",''),
            userName: LocalStorage.get("username",''),
            module: "purchasing",
            operation: "details",
            reference: poNumber,
            content: comment,
            tag:["purchase-order-details", poNumber]
        };

        commentApi.createComment(payload)
        .then(r => {
            console.log('COMMENT RES',r)
            //refresh comment
            getComments();
        })
        .catch(err => message.error("Comment registration error: " + err));        
    };

    const getComments = async () => {
        setIsCommentsLoading(true);

            const payload = {
                module: "purchasing",
                op: "details",
                ref: id
            }
            const commentList = await commentApi.getCommentsSafely(payload);
            
            if (commentList) {
                setIsCommentsLoading(false);
                setComments(commentList);
            }
    };

     // selected rows
     const rowSelection = {
        onChange: (selectedRowKeys) => {
        
        setSelectedRowKeys(selectedRowKeys);
        console.log("ACTIVE PURCHASE ITEM", selectedRowKeys);
        },
    };

    const instantCheckIn = () => {
        const items = lineItems.map(p => {
          return {
            productVariantId: p.variantId,
            quantity: p.purchasedQty,
          };
        });
        
        const payload = {
          items: items,
          orderNum: purchaseOrder.linkedOrder,
          purchaseId: purchaseOrder.orderNumber,
          remark: "INSTANT CHECK-IN",
          checkInType: "FULL"
        };
    
        purchaseApi.checkInOrder(payload)
        .then(async r => {
          console.log("R",r);
          message.success("Purchase order succesfully checked in");     
    
          if (process.env.REACT_APP_DEVELOPMENT) {
            if (purchaseOrder.linkedSource === "custom" || purchaseOrder.linkedSource === "cop") { 
              const webhookPayload = {
                orderNo: purchaseOrder.linkedOrder,
                items: items
              }
              await purchaseApi.notifyCheckIn(webhookPayload);
              getCheckIns();
            }
          }      
        })
        .catch(err => {
          console.log("ERR ", err);
          message.error("Check in failed. Error: ", err);
        });
      };

    const partialCheckIn = () => {
        history.push("/purchases/pending-purchases/partial-check-in?id=" + purchaseOrder.orderNumber);
      }; 

    const distributePurchaseItem = () => {
        if (!!purchaseOrder.purchaseItems) {
          lineItems.map((item) => {
            console.log('ITEM, POP', item);
            // if (!!item.proofOfPurchase) {
                // setPurchaseItems(...purchaseItems,item)
            //  setLineItems((item) => {
            //     return {
            //         ...lineItems,
            //         item
            //     }
            //  }) 

            //  setGotProofOfPurchase((item) => {
            //     return item.filter(
            //       (item) => Number(item.proofOfPurchase) >= 0
            //     );
            //   });

              return setPurchaseItem((purchaseItem) => {
                // console.log('purchaseItem',item)
                if (purchaseItem.itemPurchased)
                  return {
                    ...purchaseItem,
                    itemPurchased: [...purchaseItem.itemPurchased, item],
                  };
              });
            // } else {
            //   return setPurchaseItem((purchaseItem) => {
            //     console.log('purchaseItem',purchaseItem)
            //     return {
            //       ...purchaseItem,
            //       addItem: [...purchaseItem.addItem, item],
            //     };
            //   });
            // }
          });
        }
      };

    const getOrder = async () => {
        console.log('orderNUm', purchaseOrder.linkedOrder);
        if (purchaseOrder.linkedOrder) {
            const filter = [];
            filter.push(`orderNumber_eq=${purchaseOrder.linkedOrder}`);
            const orderPayload = {
                page: 1,
                pageSize: 1,
                filter,
            };
            const res = await ordersApi.getOrders(orderPayload);
            history.push("/orders/order-details/" + res.orders[0].id);
        }
       
    };
    
    const goToOrder = (orderNumber) => {
        const payload = { orderNumber: orderNumber }
        ordersApi.getOrderByNum(payload)
        .then(r => {
          console.log("ORDER",r);
          if (r.length > 1) {
            message.error("Multiple orders found for the same number. Redirection to order page failed");
          } else {
            message.success("Redirecting to order details page");
            history.push("/orders/order-details/" + r[0].id);
          }
        })
        .catch(err => {
          console.log("ERR",err);
        });
    };

    const getCheckIns = () => {
        purchaseApi.getCheckIn(id)
        .then(r => {      
          if (r) {
            // setCheckInList(r);
            console.log('r',r);
            setCheckInList(r);
            console.log('checkIns', checkInList);
          }    
        })
        .catch(err => console.log("ERR",err));
      };

    const getPurchaseOrderDetail = async () => {
            console.log('I am click');
            setIsLoading(true);
            purchaseApi
              .getPurchaseOrder(id)
              .then(async ({ purchaseOrderDetails, proofOfPurchase }) => {
                console.log('purchaseOrderDetails',purchaseOrderDetails)
                purchaseOrderDetails.proofOfPurchase = proofOfPurchase;
                setIsLoading(false);
                await setPurchaseOrder(() => purchaseOrderDetails);
                await setLineItems(() => purchaseOrderDetails.purchaseItems);
                setGotProofOfPurchase((prev) => {
                    return purchaseOrderDetails.purchaseItems.filter(
                      (item) => Number(item.proofOfPurchase) >= 0
                    );
                  });
                await distributePurchaseItem();
                await getCheckIns();
                await getComments();
                // const allEqual = arr => arr.every(val => val.id === arr[0].id);
                // allEqual(gotProofOfPurchase) ??  setIsSamePOP(true);
               
                // console.log('result',isSamePOP);
               
                message.success("Successfully fetch data", 1);
              })
              .catch((error) => {
                setIsLoading(false);
                message.error(error, 5);
              });
    }

    useEffect(async () => {
        
        await getPurchaseOrderDetail();
        distributePurchaseItem();
        // getComments();
        //getPossibeCombos();
        console.log('ORDER AFTER ID CHANGE',order);
    }, [id]);

    const columnsLineItems = [
        {
            title: "Product Name",
            dataIndex: "productName",
            key: "productName",
            render: (_, record) => {
                return (
                    <Link
                        to={{
                            pathname: "/products/product-detail",
                            search: `?id=${record.variant?.product.id}`,
                        }}
                        type="primary"
                    >
                        {record.variantName? record.productName + " - " + record.variantName : record.productName}
                    </Link>
                );
            },
        },   
        {
            title: "Pending Qty",
            dataIndex: "pendingQty",
            key: "pendingQty"
        },
        {
            title: "Purchased Qty",
            dataIndex: "purchasedQty",
            key: "orderNumber",      
        },
        {
            title: "Supplier Price",
            dataIndex: "supplierPrice",
            key: "supplierPrice"
        },
        {
            title: "Supplier Currency",
            dataIndex: "supplierPriceCurrency",
            key: "supplierPriceCurrency"
        },
        {
            title: "Order Number",
            dataIndex: "orderNumber",
            key: "orderNumber"
        },
        // {
        //     title: "Order Number",
        //     dataIndex: "id",
        //     key: "id"
        // }
    ];

    const checkInItems = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",      
        },
        {
            title:"Time",
            dataIndex:"createdAt",
            key:"createdAt",
            render: (_, record) => {
                return (moment(record.createdAt).format("DD/MM/YYYY HH:mm"));
            },
        },
        {
            title: "Check-In Type",
            dataIndex: "checkInType",
            key: "id",      
        },
        {
            title: "Remark",
            dataIndex: "remark",
            key: "id",      
        },
        {
            title: "Action",
            dataIndex: "remark",
            render: (_, record) => {
                return(<Button type="primary" size="small" onClick={()=>showDetailModal(record)}>Details</Button>);
            },
        },
    ];

    return (
        <>
             <CheckInDetails
                isCheckInDetailsOpen={isCheckInDetailsOpen}
                onCheckInModalOk={onCheckInModalOk}
                onCheckInModalCancel={onCheckInModalCancel}
                checkInObj={selectedCheckIn}
            />

            <PopDetails
                file={purchaseOrder}
                display={showPOP}
                onClosePOP = {onClosePOP}
            />
            
            <TitleBox className="title-page">
                <Title>
                    {t("Purchase Order Details")}
                </Title>
                <Breadcrumb items={[{ name: "Home", path: "/" }, Path.PURCHASE_ORDERS, { name: `${t("Purchase Order No.")} ${id || ""}` }]} />
            </TitleBox>

            <OrderDetailPageStyled title={t("Purchase Order detail")}>
                <Container>
                    <Spin spinning={isLoading}>
                        <Row gutter={[16, 16]}>
                            <Col xs={16} md={16}>
                                <Card className="order-info">
                                    <div className="order-details">
                   
                                        <Row className="detail-row">
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Purchase Order No.</div>
                                                <div className="data bold">                                           
                                                    <Title level={4}>
                                                        {id}
                                                    </Title>                                               
                                                </div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Purchaser</div>
                                                <div className="data uppercase">{purchaseOrder?.purchaser}</div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Status</div>
                                                <div className="data uppercase">
                                                    {purchaseOrder?.status}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="detail-row">
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Order Number</div>
                                                <div className="data uppercase" style={{cursor: "pointer",color:"#2cabe3", height:"150px",overflow:"scroll"}}>
                                                    {purchaseOrder?.linkedOrder?.map((i,index) => { 
                                                        let fontSize = "100%";
                                                        if (purchaseOrder.linkedOrder.length > 2)
                                                            fontSize = "80%";
                                                        return(<>          
                                                            <a style={{ cursor:"pointer", fontSize: fontSize }} href={null} onClick={() => goToOrder(i)}>{i}</a>
                                                            {(index + 1) < purchaseOrder.linkedOrder.length? (<>,&nbsp;</>) : (<></>) }
                                                        </>)
                                                    })}
                                                </div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Domestic Shipping Fee</div>
                                                <div className="data uppercase">{purchaseOrder?.domesticShipFee? purchaseOrder.domesticShipFee : "0.00"}
                                                </div>
                                            </Col>
                                            <Col xs={24} md={6} className="box-data">
                                                <div className="label">Purchasing Items</div>
                                                <div className="data">{purchaseOrder?.purchaseItems?  purchaseOrder?.purchaseItems.length : "0" }</div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            </Col>
                            
                            <Col xs={12}>                               
                                    <Card title="Proof of Purchase" className="line-items">
                                    <DragDropFile
                                    selectedRowKeys={lineItems}
                                    purchaseItem={lineItems}
                                    setUploaded={setUploaded}
                                    // onClick={getPurchaseOrderDetail()}
                                    // orderNumber="1122"
                                    // orderSource="shopify"
                                    getOrder={() => getPurchaseOrderDetail()}
                                    getPurchaseOrderDetail={()=>getPurchaseOrderDetail()}
                                    />
                                    </Card>                               
                            </Col> 
                            <Col xs={6}>
                                <h1>Purchase Order Summary</h1>
                                <List
                                dataSource={gotProofOfPurchase}
                                renderItem={(item) => (
                                    <List.Item>
                                    <div>{item.productName}</div>
                                    {/* <div>2022 Lezhin Black Tiger Is Coming Calendar</div> */}
                                    {/* <ProofOfPurchase>
                                        <span
                                        className={item.proofOfPurchase ? "available" : "non"}
                                        >
                                        ID{" "}
                                        <strong>
                                            {item.proofOfPurchase ? item.proofOfPurchase : "NONE"}
                                        </strong>
                                        </span>
                                    </ProofOfPurchase> */}
                                    </List.Item>
                                )}
                                />
                            </Col>
                            <Col xs={6}>
                                <h1>Proof of Purchase</h1>
                                {purchaseOrder?.proofOfPurchase?.length > 0 ?
                                    <Button type="primary" onClick={() => toggleInvoice(1)}>View Proof of Purchase</Button>
                                      :
                                    <span>NONE</span>
                            }
                              
                            </Col>
                            <Col xs={24}>                               
                                    <Card title="Purchased Items" className="line-items">
                                        <Table
                                            pagination={false}
                                            columns={columnsLineItems}
                                            dataSource={lineItems}
                                            rowKey="id"
                                            size="small"
                                            scroll={{
                                                x: "max-content",
                                            }}
                                            // rowSelection={{
                                            //     ...rowSelection,
                                            //   }}
                                        />
                                    </Card>                               
                            </Col> 
                            <Col xs={12}>                               
                                    <Card title="Checked-ins" className="line-items">
                                        <Table
                                            pagination={false}
                                            columns={checkInItems}
                                            dataSource={checkInList}
                                            rowKey="id"
                                            size="small"
                                            scroll={{
                                                x: "max-content",
                                            }}
                                        />
                                    </Card>                               
                            </Col>  
                            <Col md={12}>                               
                            <Card title="Comments" className="line-items">
                                        <Row>
                                            <Col md={12}>
                                                <Spin spinning={isCommentsLoading}>
                                                {comments?.length > 0? (<>
                                                    {comments.map(c => <>
                                                        <div style={{margin:"15px 0px 0px 20px"}}>
                                                            <span style={{fontSize:"85%", fontWeight:"bold"}}>
                                                                {c.userName}
                                                            </span> 
                                                            &nbsp; &nbsp;
                                                            <span style={{fontSize:"85%", fontStyle:"italic"}}>    
                                                                {moment(c.createdAt).format("DD-MM-YYYY, hh:mm a")}
                                                            </span>
                                                            <br/>
                                                            {c.content}
                                                        </div>
                                                        <br/>
                                                    </>)}
                                                </>):(<></>)}
                                                </Spin>
                                            </Col>
                                            <Col md={1} style={{backgroundColor:"whitesmoke"}}>
                                            </Col>
                                            <Col md={10} style={{backgroundColor:"whitesmoke"}}>
                                                <Row gutter={[16,16]}>
                                                    <Col md={24}>&nbsp;</Col>
                                                </Row>
                                                <Row gutter={[16,16]}>
                                                    <Col md={24} style={{backgroundColor:"white"}}>
                                                        <br/>
                                                        Add New Comment
                                                        <br/><br/>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16,16]}>
                                                    <Col md={24} style={{backgroundColor:"white"}}>
                                                        <TextArea rows={8} placeholder="Comment here..." 
                                                            style={{marginRight:"25px"}}
                                                            onChange={handleCommentChange} value={comment}/>  
                                                        <br/><br/>                                                                                        
                                                    </Col>
                                                </Row>
                                                <Row gutter={[16,16]}>
                                                    <Col md={16} style={{backgroundColor:"white"}}></Col>
                                                   
                                                        <Space direction="horizontal">
                                                            <Button type="secondary" onClick={() => setComment("")}>Clear</Button>                                                            
                                                            <Button type="primary" onClick={submitComment}>Submit</Button>
                                                        </Space>
                                                        <br/><br/>
                                                    
                                                    
                                                </Row>                                       
                                            </Col >
                                            <Col md={1} style={{backgroundColor:"whitesmoke"}}>
                                            </Col>
                                        </Row>                                        
                                    </Card>                          
                            </Col>  

                            
                            <Col md={12}>
                                <Space direction="horizontal">
                                    <Button type="primary" onClick={instantCheckIn}>
                                    {/* Full Check-in  {purchaseOrder.linkedOrder} */}
                                    Full Check-in
                                    </Button>
                                    <Button type="primary" onClick={partialCheckIn}>
                                    Partial Check-in
                                    </Button>
                                    <Button type="primary">
                                    Close PO as Completed
                                    </Button>
                                    {/* <LinkButton
                                        to={`/purchases/pending-purchases/make-purchase?purchaseOrderId=${purchaseOrder.orderNumber}`}
                                        type="primary"
                                    >
                                        Make Purchase
                                    </LinkButton>    */}
                                </Space>         
                            </Col>                                                                           
                        </Row>
                    </Spin>
                </Container>
            </OrderDetailPageStyled>
        </>
    );
}

export default PurchaseOrderDetail;

const ProofOfPurchase = styled.div`
  .non,
  .available {
    padding: 5px 13px;
    border-radius: 5px;
    color: white;
  }

  .non {
    background: #cf001c;
  }

  .available {
    background: #005acf;
  }
`;
