import { 
    Card, Button, Row, Col, Typography, message, Spin, Select, Table, Input, Space, DatePicker, Modal, 
    Switch, Form, Descriptions, Tag, Pagination, Checkbox, 
} from "antd";

import TitleHeader from "components/title-header";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import ordersApi from "apis/ordersApi";
import productsApi from "apis/productsApi";
import shipmentsApi from "apis/shipmentsApi"
import Path from "../../commons/breadcrumbs";
import moment from "moment";
import {remove}  from "diacritics";
import lookup from "country-code-lookup";
import { v4 as uuidv4, v6 as uuidv6 } from 'uuid';
import etdPlt from "../../utils/etd-plt";
import queryString from "query-string";
import { Link } from "react-router-dom";
import { debounce, lowerCase, some } from "lodash";
import { detectBookCategory } from "pages/create-shipment/utils";

import { getProvince } from "utils/funcs";

const BulkLabelPrinting = () =>{
    const [isLoading, setIsLoading] = useState(false);
    const [isResultLoading, setIsResultLoading] = useState(false);
    const [orders, setOrders] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [selectionType, setSelectionType] = useState('checkbox');
    const [dateRange, setDateRange] = useState([moment().subtract(1, "months"), moment()]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isResultOpen, setIsResultOpen] = useState(false);
    const [courierAccts, setCourierAccts] = useState([]);
    const [courierOptions, setCourierOptions] = useState([]);
    const [shippers, setShippers] = useState([]);
    const [bulkShipResult, setBulkShipResult] = useState([]);
    const [sortType, setSortType] = useState("DESC");
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const [includeShipped, setIncludeShipped] = useState(false);
    const [signRequired, setSignRequired] = useState(false);
    const [contents, setContents] = useState();
    const [category, setCategory] = useState();
    const [hsCode, setHsCode] = useState();
    const [declaredValue, setDeclaredValue] = useState();

    const [productVariant, setProductVariant] = useState();

    //Pagination
    const [current, setCurrent] = useState();
    const [currentPage, setCurrentPage] = useState();
    const [pageSize, setPageSize] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [allSelected, setAllSelected] = useState([]);

    let bulkShipArr = [];

    const { RangePicker } = DatePicker;

    const rowSelection = {
        onChange: (selectedRows) => {
            setSelectedRowKeys(selectedRows);
        },
        getCheckboxProps: (record) => {
            
            return {
              disabled: record.lineItems.length > 1 //enable first 2 rows only
            };
        }
    };

    const searchOrders = () => {        
        productsApi.getProductVariant(searchKeyword)
        .then(r => {
            setProductVariant(r.variant);
            setHsCode(r.variant.HSCode);
        })
        .catch(err => console.log("ERR", err));
        
        const payload = {
            variantId: searchKeyword,
            startDate: moment(dateRange[0]).hours(0).minutes(0).seconds(0),
            endDate: moment(dateRange[1]).hours(23).minutes(59).seconds(59),
            pageSize: pageSize,
            currentPage: currentPage,
            sortType: sortType,
            country: selectedCountry,
            includeShipped: includeShipped,
            signRequired: signRequired
        };
       
        ordersApi.getBulkPrintOrders(payload)
        .then(r => {
            console.log("R",r);
            
            const arr = r.rows.map(m => {
                return {
                    key: m.id,
                    ...m
                }
            });
            
            setTotalRows(r.count);
            setOrders(arr);
            
        })
        .catch(err => {
            console.log("ERR",err);
        });
    };

    const getCouriers = () => {
        shipmentsApi.getListShippingAccounts()
        .then(r => {
          
            const arr = r.shipping_account?.map(s => {
                return {
                    value: s.id,
                    label: s.accountName
                }
            });
            
            setCourierAccts(r.shipping_account);
            setCourierOptions(arr);
        })
        .catch(err => {
            console.log("COUR ERR",err);
        })

        shipmentsApi.getListShipper()
        .then(r => {
            const arr = r.shipper_details?.map(s => {
                return {
                    value: s.id,
                    label: s.name
                } 
            });

            setShippers(arr);
        })
        .catch(err => {
            console.log("SHIPPER ERR", err);
        })
    }

    const setFormDefaults = () => {
        //We assume search by variant id, and variant is the same
        //acrosses orders
        const lineItem = selectedOrders[0].lineItems[0];
        console.log("selectedOrders[0]", selectedOrders[0]);

        let cat = "";
        let desc = lineItem.customProductName? lineItem.customProductName : `${lineItem.productTitle} ${lineItem.productVariantTitle}`;
        const hs = lineItem.customProductName
        const value = Number(lineItem.quantity) * Number(lineItem.price);
        console.log("VALUEEEE", value, lineItem);

        if (detectBookCategory(desc)) {
            desc = `BOOK ${desc}`;
            cat = "BOOK";
        } else {
            cat = "OTHER";
        }

        setDeclaredValue(value);
        setContents(desc);
        setCategory(cat);
    }

    const openPrintConfirm = () => {   
        //console.log("orders", orders.length);
        //console.log("all selected",allSelected);    
        if (selectedOrders.length > 0) {
            setFormDefaults();
            setIsModalOpen(true);
        } else {
            message.error("Please select orders before proceeding to printing");
        }       
    };

    const onPageChange = (page, pSize) => {    
        console.log("onPageChange, page",page); 
        setCurrent(page);
        
        const actualPage = page > 0 ? (page - 1) : 0;
        console.log("actual page", actualPage); 
        setCurrentPage(actualPage);
        setPageSize(pSize);
    };

    
    const onPageSizeChange = (page, pSize) => {
        console.log("onPageSizeChange, page",page);
        setCurrent(page);

        const actualPage = page > 0? (page -1) : 0;
        setCurrentPage(actualPage);
        
        setPageSize(pSize);
    };
    

    const updateSelectedList = (isChecked, orderId, order) => {
        if (isChecked) {
            setAllSelected([...allSelected, orderId]);
            setSelectedOrders([...selectedOrders, order]);
        } else {
            setAllSelected(allSelected.filter(a => a !== orderId));
            setSelectedOrders(selectedOrders.filter(s => s.id != orderId));
        }
    };

    const changeSortType = (value) => {
        setSortType(value);
    };

    const changeCountry = (value) => {
        setSelectedCountry(value);
    };

    const refreshSearch = () => {
        //clear orders selection
        setAllSelected([]);
        setSelectedOrders([]);
        
        //reset paging
        //setting currentPage will trigger search
        if (orders.length > 0) {
            searchOrders();
        } else {
            if (currentPage === 0 && current === 1)
                searchOrders();
            else {
                setCurrentPage(0);          
                setCurrent(1);
            }           
        }
     
        
    };

    const startPrinting = () => {
        //createShipment
       console.log("SELECTED ORDERS", allSelected);
    };

    const onFinish = async (values) => {
        setIsResultOpen(true);
        setIsResultLoading(true);
        
        let insuranceParams = {
            insuranceCost: "",
            insuranceRequested: false,
            insuranceCurrency: "",
        };

        let rateNetCharge = 0;

        const courier = courierAccts.find(c => {
            if (c.id === values.courier)
                return c;
        });
        
        //setBulkShipResult([]);
        bulkShipArr = [];

        const shipAccts = await shipmentsApi.getListShippingAccounts()
        .catch(err => console.log("SHIPACCT ERR",err));

        console.log("SHIP ACCTS", shipAccts);

        const shipAccList = shipAccts.shipping_account.map(s => {
            return {
                type: s.apiType,
                id: s.id
            };
        });

        console.log("ACCLIST", shipAccList);

        //create shipment for each order
        for (let i=0; i < selectedOrders.length; i++){
            const order = selectedOrders[i];
          
            const personName = (order.addressFirstName? remove(order.addressFirstName) : "") + " " + (order.addressLastName? remove(order.addressLastName) : "");
            const countryCode = lookup.byCountry(order.addressCountry).iso2;
            const provinces = getProvince(countryCode) || [];
            
            const provinceCode = provinces?.find((item) => item.name === order.addressProvince)?.code;
          
            //finding required signature flag
            let reqSign = false;

            //get the only item lineItem (shippable item)
            const lineItem = order.lineItems.find(l => {
                if (l.productVariantId === productVariant.id)
                    return l;
            });

            //Insurance and signature fields
            let hasInsurance = order.lineItems.some(function(item) {
                return item.productTitle === 'Courier Insurance';
            });

            let signatureNeeded = order.lineItems.some(function(item) {
                return item.productTitle === 'Signature Upon Delivery';
            });

            
            if (signatureNeeded) {
                reqSign = true;
            } else {
                //Check note attributes
                const noteAttrib = order.noteAttributes;
                console.log("NOTE ATTRIB", noteAttrib);           
            
                const signUponDelivery = noteAttrib?.find(f => {
                    if(f.name === "signature-upon-delivery") {
                        return f;
                    }
                });

                if (signUponDelivery)
                    reqSign = signUponDelivery.value === "yes"? true : false;
            }            
                                        
            //rating payload
            const ratingPayload = {
                "userId": "123",
                /*
                "shippingAccounts": [
                    {
                        "type": courier.apiType,
                        "id": courier.id
                    }
                ],
                */
                "shippingAccounts": shipAccList,
                "shipperDetailsId": values.shipper,
                "sampleResponse": false,
                "invoiceNumber": order.orderNumber,
                "invoiceDate": moment().utc().endOf("day").format(),
                "shipmentDate": moment().utc().endOf("day").format(),
                "declaredValue": values.declaredValue,
                "email": order.customerEmail,
                "notificationMessage": "",
                "contents": values.content,
                "notification": false,
                "shopifyNotification": values.shopifyNotification,
                "pltShipment": true,
                "documentsOnly": false,
                "signatureRequired": reqSign,//values.signature,
                "signatureType": "DIRECT",
                "useOwnInvoice": false,
                "fedexPackagingType": values.fedexPackaging,
                "fedexServiceType": courier.fedexServiceType,
                "fedexDutiesPaidAccount": courier.accountNumber,
                "shipmentPurpose": "SOLD",
                "insuranceCost": "",
                "shippingCost": "",
                "packingCost": values.packingCost,
                "handlingCost": "",
                "otherCost": "",
                "trackingNumber": "80329091290",
                "invoiceRemark": "",
                "invoiceCurrency": "USD",
                "invoiceWeightUnit": "KG",
                "invoiceLetterhead": {
                    "content": "",
                    "fedexImageId": "IMAGE_1"
                },
                "invoiceSignature": {
                    "content": "",
                    "fedexImageId": "IMAGE_2"
                },
                "invoiceData": {
                    "documentType": "COMMERCIAL_INVOICE",
                    "fileName": "Invoice.pdf",
                    "content": "JVBERi0xLjQKJeLjz9MKNiAwIG9iago8PC9GaWx0ZXIvRmxhdGVEZWNvZGUvTGVuZ3RoIDUxPj5zdHJl"
                },
                "rincosServiceCode": "",
                "rincosServiceName": "",
                "labelType": "ZPLII",
                "consignee": {
                    "companyName": order.companyName? remove(order.companyName) : "",
                    "personName":  personName,
                    "personPhone":  order.addressPhone,
                    "personEmail": order.customerEmail,
                    "address1": order.addressLineOne? remove(order.addressLineOne) : "",
                    "city": order.addressCity? remove(order.addressCity) : "",
                    "division": order.addressProvince? remove(order.addressProvince) : "",
                    "provinceCode": provinceCode,//order.addressProvince? remove(order.addressProvince) : "",
                    "postal": order.addressZip,
                    "countryCode": countryCode,
                },
                "exportDeclaration": [
                    {
                        "id": "b358a5af-522a-4d27-a00f-78fb67828fd7",
                        "updateVariant": false,
                        "productVariantId": order.lineItems[0].productVariantId,
                        "quantity": 1,
                        "quantityUnit": "PCS",
                        "description": values.content,
                        "value": lineItem.price,
                        "totalValue": Number(lineItem.price) * Number(lineItem.quantity),
                        "weight": Number(values.weight),
                        "grossWeight":  Number(values.weight),
                        "HSCode": null,
                        "sku": "",
                        "originCountry": "KR",
                        "category":  values.category,
                    }
                ],
                "pieces": [
                    {
                        "id": "cd824c09-94cc-41ce-b213-b75eaa5db5ac",
                        "height": "10",
                        "width": "10",
                        "depth": "10",
                        "value":  Number(lineItem.price) * Number(lineItem.quantity),
                        "weight": values.weight,
                        "reference": ""
                    }
                ]
            }
            
            let cheapest = null;

            const rateRes = await shipmentsApi.getRateList(ratingPayload)
            .catch(err => {
                console.log("ERR RATE", err);
            });

            if (countryCode === "MY" || countryCode === "SG") {
                const rincosRate = rateRes.rates.find(r => {
                    if (r.courier === "rincos")
                        return r;
                });
                cheapest = rincosRate;
            } else {
                const nonRincosRates = rateRes.rates.filter(r => {
                    if (r.courier !== "rincos")
                        return r;
                });

                
                const initValue = { rateNetCharge: Number.MAX_VALUE};
                
                cheapest = nonRincosRates.reduce((total, num) => {
                    if (Number(num.rateNetCharge) < Number(total.rateNetCharge)) {                    
                        return num;
                    } else {
                        return total;
                    }
                },  initValue);
            }

            console.log("RATES, CHEAPEST", rateRes, cheapest);
            /*
            if (rateRes.rates[0]?.rateNetCharge) {
                rateNetCharge = rateRes.rates[0].rateNetCharge
                console.log("RATE", rateNetCharge);
            }
            */
            
            //create shipment payload
            //FEDEX: etd, DHL: plt, UPS: edi
            let eFormat = ""; 
            switch(courier.apiType) {
                case("dhl"):
                    eFormat = "plt";
                    break;
                case("fedex"):
                    eFormat = "etd";
                    break;
                case("ups"):
                    eFormat = "edi";
                    break;
            }

            const etdPltInfo =  etdPlt.findByCountryCode(countryCode, eFormat);
            const etdPltEnabled = etdPltInfo.hasOwnProperty("info")? false : etdPltInfo.outBound;
          
            console.log("ETD/PLT/EDI INFO", etdPltInfo);


            //Discount calculation
            //*******CONFUSING THINGS BELOW THIS LINE. TREAD LIGHTLY******/
            let totalRefunds = 0;
            const refunds = await ordersApi.getRefunds(order.id)
            if (refunds) {
                for(let i=0; i < refunds.length; i ++) {
                    totalRefunds += Number(refunds[i].adjAmount);
                }
            }

            const item = lineItem;

            const oriLineItems = order.lineItems.filter((item) => 
                (item.fulfillableQuantity > 0 ) || (item.fulfillableQuantity == 0 & item.fulfillmentStatus == "fulfilled")
            );
            
            const oriLength = oriLineItems.length;

            //Difference array between actual shipment items vs other non shipment items
            console.log("ORILINEITEMS, LINEITEM", oriLineItems, lineItem);
            const difference = oriLineItems.filter(x => x.id !== lineItem.id);

            let diffTotalAppliedDiscounts = Number(0);
            difference.map(d => {
                for(let j = 0; j < d.discountAllocations?.length; j++){
                    diffTotalAppliedDiscounts = Number(diffTotalAppliedDiscounts) + Number(d.discountAllocations[j].amount);
                }                        
            });

            //this is to get qty of non zero price of line items. we need to get it as 
            //denominator for dispersing differential discounts (discounts those dont get into final line items)
            const legit = [lineItem].filter(x => {
                let inprice = Number(x.price);

                if(x.discountAllocations){
                    for (let p=0; p < x.discountAllocations.length; p++) {
                        
                        const index = x.discountAllocations[p].discount_application_index;
                        
                        const discountApp = x.discountApplications[index];
                        if( discountApp.value_type === 'fixed_amount' /*&& discountApp.target_selection !== 'all'*/){                                    
                            inprice = inprice - Number(x.discountAllocations[p].amount/x.quantity);
                            
                        } else if (discountApp.value_type === 'percentage' /*&& discountApp.target_selection !== 'all'*/){
                            const percentage = (100 - Number( discountApp.value))/100;
                            inprice = inprice * percentage;                                    
                        }                  
                    }
                }
                if(Number(inprice) > 0){                            
                    return x;
                }
            });

            let dispersed = Number(0);
            if(diffTotalAppliedDiscounts > 0){
                dispersed = Number(diffTotalAppliedDiscounts/legit.length);
            }
            
            //FOR REFUNDS CAUSING ORDER ADJUSTMENT
            let dispersedRefund = Number(0);
            if (totalRefunds) {
                dispersedRefund = Number(totalRefunds/legit.length);
            }
            console.log('DISPERSED REFUND', dispersedRefund);

            let price = Number(item.price);
            let itemId = item.id;                   

            //this is a bit confusing.
            //discount_codes apply to whole order, not line item.
            //so if type = discount_code, we dont apply it I guess?
            if(item.discountAllocations){
                for (let p=0; p < item.discountAllocations.length; p++) {
                    
                    const index = item.discountAllocations[p].discount_application_index;
                    
                    const discountApp = item.discountApplications[index];
                    if( discountApp.value_type === 'fixed_amount' /*&& discountApp.target_selection !== 'all'*/){
                        price = price - Number(item.discountAllocations[p].amount/item.quantity);//Number( discountApp.value);
                        //console.log(price)
                    } else if (discountApp.value_type === 'percentage' /*&& discountApp.target_selection !== 'all'*/){
                        const percentage = (100 - Number( discountApp.value))/100;
                        price = price * percentage;
                        //price = price - Number(item.discountAllocations[p].amount);
                        //console.log(price)
                    }
                    
                    if(price > 0){
                        //This recent change (28/3/23) is to avoid potential bug from
                        //price discount not divided by quantity.
                        price = price - Number(dispersed/item.quantity); 
                    }
                }
            }
            
            //Apply REFUNDS adjustment if applicable
            //REFUNDS adjustment comes in negative wrt the orders
            if (Math.abs(dispersedRefund) > 0){
                price = price + (dispersedRefund/item.quantity);
            }

            //console.log('DISCOUNT CODES', discountCodes);

            const totalVal = (Number(price).toFixed(2) * Number(item.quantity)).toFixed(2);
            //End of discount calculation
            
            //If it is DHL and insurance is applied, change the params
            if (hasInsurance && cheapest.courier === "dhl") {
                insuranceParams = {
                    insuranceCost: Number(totalVal),
                    insuranceRequested: true,
                    insuranceCurrency: "USD",
                }
            }

            const shipPayload = {
                userId: "randomCitizen",
                orderId: order.id.toString(),
                orderNumber: order.orderNumber,
                shippingAccountId: cheapest.accountId,//values.courier, //NEED TO PASS 
                shipperDetailsId: values.shipper, //NEED TO PASS
                sampleResponse: false,
            
                invoiceNumber: order.orderNumber,
                invoiceDate: moment().utc().endOf("day").format(),
                shipmentDate: moment().utc().endOf("day").format(),
                declaredValue: values.declaredValue, //PARAMETERIZED
                email: order.customerEmail,
                notificationMessage: "",
                notification: false,
                shopifyNotification: values.shopifyNotification, //PARAMETERIZED
                contents: values.content || '',//PARAMETERIZED
                pltShipment: etdPltEnabled, //CALCULATED?
                documentsOnly: false, //FALSE BY DEFAULT
                signatureRequired: reqSign,//values.signature, //PARAMETERIZED
            
                signatureType: "DIRECT", // <-- hardcoded
                useOwnInvoice: false, //FOR EDT/PLT/EDI
                fedexPackagingType: cheapest?.packagingType || "", //PARAMETERIZED?
                fedexServiceType:  cheapest?.serviceType || "", //"FEDEX_INTERNATIONAL_PRIORITY", //CALCULATE?
                fedexDutiesPaidAccount: cheapest?.accountNumber || "", //PARAMETERIZED? //CALCULATE
                shipmentPurpose: "SOLD", // <-- please refer documentation from Edward and Kaamesh
                /*
                insuranceCost: "",
                insuranceRequested: false,
                insuranceCurrency: "",
                */
                ...insuranceParams,
                shippingCost: cheapest.rateNetCharge, //CALCULATED? VALUE FROM RATE
                shippingCurrency: "KRW", //CALCULATED VALUE FROM RATE?
                packingCost: values.packingCost, //PARAMETERIZED
                orderGrandTotal: values.declaredValue, //PARAMETERIZED
                handlingCost: "", // <-- hardcoded
                otherCost: "", // <-- hardcoded
                trackingNumber: "80329091290", // <-- hardcoded
                invoiceRemark: "",
                invoiceCurrency: "USD", //PARAMETERIZED?
                invoiceWeightUnit: "kg", // <-- hardcoded
                invoiceLetterhead: {
                    content: "", // <-- hardcoded
                    fedexImageId: "IMAGE_1", // <-- hardcoded
                },
                invoiceSignature: {
                    content: "", // <-- hardcoded
                    fedexImageId: "IMAGE_2", // <-- hardcoded
                },
                invoiceData: {
                    documentType: "COMMERCIAL_INVOICE", // <-- hardcoded
                    fileName: "Invoice.pdf", // <-- hardcoded
                    content: "JVBERi0xLjQKJeLjz9MKNiAwIG9iago8PC9GaWx0ZXIvRmxhdGVEZWNvZGUvTGVuZ3RoIDUxPj5zdHJl", // <-- hardcoded
                },
                rincosServiceCode: "", // <-- hardcoded
                rincosServiceName: "", // <-- hardcoded
                labelType: "ZPLII",
                consignee: {
                    companyName: (order.companyName? remove(order.companyName) : "") || personName,
                    personName: personName,
                    personPhone: order.addressPhone,
                    personEmail: order.customerEmail,
                    address1:  order.addressLineOne? remove(order.addressLineOne) : "",
                    address2:  order.addressLineTwo? remove(order.addressLineTwo) : "",
                    stateOrProvinceName:  order.addressProvince? remove(order.addressProvince) : "",
                    city: order.addressCity? remove(order.addressCity) : "",
                    division:  order.addressProvince? remove(order.addressProvince) : "",
                    provinceCode: provinceCode,// order.addressProvince? remove(order.addressProvince) : "",
                    postal: order.addressZip,
                    countryCode: countryCode,
                    iossNumber: order.iossNumber
                },
                
                //WE ASSUME ONLY 1 ITEM
                exportDeclaration: [{
                    id: uuidv4(), //randomly generated id?
                    quantity: item.quantity,
                    quantityUnit: "PCS",
                    description: values.content,//PARAMETERIZED
                    value:  price.toFixed(2).toString(),//lineItem.price,//values.declaredValue, //PARAMETERIZED
                    totalValue: totalVal, //Number(lineItem.price) * Number(lineItem.quantity),//values.declaredValue, //PARAMETERIZED
                    weight: Number(values.weight), //PARAMETERIZED
                    grossWeight: Number(values.weight), //PARAMETERIZED
                    HSCode: values.hsCode,
                    sku: "",
                    originCountry: "KR",
                    category: values.category, //PARAMETERIZED
                    productVariantId: productVariant.id,          //order.lineItems[0].productVariantId, //PARAMETERIZED
                    updateVariant: values.hsCode? true : false,
                }],
                pieces: [
                    {
                        id: uuidv4(),
                        height: values.packingHeight.toString(),
                        width: values.packingWidth.toString(),
                        depth: values.packingDepth.toString(),
                        value:  (Number(lineItem.price) * Number(lineItem.quantity)).toFixed(2).toString(),//values.declaredValue,
                        weight: values.weight,
                        reference: ""
                    }
                ],
                shipper: {
                    name: "Harumio Shipping",
                    phonenumber: "821095319828",
                    utcOffset: "+09:00",
                    email: "support@harum.io",
                    address1: "4th Flr",
                    address2: "27, World Cup buk-ro 16-gil",
                    city: "Mapo-gu",
                    postal: "03973",
                    provinceCode: "KR",
                    countryCode: "KR",
                    country: "KR"
                },
                isPoBox: false,
                isNameInLatinChars: true,
            };
            
            let response = "";
            let r = "";      
            
            console.log("SHIP PAYLOAD FOR ORDER", order.orderNumber,shipPayload);

            //switch (courier.apiType) {
            switch (cheapest.courier) {
                case ("fedex"):                    
                    r = await shipmentsApi.createShipmentFedex(shipPayload);
                    response = "";
                    
                    if (r.response?.status && (r.response?.status !== 200 || r.response?.status !== 201)) {
                        response = r.response?.data || "unknown error";
                    } else {
                        response = r;
                    }

                    bulkShipArr.push({ orderNumber: order.orderNumber, response: response, etd: etdPltEnabled, orderId: order.id });
                    
                    break;
                case ("dhl"):                    
                    r = await shipmentsApi.createShipmentDhl(shipPayload);
                    response = "";

                    if (r.response?.status && (r.response?.status !== 200 || r.response?.status !== 201)) {
                        response = r.response?.data || "unknown error";
                    } else {
                        response = r;
                    }
                    
                    bulkShipArr.push({ orderNumber: order.orderNumber, response: response, etd: etdPltEnabled, orderId: order.id });
                    
                    break;
                case ("ups"):
                    r = await shipmentsApi.createShipmentUps(shipPayload);
                    response = "";

                    if (r.response?.status && (r.response?.status !== 200 || r.response?.status !== 201)) {
                        response = r.response?.data || "unknown error";
                    } else {
                        response = r;
                    }
                    
                    bulkShipArr.push({ orderNumber: order.orderNumber, response: response, etd: etdPltEnabled, orderId: order.id});
                                        
                    break;
                case ("rincos"):
                    r = await shipmentsApi.createShipmentRincos(shipPayload);
                    response = "";
                    
                    if (r.response?.status && (r.response?.status !== 200 || r.response?.status !== 201)) {
                        response = r.response?.data || "unknown error";
                    } else {
                        response = r;
                    }
                    
                    bulkShipArr.push({ orderNumber: order.orderNumber, response: response, etd: etdPltEnabled, orderId: order.id});

                    break;
            }
                       
            await sleep(100);
        }

        if (process.env.REACT_APP_DEVELOPMENT === "false"){        
        //if (true) {
            for (let j=0; j < bulkShipArr.length; j++) {
                const ship = bulkShipArr[j];
    
                if (ship.response.trackingNumber) {
                    const params = {
                        page: 1,
                        pageSize: 1,
                        search: ship.response.trackingNumber,
                        fields: ["id", "labelBase64", "invoice_base64"],
                    };
    
                    const { shipment } = await shipmentsApi.getShipments(params);
                    
                    if (!ship.etd) {
                        try {
                            const payload = {
                                id: shipment[0]?.id,
                                documentType: ["invoice_base64"],
                            };
                            const resPrint = await shipmentsApi.printShipmentDocuments(payload);
                            //window.location = "/shipments";
                        } catch (error) {
                            console.log(error);
                        }
                    }
    
                    try {
                        const payload = {
                            id: shipment[0]?.id,
                            documentType: ["label_base64"],
                        };
                        const resPrint = await shipmentsApi.printShipmentDocuments(payload);
                        console.log(resPrint);
                        //return (window.location = "/shipments");
                    } catch (error) {
                        message.error(error.message);
                    }
                    
                    //notify shopify
                    //if (true)
                    //if (process.env.REACT_APP_DEVELOPMENT === "false") 
                    postFullFillOrder(ship.orderId);
                }
            }            
        }

        setBulkShipResult(bulkShipArr);
        setIsResultLoading(false);  
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const postFullFillOrder = async (orderId) => {
        console.log("POST-FULFILL", orderId);
        try {
            const payload = {
                orderId: orderId,
            };

            const data = {
                shopifyNotification: true,
            };
            const res = await ordersApi.postFullFillOrder(payload, queryString.stringify(data));
            console.log(res);
        } catch (error) {
            message.error(error.message);
        }
    };

    const selectAll = () => {
        const tmpOrderIdArr = [];
        const tmpOrdersArr = [];

        orders.map(o => {
            if (o.lineItems.length === 1) {
                if (!allSelected.includes(o.id)) {
                    tmpOrderIdArr.push(o.id);
                    tmpOrdersArr.push(o);
                }       
            }
        });

        setAllSelected([...allSelected, ...tmpOrderIdArr]);
        setSelectedOrders([...selectedOrders, ...tmpOrdersArr]);
    };

    const changeIncludeShipped = (e) => {
        if  (e.target.checked)
            setIncludeShipped(true);
        else 
            setIncludeShipped(false);
    };

    const changeSignRequired = (e) => {
        if (e.target.checked)
            setSignRequired(true);
        else 
            setSignRequired(false);
    };

    const nonShipmentItems = [
        "Korea Shopping Agent",
        "Courier ",
        "Signature ",
        "Domestic Shipping",
        "Bunjang",
        "Top-up",
        "Top up",
        "Topup",
        "Keyword Search",
        "Change Of",
        "Shipping top up",
        "Shipping topup",
        "Shipping top-up",
        "Service Fee",
    ].map((item) => item.toLowerCase());

    const columns = [
        {
            title: 'Selected',
            dataIndex: 'selected',
            key: 'selected',
            render: (value, record) => {
                const filtered = record.lineItems.filter(item => !nonShipmentItems.some((nonShipTitle) => lowerCase(item.productTitle).startsWith(nonShipTitle)));
                const shippables = filtered.filter(item =>  !nonShipmentItems.some((nonShipTitle) => lowerCase(item.customProductName).startsWith(nonShipTitle)));
                //const shippables = filtered2.filter(item => (item.fulfillableQuantity > 0 ) || (item.fulfillableQuantity == 0 & item.fulfillmentStatus == "fulfilled"));

                //if (record.lineItems.length > 1) {
                if (shippables.length > 1){
                    return(<><Checkbox  disabled={true}/></>)
                } else {
                    const isChecked = allSelected.includes(record.id);
                    return(<><Checkbox checked={isChecked} onChange={(e) => {
                        updateSelectedList(e.target.checked, record.id, record);
                    }}/></>)
                }
            }
        },
        {
            title: 'Order Number',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            render: (item, record) => {
                return record.combinedOrderId ? (
                    <Link to={`${Path.ORDER_DETAIL.pathParam(record.combinedOrderId)}?combine=true`}>#{record.combinedOrderId}</Link>
                ) : !record.sub ? (
                    <Link to={Path.ORDER_DETAIL.pathParam(record.id)}>
                        {record.source === "shopify" && record.orderNumber}
                        {record.source === "custom" && `${record.orderNumber}`}
                        {record.source === "whatsapp" && `WHA${record.orderNumber}`}
                        {record.source === "cop" && record.orderNumber}
                        {record.source === "ware" && record.orderNumber}
                    </Link>
                ) : (
                    ""
                );
            },
        },
        /*
        {
            title: 'Email',
            dataIndex: 'customerEmail',
            key: 'customerEmail',
        },   */
        {
            title: 'Items',
            dataIndex: 'lineItems',
            key: 'lineItems',
            render: (value, record) => {
                const filtered = record.lineItems.filter(item => !nonShipmentItems.some((nonShipTitle) => lowerCase(item.productTitle).startsWith(nonShipTitle)));
                const shippables = filtered.filter(item =>  !nonShipmentItems.some((nonShipTitle) => lowerCase(item.customProductName).startsWith(nonShipTitle)));
                //const shippables = filtered2.filter(item => (item.fulfillableQuantity > 0 ) || (item.fulfillableQuantity == 0 & item.fulfillmentStatus == "fulfilled"));

                return(<>
                    <ol>
                        {shippables?.map(v => <li>{v.productTitle || v.customProductName}</li>)}
                    </ol>
                </>)
            }
        },
        /*
        {
            title: 'Tracking',
            dataIndex: 'trackingNumber',
            key: 'trackingNumber',
            render: (value, record) => {
                if (value) {
                    return(<Tag color="#87d068">{value}</Tag>);
                } else {
                    return(<></>)
                }
                
            }
        },     
        */
        {
            title: 'Country',
            dataIndex: 'addressCountry',
            key: 'addressCountry'
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value) => {
                return(<>{moment(value).format("HH:mm:ss, DD/MM/YYYY")}</>)
            }
        },
        {
            title: 'Note',
            dataIndex: 'note',
            key: 'note',
            render: (value, record) => {
                const filtered = record.lineItems.filter(item => !nonShipmentItems.some((nonShipTitle) => lowerCase(item.productTitle).startsWith(nonShipTitle)));
                const shippables = filtered.filter(item =>  !nonShipmentItems.some((nonShipTitle) => lowerCase(item.customProductName).startsWith(nonShipTitle)));
                //const shippables = filtered2.filter(item => (item.fulfillableQuantity > 0 ) || (item.fulfillableQuantity == 0 & item.fulfillmentStatus == "fulfilled"));

                if (shippables.length > 1) {
                    //return(<>Not allowed due to items greater than one</>)
                    return {
                        props: {
                            style: {
                                background: "#f5ccc9"
                            }
                        },
                        children: <>Not allowed due to items greater than one</>
                    }
                } else {
                    return(<></>)
                }
            }
        },
    ];

    useEffect(() => {
        console.log("ALL SELECTED",allSelected);
    },[allSelected]);

    useEffect(() => {
        console.log("SELECTED ORDERS", selectedOrders);
    },[selectedOrders]);

    useEffect(() => {
        searchOrders();
    },[currentPage, pageSize]);

    useEffect(() => {
        console.log("COUNTRIES LIST",lookup.countries);
        
        const tmpArr = lookup.countries.map(l => {
            return {
                value: l.country,
                label: l.country
            }
        });

        setCountries(tmpArr);
        getCouriers();
    },[])

    return (<> 
        <TitleHeader
            listOfBreadcrumb={[
                { name: "Home", path: "/" },
                { name: Path.SHIPMENTS.name, path: Path.SHIPMENTS.pathname },
                {
                    name: Path.BULK_LABEL_PRINT.name,
                },
            ]}
            title={Path.BULK_LABEL_PRINT.name}
        />       
        <Row>
            <Col md={1}></Col>
            <Col md={21}>
                <Row style={{marginTop:"15px", padding:"15px" ,backgroundColor:"#edf1f5", border:"#D7D7D7 solid 1px"}}>
                    <Col md={22}>
                        <Row>
                            <Space direction="horizontal">
                                Keyword
                                <Input style={{width:"360px", marginRight:"10px"}} placeholder="Enter variant id..." value={searchKeyword}
                                        onChange={(e) => {setSearchKeyword(e.target.value)}}
                                />
                                Search Attribute
                                <Select
                                    defaultValue="variantId"
                                    options={[
                                        { value: "variantId", label: "Variant Id"}
                                    ]}
                                    style={{marginRight:"10px"}}
                                />
                                Country 
                                <Select                                
                                    showSearch
                                    placeholder="Search the country"
                                    options={countries}
                                    onChange={changeCountry}
                                    style={{marginRight:"10px", width:"200px"}}
                                />
                            
                                Date Range
                                <RangePicker 
                                    defaultValue={dateRange} 
                                    onChange={setDateRange} 
                                    style={{marginRight:"10px"}}
                                />
                            </Space>                            
                        </Row>
                        <Row style={{marginTop:"10px"}}>
                            <Space direction="horizontal">
                                Date Sort
                                <Select
                                    defaultValue="DESC"
                                    onChange={changeSortType}
                                    options={[
                                        { value: "DESC", label: "Descending" },
                                        { value: "ASC", label: "Ascending" }
                                    ]}
                                    style={{marginRight:"20px"}}
                                />
                                <Checkbox onChange={changeIncludeShipped} style={{marginRight:"20px"}}>
                                    Include shipped?
                                </Checkbox>
                                {/* <Checkbox onChange={changeSignRequired}>Signature required?</Checkbox>  */}
                            </Space>                            
                        </Row>                                               
                    </Col>
                    <Col md={2}>
                        <Button style={{float:"right",width:"100%", height:"100%"}} onClick={refreshSearch} type="primary">Search</Button>
                    </Col>
                </Row>
                <Row style={{marginTop:"15px"}}>
                    <Col md={24}>
                        <Spin spinning={isLoading}>
                            <Card title="Orders Containing Searched Item">
                                <Button style={{float:"left", marginBottom:"20px"}} type="primary" onClick={selectAll}>
                                    Select All
                                </Button>
                                <Button style={{float:"right",marginBottom:"20px"}} type="primary" onClick={openPrintConfirm}>
                                    Print Labels for Selected Orders
                                </Button>
                                
                                <Table 
                                    dataSource={orders}
                                    columns={columns}
                                    // rowSelection={{
                                    //     type: selectionType,
                                    //     ...rowSelection,
                                    // }}
                                    pagination={false}
                                />
                                <Pagination       
                                    current={current}                            
                                    showSizeChanger
                                    onShowSizeChange={onPageSizeChange}
                                    onChange={onPageChange}
                                    defaultCurrent={1}
                                    total={totalRows}
                                />

                                <Button style={{float:"left", marginTop:"20px"}} type="primary" onClick={selectAll}>
                                    Select All
                                </Button>
                                <Button style={{float:"right", marginTop:"20px"}} type="primary" onClick={openPrintConfirm}>
                                    Print Labels for Selected Orders
                                </Button>
                            </Card>                            
                        </Spin>                       
                    </Col>
                </Row>
            </Col>
            <Col md={2}></Col>
        </Row>

        <Modal
            visible={isModalOpen}
            onCancel={()=>{setIsModalOpen(false);}}
            onOk={startPrinting}
            width={1000}
            footer={[
                <Button type="primary" onClick={() =>{setIsModalOpen(false)}}>Close</Button>
            ]}
        >
            {/*             
            CONFIRM PRINT. {JSON.stringify(selectedRowKeys)}            
            <ol>
                {selectedOrders.map(s => 
                    <li>{s.id + "," + s.customerEmail}</li>
                )}
            </ol> 
            */}
            
            <Row>
                <Col md={24}>
                    <Card title="Confirm Creating Bulk Shipments">
                    <p>
                        Selected Orders: {selectedOrders.map(s => <Tag>{s.orderNumber}</Tag>)}
                    </p>
                    <Row>
                        <Col md={24}>
                            <Form
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                            >
                                <Form.Item
                                    label="Courier"
                                    name="courier"   
                                    initialValue={1}
                                    hidden                                 
                                >
                                    <Select                                 
                                        defaultValue={1}
                                        options={courierOptions}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Shipper"
                                    name="shipper"  
                                    initialValue={1} 
                                >
                                    <Select 
                                        defaultValue={1}
                                        options={shippers}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label="Contents"
                                    name="content"  
                                    initialValue={contents}
                                    rules={[{ required: true, message: 'Please input package contents descripton' }]}
                                >
                                    <Input placeholder="Contents description"/>
                                </Form.Item>                           
                                <Form.Item
                                    label="Fedex Packaging"
                                    name="fedexPackaging"  
                                    initialValue={"YOUR_PACKAGING"} 
                                >
                                    <Select                                        
                                        options={[
                                            { value: "YOUR_PACKAGING", label: "Your Packaging" },
                                            { value: "FEDEX_PAK", label: "Fedex Packaging" }
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    initialValue={category}
                                    label="Item Category"
                                    name="category"  
                                    rules={[{ required: true, message: 'Please input item category' }]}
                                >
                                    <Input placeholder="Books, Toys, etc..."/>
                                </Form.Item>
                                <Form.Item                                    
                                    label="HS Code"
                                    name="hsCode"  
                                    initialValue={hsCode}
                                    rules={[{ required: true, message: 'Please input HS Code' }]}
                                >
                                    <Input placeholder="0000.00.0000"/>
                                </Form.Item>
                                <Row>
                                    {/* <Col md={8}>
                                        <Form.Item
                                            label="Signature Required?"
                                            name="signature"  
                                            valuePropName="checked"
                                            initialValue={false}
                                        >
                                            <Switch defaultChecked onChange={(checked)=>{ console.log(`switch to ${checked}`);}} />
                                        </Form.Item>
                                    </Col> */}
                                    <Col md={8}>
                                        <Form.Item
                                            label="Notify Shopify?"
                                            name="shopifyNotification"  
                                            valuePropName="checked"
                                            initialValue={true}
                                        >
                                            <Switch defaultChecked onChange={(checked)=>{ console.log(`switch to ${checked}`);}} />
                                        </Form.Item>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Item
                                            label="Weight"
                                            name="weight"  
                                            initialValue={0.5}
                                            rules={[{ required: true, message: 'Please input weight' }]}
                                        >
                                            <Input placeholder="0.00" addonAfter="KG"/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={12}>
                                        <Form.Item
                                            label="Declared Value"
                                            name="declaredValue"                                             
                                            initialValue={declaredValue} 
                                            rules={[{ required: true, message: 'Please input declared value' }]}
                                        >
                                            <Input disabled placeholder="0.00" addonBefore="USD"/>
                                        </Form.Item>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Item
                                            label="Packing Cost (Optional)"
                                            name="packingCost"
                                            style={{marginLeft:"5px"}}  
                                            initialValue={0.00}
                                            rules={[{ required: false, message: 'Please input packing cost' }]}
                                        >
                                            <Input placeholder="0.00" addonBefore="USD"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={8}>
                                        <Form.Item
                                            label="Package Height"
                                            name="packingHeight"
                                            style={{marginLeft:"5px"}}  
                                            initialValue={10.00}
                                            rules={[{ required: true, message: 'Please input packing height' }]}
                                        >
                                            <Input placeholder="10.00" addonAfter="cm"/>
                                        </Form.Item>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Item
                                            label="Package Width"
                                            name="packingWidth"
                                            style={{marginLeft:"5px"}}  
                                            initialValue={10.00}
                                            rules={[{ required: true, message: 'Please input packing width' }]}
                                        >
                                            <Input placeholder="10.00" addonAfter="cm"/>
                                        </Form.Item>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Item
                                            label="Package Depth"
                                            name="packingDepth"
                                            style={{marginLeft:"5px"}}  
                                            initialValue={10.00}
                                            rules={[{ required: true, message: 'Please input packing depth' }]}
                                        >
                                            <Input placeholder="10.00" addonAfter="cm"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Button 
                                        type="primary" 
                                        htmlType="submit"
                                        style={{float:"left", height:"50px", width:"100%"}}
                                    >
                                        Print Labels for {selectedOrders.length} Orders 
                                    </Button>
                                </Row>
                            </Form>
                        </Col>                       
                        </Row>                                           
                    </Card>
                </Col>
            </Row>           
        </Modal>

        <Modal
            visible={isResultOpen}
            onCancel={()=>{setIsResultOpen(false);}}    
            onOk={() =>{ setIsResultOpen(false); setIsModalOpen(false);}}
            width={900}   
            footer={[
                <Button type="primary" onClick={() =>{ setIsResultOpen(false); setIsModalOpen(false)}}>Close</Button>
            ]}     
        >
            <br/>
            <Row>
                <Col md={24}>
                    <Spin spinning={isResultLoading}>
                        <Descriptions  title="Orders and Results" bordered>
                            <Descriptions.Item label="Order Number" span={3}>Tracking Number/Status</Descriptions.Item>
                            {
                                bulkShipResult.map(b => 
                                    <Descriptions.Item label={b.orderNumber} span={3}>
                                        {b.response?.data?.detail || b.response?.message || b.response.trackingNumber}
                                    </Descriptions.Item>
                                )
                            }
                        </Descriptions>
                    </Spin>
                </Col>
            </Row>          
        </Modal>
    </>);
}

function sleep(ms) { 
    return new Promise(resolve => setTimeout(resolve, ms)); 
}

export default BulkLabelPrinting;