import moment from "moment";
import momentTz from "moment-timezone";
import errorHelper from "./errorHelper";
import kodNegara from "kod-negara";

export const getStateProvinceCode = (provinceName, countryCode) => {
    const ca = {
        Alberta: "AB",
        "British Columbia": "BC",
        Manitoba: "MB",
        "New Brunswick": "NB",
        Newfoundland: "NL",
        "Northwest Territories": "NT",
        "Nova Scotia": "NS",
        Nunavut: "NU",
        Ontario: "ON",
        "Prince Edward Island": "PE",
        Quebec: "QC",
        Saskatchewan: "SK",
        Yukon: "YT",
    };
    const us = {
        Alabama: "AL",
        Alaska: "AK",
        Arizona: "AZ",
        Arkansas: "AR",
        California: "CA",
        Colorado: "CO",
        Connecticut: "CT",
        Delaware: "DE",
        "District of Columbia": "DC",
        Florida: "FL",
        Georgia: "GA",
        Hawaii: "HI",
        Idaho: "ID",
        Illinois: "IL",
        Indiana: "IN",
        Iowa: "IA",
        Kansas: "KS",
        Kentucky: "KY",
        Louisiana: "LA",
        Maine: "ME",
        Maryland: "MD",
        Massachusetts: "MA",
        Michigan: "MI",
        Minnesota: "MN",
        Mississippi: "MS",
        Missouri: "MO",
        Montana: "MT",
        Nebraska: "NE",
        Nevada: "NV",
        "New Hampshire": "NH",
        "New Jersey": "NJ",
        "New Mexico": "NM",
        "New York": "NY",
        "North Carolina": "NC",
        "North Dakota": "ND",
        Ohio: "OH",
        Oklahoma: "OK",
        Oregon: "OR",
        Pennsylvania: "PA",
        "Rhode Island": "RI",
        "South Carolina": "SC",
        "South Dakota": "SD",
        Tennessee: "TN",
        Texas: "TX",
        Utah: "UT",
        Vermont: "VT",
        Virginia: "VA",
        "Washington State": "WA",
        "West Virginia": "WV",
        Wisconsin: "WI",
        Wyoming: "WY",
        "Puerto Rico": "PR",
    };
    const mx = {
        Aguascalientes: "AG",
        "Baja California": "BC",
        "Baja California Sur": "BS",
        Campeche: "CM",
        Chiapas: "CS",
        Chihuahua: "CH",
        "Ciudad de México": "DF",
        Coahuila: "CO",
        Colima: "CL",
        Durango: "DG",
        "Estado de México": "EM",
        Guanajuato: "GT",
        Guerrero: "GR",
        Hidalgo: "HG",
        Jalisco: "JA",
        Michoacán: "MI",
        Morelos: "MO",
        Nayarit: "NA",
        "Nuevo León": "NL",
        Oaxaca: "OA",
        Puebla: "PU",
        Querétaro: "QE",
        "Quintana Roo": "QR",
        "San Luis Potosí": "SL",
        Sinaloa: "SI",
        Sonora: "SO",
        Tabasco: "TB",
        Tamaulipas: "TM",
        Tlaxcala: "TL",
        Veracruz: "VE",
        Yucatán: "YU",
        Zacatecas: "ZA",
    };
    const ae = {
        "Abu Dhabi": "AB",
        Ajman: "AJ",
        Dubai: "DU",
        Fujairah: "FU",
        "Ras al-Khaimah": "RA",
        Sharjah: "SH",
        "Umm al-Quwain": "UM",
    };
    const india = {
        "Andaman & Nicobar (U.T)": "AN",
        "Andhra Pradesh": "AP",
        "Arunachal Pradesh": "AR",
        Assam: "AS",
        Bihar: "BR",
        Chattisgarh: "CG",
        "Chandigarh (U.T.)": "CH",
        "Daman & Diu (U.T.)": "DD",
        "Delhi (U.T.)": "DL",
        "Dadra and Nagar Haveli (U.T.)": "DN",
        Goa: "GA",
        Gujarat: "GJ",
        Haryana: "HR",
        "Himachal Pradesh": "HP",
        "Jammu & Kashmir": "JK",
        Jharkhand: "JH",
        Karnataka: "KA",
        Kerala: "KL",
        "Lakshadweep (U.T)": "LD",
        "Madhya Pradesh": "MP",
        Maharashtra: "MH",
        Manipur: "MN",
        Meghalaya: "ML",
        Mizoram: "MZ",
        Nagaland: "NL",
        Orissa: "OR",
        Punjab: "PB",
        "Puducherry (U.T.)": "PY",
        Rajasthan: "RJ",
        Sikkim: "SK",
        "Tamil Nadu": "TN",
        Tripura: "TR",
        Uttaranchal: "UA",
        "Uttar Pradesh": "UP",
        "West Bengal": "WB",
    };

    if (countryCode === "IN") {
        return india[provinceName];
    } else if (countryCode === "CA") {
        return ca[provinceName];
    } else if (countryCode === "MX") {
        return mx[provinceName];
    } else if (countryCode === "AE") {
        return ae[provinceName];
    } else if (countryCode === "US") {
        return us[provinceName];
    } else {
        return countryCode;
    }
};
export const truncateText = (str, n) => {
    if(!str)
        return;

    return (str.length > n) ? str.slice(0, n-1) + '...' : str;
};

export const twoDecimal = (number) => (Math.round(number * 100) / 100).toFixed(2);

export const numberWithCommas = (x) => {
    return x.toLocaleString();
};

export const generateIdWithChar = () => {
    const charSet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var randomString = "";
    for (let i = 0; i < 4; i++) {
        const randomPoz = Math.floor(Math.random() * charSet.length);
        randomString += charSet.substring(randomPoz, randomPoz + 1);
    }

    return `#${randomString}_${(Math.random() * (10000 - 1000) + 1000).toFixed(0)}`;
};

export const alertUndefinedObj = (object, messageForUndefined = "has undef") => {
    const obj = { ...object };
    for (const key in obj) {
        if (obj[key] === undefined) {
            alert(`${key} ${messageForUndefined}`);
            return true;
        }
    }
};

export const gramToKg = (gram) => {
    return gram / 1000;
};

export const generateRandom = (min, max) => {
    const random = Math.random() * (max - min) + min;
    return random.toFixed(0);
};
// activeCurrency: String = the activated currency in settings
// allRates: Object = all USD base rates that u get from currencyExchangeApi
// responseRate: String = the currecy code Eg. MYR, KRW, USD u get from BE responses
// value: Number = the value u get from BE responses
export const currencyExchangeFunc = (activeCurrency, respondedCurrency, allRates, value) => {
    const ratio = Number(value) / Number(allRates[respondedCurrency]);

    if (activeCurrency === "USD") {
        return Number(ratio);
    } else {
        return Number(ratio) * Number(allRates[activeCurrency]);
    }
};

export const validFunc = (key, arr) => {
    return !arr?.map((item) => Number(item[key])).some((item) => isNaN(item));
};

export const getCountryObj = (countryName) => {
    return kodNegara.findOne("countryNameEn", countryName);
};

export const getCountryCode = (countryName) => {
    if (countryName?.toLowerCase() === "united states") {
        return kodNegara.findOne("countryNameEn", "United States of America")?.countryCode;
    } else {
        return kodNegara.findOne("countryNameEn", countryName)?.countryCode;
    }
};

export const getCountryNameWithCode = (countryName) => {
    const country = kodNegara.findOne("countryNameEn", countryName);
    return `${country["countryNameEn"]} (${country["countryCode"]})`;
};

export const formatToCurrency = (currency, amount) => {
    if (currency.toUpperCase() === "KRW") {
        return String(currency) + " " + Number(amount).toLocaleString();
    } else {
        return String(currency) + " " + Number(amount).toFixed(2).toLocaleString();
    }
};

export const genId = () => Math.random().toString(36).substr(2, 9);

export const check2ArrayPermission = (arrPermissionAccount = [], arrPermissionFeature = []) => {
    if (arrPermissionFeature.length === 0) {
        return true;
    }
    return arrPermissionFeature.some((per) => arrPermissionAccount.includes(per));
};

export const checkRuleKey = (ruleCheck, arrKey = []) => {
    if (arrKey.length === 0) {
        return true;
    }

    return arrKey.find((rule) => rule === ruleCheck);
};

export const getPeriod = (expired) => {
    const expiredMoment = moment(expired).startOf("date");
    const now = moment().startOf("date");
    const years = expiredMoment.diff(now, "years");
    const months = expiredMoment.diff(now, "months") - years * 12;
    let period = "";
    if (expiredMoment.isSameOrBefore(now)) {
        return "Expired";
    }
    if (years > 0) {
        period = `${years} year${years > 1 ? "s" : ""}`;
    }
    if (months > 0) {
        period += `${period ? " " : ""}${months} month${months > 1 ? "s" : ""}`;
    }
    if (years === 0 && months === 0) {
        period = "less than 1 month";
    }
    return period;
};

export const getYearMonthPassportRemaining = (expired) => {
    const expiredMoment = moment(expired).startOf("date");
    const now = moment().startOf("date");
    const years = expiredMoment.diff(now, "years");
    const months = expiredMoment.diff(now, "months") - years * 12;

    return {
        years,
        months,
    };
};

export const formatCurrency = (value, separate = ",", decimal = 2) =>
    value
        ? value
              .toFixed(decimal)
              .replace(/\d(?=(\d{3})+(?!\d))/g, `$&${separate}`)
              .replace(".00", "")
        : value;

export const removeBlankNull = (objValue) => {
    const value = { ...objValue };
    // eslint-disable-next-line no-restricted-syntax
    for (const param in value) {
        if (value[param] === null || value[param] === "") {
            delete value[param];
        } else if (Array.isArray(value[param]) && value[param].length === 0) {
            delete value[param];
        }
    }
    return value;
};

export const renderPrice = (cell) => {
    if (cell === null || cell === undefined || cell === "") {
        return "";
    }
    return `${formatCurrency(cell)} 円`;
};
export const renderDate = (cell) => moment(cell).format("YYYY-M-DD HH:mm:ss");

export const disabledEndDate = (current, startTime) => {
    if (!current || !startTime) {
        return false;
    }
    const startDate = startTime.clone();
    return current < startDate.startOf("day");
};

export function disabledBeforeNow(current) {
    // Can not select days before today
    return current && current < moment().startOf("day");
}
export function handleErrorCallback(api, callback = () => null) {
    // Can not select days before today
    api.catch((err) => {
        errorHelper(err);
    }).finally(() => {
        callback();
    });
}

export const getProvince = (countryCode) => {
    if (countryCode === "IN") {
        return [
            {
                name: "Andhra Pradesh",
                code: "AP",
            },
            {
                name: "Arunachal Pradesh",
                code: "AR",
            },
            {
                name: "Assam",
                code: "AS",
            },
            {
                name: "Bihar",
                code: "BR",
            },
            {
                name: "Chhattisgarh",
                code: "CT",
            },
            {
                name: "Goa",
                code: "GA",
            },
            {
                name: "Gujarat",
                code: "GJ",
            },
            {
                name: "Haryana",
                code: "HR",
            },
            {
                name: "Himachal Pradesh",
                code: "HP",
            },
            {
                name: "Jammu and Kashmir",
                code: "JK",
            },
            {
                name: "Jharkhand",
                code: "JH",
            },
            {
                name: "Karnataka",
                code: "KA",
            },
            {
                name: "Kerala",
                code: "KL",
            },
            {
                name: "Madhya Pradesh",
                code: "MP",
            },
            {
                name: "Maharashtra",
                code: "MH",
            },
            {
                name: "Manipur",
                code: "MN",
            },
            {
                name: "Meghalaya",
                code: "ML",
            },
            {
                name: "Mizoram",
                code: "MZ",
            },
            {
                name: "Nagaland",
                code: "NL",
            },
            {
                name: "Odisha",
                code: "OR",
            },
            {
                name: "Punjab",
                code: "PB",
            },
            {
                name: "Rajasthan",
                code: "RJ",
            },
            {
                name: "Sikkim",
                code: "SK",
            },
            {
                name: "Tamil Nadu",
                code: "TN",
            },
            {
                name: "Telangana",
                code: "TG",
            },
            {
                name: "Tripura",
                code: "TR",
            },
            {
                name: "Uttar Pradesh",
                code: "UP",
            },
            {
                name: "Uttarakhand",
                code: "UT",
            },
            {
                name: "West Bengal",
                code: "WB",
            },
            {
                name: "Andaman and Nicobar Islands",
                code: "AN",
            },
            {
                name: "Chandigarh",
                code: "CH",
            },
            {
                name: "Dadra and Nagar Haveli",
                code: "DN",
            },
            {
                name: "Daman and Diu",
                code: "DD",
            },
            {
                name: "Lakshadweep",
                code: "LD",
            },
            {
                name: "National Capital Territory of Delhi",
                code: "DL",
            },
            {
                name: "Puducherry",
                code: "PY",
            },
        ];
    }
    if (countryCode === "CA") {
        return [
            {
                name: "Alberta",
                code: "AB",
            },
            {
                name: "British Columbia",
                code: "BC",
            },
            {
                name: "Manitoba",
                code: "MB",
            },
            {
                name: "New Brunswick",
                code: "NB",
            },
            {
                name: "Newfoundland and Labrador",
                code: "NL",
            },
            {
                name: "Nova Scotia",
                code: "NS",
            },
            {
                name: "Nunavut",
                code: "NU",
            },
            {
                name: "Northwest Territories",
                code: "NT",
            },
            {
                name: "Ontario",
                code: "ON",
            },
            {
                name: "Prince Edward Island",
                code: "PE",
            },
            {
                name: "Quebec",
                code: "QC",
            },
            {
                name: "Saskatchewan",
                code: "SK",
            },
            {
                name: "Yukon",
                code: "YT",
            },
        ];
    }
    if (countryCode === "US") {
        return [
            {
                name: "Alabama",
                code: "AL",
            },
            {
                name: "Alaska",
                code: "AK",
            },
            {
                name: "Arizona",
                code: "AZ",
            },
            {
                name: "Arkansas",
                code: "AR",
            },
            {
                name: "California",
                code: "CA",
            },
            {
                name: "Colorado",
                code: "CO",
            },
            {
                name: "Connecticut",
                code: "CT",
            },
            {
                name: "District of Columbia",
                code: "DC",
            },
            {
                name: "Delaware",
                code: "DE",
            },
            {
                name: "Florida",
                code: "FL",
            },
            {
                name: "Georgia",
                code: "GA",
            },
            {
                name: "Hawaii",
                code: "HI",
            },
            {
                name: "Idaho",
                code: "ID",
            },
            {
                name: "Illinois",
                code: "IL",
            },
            {
                name: "Indiana",
                code: "IN",
            },
            {
                name: "Iowa",
                code: "IA",
            },
            {
                name: "Kansas",
                code: "KS",
            },
            {
                name: "Kentucky",
                code: "KY",
            },
            {
                name: "Louisiana",
                code: "LA",
            },
            {
                name: "Maine",
                code: "ME",
            },
            {
                name: "Maryland",
                code: "MD",
            },
            {
                name: "Massachusetts",
                code: "MA",
            },
            {
                name: "Michigan",
                code: "MI",
            },
            {
                name: "Minnesota",
                code: "MN",
            },
            {
                name: "Mississippi",
                code: "MS",
            },
            {
                name: "Missouri",
                code: "MO",
            },
            {
                name: "Montana",
                code: "MT",
            },
            {
                name: "Nebraska",
                code: "NE",
            },
            {
                name: "Nevada",
                code: "NV",
            },
            {
                name: "New Hampshire",
                code: "NH",
            },
            {
                name: "New Jersey",
                code: "NJ",
            },
            {
                name: "New Mexico",
                code: "NM",
            },
            {
                name: "New York",
                code: "NY",
            },
            {
                name: "North Carolina",
                code: "NC",
            },
            {
                name: "North Dakota",
                code: "ND",
            },
            {
                name: "Ohio",
                code: "OH",
            },
            {
                name: "Oklahoma",
                code: "OK",
            },
            {
                name: "Oregon",
                code: "OR",
            },
            {
                name: "Pennsylvania",
                code: "PA",
            },
            {
                name: "Rhode Island",
                code: "RI",
            },
            {
                name: "South Carolina",
                code: "SC",
            },
            {
                name: "South Dakota",
                code: "SD",
            },
            {
                name: "Tennessee",
                code: "TN",
            },
            {
                name: "Texas",
                code: "TX",
            },
            {
                name: "Utah",
                code: "UT",
            },
            {
                name: "Vermont",
                code: "VT",
            },
            {
                name: "Virginia",
                code: "VA",
            },
            {
                name: "Washington",
                code: "WA",
            },
            {
                name: "West Virginia",
                code: "WV",
            },
            {
                name: "Wisconsin",
                code: "WI",
            },
            {
                name: "Wyoming",
                code: "WY",
            },
            {
                name: "American Samoa",
                code: "AS",
            },
            {
                name: "Guam",
                code: "GU",
            },
            {
                name: "Northern Mariana Islands",
                code: "MP",
            },
            {
                name: "Puerto Rico",
                code: "PR",
            },
            {
                name: "United States Minor Outlying Islands",
                code: "UM",
            },
            {
                name: "Virgin Islands",
                code: "VI",
            },
        ];
    }
    if (countryCode === "MX") {
        return [
            {
                name: "Aguascalientes",
                code: "AG",
            },
            {
                name: "Baja California",
                code: "BC",
            },
            {
                name: "Baja California Sur",
                code: "BS",
            },
            {
                name: "Campeche",
                code: "CM",
            },
            {
                name: "Chiapas",
                code: "CS",
            },
            {
                name: "Chihuahua",
                code: "CH",
            },
            {
                name: "Coahuila",
                code: "MX",
            },
            {
                name: "Colima",
                code: "CL",
            },
            {
                name: "Federal District",
                code: "DF",
            },
            {
                name: "Durango",
                code: "DG",
            },
            {
                name: "Guanajuato",
                code: "GT",
            },
            {
                name: "Guerrero",
                code: "GR",
            },
            {
                name: "Hidalgo",
                code: "HG",
            },
            {
                name: "Jalisco",
                code: "JA",
            },
            {
                name: "Mexico",
                code: "ME",
            },
            {
                name: "Michoacán",
                code: "MI",
            },
            {
                name: "Morelos",
                code: "MO",
            },
            {
                name: "Nayarit",
                code: "NA",
            },
            {
                name: "Nuevo León",
                code: "NL",
            },
            {
                name: "Oaxaca",
                code: "OA",
            },
            {
                name: "Puebla",
                code: "PU",
            },
            {
                name: "Querétaro",
                code: "QE",
            },
            {
                name: "Quintana Roo",
                code: "QR",
            },
            {
                name: "San Luis Potosí",
                code: "SL",
            },
            {
                name: "Sinaloa",
                code: "SI",
            },
            {
                name: "Sonora",
                code: "SO",
            },
            {
                name: "Tabasco",
                code: "TB",
            },
            {
                name: "Tamaulipas",
                code: "TM",
            },
            {
                name: "Tlaxcala",
                code: "TL",
            },
            {
                name: "Veracruz",
                code: "VE",
            },
            {
                name: "Yucatán",
                code: "YU",
            },
            {
                name: "Zacatecas",
                code: "ZA",
            },
        ];
    }
    if (countryCode === "AE") {
        return [
            {
                name: "Abu Dhabi",
                code: "AB",
            },
            {
                name: "Ajman",
                code: "AJ",
            },
            {
                name: "Dubai",
                code: "DU",
            },
            {
                name: "Fujairah",
                code: "FU",
            },
            {
                name: "Ras al-Khaimah",
                code: "RA",
            },
            {
                name: "Sharjah",
                code: "SH",
            },
            {
                name: "Umm al-Quwain",
                code: "UM",
            },
        ];
    }
};

export { moment, momentTz };
