import React, { useEffect, useState } from "react";
import { List, Button, Typography, Divider, Collapse } from "antd";
import { ListHeader } from "./index.style";
import moment from "moment";
import { Link } from "react-router-dom";
import Breadcrumbs from "commons/breadcrumbs";
import ordersApi from "apis/ordersApi";
import LinkButton from "components/linkButton/LinkButton";
import Path from "../../commons/breadcrumbs";
import purchaseApi from "apis/purchaseApi";
import productsApi from "apis/productsApi";

const { Title, Paragraph } = Typography;

const ProductList = ({
  changePageHandler,
  data,
  params,
  isLoading,
  setIsLoading,
  makePurchaseHandler,
  setActiveVariant,
  setIsModal,
}) => {
  const { products } = data;
  const [orderVarObj, setOrderVarObj] = useState({});

  // const productsIdHaveOrdersArray = products
  //   ?.filter((prod) => prod.orders.length > 0)
  //   ?.map((prod) => prod.id);

  const openModal = async (variants) => {
    console.log(variants);
    setIsLoading(true);
    try {
      const res = await Promise.all(
        variants?.map((item) =>
          productsApi.getProductVariant(item.id).then(({ variant }) => variant)
        )
      );
      console.log("VARIANT RESPONSE", res);
      const draft = res.map((item) => {
        return {
          key: item.id,
          id: item.id,
          title: item.title,
          in_stock: item.in_stock,
          quantity: 0,
        };
      });
      setActiveVariant(draft);
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
      setIsModal(true);
    }
  };

  const getOrderVariant = async () => {
    const params = {
      page: 1,
      pageSize: 100000,
      includeLineItems: false,
      combinedOrders: false,
      // filter: `lineItems.productId_in=[${productsIdHaveOrdersArray}]`,
      fields: ["id", "orderNumber"],
      groupByProductId: true,
    };
    try {
      const res = await ordersApi.getOrders(params);
      setOrderVarObj(res);
    } catch (error) {}
  };

  console.log("ORDERVAR", orderVarObj[1278]);

  // useEffect(() => {
  //   if (!!productsIdHaveOrdersArray?.length) {
  //     getOrderVariant();
  //   }
  // }, [products]);

  console.log(data?.totalCount);
  return (
    <>
      <List
        loading={isLoading}
        itemLayout="vertical"
        size="large"
        split={true}
        pagination={{
          onChange: changePageHandler,
          total: data?.totalCount,
          showTotal: (total, range) =>
            `Showing ${range[0]}-${range[1]} of ${total} items`,
          pageSize: params.pageSize,
          defaultPageSize: 10,
          style: { margin: "10px 0" },
          current: params.page,
        }}
        dataSource={products}
        renderItem={(product, index) => (
          <List.Item
            key={index}
            // actions={[
            //   <Button size="medium" onClick={() => makePurchaseHandler(index)}>
            //     Make Purchase
            //   </Button>,
            //   <Button size="medium" onClick={() => openModal(product.variants)}>
            //     Receive Stock
            //   </Button>,
            //   // <Button size="medium">Edit Details</Button>,
            //   // <Button size="medium">Print Label</Button>,
            // ]}
            // extra={
            //   product.shopifyId ? (
            //     <img
            //       style={{ objectFit: "cover" }}
            //       width={280}
            //       height={220}
            //       alt="productImage"
            //       src={product.shopifyProduct?.image?.src || "/NOIMAGE.png"}
            //     />
            //   ) : (
            //     <img
            //       style={{ objectFit: "cover" }}
            //       width={280}
            //       height={220}
            //       alt="productImage"
            //       src="/NOIMAGE.png"
            //     />
            //   )
            // }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "2px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Paragraph
                  style={{
                    borderLeft: `20px solid ${
                      product.shopifyId ? "#96bf48" : "black"
                    }`,
                    paddingLeft: "10px",
                    margin: 0,
                  }}
                >
                  {product.shopifyId ? "SHOPIFY ID" : "WARE ID"}
                </Paragraph>

                <Divider type="vertical" />

                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://harumio.myshopify.com/admin/products/${product.shopifyId}`}
                >
                  {product.shopifyId || product.id}
                </a>

                <Divider type="vertical" />

                <p style={{ margin: 0, color: "#a7a7a7" }}>
                  Created At {moment(product.createdAt).format("DD-MM-YYYY")}
                </p>
              </div>
            </div>
            <ListHeader>
              <Link to={Breadcrumbs.PRODUCT_DETAIL.pathParam(product.id)}>
                <Title
                  style={{
                    maxWidth: "40ch",
                    overflow: "hidden",
                    height: "1.4em",
                  }}
                  level={3}
                >
                  {product.title}
                </Title>
              </Link>
            </ListHeader>

            {/* {product.variants.length > 0 ? (
              product.variants.map((variant, index) => {
                return (
                  <Collapse.Panel header="" key={index}>
                    {variant.id} |{" "}
                    {variant.title.toUpperCase() || product.title}
                  </Collapse.Panel>
                );
              })
            ) : (
              <p>"No Variant Available"</p>
            )} */}

            {/* <Collapse>
              <Collapse.Panel
                header={
                  <strong>{`Product Variants (${product.variants.length})`}</strong>
                }
              >
                <div>
                  {product.variants.length > 0 ? (
                    product.variants.map((variant, index) => {
                      return (
                        <Button
                          style={{
                            margin: "5px",
                            background: "orange",
                            color: "white",
                            border: "none",
                          }}
                          size="large"
                        >
                          {variant.id} |{" "}
                          {variant.title.toUpperCase() || product.title}
                        </Button>
                      );
                    })
                  ) : (
                    <p>No Order Available</p>
                  )}
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                header={
                  !orderVarObj[product.id]?.length ? (
                    "Orders Containing Variant (Not Available)"
                  ) : (
                    <strong>{`Orders Containing Variant (${
                      orderVarObj[product.id]?.length
                    })`}</strong>
                  )
                }
                disabled={!orderVarObj[product.id]?.length}
              >
                <div>
                  {orderVarObj[product.id]?.map((item) => {
                    return (
                      <LinkButton
                        style={{
                          margin: "5px",
                          background: "orange",
                          color: "white",
                          border: "none",
                        }}
                        size="large"
                        to={`${Path.ORDER_DETAIL.pathParam(item.id)}`}
                      >
                        {item.orderNumber}
                      </LinkButton>
                    );
                  })}
                </div>
              </Collapse.Panel>
            </Collapse> */}
          </List.Item>
        )}
      />
    </>
  );
};

export default ProductList;
