import { Avatar, Button, Row, Col, List, Typography, message, Spin, Upload, Card, Space, Checkbox, Table, Select, DatePicker } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Path from "../../commons/breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import Box from "components/box/Box";
import { useTranslation } from "react-i18next";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import { Icon } from "@iconify/react";
import moment from "moment";
import ordersApi from "apis/ordersApi";
import axios from "axios";

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;
 /*
    OrderId	OrderDate	ShippingDate	TrackingNumber.	LineItemName	LineItemQuantity	
    LineItemPrice	OrderPrice	CustomerName	SupplierPrice	ShippingPrice	AccountingStatus	
    ShopifyId	RequireShipping	FinancialStatus	ItemRefund	NonItemRefund
*/


const OrdersTransactions = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [isFileLoading, setIsFileLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const [dateRange, setDateRange] = useState([moment().subtract(1, "days"), moment()]);
    const [totalRows, setTotalRows] = useState(0);
    const [filter, setFilter] = useState('none');
    const [txnFiles, setTxnFiles] = useState([]);

    const columns = [
        {
            title: '#',
            dataIndex: 'OrderId',
            key: 'OrderId',
            render: (item, record, index) => {
                return (<>{index+1}</>);
            }
        },
        {
            title: 'ID',
            dataIndex: 'orderId',
            key: 'orderId'
        },
        {
            title: 'Order #',
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            render: (item, record) => {
                return(<Link to={Path.ORDER_DETAIL.pathParam(record.orderId)}>{item}</Link>);
            }
        },
        /*
        {
            title: 'Shopify Id',
            dataIndex: 'shopifyId',
            key: 'shopifyId',
        },
        */
        {
            title: 'Order Date (KST)',
            dataIndex: 'orderDate',
            key: 'orderDate',
            render: (item) => {
                return(<>{item? moment(item).utcOffset("+0900").format("YYYY-MM-DD HH:mm"): ''}</>)
            }
        },
        {
            title: 'Shipping Date (KST)',
            dataIndex: 'shippingDate',
            key: 'shippingDate',
            render: (item) => {
                return(<>{item? moment(item).utcOffset("+0900").format("YYYY-MM-DD HH:mm"): ''}</>)
            }
        },
        {
            title: 'Tracking #',
            dataIndex: 'trackingNumber',
            key: 'trackingNumber',
        },
        {
            title: 'Customer Name',
            dataIndex: 'customerName',
            key: 'customerName',
        },
        {
            title: 'Item Name',
            dataIndex: 'lineItemName',
            key: 'lineItemName',
        },
        {
            title: 'Item Quantity',
            dataIndex: 'lineItemQty',
            key: 'lineItemQty',
        },
        {
            title: 'Require Shipping',
            dataIndex: 'requiresShipping',
            key: 'requiresShipping',
            render: (item) => {
                return(<Checkbox checked={item}/>)
            }
        },
        {
            title: 'Item Price',
            dataIndex: 'lineItemPrice',
            key: 'lineItemPrice',
        },
        {
            title: 'Order Price',
            dataIndex: 'orderPrice',
            key: 'orderPrice',
        },
      
        {
            title: 'Supplier Price',
            dataIndex: 'supplierPrice',
            key: 'supplierPrice',
        },
        {
            title: 'Rated Shipping Price',
            dataIndex: 'shippingPrice',
            key: 'shippingPrice',
        },
        {
            title: 'Actual Shipping Price',
            dataIndex: 'actualShippingPrice',
            key: 'actualShippingPrice',
        },
        /*
        {
            title: 'Accounting Status',
            dataIndex: 'accountingStatus',
            key: 'accountingStatus',
        },
        */     
        {
            title: 'Financial Status',
            dataIndex: 'financialStatus',
            key: 'financialStatus',
        },
    ];

    const txnFilesCol = [
        {
            title: '#',
            dataIndex: 'OrderId',
            key: 'OrderId',
            render: (item, record, index) => {
                return (<>{index+1}</>);
            }
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Filename',
            dataIndex: 'filename',
            key: 'filename'
        },
        {
            title: 'Date Range',
            dataIndex: 'dateRange',
            key: 'dateRange',
            render: (item) => {
                return(<>{moment(item[0]).zone('+0000').format("DD-MM-YYYY")} to {moment(item[1]).zone('+0000').format("DD-MM-YYYY")}</>)
            }
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (item, product) => {
                return(<>{moment(item).format("hh:mm a, DD-MM-YYYY")}</>)
            }
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            render: (item, record) => {
                const rangeTxt = `${moment(record.dateRange[0]).zone('+0000').format("DD-MM-YYYY")}_to_${moment(record.dateRange[1]).zone('+0000').format("DD-MM-YYYY")}`;
                return(<Button type="primary" onClick={() => downloadTxnFile(item, rangeTxt)}>Download</Button>)
            }
        },
    ];

    const downloadTxnFile = (id, rangeTxt) => {
        axios.get(process.env.REACT_APP_API_URL+`/api/orders/download-txn-log/${id}`, {
            headers: {              
                "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
            },
            responseType: "blob"
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `OrdersTxns_${rangeTxt}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((err) => {
            if(err.response?.status === 404)
                alert('No order transactions found');  
        })
        .finally(() => {
            //setIsLoading(false);
        });        
    };

    const fetchData = async () => {
        //setIsLoading(true);
        const txns = await ordersApi.getTransactions(moment(dateRange[0]).format('YYYY-MM-DD'), moment(dateRange[1]).format('YYYY-MM-DD'), filter);
        setTotalRows(txns.length);
        console.log('TXNS', txns);
        setTransactions(txns);
        //setIsLoading(false);
    };

    const generateLogFile = () => {
        const minDate = moment(dateRange[0]).format('YYYY-MM-DD');
        const maxDate = moment(dateRange[1]).format('YYYY-MM-DD');
        
        ordersApi.generateTxnLog(minDate, maxDate, filter)
        .then(r => {
            console.log("Excel file generation result",r);
            if (r?.message) {
                message.info(r.message, 7);
            }
        })
        .catch(err => { 
            console.log("Excel file generation error", err)
        });
    }

    const fetchExcelData = async () => {
        //setIsLoading(true);
        axios.get(process.env.REACT_APP_API_URL+`/api/orders/get-txns/${moment(dateRange[0]).format('YYYY-MM-DD')}/${moment(dateRange[1]).format('YYYY-MM-DD')}/true?filter=`+filter,{
            headers: {              
                "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
            },
            responseType: "blob"
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `OrdersTxns_${moment().format("YYYYMMDD-HHmmss")}.xlsx`);
            document.body.appendChild(link);
            link.click();
        })
        .catch((err) => {
            if(err.response?.status === 404)
                alert('No order transactions found');  
        })
        .finally(() => {
            //setIsLoading(false);
        });        
    };
    
    const fetchTxnLogFiles = () => {
        setIsLoading(true);
        ordersApi.getTxnLogFiles()
        .then(r => {
            console.log("TXN FILES",r);
            setTxnFiles(r);
        })
        .catch(err => console.log("TXN FILES err", err))
        .finally(() => {setIsLoading(false)});
    }

    const changeFilter = (value) => {
        setFilter(value);
    }

    useEffect(async ()=>{
       fetchTxnLogFiles();
    },[])
    return(
        <>
            <TitleHeader
                listOfBreadcrumb={[
                    { name: "Home", path: "/" },
                    { name: Path.ACCOUNTING.name, path: Path.ACCOUNTING.pathname },
                    {
                        name: Path.ORDERS_TXNS.name,
                    },
                ]}
                title={t(Path.ORDERS_TXNS.name)}
            />
            <Box>
                <Container>                      
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={24}>
                                Date Range &nbsp;
                                <RangePicker defaultValue={dateRange} onChange={setDateRange} />
                                &nbsp;
                                Filter 
                                &nbsp;
                                <Select 
                                    style={{
                                        width: 250,
                                    }}
                                    onChange={changeFilter}
                                    defaultValue="none"
                                    options={[
                                        {
                                            value: "none",
                                            label: "None"
                                        },
                                        {
                                            value: "missingShipment",
                                            label: "Missing Shipments"
                                        },
                                        {
                                            value: "missingSuppPrice",
                                            label: "Missing Supplier Prices"
                                        },
                                        {
                                            value: "shipPriceAnomalies",
                                            label: "Shipments Prices Anomalies"
                                        }
                                    ]}                          
                                />  
                                &nbsp;&nbsp;
                                {/* <Button type="primary" onClick={fetchData}>Show Data</Button>
                                &nbsp; */}
                                <Button style={{backgroundColor:'#34c25a',color: 'white'}} onClick={generateLogFile}>Generate Spreadsheet File</Button>
                            </Col>
                        </Row>                       
                        <Row  gutter={[16, 16]}>                   
                            <Col xs={24} md={24} style={{marginTop:"20px"}}>
                                <Table dataSource={txnFiles} columns={txnFilesCol} loading={isLoading} pagination={false}/> 
                            </Col>                                                    
                        </Row>                    
                </Container>
            </Box>            
        </>
    );
};

export default OrdersTransactions;