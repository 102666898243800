import { Card } from "antd";
import styled from "styled-components";
import Box from "../../components/box/Box";

export const ShipCard = styled(Card)`
  box-shadow: 0px 4px 4px rgba(51, 96, 35, 0.25);
  border-radius: 10px;
  margin-bottom: 10px;
  .ant-card-head {
    background: #4fc3f7;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .ant-typography {
      color: #fff;
    }
  }
  .box-data {
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    padding-bottom: 8px;
  }
  .ant-avatar {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
    min-width: 80px;
  }
  .box-table-data {
    overflow-x: auto;
  }
  table {
    width: 100%;
    th {
      padding-left: 6px;
      padding-right: 6px;
      line-height: 19px;
    }
    td {
      text-align: center;
      font-weight: bold;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      padding-top: 16px;
    }
  }
  .actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    button {
      width: 130px;
      margin-bottom: 8px;
      /* border-radius: 10px; */
      &.purchase-btn {
        background-color: var(--success);
        border-color: var(--success);
      }
      &.edit-btn {
        background-color: #628cf9;
        border-color: #628cf9;
      }
    }
  }
  .order-containing-variants {
    h4 {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
    .ant-list {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 90px;
    }
    .ant-tag {
      color: #fff;
      background: #87d068;
      border-color: transparent;
      &.pending-purchase {
        background: #f50;
      }
      &.allocated {
        background: #87d068;
      }
      &.confirmed {
        background: #28a745;
      }
      &.partially-allocated {
        background: #adc6ff;
      }
      &.ready-to-pack {
        background: #108ee9;
      }
      &.received {
        background: #17a2b8;
      }
    }
  }
  .exports_list {
    h4 {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      color: #000000;
    }
    .ant-list {
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 90px;
    }
    .ant-tag {
      font-size: 15px;
      color: #002c8c;
      border-color: #fa8c16;
      font-style:italic;
    }
  }
`;


export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  background-color: #edf1f5;
  padding: 12px 24px;
  border-bottom: 1px solid #78828c21;
  h1.ant-typography {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
  }
  h2.ant-typography {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 6px;
  }
`;
export const ProductInfoCardStyled = styled(Card)`
  .ant-card-head {
    background: #4fc3f7;
    .ant-card-head-title {
      color: #fff;
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      color: #ffffff;
      text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
      padding-top: 11px;
      padding-bottom: 8px;
    }
  }
  &.set-details {
    margin-bottom: 16px;
    .note {
      font-size: 14px;
      line-height: 19px;
    }
  }
  &.statistics {
    ul {
      li {
        .statistic-title {
          font-weight: 600;
          font-size: 18px;
          line-height: 25px;
          padding-bottom: 8px;
        }
        .box-data {
          padding-left: 28px;
          padding-bottom: 12px;
          .label {
            font-size: 14px;
            line-height: 19px;
            color: #000000;
          }
        }
      }
    }
  }
`;

export const ProductDetailPageStyled = styled(Box)`
  .product-info .ant-card-body {
    .box-data {
      display: flex;
      padding-bottom: 12px;
      .label {
        font-weight: bold;
        font-size: 14px;
        line-height: 19px;
        color: #000000;
        padding-right: 10px;
        flex: 0 0 auto;
      }
      .data {
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        word-break: break-word;
        &.bold {
          font-weight: bold;
        }
        &.uppercase {
          text-transform: uppercase;
        }
        .ant-tag {
          border-radius: 10px;
          padding-top: 5px;
          padding-bottom: 5px;
          font-size: 12px;
          line-height: 14px;
        }
        &.description {
          background-color: #f6f6f6;
          max-height: 92px;
          overflow: auto;
          padding: 4px;
        }
      }
    }
    .featured-images {
      .box-data {
        flex-wrap: wrap;
        .label {
          flex: 0 0 100%;
          padding-bottom: 12px;
        }
      }
    }
  }
  .variants-container {
    padding-top: 8px;
    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 27px;
      color: #000000;
    }
  }
`;
