import { Button, Checkbox, Col, DatePicker, Divider, Form, Input, message, Row, Select, Tag } from "antd";
import kodNegara from "kod-negara";
import produce from "immer";
import { debounce } from "lodash";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { twoDecimal } from "utils/funcs";
import { ButtonPlacement, FormBody, FormHeader, FormWrapper } from "./index.style";
import sensitiveItems from "utils/sensitivePackage";
import productsApi from "apis/productsApi";

const { Option } = Select;

const ExportDeclare = ({ setManualChange, form, currency, detectBookCategory, piecesRecheckDataHandler, subTotal, discountCodes}) => {   
    //this is a regular expression for words not containing sensitive items.
    //the regexp will be used in formItem rules
    const sensitiveRegexp = `^(?!.*(` + sensitiveItems.join('|') + `)).*$`;
    const regex = new RegExp(sensitiveRegexp, 'i');

    const [help, setHelp] = useState({});
    const [validateStatus, setValidateStatus] = useState({});
    const [weightVal, setWeightVal] = useState();

    let tempHelp = {};
    let tempValidate = {};

    const weightUnit = "kg";
    const recheckDataHandler = debounce(() => {
        console.log("RUN RECHECKDATAHANDLER");
        const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
        if (exportDeclarationItems === undefined || exportDeclarationItems?.length === 0) {
            console.log("RUN RECHECKDATAHANDLER IF 1");

            form.setFieldsValue({
                totalDeclaredValue: 0,
                totalDeclaredWeight: 0,
                totalDeclaredGrossWeight: 0,
            });
            piecesRecheckDataHandler();
        }

        if (exportDeclarationItems?.length >= 1) {
            console.log("RUN RECHECKDATAHANDLER IF 2");
            const totalDeclaredWeight = exportDeclarationItems?.map((item) => Number(item.weight)).reduce((prev, curr) => prev + curr);

            const totalDeclaredGrossWeight = exportDeclarationItems?.map((item) => Number(item.grossWeight)).reduce((prev, curr) => prev + curr);

            const totalDeclaredValue = exportDeclarationItems?.map((item) => Number(item.totalValue)).reduce((prev, curr) => prev + curr);
            //const totalDeclaredValue = subTotal;

            console.log("TOTAL DECLARED VALUE", totalDeclaredValue);
            console.log("TOTAL DECLARED WEIGHT", totalDeclaredWeight);
            const pieces = form.getFieldValue("pieces");

            const prodPieces = produce(pieces, (draft) => {
                draft[0].weight = twoDecimal(totalDeclaredWeight);
                draft[0].grossWeight = twoDecimal(totalDeclaredGrossWeight);
                draft[0].value = twoDecimal(totalDeclaredValue);
            });
            console.log("TOTAL DECLARE WEIGHT", totalDeclaredWeight);
            setManualChange({
                status: false,
                manualValue: twoDecimal(totalDeclaredWeight),
            });
            form.setFieldsValue({
                totalDeclaredWeight: twoDecimal(totalDeclaredWeight),
                totalDeclaredGrossWeight: twoDecimal(totalDeclaredGrossWeight),
                totalDeclaredValue: twoDecimal(totalDeclaredValue),
                pieces: prodPieces,
            });
            piecesRecheckDataHandler();
        }
    }, [700]); // <-- wait for exportDeclaration Finish

    const clearInput = (field, fieldName) => {
        const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
        const newDraft = produce(exportDeclarationItems, (draft) => {
            draft[field.name][fieldName] = undefined;
        });
        return form.setFieldsValue({
            exportDeclarationItems: newDraft,
        });
    };

    const onChangeCountry = (value, index) => {
        const result = produce(form.getFieldsValue(), (draft) => {
            draft.exportDeclarationItems[index].originCountry = value.match("[a-zA-Z]+")[0];
        });
        form.setFieldsValue(result);
    };

    const categoryOnChangeHandler = (value, row) => {
        const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
        const oriDesc = exportDeclarationItems[row].description;

        if (value.toLowerCase() === "book") {
            if (!oriDesc.toLowerCase().startsWith("book")) {
                const newDesc = `BOOK ${oriDesc}`;
                const newDescription = produce(exportDeclarationItems, (draft) => {
                    draft[row].description = newDesc;
                });
                const newContents = newDescription?.map((item) => item.description).join(", ");
                form.setFieldsValue({
                    exportDeclarationItems: newDescription,
                    contents: newContents,
                });
            }
        } else if (value.toLowerCase() === "other") {
            if (oriDesc.toLowerCase().startsWith("book")) {
                const newDesc = oriDesc.slice(5, -1);
                const newDescription = produce(exportDeclarationItems, (draft) => {
                    draft[row].description = newDesc;
                });
                const newContents = newDescription?.map((item) => item.description).join(", ");
                form.setFieldsValue({
                    exportDeclarationItems: newDescription,
                    contents: newContents,
                });
            }
        }
    };

    const manualType = debounce((event) => {
        const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
        const row = event.target.id.split("_")[1];

        const newContent = exportDeclarationItems?.map((item) => item.description).join(", ");

        /*Previous code. Not sure why BOOK items set not to update form contents. Leave it here
         *meanwhile
        if (detectBookCategory(event.target.value)) {
            if (!exportDeclarationItems[row].description.toLowerCase().startsWith("book")) {
                form.setFieldsValue({
                    contents: newContent,
                });
            } 
        } else {
            form.setFieldsValue({
                contents: newContent,
            });
        }
        */
        form.setFieldsValue({
            contents: newContent,
        });        
        form.validateFields(['contents']);
    }, 500);

    // this function will be using by weight and grossWeight
    const checkWeightZero = debounce((event) => {
        const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
        const arr = event.target.id.split("_");
        const index = Number(arr[1]);
        const value = event.target.value;
        if (Number(value) <= 0) {
            const result = produce(exportDeclarationItems, (draft) => {
                draft[index].weight = twoDecimal(0);
                draft[index].grossWeight = twoDecimal(0);
            });

            form.setFieldsValue({
                exportDeclarationItems: result,
            });
        } else {
            const result = produce(exportDeclarationItems, (draft) => {
                draft[index].weight = twoDecimal(value);
                draft[index].grossWeight = twoDecimal(value);
            });

            form.setFieldsValue({
                exportDeclarationItems: result,
            });
        }

        // in case they want back 0.02
        // if (activeCourier?.apiType?.toLowerCase() === "dhl") {
        //   if (Number(value) === 0 || value === undefined || Number(value) <= 0.02) {
        //     const result = produce(exportDeclarationItems, (draft) => {
        //       draft[index].weight = "0.02";
        //       draft[index].grossWeight = "0.02";
        //     });
        //     form.setFieldsValue({
        //       exportDeclarationItems: result,
        //     });
        //   } else {
        //     const result = produce(exportDeclarationItems, (draft) => {
        //       draft[index].weight = twoDecimal(value);
        //       draft[index].grossWeight = twoDecimal(value);
        //     });
        //     form.setFieldsValue({
        //       exportDeclarationItems: result,
        //     });
        //   }
        // } else {
        //   const result = produce(exportDeclarationItems, (draft) => {
        //     draft[index].weight = twoDecimal(value);
        //     draft[index].grossWeight = twoDecimal(value);
        //   });
        //   form.setFieldsValue({
        //     exportDeclarationItems: result,
        //   });
        // }

        return recheckDataHandler();
    }, 1500);

    const quantityAndUnitPriceOnChange = debounce((event) => {
        const exportDeclarationItems = form.getFieldValue("exportDeclarationItems");
        const arr = event.target.id.split("_");
        const index = Number(arr[1]);
        const value = event.target.value;
        if (arr[2] === "quantity") {
            const unitPrice = exportDeclarationItems[index].unitPrice;
            const newTotalValue = Number(unitPrice) === 0 || isNaN(Number(unitPrice)) ? 0 : Number(unitPrice) * Number(value);
            const prod = produce(exportDeclarationItems, (draft) => {
                draft[index].totalValue = newTotalValue;
            });

            form.setFieldsValue({
                exportDeclarationItems: prod,
            });
        } else if (arr[2] === "unitPrice") {
            const quantity = exportDeclarationItems[index].quantity;
            const newTotalValue = Number(quantity) === 0 || isNaN(Number(quantity)) ? 0 : Number(quantity) * Number(value);
            const prod = produce(exportDeclarationItems, (draft) => {
                draft[index].totalValue = newTotalValue;
            });

            form.setFieldsValue({
                exportDeclarationItems: prod,
            });
        }
        recheckDataHandler();
    }, 3000);

    const saveWeight = async (item) => {        
        const variantId = form.getFieldValue("exportDeclarationItems")[item]["productVariantId"];
        const weight = form.getFieldValue("exportDeclarationItems")[item]["weight"];
             
        const payload = {
            id: variantId,
            weight: weight
        };
        productsApi.updateVariantWeight(payload)
        .then(r => {
            console.log('UPDATE RESPONSE', r);
            message.success("Variant weight updated successfully");
        })
        .catch(err => {            
            console.log('ERROR RESPONSE', err);
            message.error("Error in updating variant weight: " + err.toString());
        });
    };

    useEffect(() => {
        console.log("RUN USE EFFECT RECHECKDATA HANDLER");
        recheckDataHandler();
        setWeightVal(999);
    }, []);

    useEffect(() => {
        recheckDataHandler();
    },[subTotal]);
    return (
        <FormWrapper>
            <FormHeader className="greenBoldTitle">Export Declaration</FormHeader>
            <FormBody>
                <Row gutter={24}>
                    <Col lg={8}>
                        {/* dont simply change this name! It will broke entire logic! */}
                        <Form.Item
                            name="totalDeclaredWeight"
                            label="Total Declared Weight"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (!value || value < 0.001) {
                                            return Promise.reject(new Error("Cannot less than 0.001"));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="1.00" addonBefore={weightUnit.toUpperCase()} disabled />
                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        {/* dont simply change this name! It will broke entire logic! */}
                        <Form.Item
                            name="totalDeclaredGrossWeight"
                            label="Total Declared Gross Weight"
                            rules={[
                                () => ({
                                    validator(_, value) {
                                        if (!value || value < 0.001) {
                                            return Promise.reject(new Error("Cannot less than 0.001"));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="1.00" addonBefore={weightUnit.toUpperCase()} disabled />
                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        {/* dont simply change this name! It will broke entire logic! */}
                        <Form.Item
                            name="totalDeclaredValue"
                            label="Total Declared Value"
                            rules={[
                                {
                                    required: true,
                                    message: "Please recheck data",
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value || value < 0.001) {
                                            return Promise.reject(new Error("Cannot less than 0.001"));
                                        }
                                        return Promise.resolve();
                                    },
                                }),
                            ]}
                        >
                            <Input placeholder="1.00" addonBefore="USD" disabled />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col lg={8}>
                        <Form.Item
                            name="invoice"
                            label="Invoice Number"
                            rules={[
                                {
                                    required: true,
                                    message: "Invoice number is required",
                                },
                            ]}
                        >
                            <Input placeholder="1234567890" />
                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        <Form.Item name="invoiceDate" label="Invoice Date" initialValue={moment()}>
                            <DatePicker format="YYYY-M-DD" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.List name="exportDeclarationItems">
                    {(fields, { add, remove }) => {
                        return (
                            <Fragment>
                                <ButtonPlacement>
                                    <Button
                                        className="submit"
                                        onClick={() => {
                                            add();
                                            return recheckDataHandler();
                                        }}
                                    >
                                        Add Item
                                    </Button>
                                </ButtonPlacement>
                                {fields.map((exportItem) => {
                                    return (
                                        <Fragment key={exportItem.name}>
                                            <Divider />
                                            <p className="greenBoldTitle">Item Declaration {exportItem.name + 1}</p>

                                            <Row gutter={16}>
                                                <Col lg={24}>
                                                    <Form.Item
                                                        onChange={manualType}
                                                        name={[exportItem.name, "description"]}
                                                        help={help[exportItem.name]}
                                                        validateStatus={validateStatus[exportItem.name]}
                                                        label="Description"                                                       
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Description is required",
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    
                                                                    if(!regex.test(value)){    
                                                                        tempHelp = help;                                                                    
                                                                        tempHelp[exportItem.name] = 'CAUTION: Sensitive item detected. Handle with care';
                                                                        
                                                                        setHelp(tempHelp);
                                                                        
                                                                        tempValidate = validateStatus;
                                                                        tempValidate[exportItem.name] = 'warning';
                                                                        setValidateStatus(tempValidate);
                                                                    } else {
                                                                        tempHelp = help;
                                                                        tempHelp[exportItem.name] = '';
                                                                        
                                                                        setHelp(tempHelp);
                                                                        
                                                                        tempValidate = validateStatus;
                                                                        tempValidate[exportItem.name] = 'success';
                                                                        setValidateStatus(tempValidate);
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),                                                    
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input.TextArea placeholder="Description..." style={{fontSize: "22px"}}/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col md={6}>
                                                    <Form.Item name={[exportItem.name, "sku"]} label="SKU">
                                                        <Input placeholder="SKU..." />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Item name={[exportItem.name, "commCode"]} label="Comm Code">
                                                        <Input placeholder="Comm Code..." />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Item name={[exportItem.name, "hsCode"]} label="HSCode">
                                                        <Input placeholder="HSCode..." />
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Item name={[exportItem.name, "updateVariant"]} label="Update Product Variant">
                                                        <Checkbox.Group
                                                            options={[
                                                                {
                                                                    label: "True",
                                                                    value: "updateVariant",
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>                                                    
                                                </Col>
                                            </Row>
                                            {discountCodes? (discountCodes.length > 0 ? (<Row gutter={16}><Col md={8}></Col><Col md={8}></Col>
                                                                <Col md={8}><Tag color="#87d068">Price adjusted for order discount</Tag></Col>
                                                            </Row>):(<></>)):(<></>)}
                                            <Row gutter={16}>
                                                <Col md={8}>
                                                    <Row>
                                                        <Col md={20}>
                                                            <Form.Item
                                                                name={[exportItem.name, "weight"]}
                                                                label="Weight"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        whitespace: false,
                                                                        message: "Weight is required",
                                                                    },
                                                                    ({ getFieldValue }) => ({
                                                                        validator(_, value) {
                                                                            const weight = getFieldValue("exportDeclarationItems")[exportItem.name]["weight"];
                                                                            if (!value || Number(weight) < 0) {
                                                                                return Promise.reject(new Error("Cannot less than 0 or empty"));
                                                                            }
                                                                            return Promise.resolve();
                                                                        },
                                                                    }),
                                                                ]}
                                                                hasFeedback
                                                                initialValue="0.00"
                                                            >                                                     
                                                                <Input
                                                                    placeholder="Weight..."
                                                                    addonBefore={weightUnit.toUpperCase()}
                                                                    onChange={checkWeightZero}
                                                                    onClick={() => clearInput(exportItem, "weight")}                                                                                                                           
                                                                />                                                                                                                                                                            
                                                            </Form.Item>
                                                        </Col>
                                                        
                                                        <Col md={3}>
                                                            <div style={{height:"100%"}}>
                                                                <Button type="primary" style={{top:"35%"}} onClick={()=>{saveWeight(exportItem.name)}}>Save</Button>
                                                            </div>                                                             
                                                        </Col>                                                                                                      
                                                    </Row>                                                                                                                                                 
                                                </Col>
                                                <Col md={8}>                                                                                                      
                                                    <Form.Item
                                                        name={[exportItem.name, "grossWeight"]}
                                                        label="Gross Weight"
                                                        initialValue="0.00"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Weight is required",
                                                            },
                                                            ({ getFieldValue }) => ({
                                                                validator(_, value) {
                                                                    const weight = getFieldValue("exportDeclarationItems")[exportItem.name]["grossWeight"];
                                                                    if (!value || Number(weight) < 0) {
                                                                        return Promise.reject(new Error("Cannot less than 0 or empty"));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            }),
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input
                                                            placeholder="Gross Weight..."
                                                            addonBefore={weightUnit.toUpperCase()}
                                                            onChange={checkWeightZero}
                                                            onClick={() => clearInput(exportItem, "grossWeight")}
                                                        />
                                                    </Form.Item>                                                    
                                                </Col>
                                                <Col md={8}>                                              
                                                    <Form.Item
                                                        name={[exportItem.name, "unitPrice"]}
                                                        label="Unit Price"
                                                        initialValue={0}
                                                        // rules={[
                                                        //     {
                                                        //         required: true,
                                                        //         message: "Unit Price is required",
                                                        //     },
                                                        // ]}
                                                        hasFeedback
                                                        onChange={recheckDataHandler}
                                                    >                                                  
                                                        <Input placeholder="Unit Price..." addonBefore={currency} onChange={quantityAndUnitPriceOnChange} />                                                        
                                                    </Form.Item>                                                                                                                                
                                                </Col>
                                            </Row>
                                            <Row gutter={16}>
                                                <Col md={6}>
                                                    <Form.Item
                                                        name={[exportItem.name, "originCountry"]}
                                                        label="Origin Country"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Origin Country is required",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                        tooltip="The origin of product"
                                                        initialValue={"KR"}
                                                    >
                                                        <Select
                                                            showSearch
                                                            style={{ width: "100%" }}
                                                            onChange={(value) => onChangeCountry(value, exportItem.name)}
                                                        >
                                                            {kodNegara.all().map((item, index) => (
                                                                <Option key={index} d value={item.countryCode}>
                                                                    {item.countryNameEn} ({item.countryCode})
                                                                </Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Item
                                                        name={[exportItem.name, "quantity"]}
                                                        label="Quantity"
                                                        initialValue={0}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "Quantity is required",
                                                            },
                                                        ]}
                                                        hasFeedback
                                                    >
                                                        <Input
                                                            placeholder="Quantity..."
                                                            addonBefore={"pcs".toUpperCase()}
                                                            onChange={quantityAndUnitPriceOnChange}
                                                            onClick={() => clearInput(exportItem, "quantity")}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Item
                                                        name={[exportItem.name, "totalValue"]}
                                                        label="Total Value"
                                                        initialValue={0}
                                                        rules={[
                                                            {
                                                                required: false,
                                                            },
                                                        ]}
                                                    >
                                                        <Input placeholder="Total Value..." addonBefore={currency} onChange={recheckDataHandler} />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Item name={[exportItem.name, "category"]} label="Category" hasFeedback initialValue="Other">
                                                        <Select onChange={(value) => categoryOnChangeHandler(value, exportItem.name)}>
                                                            <Select.Option value="OTHER">Other</Select.Option>
                                                            <Select.Option value="BOOK">Book</Select.Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <ButtonPlacement>
                                                <Button
                                                    onClick={(e) => {
                                                        remove(exportItem.name);
                                                        manualType(e);
                                                        return recheckDataHandler();
                                                    }}
                                                    className="reset"
                                                >
                                                    Remove Item
                                                </Button>
                                            </ButtonPlacement>
                                        </Fragment>
                                    );
                                })}
                            </Fragment>
                        );
                    }}
                </Form.List>
            </FormBody>
        </FormWrapper>
    );
};

export default ExportDeclare;
