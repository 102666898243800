import {
  Col,
  Divider,
  message,
  Modal,
  Row,
  Typography,
  Table,
  Input,
  Form,
  Tabs,
} from "antd";
import Path from "commons/breadcrumbs";
import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Container from "components/container/Container";
import useStateCallback from "hooks/useStateCallback";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { moment, removeBlankNull } from "utils/funcs";
import productsApi from "../../apis/productsApi";
import ProductFilterForm from "./ProductFilterForm";
import { ProductPageStyled, TitleBox } from "./index.style";
import LiveFeedsData from "pages/all-shipments/LiveFeedsData";
import ProductList from "./ProductList";
import purchaseApi from "apis/purchaseApi";
import { debounce } from "lodash";
import produce from "immer";
import inventoryApi from "apis/inventoryApi";
import LocalStorage from "utils/storage";

const { Title } = Typography;
const { Column } = Table;
const { TabPane } = Tabs;

const Products = () => {
  const [form] = Form.useForm();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isExportModal, setIsExportModal] = useState(false);
  const [activeVariant, setActiveVariant] = useState([
    { key: "", id: "", variantTitle: "", in_stock: 0, addQuantity: 0 },
  ]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [data, setData] = useState([]);
  const [dailyStatus, setDailyStatus] = useState();
  const [params, setParams] = useStateCallback({
    page: 1,
    pageSize: 10,
    source: "shopify",
    inTransit: "",
    inStock: "",
    title: "",
    // createdAt: ["", ""],
  });

  const { t } = useTranslation();

  const handleChangeStatus = (activeKey) => {
    setParams({ ...params, source: activeKey, page: 1 });
  };

  const getPendingVariantList = () => {
    const variantsForEachCards = data?.products?.map((item) => item.variants);
    console.log("VARIANTS", variantsForEachCards);
    // const param = {
    //   page: 1,
    //   pageSize: 10,
    //   variantId:
    // }
    // purchaseApi.getListPendingVariants(param).then(res => console.log(res))
  };

  // getPendingVariantList();

  const makePurchaseHandler = (indexRow) => {
    setIsLoading(true);
    try {
      const makePurchase = {
        purchaseDate: moment().format("MM/DD/YYYY"),
        purchaseTime: moment().format("HH:mm:ss"),
        purchaser: LocalStorage.get("username","n/a"),
        purchaseItem: data?.products[indexRow]?.variants?.map((obj) => {
          return {
            productName: data?.products[indexRow].title,
            variantName: obj?.title,
            variantId: obj?.id,
            supplier: data?.products[indexRow].vendor,
            purchaseLink: obj?.sourceUrl || "",
            pendingQty: Number(data?.products[indexRow].pending) || 0,
            purchasedQty: 1,
            supplierPrice: 0,
            unitPrice: Number(obj?.price),
            supplierCurrency: obj?.currency,
            paidFrom: "",
            exchangeRate: 1180.62,
          };
        }),
      };

      purchaseApi
        .postMakePurchaseOrder(makePurchase)
        .then(({ payload }) => {
          let purchaseOrderId = [];
          purchaseOrderId.push(payload.id);
          message.success("Successfully Created Draft Purchase", 5);
          history.push({
            pathname: "/purchases/pending-purchases/make-purchase",
            search: queryString.stringify({
              purchaseOrderId,
            }),
          });
        })
        .catch((error) => {
          message.error(error.message, 3);
        });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      return message.error(error.message, 5);
    }
  };

  const handleGetList = async (callback = () => null) => {
    try {
      setIsLoading(true);
      const filter = [];
      // filter title
      if (params.title) {
        filter.push(`title_iLike=%${params.title}%`);
      }
      if (params.source) {
        filter.push(`source_iLike=%${params.source}%`);
      }
      const paramsRequest = {
        page: params.page,
        pageSize: params.pageSize,
        inTransit: params.inTransit,
        inStock: params.inStock,
        sort: "createdAt_desc",
        includeVariants: false,
        includeStats: false,
        filter,
      };

      const data = removeBlankNull(paramsRequest);
      const res = await productsApi.getProducts(data);
      if (Array.isArray(res?.products)) {
        setData(res);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setIsLoading(false);
      callback();
    }
  };

  console.log(
    "SELECTED ROWS",
    activeVariant.filter((item) => selectedRows.includes(item.key))
  );

  const onOk = () => {
    Promise.all(
      activeVariant
        .filter((item) => selectedRows.includes(item.key))
        .map((variant) => {
          const payload = {
            quantity: variant.quantity,
          };
          return inventoryApi.addVariantInventory(
            variant.id,
            JSON.stringify(payload)
          );
        })
    )
      .then((res) => {
        message.success("Succesfully update product inventory", 5);
        setIsLoading(false);
        // updateNewValue
        // const param = {
        //   page: 1,
        //   pageSize: 10,
        //   variantId: item.id,
        // };
        // const res = await purchaseApi.getListPendingVariants(param);
        setIsModal(false);
      })
      .catch((error) => {
        message.error(error, 5);
        setIsModal(false);
      });
    //reset active variant
  };

  const onCancel = (event) => {
    setIsModal(false);
  };
  
  // console.log("PRODUCT", data);
  const onChangeQuantity = debounce((event, record, index) => {
    const res = produce(activeVariant, (draft) => {
      draft[index].quantity = event.target.value;
    });
    setActiveVariant(res);
  }, 1000);

  const changePageHandler = (page, pageSize) => {
    setParams({ ...params, page, pageSize });
  };

  // effect first load
  useEffect(() => {
    const paramsQuery = queryString.parse(history.location.search);
    const data = removeBlankNull(paramsQuery);
    if (data.page) {
      data.page = parseInt(data.page);
    }
    setParams({ ...params, ...data }, () => {
      setInit(true);
    });

    // initial set filter form
    form.setFieldsValue({
      title: data.title,
      inTransit: params.inTransit,
      inStock: params.inStock,
    });
  }, []);

  // effect first load as well, the init=true
  // useEffect(() => {
  //   if (init) {
  //     const data = removeBlankNull(params);
  //     delete data.pageSize;
  //     if (data.page === 1) {
  //       delete data.page;
  //     }
  //     history.push({
  //       pathname: "/products",
  //       search: `?${queryString.stringify(data)}`,
  //     });
  //     handleGetList();
  //   }
  // }, [params.page, params.pageSize, init]);

  // console.log("ACTIVE VARIANT", activeVariant);

  // useEffect(() => {
  //   const data = removeBlankNull(params);
  //   history.push({
  //     pathname: "/products",
  //     search: `?${queryString.stringify(data)}`,
  //   });
  //   handleGetList();
  // }, [params.title, params.page, params.pageSize, !init]);

  useEffect(() => {
    const data = removeBlankNull(params);
    history.push({
      pathname: "/products",
      search: `?${queryString.stringify(data)}`,
    });
    handleGetList();
  }, [params.title, params.page, params.pageSize, !init, params.inStock, params.inTransit,params.source]);

  return (
    <>
      <Modal
        title="Receive Stock"
        visible={isModal}
        onOk={onOk}
        onCancel={onCancel}
        width={700}
      >
        <Table
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRows(selectedRowKeys);
            },
          }}
          dataSource={activeVariant}
          pagination={false}
          style={{ marginTop: "26px" }}
        >
          <Column title="Id" key="id" dataIndex="id" />
          <Column title="Title" key="title" dataIndex="title" />
          <Column title="In Stock" key="in_stock" dataIndex="in_stock" />
          <Column
            title="Add Quantity"
            key="quantity"
            dataIndex="quantity"
            render={(value, record, index) => {
              return (
                <Input
                  name="quantity"
                  placeholder="Quantity"
                  onChange={(event) => {
                    const result = produce(activeVariant, (draft) => {
                      draft[index].quantity = Number(event.target.value);
                    });
                    setActiveVariant(result);
                  }}
                  type="number"
                  addonAfter="PCS"
                  value={value}
                />
              );
            }}
          />
        </Table>
      </Modal>
      <Modal
        title="Add to Export"
        visible={isExportModal}
        onOk={onOk}
        onCancel={onCancel}
        width={700}
      >
        <Table
          rowSelection={{
            onChange: (selectedRowKeys) => {
              setSelectedRows(selectedRowKeys);
            },
          }}
          dataSource={activeVariant}
          pagination={false}
          style={{ marginTop: "26px" }}
        >
          <Column title="Id" key="id" dataIndex="id" />
          <Column title="Title" key="title" dataIndex="title" />
          <Column title="In Stock" key="in_stock" dataIndex="in_stock" />
          <Column
            title="Add Quantity"
            key="quantity"
            dataIndex="quantity"
            render={(value, record, index) => {
              return (
                <Input
                  name="quantity"
                  placeholder="Quantity"
                  onChange={(event) => {
                    const result = produce(activeVariant, (draft) => {
                      draft[index].quantity = Number(event.target.value);
                    });
                    setActiveVariant(result);
                  }}
                  type="number"
                  addonAfter="PCS"
                  value={value}
                />
              );
            }}
          />
        </Table>
      </Modal>
      <TitleBox className="title-page">
        <Title>{t("Products")}</Title>
        <Breadcrumb
          items={[{ name: "Home", path: "/" }, { name: Path.PRODUCTS.name }]}
        />
      </TitleBox>
      <ProductPageStyled title={t("Products")}>
        <LiveFeedsData dailyStatus={dailyStatus} />

        <Container>
          <Title>Products</Title>
          <Divider />
          <Row gutter={24}>
            {/* left side  */}
            <Col sm={24} md={5} lg={5}>
              <ProductFilterForm form={form} setParams={setParams} />
            </Col>
            {/* right side  */}
            {/* new card */}
            <Col sm={24} md={19} lg={19}>
                <Tabs
                type="card"
                onChange={handleChangeStatus}
                activeKey={params.source}
                >
                <TabPane key="" tab="All" />
                <TabPane key="shopify" tab="Shopify" />
                 {/* <TabPane key={3} tab="Poizon" /> */}
              </Tabs>
              <ProductList
                changePageHandler={changePageHandler}
                data={data}
                params={params}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                makePurchaseHandler={makePurchaseHandler}
                setActiveVariant={setActiveVariant}
                setIsModal={setIsModal}
                setIsExportModal={setIsExportModal}
              />
            </Col>
          </Row>
        </Container>
      </ProductPageStyled>
    </>
  );
};

export default Products;
