import {
    Button,
    Col,
    Divider,
    Input,
    message,
    Row,
    Select,
    Space,
    Table,
    Typography,
    List,
    Spin,
    Avatar,
    Image,
    InputNumber,
} from "antd";
import purchaseApi from "apis/purchaseApi";
import Container from "components/container/Container";
import produce from "immer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { Link, useParams, useHistory } from "react-router-dom";
import Path from "../../commons/breadcrumbs";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import { TitleBox } from "./index.style";
import queryString from "query-string";
import styled from "styled-components";
import DragDropFile from "components/drag-drop-image";
import { debounce, isArray } from "lodash";
import LinkButton from "components/linkButton/LinkButton";
import productsApi from "apis/productsApi";
import ordersApi from "apis/ordersApi";
import moment from "moment";
import LocalStorage from "utils/storage";

const { Title } = Typography;
const { Column } = Table;
const { Option } = Select;

const OrderPurchase = () => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);  
    const [order, setOrder] = useState();    
    const [lineItems, setLineItems] = useState([]);
    const [supplierPriceArr, setSupplierPriceArr] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectionType, setSelectionType] = useState('checkbox');
    const history = useHistory();
    const [prettyNote, setPrettyNote] = useState();
    const [refunds, setRefunds] = useState();
    const [totalRefunds, setTotalRefunds] = useState();
    const [totalPrice, setTotalPrice] = useState();
    const [domesticFee, setDomesticFee] = useState();

    const rowSelection = {
        onChange: (selectedRows) => {
            //console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRowKeys(selectedRows);
        },
    };

    const parseNote = (noteStr) => {                   
        const httpsLinkRegex2 = /https?:\/\/[\S\d\w]+\s+.*/g;          
        const linkArr = noteStr.match(httpsLinkRegex2) || [];          
        const txtArr = noteStr.split(httpsLinkRegex2) || [];        

        const linkObjArr = [];
        for(let i=0; i < linkArr.length; i++){                
            let tmpArr = linkArr[i].split('\n');           
            
            if(!tmpArr[1]){
                //split by newline not going right. probably link separated by whitespace                
                const temp = linkArr[i].split(' ');               
                tmpArr[0] = temp[0];      
                const desc =  (temp.slice(1,temp.length-1)).reduce((prev,next) => {return prev + ' ' + next},'');   
                //console.log('DESC', desc, 'LEN', desc?.length);       
                tmpArr[1] = desc?.trim();
            }
            
            const obj = {link: tmpArr[0]?.trim(), desc:(tmpArr[1]?.length > 0)? tmpArr[1] : 'link'};           
            linkObjArr.push(obj);
        }
        
        const tmpArr = txtArr.map((t,index) => {
            let element = t;
            if(index < linkObjArr.length){
                const linkStr = `<a href='${linkObjArr[index].link}' target="_blank">${linkObjArr[index].desc}</a>`;
                element = element + linkStr;
            }

            return element;
        });
        console.log('tmpArr', tmpArr);
        const finalStr = tmpArr.toString().replace(/\n/g, '<br/>').replace(/,/g, ' ');
        setPrettyNote(finalStr);                 
    };

    const getOrderPendingPurchase = async () => {
        setIsLoading(true);
        const payload = {
            id: id
        };

        const res = await ordersApi.getOrderById(payload);
        await setOrder(res.order);

        let tempArr = [];
       
        for (let i=0; i < res.order.lineItems.length; i++) {
            const l = res.order.lineItems[i];
            const r = await productsApi.getProductById(l.productId);
            //
            l["product"] = r.product;
            l["key"] = l.id;
            l["purchasedQty"] = 0;
            let checker = 0;
            console.log('r', r.product.title);
            if (l.customProductName?.toLowerCase().includes("bunjang") || r.product.title?.toLowerCase().includes("bunjang") ) {
                checker = 1
            }
            if (l.customProductName?.toLowerCase().includes("domestic shipping") || r.product.title?.toLowerCase().includes("domestic shipping")) {
                checker = 1
            }
            if (l.customProductName?.toLowerCase().includes("korea shopping agent") || r.product.title?.toLowerCase().includes("korea shopping agent")) {
                checker = 1
            }
            if (l.customProductName?.toLowerCase().includes("top-up shipping fee") || r.product.title?.toLowerCase().includes("top-up shipping fee") ) {
                checker = 1
            }

            if (checker == 0) {
                tempArr.push(l);
            }
        }
       
        setLineItems(tempArr);

        if (res.order.note) {
            parseNote(res.order.note)
        }
        else {
            setPrettyNote('Not Available')
        }

        setTotalPrice(res.order.totalPrice);

        //Retrieve refund for this order if applicable
        ordersApi.getRefunds(id)
        .then((r) => {
            console.log('REFUNDS', r);
            const refundsArr = [];
            if (r.length > 0) {
            r.forEach(refund => {                                
                //order adj refund
                if (refund.json.order_adjustments.length > 0) {                                                                        
                    refund.json.order_adjustments.forEach(x => {                                        
                        const refundObj = {
                            details: 'Order Adjustment Refund',
                            reason: x.reason,
                            createdTime: refund.createdTime,
                            processedTime:refund.processedTime,
                            refundAmt: x.amount? Math.abs(x.amount) : Number(0)
                        }

                        refundsArr.push(refundObj);
                    });                         
                }

                //line items refund
                if (refund.json.refund_line_items.length > 0) {
                    refund.json.refund_line_items.forEach(x => {
                        const refundObj = {
                            details: 'Line Items Refund',
                            reason: x.line_item.name,
                            createdTime: refund.createdTime,
                            processedTime:refund.processedTime,
                            refundAmt: x.subtotal? Math.abs(x.subtotal) : Number(0)
                        };

                        refundsArr.push(refundObj)
                    });                                  
                }

                //others
                if (refund.json.refund_line_items.length === 0 && refund.json.order_adjustments.length === 0) {
                    //not yet implemented. tested so far no needs for transaction[].
                    //might be in the future bugs?
                }
                
            });
            setRefunds(refundsArr);
            setTotalRefunds(refundsArr.reduce((prev,next) => {return prev + next.refundAmt}, Number(0)))
        }
            
            //setTotalRefunds(r.reduce((prev,next) =>{return prev + Math.abs(next.adjAmount)},Number(0)));
        });
        

        setIsLoading(false);
    };
    
    const handleSuppPriceChange = (e, index) => {
        const arr = lineItems.map((s,i) => {
            if (i === index) {                
                s.supplierPrice = e.target.value;

                //Calculate margin
                //multiply variant price with 1,000 (1 USD = 1000 KRW)
                s["margin"] = Number(s.price * 1000) - Number(s.supplierPrice);
            }

            return s
        });

        setLineItems(arr);
    };

    const handleDomesticFeeChange = async (e) => {
        if (!isNaN(e)) {
            await setDomesticFee(e);
        }    
    };

    const handlePurchQtyChange = (e, index) => {
        const arr = lineItems.map((s,i) => {
            if (i === index) {
                s.purchasedQty = e.target.value;
            }

            return s
        });

        setLineItems(arr);  
    };

    const createPurchaseOrder = async () => {

        // if (!domesticFee) {
        //     message.error('Please provide domestic shipping fee');
        //     return;
        // } 
        
        const selectedItems = lineItems.filter(l => {
            if(selectedRowKeys.includes(l.id))
                return l;
        });

        const purchaseItems = selectedItems.map(s => {
            const item = {
                productName: s.productTitle || s.customProductName,
                variantName: s.productVariantTitle || "n/a", 
                supplier: s.product?.vendor || "n/a",
                purchaseLink : '',
                pendingQty: 1, //HARD CODED. NEED TO CHANGE IT SOON
                purchasedQty: s.purchasedQty,
                supplierPrice: s.supplierPrice,
                unitPrice: s.price,
                supplierCurrency: "USD",
                paidFrom: "HARUMIO BANK", //HARD CODED. NEED TO CHANGE IT
                exchangeRate: 1.5,
                variantId:s.productVariantId
            }
            return item;
        });

        console.log("SELECTED ITEM", selectedItems);

         if (!purchaseItems) {
            message('Please add domestic shipping fee');
            return; 
        }

        console.log("PURCHASE LIST", purchaseItems);
        
        const payload = {
            purchaseDate: moment().format("DD/MM/YYYY"),
            purchaseTime: moment().format("HH:mm:ss"),
            purchaser: LocalStorage.get("username","n/a"),
            purchaseItem: purchaseItems,
            linkedOrder: order.orderNumber,
            linkedSource: order.source,
        };
        
        const result = await purchaseApi.postMakePurchaseOrder(payload);
        console.log("RESULT", result.payload);

        if (result.payload) {
            const purchOrder = result.payload;
            message.success("Purchase order created. Redirecting to purchase order page");
            history.push(`/purchases/pending-purchases/make-purchase?purchaseOrderId=${purchOrder.id}&orderNumber=${order.orderNumber}&orderSource=${order.source}`);
        } else {
            message.error("Error in creating purchase order. No purchase order returned.");
        }
    };

    const column2 = [
        {
            title: "Product",
            dataIndex: "product",
            with:200,
            render: (_, record) => (<>
                <div>{record.productTitle || record.customProductName}</div>
                <div>Variant: {record.productVariantTitle}</div>
            </>)
        },
        {
            title: "Image",
            dataIndex: "image",                     
            render: (value, record) => {
                return <img src={record.image ? record.image : record.variant?.images?.length > 0 && record.variant?.images[0] != 'null' ? record.variant.images[0] :  "/no-image.png" } width="120"></img>;
            },
        },
        {
            title: "Status",
            dataIndex: "status",                    
            render: (value, record) => (<>
                    {record.status}
            </>)
        },
        {
            title: "Ordered Qty",
            dataIndex: "quantity",                    
            render: (value, record) => (<>
                    {record.quantity}
            </>)
        },
        {
            title: "Price",
            dataIndex: "price",                      
            render: (value, record) => (<>
                    {record.price}
            </>)
        },
        {
            title: "Supplier",
            dataIndex: "supplier",                       
            render: (value, record) =>  (<>
                {record.product?.vendor || "n/a"}
            </>)    
        },       
        {
            title: "Pending Qty",
            dataIndex: "pendingQty",                      
            render: (value, record) => (<>
                1
            </>)                    
        },
        {
            title: "Purchased Qty",
            dataIndex: "purchQty",                
            render: (value, record, index) => (<>
                <Input type="text" addonAfter="PCS" value={record.purchasedQty} onChange={(e) => handlePurchQtyChange(e,index)}/>
            </>)
                    
        },
        {
            title: "Supplier Price (KRW)",
            dataIndex: "supplierPrice",                  
            render: (value, record, index) => (<>
            {/*onChange={(e)=>handleSuppPriceChange(e,index)}*/}
                <Input value={record.supplierPrice} onChange={(e) => handleSuppPriceChange(e,index)}/>    
            </>)                    
        },
        {
            title: "Margin (KRW)",
            dataIndex: "margin",                
            render: (value, record, index) => (<>
                {record.margin?.toFixed(2) || "n/a"}
            </>)                    
        },       
    ];
    
    useEffect(() => {
        //console.log("SUPPLIER PRICE ARR", supplierPriceArr);
    },[supplierPriceArr]);

    useEffect(() => {
        getOrderPendingPurchase();
    },[]);

    return (
        <div>
        <TitleBox className="title-page">
        <Title>{("Order Purchase")}</Title>
        <Breadcrumbs
            items={[
            { name: "Home", path: "/" },
            {
                name: Path.PURCHASES.name,
                path: Path.ORDER_PURCHASE.pathname,
            },
            { name: Path.ORDER_PURCHASE.name },
            ]}
        />
        </TitleBox>
        <Container>   
            <Spin spinning={isLoading}>
                <div className="order-details">                              
                    <Row className="detail-row">
                        <Col xs={24} md={6} className="box-data">
                            <div className="label">Order Number</div>
                            <div className="data bold">                                           
                                <Title level={4}>
                                    {order?.orderNumber}
                                </Title>                                               
                            </div>
                        </Col>
                        <Col xs={24} md={6} className="box-data">
                            <div className="label">Email</div>
                            <div className="data">{order?.customerEmail}</div>
                        </Col>
                        <Col xs={24} md={6} className="box-data">
                            <div className="label">Total Price</div>
                            <div className="data">{totalRefunds? (totalPrice - totalRefunds).toFixed(2) : totalPrice}</div>
                        </Col>
                        <Col xs={24} md={6} className="box-data">
                            <div className="label">{order?.financialStatus === "paid" ? "Financial Status" : "Total outstanding"}</div>
                            {order?.financialStatus==="paid" ? 
                             <div className="data uppercase" >
                                {order?.financialStatus}
                            </div> :
                                <div className="data uppercase" style={{ color: "red",fontSize: "1.2em"}}>
                                {order?.totalOutstanding}
                            </div>
                            }
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col xs={24} md={6} className="box-data">
                            <div className="label">Status</div>
                            <div className="data uppercase">{order?.status}</div>
                        </Col>
                        <Col xs={24} md={6} className="box-data">
                            <div className="label">Shipping Date</div>
                            {/* {shipInfo ? (
                                <div className="data">{moment(shipInfo?.createdAt).format("DD MMM YYYY, hh:mm a")}</div>                                                  
                            ):(                                                   
                                <div className="data">No Shipping Info</div>                                                    
                            )} */}
                            
                            {/*<div className="data">{order?.shopifyShippingLine}</div>*/}
                        </Col>
                        <Col xs={24} md={6} className="box-data">
                            <div className="label">Created At</div>
                            <div className="data data-sm">{moment(order?.createdAt).format("DD MMM YYYY, hh:mm a")}</div>
                        </Col>
                        <Col xs={24} md={6} className="box-data">
                            <div className="label">Processed At</div>
                            <div className="data data-sm">{moment(order?.updatedAt).format("DD MMM YYYY, hh:mm a")}</div>
                        </Col>
                    </Row>
                    <Row className="detail-row">
                        <Col xs={24} md={6} className="box-data" style={{ marginTop: 8}}>
                            <div className="label">Order Notes</div>
                            <div style={{height:"150px",overflow:"scroll", width: "420px"}}>                                                
                                <div className="data" dangerouslySetInnerHTML={{__html: prettyNote}}></div> 
                            </div> 
                        </Col>
                        <Col xs={24} md={6} className="box-data" style={{ marginTop: 8}}>
                            <div className="label">Order Issues</div>
                            <div style={{height:"150px", width: "420px"}}>    
                            {order?.alerts?.length > 0 ?
                                <div className="data">{order.alerts[0].alert}</div> 
                                :
                                <div className="data">None</div> 
                            }
                            <div className="data">{order?.alerts[0]?.remark}</div>                                             
                                
                            </div> 
                        </Col>
                        <Col xs={24} md={6} className="box-data" style={{ marginTop: 8}}>
                            <div className="label">Risk</div>
                            {order?.risk == "0.5" ?  <div className="data bold" style={{ color: "red",fontSize: "1.2em"}}>MEDIUM</div> : 
                            order?.risk == "1.0"? <div className="data bold" style={{ color: "red",fontSize: "1.2em"}}>HIGH</div> : 
                            <div className="data bold" style={{fontSize: "1.2em"}}>LOW</div>
                            }
                            
                        </Col>
                    </Row>
                </div>
                <TableContainer                    
                    dataSource={lineItems}
                    columns={column2}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    rowClassName={record => record.status != "pending purchase" && "disabled-row"}
                    pagination={false}
                />
                <br/>
                <Button type="primary" onClick={createPurchaseOrder}>Create Purchase Order</Button>
            </Spin>            
        </Container>
    </div>
    );
}

export default OrderPurchase;

export const TableContainer = styled(Table)`
  .disabled-row {
    background-color: #f2f0f0;
    pointer-events: none;
  }
`
