import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, setProfile } from "store/slices/accountSlice";
import { changeLanguage } from "../store/slices/commonSlice";
import LocalStorage, { SessionStorage } from "../utils/storage";

function Init({ children }) {
    const [loading, setLoading] = React.useState(true);
    const dispatch = useDispatch();
    const location = useSelector((state) => state.router.location);

    const initialStateFunc = () => {
        const currentLanguage = LocalStorage.has("lang") ? LocalStorage.get("lang") : "en";
        const profile = LocalStorage.get("profile");
        const permission = LocalStorage.get("permission");
        const modules = LocalStorage.get("permissions",[]);
     
        dispatch(changeLanguage({ language: currentLanguage }));
        dispatch(
            setProfile({
                profile,
                permission,
                permissionKey: modules
                /*
                permissionKey: [
                    "allOrders",
                    "createOrder",
                    "orders",
                    "orderDetails",
                    "shipments",
                    "readyToShip",
                    "createShipment",
                    "todayShipment",
                    "allShipments",
                    "courierList",
                    "products",
                    "productDetail",
                    "settings",
                    "storeDetails",
                    "usersAndPermissions",
                    "salesAndChannels",
                    "languages",
                    "policies",
                    "addStaff",
                    "editStaff",
                    "createProduct",
                    "createdShipment",
                    "rateShipment",
                    "shipperDetails",
                ],*/
            })
        );
    };

    window.addEventListener("storage", (event) => {
        const token = SessionStorage.get("token");
        if (event.key === "REQUESTING_SHARED_CREDENTIALS" && !!token) {
            LocalStorage.set("CREDENTIAL_SHARING", token);
            LocalStorage.remove("CREDENTIAL_SHARING", token);
        }
        if (event.key === "CREDENTIAL_SHARING" && !token) {
            SessionStorage.set("token", JSON.parse(event.newValue));
            dispatch(
                login({
                    token: SessionStorage.has("token"),
                })
            );
            initialStateFunc();
            window.location.replace(`${location.pathname}${location.search}`);
        }
    });

    const getProfile = () => {
        setLoading(false);
    };

    React.useEffect(() => {
        if (SessionStorage.has("token")) {
            getProfile();
        } else {
            setLoading(false);
        }
    }, []);

    React.useEffect(() => {
        LocalStorage.set("REQUESTING_SHARED_CREDENTIALS", Date.now().toString());
        LocalStorage.remove("REQUESTING_SHARED_CREDENTIALS");
        initialStateFunc();
    }, []);

    return loading ? null : children;
}

export default Init;
