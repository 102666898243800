import { Avatar, Button, Row, Col, List, Typography, message, Spin, Tag, Card, Space, Select, Table, Input } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Path from "../../commons/breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import Box from "components/box/Box";
import { useTranslation } from "react-i18next";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import { Icon } from "@iconify/react";
import axios from "axios";

const { Title, Paragraph, Text } = Typography;

const UploadSupplierPricesXls = () => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState('No file selected');
    const [fileSize, setFileSize] = useState();
    const [opResult, setOpResult] = useState();

    const openFilePicker = () => {
        const input = document.createElement('input');
        input.type = 'file';
        
        input.onchange = e => {             
            const file = e.target.files[0];
            setSelectedFile(file.name); 
            setFileSize(file.size);

            // setting up the reader
            const reader = new FileReader();
            reader.readAsBinaryString(file);
            // here we tell the reader what to do when it's done reading...
            reader.onload = async readerEvent => {
                setIsLoading(true);
                const content = readerEvent.target.result; // this is the content!             

                const form = new FormData();
                //form.append('courier', selectedCourier);
                form.append('file', file);                

                //shipmentsApi.updateFromXls(payload);
                axios.post(process.env.REACT_APP_API_URL + "/api/accounting/update-supplier-price", form,{
                    headers: {                      
                        "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                        "Content-Type": "multipart/form-data"
                    },                    
                })
                .then(r => {
                    console.log("RESPONSE", r);
                    
                    message.success("File upload & process completed. \r\nTotal rows: " + r.data.rows);

                    const result = {
                        status: "success",
                        rows: r.data.rows
                    };

                    setOpResult(result);
                })
                .catch(err => {
                    console.log("ERROR", err.response.data);
                    message.error(err.response.data.error + ": " + err.response.data.message);
                    const result = {
                        status: "failure",
                        message: err.response?.data?.message || ''
                    };

                    setOpResult(result);
                })
                .finally(() => {
                    setIsLoading(false);
                });
            }
        }
        
        input.click();
    };

    return (<>
        <TitleHeader
            listOfBreadcrumb={[
                { name: "Home", path: "/" },
                { name: Path.ACCOUNTING.name, path: Path.ACCOUNTING.pathname },
                {
                    name: Path.UPLOAD_SUPPLIER_PRICES.name,
                },
            ]}
            title={t(Path.UPLOAD_SUPPLIER_PRICES.name)}
        />
        <Box>
            <Container>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={15}>
                        <Text mark>Warning: Upload only Microsoft Excel files (xlsx, xls). CSV files will result in errors during processing</Text>
                        <br/><br/>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} md={15}>
                        <Space direction="horizontal">                      
                            {/**
                            <input type="file" placeholder="Select a File..."/>  
                             */}
                            <Text>{selectedFile}</Text>
                            <Button type="primary" onClick={openFilePicker}>Select File & Upload</Button>                           
                        </Space>                        
                    </Col>
                    <Col xs={24} md={9}>
                        <Spin spinning={isLoading}>
                            <Card title="Upload Result">
                                <Col>
                                    <Row><Col md={4}>File name &nbsp;</Col><Col>{selectedFile || ''}</Col></Row>
                                    <Row><Col md={4}>File size &nbsp;</Col><Col>{fileSize || '0'} Bytes</Col></Row>
                                    <Row><Col md={4}>Status &nbsp;</Col><Col>{opResult?.status || ''}</Col></Row>
                                    <Row><Col md={4}>Total Rows &nbsp;</Col><Col>{opResult?.rows || 0}</Col></Row>
                                </Col>
                            </Card>                          
                        </Spin>
                    </Col>
                </Row>
            </Container>
        </Box>        
    </>);
}

export default UploadSupplierPricesXls;