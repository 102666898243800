import { Button, Carousel, Col, Divider, List, message, Modal, Row, Space, Table } from "antd";
import purchaseApi from "apis/purchaseApi";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";
import {
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import LinkButton from "components/linkButton/LinkButton";
import moment from "moment";
import Column from "antd/lib/table/Column";
import CheckInDetails from "./check-in-details";
// this modal will display similar information like make purchase does
// so we need to fetch specific purchase-order/{id}

const ModalPurchaseOrder = ({
  isModal,
  onOkHandler,
  onCancelHandler,
  orderNumber,
  proofOfPurchase,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [purchaseOrder, setPurchaseOrder] = useState({});
  const [checkInList, setCheckInList] = useState([]);
  const [purchaseItem, setPurchaseItem] = useState({
    itemPurchased: [],
    addItem: [],
  });
  const history = useHistory();
  const [isCheckInDetailsOpen, setIsCheckInDetailsOpen] = useState(false);
  const [selectedCheckIn, setSelectedCheckIn] = useState();

  const getPurchaseOrder = () => {
    setIsLoading(true);
    purchaseApi
      .getPurchaseOrder(orderNumber)
      .then(({ purchaseOrderDetails }) => {
        setIsLoading(false);
        setPurchaseOrder(() => purchaseOrderDetails);
        
        message.success("Successfully fetch data", 1);
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(error, 5);
      });
  };

  const distributePurchaseItem = () => {
    if (!!purchaseOrder.purchaseItems) {
      purchaseOrder.purchaseItems.map((item) => {
        // if (!!item.proofOfPurchase) {

          return setPurchaseItem((purchaseItem) => {
            console.log('purchaseItem',purchaseItem)
            if (purchaseItem.itemPurchased)
              return {
                ...purchaseItem,
                itemPurchased: [...purchaseItem.itemPurchased, item],
              };
          });
        // } else {
        //   return setPurchaseItem((purchaseItem) => {
        //     console.log('purchaseItem',purchaseItem)
        //     return {
        //       ...purchaseItem,
        //       addItem: [...purchaseItem.addItem, item],
        //     };
        //   });
        // }
      });
    }
  };

  const removeItem = (item) => {
    setPurchaseItem((prev) => {
      const newItemPurchased = prev.itemPurchased.filter((obj) => obj !== item);
      console.log("newItemPurchased",newItemPurchased)
      return { ...prev, itemPurchased: newItemPurchased };
    });
    setPurchaseItem((prev) => {
      const newAddItem = [...prev.addItem, item];
      return { ...prev, addItem: newAddItem };
    });
  };

  const addItem = (item) => {
    setPurchaseItem((prev) => {
      const newAddItem = prev.addItem.filter((obj) => obj !== item);
      return { ...prev, addItem: newAddItem };
    });
    setPurchaseItem((prev) => {
      const newItemPurchased = [...prev.itemPurchased, item];
      return { ...prev, itemPurchased: newItemPurchased };
    });
  };

  const purchaseMadeTotal = (purchase) => {
    return purchase
      .map((item) => item.purchasedQty)
      .reduce((prev, curr) => prev + curr);
  };

  const instantCheckIn = () => {
    const items = purchaseItem.itemPurchased.map(p => {
      return {
        productVariantId: p.variantId,
        quantity: p.purchasedQty,
      };
    });
    
    const payload = {
      items: items,
      orderNum: purchaseOrder.linkedOrder,
      purchaseId: purchaseOrder.orderNumber,
      remark: "INSTANT CHECK-IN",
      checkInType: "FULL"
    };

    purchaseApi.checkInOrder(payload)
    .then(r => {
      console.log("R",r);
      message.success("Purchase order succesfully checked in");     

      if (process.env.REACT_APP_DEVELOPMENT) {
        if (purchaseOrder.linkedSource === "custom" || purchaseOrder.linkedSource === "cop") { 
          const webhookPayload = {
            orderNo: purchaseOrder.linkedOrder,
            items: items
          }
          purchaseApi.notifyCheckIn(webhookPayload);
        }
      }      
    })
    .catch(err => {
      console.log("ERR ", err);
      message.error("Check in failed. Error: ", err);
    });
  };

  const getCheckIns = () => {
    purchaseApi.getCheckIn(purchaseOrder.orderNumber)
    .then(r => {      
      if (r) {
        setCheckInList(r);
      }    
    })
    .catch(err => console.log("ERR",err));
  };

  const partialCheckIn = () => {
    history.push("/purchases/pending-purchases/partial-check-in?id=" + purchaseOrder.orderNumber);
  }; 

  const onCheckInModalOk = () => {
    setIsCheckInDetailsOpen(false);
  }

  const onCheckInModalCancel = () => {
    setIsCheckInDetailsOpen(false);
  };

  const showDetailModal = (checkin) => {
    setSelectedCheckIn(checkin);
    setIsCheckInDetailsOpen(true);
  }

  useEffect(() => {
    getPurchaseOrder();    
  }, []);

  useEffect(() => {
    distributePurchaseItem();
    getCheckIns();
  }, [purchaseOrder]);

  // console.log(
  //   "PO",
  //   purchaseOrder.purchaseItems?.map((item) => item.purchasedQty)
  // );
  // console.log("POP", proofOfPurchase);
  // console.log("PURCHASE ITEM", purchaseOrder);
  // console.log(!!purchaseOrder?.purchaseItems.proofOfPurchase);
  // console.log("PURCHASE ORDER", purchaseOrder.purchaseItems);

  return (
    <Modal
      title="Purchase Order"
      visible={isModal}
      onOk={onOkHandler}
      onCancel={onCancelHandler}
      width={1000}
    >
      <Row gutter={16}>
        <Col md={12}>
          {proofOfPurchase?.length ? (
            <Carousel>
              {proofOfPurchase.map((popImage, index) => (
                <img
                  key={index}
                  title={popImage.fileName}
                  src={popImage.fileUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                  alt={popImage.fileName}
                />
              ))}
            </Carousel>
          ) : (
            <NoImage>
              <SearchOutlined height={500} />
              "Please go back to Make Purchase Page to upload the image.."
              <LinkButton
                to={`/purchases/pending-purchases/make-purchase?purchaseOrderId=${purchaseOrder.orderNumber}`}
                type="primary"
              >
                Make Purchase
              </LinkButton>             
            </NoImage>
          )}
        </Col>
        <Col md={12}>
          <Row>
            <List
              loading={isLoading}
              style={{ width: "100%" }}
              header={<div className="bold">Item Purchased</div>}
              dataSource={purchaseItem.itemPurchased}
              renderItem={(item) => (
                <List.Item>
                  <div
                    style={{
                      width: "auto",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.variantName == "" || "default" ? item.productName : item.variantName}
                  </div>
                  <div>{item.purchasedQty}</div>
                  {!!item.proofOfPurchase ? (
                    <SavedTag>SAVED</SavedTag>
                  ) : undefined
                  // (
                  //   <CloseCircleTwoTone onClick={() => removeItem(item)} />
                  // )
                  }
                </List.Item>
              )}
            />
          </Row>
          {/* <Row md={24}>
            <List
              loading={isLoading}
              style={{ width: "100%" }}
              header={<div className="bold">Add Item</div>}
              dataSource={purchaseItem.addItem}
              renderItem={(item) => (
                <List.Item>
                  <div
                    style={{
                      width: "auto",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.variantName == "" ? item.productName : item.variantName}
                  </div>
                  <div>{item.purchasedQty}</div>
                  <CheckCircleTwoTone
                    twoToneColor="#5beb2f"
                    onClick={() => addItem(item)}
                  />
                </List.Item>
              )}
            />
          </Row> */}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col md={12}>
          <h1>Purchase Order Details</h1>
          <DetailList>
            <li>
              <span className="bold">Purchase Made: </span>
              <span className="grey-text">
                {purchaseOrder.purchaseItems?.length
                  ? purchaseMadeTotal(purchaseOrder.purchaseItems) + " Items"
                  : "0"}
              </span>
            </li>
            <li>
              <span className="bold">Purchaser: </span>
              <span className="grey-text">{purchaseOrder.purchaser}</span>
            </li>
            <li>
              <span className="bold">Status: </span>
              <span className="grey-text">{purchaseOrder.status}</span>
            </li>
            <li>
              <span className="bold">Order Number: </span>
              <span className="grey-text">{purchaseOrder.linkedOrder? purchaseOrder.linkedOrder : '' }</span>
            </li>
            <li>
              <span className="bold">Domestic Shipping Fee: </span>
              <span className="grey-text">{purchaseOrder.domesticShipFee? Number(purchaseOrder.domesticShipFee).toFixed(2) : 'none' }</span>
            </li>
            
          </DetailList>          
        </Col>
        <Col md={12}>
          <Space direction="horizontal">
            <Button type="primary" onClick={instantCheckIn}>
              {/* Full Check-in  {purchaseOrder.linkedOrder} */}
              Full Check-in
            </Button>

            <Button type="primary" onClick={partialCheckIn}>
              Partial Check-in
            </Button>

            <Button type="primary">
              Close PO as Completed
            </Button>

          </Space>         
        </Col>
      </Row>
      <Divider/>
      <Row>
        <Col md={24}>
          <h1>Check-Ins</h1>
          {checkInList.length > 0? (
            <Table
            size="small"
            dataSource={checkInList}
            >
              <Column 
                title="ID"
                dataIndex="id"              
              />
              <Column
                title="Time"
                dataIndex="createdAt"
                key="createdAt"
                render={(value,record)=>{return moment(value).format("DD/MM/YYYY HH:mm")}} 
              />
              <Column 
                title="Check-In Type"
                dataIndex="checkInType"
              />
              <Column 
                title="Remark"
                dataIndex="remark"
              />
              <Column
                title="Action"
                dataIndex="remark"
                render={(value,record)=>{
                  return(<Button type="primary" size="small" onClick={()=>showDetailModal(record)}>Details</Button>);
                }}
              />
            </Table>
          ):(<></>)}
          
          {/* {checkInList.length > 0? 
            (checkInList.map(c => <p>{moment(c.createdAt).format("DD/MM/YYYY HH:mm") + ", " + c.checkInType}</p>))
            : 
            (<p>N/A</p>)
          } */}
        </Col>
       
      </Row>
      <CheckInDetails
        isCheckInDetailsOpen={isCheckInDetailsOpen}
        onCheckInModalOk={onCheckInModalOk}
        onCheckInModalCancel={onCheckInModalCancel}
        checkInObj={selectedCheckIn}
      />
    </Modal>
  );
};

export default ModalPurchaseOrder;

ModalPurchaseOrder.propTypes = {
  orderNumber: PropTypes.number,
};

const NoImage = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SavedTag = styled.div`
  border: 1px solid #003cff;
  color: #003cff;
  border-radius: 3px;
  padding: 3px 10px;
`;

const DetailList = styled.ul`
  .bold {
    font-weight: 600;
  }
  .grey-text {
    color: #3c3c3c;
  }
`;
