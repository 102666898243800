import { Col, Row, Form, Input, Select, Button, message, Typography, Card, Checkbox } from "antd";
import shipmentsApi from "apis/shipmentsApi";
import kodNegara from "kod-negara";
import { groupBy } from "lodash";
import React, { useMemo, useState, useEffect } from "react";
import { ButtonPlacement, FormBody, FormHeader, FormWrapper, ShippingAccountsGroupsWrapper } from "./index.style";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { restructureWeight } from "./utils";
import { twoDecimal } from "utils/funcs";
import shipmentFilter from "../../utils/shipmentFilter";

const { Option } = Select;
const { Text } = Typography;

const ShipmentRateDetail = ({ manualChange, setManualChange, isLoading, form, setIsLoading, setRates, setRatesError, ratesError ,shippingAccounts, activeShipper, doneLoadingFlag, setSummary }) => {
    const { currency } = useSelector((state) => state.common);
    const [shippingAccountsSelected, setShippingAccountsSelected] = useState([]);
    const [emptyIndex, setEmptyIndex] = useState(0);

    const manualChangeFunc = (value) => {
        setManualChange((prev) => {
            return {
                status: true,
                manualValue: value,
            };
        });
    };

    const rearrangeGroupBY = (groupByObjResult) => {
        const rearrange = [];
        for (const key in groupByObjResult) {
            if (key === "dhl") {
                rearrange[0] = groupByObjResult[key];
            } else if (key === "fedex") {
                rearrange[1] = groupByObjResult[key];
            } else if (key === "ups") {
                rearrange[2] = groupByObjResult[key];
            } else if (key === "rincos") {
                rearrange[3] = groupByObjResult[key];
            }
        }
        return rearrange;
    };

    const shippingAccountsGroups = useMemo(() => rearrangeGroupBY(groupBy(shippingAccounts, "apiType")), [shippingAccounts]);

    useEffect(async() => {
        console.log('SHIPPING ACC', shippingAccounts);
        console.log('SHIPPING GROUP', shippingAccountsGroups);
        if (shippingAccounts?.length > 0) {
            await setEmptyIndex(shippingAccounts.length - 1)
            
            //await setShippingAccountsSelected([].concat(...shippingAccountsGroups.map((groupShippings) => groupShippings.map((item) => item))));            
            
            //remove rincos from selection at page startup
            const tempArr = [].concat(...shippingAccountsGroups.map((groupShippings) => groupShippings.filter((item) => {
                if(item.apiType !== "rincos")
                    return item;
            }))); 
           
            setShippingAccountsSelected(tempArr);
        }        
    }, [shippingAccounts]);
    
    const handleCheckShippingAccount = async(e, id) => {
       
        setEmptyIndex(await shippingAccountsSelected.findIndex((obj) => Object.keys(obj).length === 0))
        emptyIndex  >= 0 ? (shippingAccountsSelected.splice(emptyIndex,1)) : (shippingAccountsSelected.splice(emptyIndex,0)) 

       setShippingAccountsSelected([...shippingAccountsSelected])
        if (e.target.checked) {
            setShippingAccountsSelected([...shippingAccountsSelected, id]);
        } else {
            setShippingAccountsSelected(shippingAccountsSelected.filter((item) => item != id));
        }
    };

    const fasterRateShipments = async () => {
        if (shippingAccounts === undefined) {
            return alert("Shipping Accounts Undefined");
        }
        
        if(ratesError){
            return;
        }

        const response = form.getFieldsValue();
        setIsLoading(true);

        let pieces = response?.pieces?.map((item) => {
            return {
                id: uuidv4(),
                height: String(item.height),
                width: String(item.width),
                depth: String(item.depth),
                value: String(item.value),
                weight: String((Math.round(item.weight * 100) / 100).toFixed(2)),
                reference: item.reference,
            };
        });

        if (manualChange.status) {
            pieces = response?.pieces?.map((item) => {
                return {
                    id: uuidv4(),
                    height: String(item.height),
                    width: String(item.width),
                    depth: String(item.depth),
                    value: String(item.value),
                    weight: String(twoDecimal(Number(manualChange.manualValue) / response.pieces?.length)),
                    reference: item.reference,
                };
            });
        }

        //Check recipient address
        if(!response?.postal){
            alert('Postal is required for getting shipment rates');
            setIsLoading(false);
            return;
        }
        if(!response?.countryCode){
            alert('Country code is required for getting shipment rates');
            setIsLoading(false);
            return;
        }
        if(!response?.city){
            alert('City is required for getting shipment rates');
            setIsLoading(false);
            return;
        }
       
        const exportDeclaration = response?.exportDeclarationItems?.map((item) => {
            return {
                id: uuidv4(),
                updateVariant: item.updateVariant?.includes("updateVariant") || false,
                productVariantId: item.productVariantId,
                quantity: item.quantity,
                quantityUnit: "PCS",
                description: item.description,
                value: String(item.unitPrice),
                totalValue: String(item.totalValue),
                weight: String((Math.round(item.weight * 100) / 100).toFixed(2)),
                grossWeight: String((Math.round(item.grossWeight * 100) / 100).toFixed(2)),
                HSCode: item.hsCode,
                sku: item.sku,
                originCountry: item.originCountry,
                category: item.category,
            };
        })

        const callObj = {
            userId: "123", // hardcoded, please change them once user done
            shippingAccounts: shippingAccountsSelected.map((acc) => ({
                type: acc.apiType,
                id: acc.id,
            })),
            shipperDetailsId: activeShipper.id,
            sampleResponse: false,
            invoiceNumber: String(response?.invoice),
            invoiceDate: moment(response?.exportDeclarations?.invoiceDate).utc().format(),
            shipmentDate: moment(response?.shipmentDate).utc().format(),
            declaredValue: String(response?.totalDeclaredValue),
            email: response?.personEmail,
            notificationMessage: response?.notificationMessage,
            contents: response?.contents,
            notification: response?.additionalCourierOptions?.includes("notification"),
            shopifyNotification: response?.additionalCourierOptions?.includes("shopifyNotification"),
            pltShipment: response?.additionalCourierOptions?.includes("pltShipment"),
            documentsOnly: response?.additionalCourierOptions?.includes("documentsOnly"),
            signatureRequired: response?.additionalCourierOptions?.includes("signatureRequired"),
            signatureType: "DIRECT", // <-- hardcoded
            useOwnInvoice: response?.additionalCourierOptions?.includes("useOwnInvoice"),
            fedexPackagingType: response?.fedexPackagingType, // <-- ada dalam packagingInformation
            fedexServiceType: response?.fedexServiceType, // <-- ada dalam packagingInformation
            fedexDutiesPaidAccount: response?.fedexDutiesPaidAccount,
            shipmentPurpose: response?.shipmentPurpose, // <-- please refer documentation from Edward and Kaamesh
            insuranceCost: "", // <-- hardcoded
            shippingCost: "", // <-- hardcoded
            packingCost: String(response?.totalDeclaredValue),
            handlingCost: "", // <-- hardcoded
            otherCost: "", // <-- hardcoded
            trackingNumber: "80329091290", // <-- hardcoded
            invoiceRemark: response?.invoiceRemark,
            invoiceCurrency: currency,
            invoiceWeightUnit: "KG", // <-- hardcoded
            invoiceLetterhead: {
                content: "", // <-- hardcoded
                fedexImageId: "IMAGE_1", // <-- hardcoded
            },
            invoiceSignature: {
                content: "", // <-- hardcoded
                fedexImageId: "IMAGE_2", // <-- hardcoded
            },
            invoiceData: {
                documentType: "COMMERCIAL_INVOICE", // <-- hardcoded
                fileName: "Invoice.pdf", // <-- hardcoded
                content: "JVBERi0xLjQKJeLjz9MKNiAwIG9iago8PC9GaWx0ZXIvRmxhdGVEZWNvZGUvTGVuZ3RoIDUxPj5zdHJl", // <-- hardcoded
            },
            rincosServiceCode: "", // <-- hardcoded
            rincosServiceName: "", // <-- hardcoded
            labelType: "ZPLII", // <-- hardcoded
            consignee: {
                companyName: response?.companyName,
                personName: response?.personName,
                personPhone: response?.personPhone,
                personEmail: response?.personEmail,
                address1: response?.address1,
                address2: response?.address2,
                stateOrProvinceName: response?.stateOrProvinceName,
                city: response?.city,
                division: response?.division,
                provinceCode: response?.provinceCode,
                postal: response?.postal,
                countryCode: response?.countryCode,
            },
            exportDeclaration,
            pieces,
        };

        console.log("RATE SHIPMENT JSON:", JSON.stringify(callObj));

        shipmentsApi
            .getRateList(callObj)
            .then((res) => {
                if (res === undefined) {
                    return alert("Undefined response");
                }
                
                const countryCode = response?.countryCode;
                const { rates, errors } = res;
                let countryFiltered = rates;

                const exist = shipmentFilter.disallowFedexIntPrio.find(o => {return o === countryCode;});    
                const FEDEX_INT_PRIOR_ID = 4;
                const FEDEX_CONN_PLUS_ID = 5; 
                    
                if(exist){
                    //Fedex Internation Prio is account id 4
                    //Fedex Connect Plust is account id 5
                    countryFiltered = rates?.filter((i) => {return !(i.accountId === FEDEX_INT_PRIOR_ID)})
                } else {
                    countryFiltered = rates?.filter((i) => {return !(i.accountId === FEDEX_CONN_PLUS_ID)});
                }


                const filteredRates = countryFiltered?.filter((item) => !isNaN(item.rateNetCharge)) || [];
                setRatesError(() => errors);
                setRates(() => filteredRates);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                message.error(error.message);
            });        
    };

    const rateShipments = async () => {
        if (shippingAccounts === undefined) {
            return alert("Shipping Accounts Undefined");
        }

        //Code for enabling rincos for MY/SG
        let rincos = null;
        const countryCode = form.getFieldValue("countryCode");
        
        if(countryCode === "SG" || countryCode === "MY"){  
            rincos = shippingAccounts.find(s => {return s.apiType === "rincos"});
        }
        //end of code

        setEmptyIndex(await shippingAccountsSelected.findIndex((obj) => Object.keys(obj).length === 0))
        emptyIndex  >= 0 ? (shippingAccountsSelected.splice(emptyIndex,1)) : (shippingAccountsSelected.splice(emptyIndex,0)) 
        
        //Code for enabling rincos for MY/SG
        //IT IS UGLY. We are fighting react hook async update here.
        if(rincos){
            if (shippingAccountsSelected.some(s => s.apiType == "rincos") === false)
                setShippingAccountsSelected([...shippingAccountsSelected, rincos]);
            else
                setShippingAccountsSelected([...shippingAccountsSelected]);
        } else {
            setShippingAccountsSelected([...shippingAccountsSelected]);
        }           
        //end of code
        //setShippingAccountsSelected([...shippingAccountsSelected]); <--original code before rincos SG/MY

        form.validateFields()
            .then((response) => {
                setIsLoading(true);

                let pieces = response?.pieces?.map((item) => {
                    return {
                        id: uuidv4(),
                        height: String(item.height),
                        width: String(item.width),
                        depth: String(item.depth),
                        value: String(item.value),
                        weight: String((Math.round(item.weight * 100) / 100).toFixed(2)),
                        reference: item.reference,
                    };
                });

                if (manualChange.status) {
                    pieces = response?.pieces?.map((item) => {
                        return {
                            id: uuidv4(),
                            height: String(item.height),
                            width: String(item.width),
                            depth: String(item.depth),
                            value: String(item.value),
                            weight: String(twoDecimal(Number(manualChange.manualValue) / response.pieces?.length)),
                            reference: item.reference,
                        };
                    });
                }

                const exportDeclaration = restructureWeight(
                    response?.exportDeclarationItems?.map((item) => {
                        return {
                            id: uuidv4(),
                            updateVariant: item.updateVariant?.includes("updateVariant") || false,
                            productVariantId: item.productVariantId,
                            quantity: item.quantity,
                            quantityUnit: "PCS",
                            description: item.description,
                            value: String(item.unitPrice),
                            totalValue: String(item.totalValue),
                            weight: String((Math.round(item.weight * 100) / 100).toFixed(2)),
                            grossWeight: String((Math.round(item.grossWeight * 100) / 100).toFixed(2)),
                            HSCode: item.hsCode,
                            sku: item.sku,
                            originCountry: item.originCountry,
                            category: item.category,
                        };
                    })
                );

                const callObj = {
                    userId: "123", // hardcoded, please change them once user done
                    /*original code BEFORE adding rincos for MY and SG
                    shippingAccounts: shippingAccountsSelected.map((acc) => ({
                        type: acc.apiType,
                        id: acc.id,
                    })),
                    */
                    //start: adding rincos for MY and SG
                    shippingAccounts: (countryCode === "SG" || countryCode === "MY")? 
                        ( shippingAccountsSelected.some(s => s.apiType == "rincos")?
                            (shippingAccountsSelected.map((acc) => ({
                                type: acc.apiType,
                                id: acc.id,
                            })))
                            :
                            (shippingAccountsSelected.map((acc) => ({
                                type: acc.apiType,
                                id: acc.id,
                            })).concat({type: rincos.apiType, id: rincos.id}))
                        )
                        :
                        (shippingAccountsSelected.map((acc) => ({
                            type: acc.apiType,
                            id: acc.id,
                        }))),
                    //end
                    shipperDetailsId: activeShipper.id,
                    sampleResponse: false,
                    invoiceNumber: String(response?.invoice),
                    invoiceDate: moment(response?.exportDeclarations?.invoiceDate).utc().format(),
                    shipmentDate: moment(response?.shipmentDate).utc().format(),
                    declaredValue: String(response?.totalDeclaredValue),
                    email: response?.personEmail,
                    notificationMessage: response?.notificationMessage,
                    contents: response?.contents,
                    notification: response?.additionalCourierOptions?.includes("notification"),
                    shopifyNotification: response?.additionalCourierOptions?.includes("shopifyNotification"),
                    pltShipment: response?.additionalCourierOptions?.includes("pltShipment"),
                    documentsOnly: response?.additionalCourierOptions?.includes("documentsOnly"),
                    signatureRequired: response?.additionalCourierOptions?.includes("signatureRequired"),
                    signatureType: "DIRECT", // <-- hardcoded
                    useOwnInvoice: response?.additionalCourierOptions?.includes("useOwnInvoice"),
                    fedexPackagingType: response?.fedexPackagingType, // <-- ada dalam packagingInformation
                    fedexServiceType: response?.fedexServiceType, // <-- ada dalam packagingInformation
                    fedexDutiesPaidAccount: response?.fedexDutiesPaidAccount,
                    shipmentPurpose: response?.shipmentPurpose, // <-- please refer documentation from Edward and Kaamesh
                    insuranceCost: "", // <-- hardcoded
                    shippingCost: "", // <-- hardcoded
                    packingCost: String(response?.totalDeclaredValue),
                    handlingCost: "", // <-- hardcoded
                    otherCost: "", // <-- hardcoded
                    trackingNumber: "80329091290", // <-- hardcoded
                    invoiceRemark: response?.invoiceRemark,
                    invoiceCurrency: currency,
                    invoiceWeightUnit: "KG", // <-- hardcoded
                    invoiceLetterhead: {
                        content: "", // <-- hardcoded
                        fedexImageId: "IMAGE_1", // <-- hardcoded
                    },
                    invoiceSignature: {
                        content: "", // <-- hardcoded
                        fedexImageId: "IMAGE_2", // <-- hardcoded
                    },
                    invoiceData: {
                        documentType: "COMMERCIAL_INVOICE", // <-- hardcoded
                        fileName: "Invoice.pdf", // <-- hardcoded
                        content: "JVBERi0xLjQKJeLjz9MKNiAwIG9iago8PC9GaWx0ZXIvRmxhdGVEZWNvZGUvTGVuZ3RoIDUxPj5zdHJl", // <-- hardcoded
                    },
                    rincosServiceCode: "", // <-- hardcoded
                    rincosServiceName: "", // <-- hardcoded
                    labelType: "ZPLII", // <-- hardcoded
                    consignee: {
                        companyName: response?.companyName,
                        personName: response?.personName,
                        personPhone: response?.personPhone,
                        personEmail: response?.personEmail,
                        address1: response?.address1,
                        address2: response?.address2,
                        stateOrProvinceName: response?.stateOrProvinceName,
                        city: response?.city,
                        division: response?.division,
                        provinceCode: response?.provinceCode,
                        postal: response?.postal,
                        countryCode: response?.countryCode,
                    },
                    exportDeclaration,
                    pieces,
                };

                console.log("RATE SHIPMENT JSON:", JSON.stringify(callObj));

                shipmentsApi
                    .getRateList(callObj)
                    .then((res) => {
                        if (res === undefined) {
                            return alert("Undefined response");
                        }
                        
                        const countryCode = response?.countryCode;
                        const { rates, errors } = res;
                        let countryFiltered = rates;
        
                        const exist = shipmentFilter.disallowFedexIntPrio.find(o => {return o === countryCode;});    
                        const FEDEX_INT_PRIOR_ID = 4;
                        const FEDEX_CONN_PLUS_ID = 5; 
                            
                        if(exist){
                            //Fedex Internation Prio is account id 4
                            //Fedex Connect Plust is account id 5
                            countryFiltered = rates?.filter((i) => {return !(i.accountId === FEDEX_INT_PRIOR_ID)})
                        } else {
                            countryFiltered = rates?.filter((i) => {return !(i.accountId === FEDEX_CONN_PLUS_ID)});
                        }
        
        
                        const filteredRates = countryFiltered?.filter((item) => !isNaN(item.rateNetCharge)) || [];
                        setRatesError(() => errors);
                        setRates(() => filteredRates);
                        setIsLoading(false);
                    })
                    .catch((error) => {
                        setIsLoading(false);
                        message.error(error.message);
                    });
            })
            .catch((error) => {
                setRatesError(error?.errorFields);
                alert("Please fill in all the required information and make sure there is no error before Check Rates.");
                window.scrollTo(0, 0);
            });
    };
    useEffect(() => {
        if(doneLoadingFlag){
            setTimeout(function (){  
                // Something you want delayed.            
                //fasterRateShipments();
                rateShipments();
                setSummary(() => form.getFieldsValue());         
            }, 2000);
        }
       
    },[doneLoadingFlag]);
    return (
        <FormWrapper>
            <FormHeader className="greenBoldTitle">Shipment Rate Details</FormHeader>
            <FormBody>
                <Row gutter={16}>
                    <Col md={8}>
                        <Form.Item name="postal" label="Postcode" tooltip="Postcode/Postal Code" required extra="Please fill Consignee/Poscode details">
                            <Input placeholder="Postal Code/ Postcode" disabled />
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item name="countryCode" label="Destination" tooltip="Destination" required extra="Please fill Consignee/Destinations details">
                            <Select showSearch style={{ width: "100%" }} disabled>
                                {kodNegara.all().map((item, index) => (
                                    <Option key={index} value={item.countryCode}>
                                        {item.countryNameEn} ({item.countryCode})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={8}>
                        <Form.Item label="Check Weight" tooltip="Check Weight" extra="Click Recheck Data After Updated Packaging Informations" required>
                            <Input                                
                                value={manualChange.manualValue}
                                type="number"
                                step="0.01"
                                style={{
                                    width: "100%",
                                }}
                                addonBefore={"kg".toUpperCase()}
                                onChange={(event) => manualChangeFunc(event.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <ShippingAccountsGroupsWrapper span={24}>
                        <Text className="title">Rate for account:</Text>
                        {shippingAccountsGroups?.map((groupShippings) => (
                            <div key={groupShippings[0]?.apiType} className="shipping-accounts-group">
                                <Text className="api-type">{groupShippings[0]?.apiType}</Text>
                                <Row gutter={16}>
                                    
                                    {groupShippings.map((shippingAccount) => (
                                        <Col xs={24} sm={12} md={6} key={shippingAccount.id}>
                                            <Card>
                                                <Checkbox                                          
                                                    checked={shippingAccountsSelected.some((item) => item === shippingAccount)}
                                                    //unchecked={shippingAccountsSelected.filter((item) => item.apiType == "rincos")}
                                                    //unchecked={rincosAcc}
                                                    onChange={(e) => handleCheckShippingAccount(e, shippingAccount)}
                                                >
                                                    <div>{shippingAccount.accountName}</div>
                                                    <div>{shippingAccount.accountNumber}</div>
                                                </Checkbox>
                                            </Card>
                                        </Col>
                                    ))}                          
                                </Row>
                            </div>
                        ))}
                    </ShippingAccountsGroupsWrapper>
                </Row>
               
                <Button type="primary" loading={isLoading} onClick={rateShipments}>
                    Check Rate
                </Button>
                
            </FormBody>
        </FormWrapper>
    );
};

export default ShipmentRateDetail;
