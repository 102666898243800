import { Checkbox, Col, Form, Input, Row, Select } from "antd";
import kodNegara from "kod-negara";
import PropTypes from "prop-types";
import { FormBody, FormHeader, FormWrapper } from "./index.style";
import { useState } from "react";
import { useEffect, useCallback } from "react";
import { getCountryCode, getProvince } from "utils/funcs";

const { Option } = Select;

const ConsigneeDetail = ({ fromOrder, form, checkingEtdPltOutBound, etdPltSatisfied, activeCourier, setRatesError }) => {
    const [activeProvinces, setActiveProvinces] = useState([]);
    const [addrLenRule, setAddrLenRule] = useState({max: 45, message: "Value should be less or equal to 45 character"});

    const onSelect = (value, fieldName) => {
        console.log("DIPILIH", value, fieldName);
        const countryCode = kodNegara.findOne("countryNameEn", value).countryCode;
        const provinces = getProvince(countryCode);
        checkingEtdPltOutBound(countryCode, activeCourier.apiType);
        if (!provinces?.length || provinces === undefined) {
            setActiveProvinces([]);
            // clear code
            form.setFieldsValue({
                provinceCode: "",
            });
        }
        if (!!provinces) {
            setActiveProvinces(provinces);
        }
        form.setFieldsValue({ [fieldName]: countryCode });
    };

    const onSelectProvince = (value) => {
        const provinceCode = activeProvinces?.find((item) => item.name === value).code;
        return form.setFieldsValue({ provinceCode });
    };

    useEffect(() => {
        if (fromOrder) {
            const countryCode = getCountryCode(fromOrder.addressCountry);
            const provinces = getProvince(countryCode);
            if (!provinces?.length || provinces === undefined) {
                setActiveProvinces([]);
                // clear province code
                form.setFieldsValue({
                    provinceCode: "",
                });
            }
            if (!!provinces) {
                setActiveProvinces(provinces);
            }
        }
    }, [fromOrder]);

    //Limiting address lines to 35 chars for Fedex, 45 chars for others
    useEffect(() => {
        const FEDEX_ADDR_MAXLEN = 35;       
        const COURIER_ADDR_MAXLEN = 45;
        if(activeCourier.apiType === 'fedex'){
            setAddrLenRule({max: FEDEX_ADDR_MAXLEN, message: "Value should be less or equal to 35 character"});
        } else {
            setAddrLenRule({max: COURIER_ADDR_MAXLEN, message: "Value should be less or equal to 45 character"});
        }
        
        form.validateFields(['address1','address2'])
        .then(values => {
        })
        .catch(errorInfo => {
            const errorFields = errorInfo.errorFields;  
            //setRatesError(() => errorFields);         
        });
    },[activeCourier]);

    return (
        <FormWrapper>
            <FormHeader className="greenBoldTitle">Consignee Details</FormHeader>
            <FormBody>
                <Row gutter={16}>
                    <Col lg={6}>
                        <Form.Item
                            name="personName"
                            label="Name"
                            rules={[
                                {
                                    required: true,
                                    message: "Receiver name is required",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="Name" />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="personEmail"
                            label="Email"
                            rules={[
                                {
                                    type: "email",
                                    required: true,
                                    message: "Please insert consignee email",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="Email" type="email" />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="personPhone"
                            label="Phone"
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "Consignee's contact number",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="companyName"
                            label="Company"
                            rules={[
                                {
                                    required: true,
                                    message: "Insert customer name if they don't have company",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={8}>
                        <Form.Item
                            name="address1"
                            label="Address Line 1"
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "Address 1 is required",
                                },
                                addrLenRule,
                                {/*
                                    {
                                        max: 45,
                                        message: "Value should be less or equal to 45 character",
                                    },
                                */}
                            ]}                           
                            hasFeedback
                            extra="Eg. Apartment Number, Unit, Block"
                        >
                            <Input placeholder="Unit 10, Level 2, Manor Apartment"/>
                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        <Form.Item
                            name="address2"
                            label="Address Line 2"
                            rules={[
                                {
                                    type: "string",
                                    required: false,
                                },
                                addrLenRule,
                                {/**
                                {
                                    max: 45,
                                    message: "Value should be less or equal to 45 character",
                                },
                                 */}
                                
                            ]}
                            hasFeedback
                            extra="Eg. Road, Street"
                        >
                            <Input placeholder="Abbey Road" />
                        </Form.Item>
                    </Col>
                    <Col lg={8}>
                        <Form.Item
                            name="city"
                            label="City"
                            rules={[
                                {
                                    type: "string",
                                    required: true,
                                    message: "City is required",
                                },
                            ]}
                            hasFeedback
                        >
                            <Input placeholder="London" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={6}>
                        <Form.Item name="provinceCode" label="Province Code" initialValue={"KR"} extra="Please choose country code first">
                            {activeProvinces.length > 1 ? (
                                <Select showSearch={true} style={{ width: "100%" }} onSelect={onSelectProvince}>
                                    {activeProvinces?.map((item, index) => (
                                        <Option key={index} value={item.name}>
                                            {item.name}
                                        </Option>
                                    ))}
                                </Select>
                            ) : (
                                <Input placeholder="No Need Province Code" disabled />
                            )}
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="division"
                            label="Division/State/Province"
                            rules={[
                                {
                                    type: "string",
                                    required: false,
                                },
                            ]}
                        >
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="countryCode"
                            label="Country Code"
                            rules={[
                                {
                                    required: true,
                                    message: "Please choose one",
                                },
                            ]}
                            initialValue={"KR"}
                            extra="Search using name"
                        >
                            <Select showSearch style={{ width: "100%" }} onSelect={(value) => onSelect(value, "countryCode")}>
                                {kodNegara.all().map((item, index) => (
                                    <Option key={index} value={item.countryNameEn}>
                                        {item.countryNameEn} ({item.countryCode})
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6}>
                        <Form.Item
                            name="postal"
                            label="Postal Code"
                            rules={[
                                {
                                    required: true,
                                    message: "Postal Code is required",
                                },
                            ]}
                            extra="Eg. 93050"
                            hasFeedback
                        >
                            <Input placeholder="93050" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={6}>
                        <Form.Item
                            name="iossNumber"
                            label="IOSS Number"
                            rules={[
                                {
                                    required: false,
                                },
                            ]}
                            extra="Eg. 3720000225"
                            hasFeedback
                        >
                            <Input placeholder="3720000225" />
                        </Form.Item>
                    </Col>
                    <Col g={6} >
                        <Form.Item
                            name="isPoBox"
                            label="PO Box Status"
                            valuePropName="checked"
                        >   
                            
                            <Checkbox>Is the address a PO Box?</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
            </FormBody>
        </FormWrapper>
    );
};

ConsigneeDetail.propTypes = {
    consignee: PropTypes.object,
    onChange: PropTypes.func,
};

export default ConsigneeDetail;
