import { Avatar, Button, Row, Col, Typography, message, Spin, DatePicker, Modal, Input, Space } from "antd";
import React, { useState, useEffect } from "react";
import Path from "../../commons/breadcrumbs";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import moment from "moment";
import axios from "axios";


const DownloadPicsTest = () => {
    const downloadPics = () => {
        axios.get(process.env.REACT_APP_API_URL + "/api/accounting/dl-pics-test", {
            headers: {                      
                "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                //"Content-Type": "multipart/form-data"
            },   
            responseType: "blob",
            timeout: 0                 
        })
        .then(response => {                    
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `ZIP_${moment().format("YYYYMMDD-HHmmss")}.zip`);
            document.body.appendChild(link);
            link.click();
        })
        .catch(err => {                  
            if(err.response?.status === 404)
                alert('No zip found');  
        });
    };

    return (<>
        <TitleHeader
            listOfBreadcrumb={[
                { name: "Home", path: "/" },
                { name: Path.DEV.name, path: Path.DEV.pathname },
                {
                    name: Path.DL_PICS_TEST.name,
                },
            ]}
            title={Path.DL_PICS_TEST.name}
        />
        <Box>
            <Container>                      
                <Row gutter={[16, 16]}>
                    <Button type="primary" onClick={downloadPics}>TEST PICS DL</Button>
                </Row>
            </Container>
        </Box>
    </>);
};

export default DownloadPicsTest;
/*
const kayeTest = () => {
    axios.get(process.env.REACT_APP_API_URL + "/api/accounting/kaye-test", {
        headers: {                      
            "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
            //"Content-Type": "multipart/form-data"
        },   
        responseType: "blob",
        timeout: 0                 
    })
    .then(response => {                    
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `ZIP_${moment().format("YYYYMMDD-HHmmss")}.zip`);
        document.body.appendChild(link);
        link.click();
    })
    .catch(err => {                  
        if(err.response?.status === 404)
            alert('No zip found');  
    });
}*/