import {
    Button,
    Col,
    Divider,
    Input,
    message,
    Row,
    Select,
    Space,
    Table,
    Typography,
    List,
    Spin,
    Avatar,
    Image,
  } from "antd";
  import purchaseApi from "apis/purchaseApi";
  import Container from "components/container/Container";
  import produce from "immer";
  import { useEffect, useState } from "react";
  import { useTranslation } from "react-i18next";
  import { useLocation } from "react-router";
  import { useHistory } from "react-router-dom";
  import Path from "../../commons/breadcrumbs";
  import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
  import { TitleBox } from "./index.style";
  import queryString from "query-string";
  import styled from "styled-components";
  import DragDropFile from "components/drag-drop-image";
  import { debounce, isArray } from "lodash";
  import LinkButton from "components/linkButton/LinkButton";
  import productsApi from "apis/productsApi";
  
  const { Title } = Typography;
  const { Column } = Table;
  const { Option } = Select;
  
  function sleep(ms) { 
    return new Promise(resolve => setTimeout(resolve, ms)); 
  }
  const PartialCheckIn = () => {    
    const { search } = useLocation();
    const poId = queryString.parse(search).id;
    const [isLoading, setIsLoading] = useState(false);
    const [purchaseItems, setPurchaseItems] = useState([]);
    const [purchaseOrder, setPurchaseOrder] =  useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loadingVariantImage, setLoadingVariantImage] = useState(false);
    const history = useHistory();
    // selected rows
    const rowSelection = {
        onChange: (selectedRowKeys) => {
        console.log("ACTIVE PURCHASE ITEM", selectedRowKeys);
        setSelectedRowKeys(selectedRowKeys);
        },
    };

    const numberChangeHandler = () => {};

    const handleCheckInQtyChange = (e, index) => {
        const arr = purchaseItems.map((s,i) => {
            if (i === index) {
                s.checkInQty = e.target.value;
            }

            return s
        });

        setPurchaseItems(arr);  
    };

    const createPartialCheckIn = async () => {
        const selectedItems = purchaseItems.filter(l => {
            if(selectedRowKeys.includes(l.id))
                return l;
        });

        console.log("SELECTED ITEMS", selectedItems);
        //return;
        const checkInItems = selectedItems.map(s => {
            const obj = {
                productVariantId: s.variantId,
                quantity: s.checkInQty,                
            }

            return obj;
        });

        const payload = {
            items: checkInItems,
            orderNum: purchaseOrder.linkedOrder,
            purchaseId: purchaseOrder.orderNumber,
            remark: "PARTIAL CHECK-IN"
        }
        
        purchaseApi.partialCheckInOrder(payload)
        .then(async r => {
            console.log("R",r);
            message.success("Purchase order succesfully partially checked in");   
            await sleep(1000);
            message.success("Redirecting to purchase order details...");
            await sleep(2000);
            history.push(Path.PURCHASE_ORDER_DETAILS.pathParam(purchaseOrder.orderNumber));  
        })
        .catch(err => {
            console.log("ERR ", err);
            message.error("Packing failed. Error: ", err);
        });
    };

    useEffect(()=>{
        console.log("POID", poId);
        if (poId) {
            purchaseApi.getPurchaseOrder(poId)
            .then(r => {
                console.log("PO", r);
                setPurchaseOrder(r?.purchaseOrderDetails);
                const items = r?.purchaseOrderDetails?.purchaseItems?.map(m => {
                    return {
                        key: m.id,
                        checkInQty: 1,
                        ...m
                    };
                });

                //console.log("ITEMSS", items)
                setPurchaseItems(items);
            })
            .catch(err => {
                console.log("PO ERROR", err);
            })
        }
    },[])
    return (
        <div>
            <TitleBox className="title-page">
                <Title>Purchase Partial Check-In</Title>
                <Breadcrumbs
                    items={[
                        { name: "Home", path: "/" },
                        {
                        name: Path.PENDING_PURCHASE.name,
                        path: Path.PENDING_PURCHASE.pathname,
                        },
                        { name: Path.PURCHASING_PARTIAL_CHECK_IN.name },
                    ]}
                />
            </TitleBox>
            <Container>                
                <Table
                    loading={isLoading}
                    dataSource={purchaseItems}
                    rowSelection={{
                        ...rowSelection,
                    }}
                    pagination={false}
                >
                    <Column
                        title="Product"
                        dataIndex="variantName"
                        key="variantName" 
                        render={(value,record) => {
                            return record.productName + ' ' + record.variantName
                        }}              
                    />
                    <Column
                        title="Image"
                        dataIndex="variantImage"
                        key="variantImage"
                        render={(value) => {
                        if (loadingVariantImage) {
                            return <Spin />;
                        }
                        if (!value) {
                            return (
                            <Avatar size={82} shape="square" src={"/no-image.png"} />
                            );
                        }
                        return (
                            <Image width={82} src={value} />
                        );
                        }}
                    />
                    <Column
                        title="Supplier"
                        dataIndex="supplier"
                        key="supplier"
                        render={(value, record, index) => {
                        return (
                            <>
                                {value}
                            </>
                        );
                        }}
                    />
                    <Column
                        title="Pending Quantity"
                        dataIndex="pendingQty"
                        key="pendingQty"
                    />
                    <Column
                        title="Purchased Quantity"
                        dataIndex="purchasedQty"
                        key="purchasedQty"
                        
                    />
                    <Column
                        title="Check-In Quantity"
                        dataIndex="checkInQty"
                        render={(value,record,index)=> {
                            return(<>
                                <Input type="text" addonAfter="PCS" value={record.checkInQty} onChange={(e) => handleCheckInQtyChange(e,index)}/>
                            </>);
                        }}
                    />
                    <Column
                        title="Supplier Price"
                        dataIndex="supplierPrice"
                        key="supplierPrice"
                        render={(value, record, index) => {
                        return (
                            <>                        
                                {value}
                            </>

                        );
                        }}
                    />            
                </Table>
                <Divider />
                <Row style={{ marginBottom: "30px" }}>
                <Col md={14} style={{ paddingRight: "30px" }}>
                   
                </Col>
                <Col md={10}>
                    
                </Col>
                </Row>
                <Row align="end">
                <Space>
                    <Button
                        disabled={!selectedRowKeys.length}
                        type="primary"
                        onClick={createPartialCheckIn}
                    >
                        Check-In
                    </Button>                   
                </Space>
                </Row>
            </Container>
        </div>
    );
  }

  export default PartialCheckIn;