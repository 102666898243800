import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { mediaApi } from "apis/mediaApi";
import purchaseApi from "apis/purchaseApi";
import styled from "styled-components";

const { Dragger } = Upload;

const DragDropFile = ({ selectedRowKeys, purchaseItem, setUploaded, getOrder, getPurchaseOrderDetail }) => {

  // const activePurchaseItem = purchaseItem.filter((item) => 
  //    selectedRowKeys.includes(item.id)
  // );
  // console.log('active', activePurchaseItem)

  const uploadProof = (options) => {
    const { onSuccess, onError, file } = options;
    const formData = new FormData();
    formData.append("file", file);
    mediaApi
      .uploadProofOfPurchase(formData)
      .then(async ({ proofOfPurchase }) => {
        console.log("RESPONSE FROM UPLOADED IMAGE!!!", proofOfPurchase);       

        onSuccess("Uploaded");      

        const fileUrl = proofOfPurchase.fileUrl || "n/a";

        console.log('activePurchaseItem', purchaseItem);
        // Promise.all(
        //   await activePurchaseItem.map((item) =>
        //     purchaseApi.putUpdateProofOfPurchase(item.id, proofOfPurchase.id)
        //   )
        //  )
     
          await purchaseItem.map(async (item) =>
            await purchaseApi.putUpdateProofOfPurchase(item.id, proofOfPurchase.id)
          )
        
          getPurchaseOrderDetail().then(() => {
            message.success("Succesfully update Proof of Purchase", 3); 
            setUploaded(true);
            // return foundPurchaseItem;
          })
          getOrder(); 
         

          // .then(({ foundPurchaseItem }) => {
          //   console.log('found', foundPurchaseItem);
          //   message.success("Succesfully update Proof of Purchase", 3); 
          //   getOrder();       
            
          //   //----------------this is for COP notif
          //   // console.log("ORDER NUMBER", orderNumber, "ORDER SOURCE", orderSource) 
            
          //   // //COP Webhooks 3.2
          //   // if (process.env.REACT_APP_DEVELOPMENT) {
          //   //   if (orderSource === "custom" || orderSource === "cop") { 
          //   //     //trigger webhook for custom platform               
          //   //     const items = activePurchaseItem.map(n => {
          //   //       return {
          //   //         item_id: n.id,
          //   //         photos: fileUrl
          //   //       }
          //   //     });
          //   //     const payload = {
          //   //       orderNo: orderNumber,
          //   //       items: items
          //   //     };
          //   //     console.log("CUSTOM ITEM LA!3");
          //   //     purchaseApi.notifyItemsArrival(payload)
          //   //     .then(r => console.log("COP ITEM ARRIVAL WEBHOOK RESPONSE",r));
          //   //   }            
          //   // }

          //   setUploaded(true);
          
          // })
          
      })
      .catch((error) => onError(error.message));
  };

  return (
    <CustomDragger>
      <Dragger
        // disabled={!activePurchaseItem.length}
        className="parrent"
        name="file"
        multiple={true}
        customRequest={uploadProof}
        showUploadList={true}
        onChange={(info) => console.log(info)}
        fileList={[]} //empty
      >
        <p
          className="ant-upload-drag-icon"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <InboxOutlined  />
        </p>
        <p className="ant-upload-text">
          Please select a purchase to enable upload..
        </p>
        <p className="ant-upload-hint">Support for a single or bulk upload.</p>
      </Dragger>
    </CustomDragger>
  );
};

export default DragDropFile;

const CustomDragger = styled.div`
  span {
    display: flex;
    justify-content: start;
    .ant-upload.ant-upload-drag {
      width: 50%;
      margin: 20px;
      justify-self: center;
    }

    .ant-upload-list.ant-upload-list-picture {
      width: 50%;
      margin: 20px;

      .ant-upload-list-item {
        border: 1px solid black;
        width: 200px;
        margin-right: 8px;
      }
    }
  }
`;
