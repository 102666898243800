import { Badge, Button, Col, Row, Tag, message, Checkbox } from "antd";
import ordersApi from "apis/ordersApi";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Path from "../../../commons/breadcrumbs";
import FilterTable from "../../../components/table/FilterTable";
import { CombineModalStyled } from "./styles";
import { useHistory } from "react-router-dom";

const columns = (t) => [
    {
        title: t("Order"),
        align: "center",
        key: "id",
        dataIndex: "id",
        width: 50,
        className: "order-id",
        sorter: ({ id: a }, { id: b }) => a - b,
        filter: { dataIndex: "id" },
        render: (item, record) =>
            record.combinedOrderId ? (
                <Link to={`${Path.ORDER_DETAIL.pathParam(record.combinedOrderId)}?combine=true`}>#{record.combinedOrderId}</Link>
            ) : (
                <Link to={Path.ORDER_DETAIL.pathParam(item)}>{record.orderNumber}</Link>
            ),
    },
    {
        title: t("Customer"),
        dataIndex: "customerEmail",
        key: "customerEmail",
        sorter: ({ email: a }, { email: b }) => (a || "").localeCompare(b || "", "en", { sensitivity: "base" }),
        filter: { dataIndex: "customerEmail" },
        render: (email, record) => (
            <>
                <div>{email}</div>
                {record.trackingNumber ? (
                    <div>
                        <Tag color="#28a745" style={{ borderRadius: 20 }}>
                            {record.trackingNumber}
                        </Tag>
                    </div>
                ) : (
                    ""
                )}
            </>
        ),
    },
    {
        title: t("Source"),
        dataIndex: "source",
        key: "source"
    },
    {
        title: t("Date ordered"),
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        sorter: ({ createdAt: a }, { createdAt: b }) => moment(a).unix() - moment(b).unix(),
        filter: { dataIndex: "createdAt", type: "date" },
        render: (item) => moment(item).format("DD MMM YYYY, hh:MM a"),
    },
    {
        title: t("Financial status"),
        dataIndex: "financialStatus",
        key: "financialStatus",
        width: 160,
        className: "financial-status",
        sorter: ({ financialStatus: a }, { financialStatus: b }) => (a || "").localeCompare(b || "", "en", { sensitivity: "base" }),
        filters: [
            { text: "Paid", value: "paid" },
            { text: "Pending", value: "pending" },
            { text: "Voided", value: "voided" },
        ],
        onFilter: (value, record) => record.financialStatus?.indexOf(value) === 0,
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (item) => {
            let color = "success";
            switch (item) {
                case "paid":
                    color = "success";
                    break;
                case "pending":
                    color = "warning";
                    break;
                case "voided":
                    color = "danger";
                    break;
                default:
                    break;
            }
            return <Badge color={color}>{item}</Badge>;
        },
    },
    {
        title: t("Status"),
        dataIndex: "status",
        key: "status",
        align: "left",
        width: 180,
        sorter: ({ status: a }, { status: b }) => (a || "").localeCompare(b || "", "en", { sensitivity: "base" }),
        filters: [
            { text: "Confirmed", value: "confirmed" },
            { text: "Received", value: "received" },
            { text: "Cancelled", value: "cancelled" },
        ],
        onFilter: (value, record) => record.status?.indexOf(value) === 0,
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        render: (item) => {
            let color = "#87d068";
            switch (item) {
                case "confirmed":
                    color = "#28a745";
                    break;
                case "received":
                    color = "#17a2b8";
                    break;
                case "cancelled":
                    color = "#dc3545";
                    break;
                default:
                    break;
            }
            return (
                <Tag color={color} style={{ borderRadius: 20 }}>
                    {item}
                </Tag>
            );
        },
    },
];

function CombineModal({ visible, onCancel, onSuccess, parentPage='' }) {
    const [data, setData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const history = useHistory();
    const [combinedOrderId, setCombinedOrderId] = useState();     
    const [unshippedOnly, setUnshippedOnly] = useState(true);
    const [saveBtnTitle, setSaveBtnTitle] = useState('');
    const [sameSourceOnly, setSameSourceOnly] = useState(true);
    
    const populateData = async () => {
        let rows = [];
        const combos = visible?.combinedOrders || [];
        //const sameSourceOnly = true;

        /*
        if(unshippedOnly){
            if (sameSourceOnly) {
                console.log("SAME SOURCE ONLY && UNSHIPPED");
                rows = visible?.possibleCombinedOrders.filter((item) => item.id !== visible.id && item.status !== "refunded" && item.source === visible.source);                            
            } else {
                rows = visible?.possibleCombinedOrders.filter((item) => item.id !== visible.id && item.status !== "completed" && item.status !== "refunded")
                                //.sort((a,b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0))
                                .filter((item) => item.trackingNumber == null);
            }
            
        } else {
            if (sameSourceOnly) {
                console.log("SAME SOURCE ONLY && INCLUDE SHIPPED");
                rows = visible?.possibleCombinedOrders.filter((item) => item.id !== visible.id && item.status !== "refunded" && item.source === visible?.source);                            
            } else {
                rows = visible?.possibleCombinedOrders.filter((item) => item.id !== visible.id && item.status !== "refunded");
                            //.sort((a,b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
            }
        }
        */
        rows = visible?.possibleCombinedOrders.filter((item) => item.id !== visible.id && item.status !== "refunded");
        if (unshippedOnly) 
            rows = rows?.filter((item) => item.trackingNumber == null && item.status !== "completed");
        
        if (sameSourceOnly)
            rows = rows?.filter((item) => item.source === visible.source);

        //append rows with orders in combinedOrders        
        const append =  visible?.possibleCombinedOrders.filter(v => combos.includes(v.id));
        console.log('COMBOS', combos);
        console.log('APPEND',append);
        
        append?.map(a => {
            if(!rows.includes(a)) 
                rows.push(a);
        });
        
        //we sort it
        rows?.sort((a,b) => (a.orderNumber > b.orderNumber) ? 1 : ((b.orderNumber > a.orderNumber) ? -1 : 0));
        
        console.log('ROWS', rows);
        setData(rows);
    };

    useEffect(async() => {    
        populateData();     
    },[unshippedOnly, sameSourceOnly]);

    useEffect(async () => {
        //console.log('VISBLE', visible); 
        if(visible?.id){
            const tmpComboId = await ordersApi.getCombinedOrderById(visible?.id);
            setCombinedOrderId(tmpComboId.id);     
        }         

        if (visible?.id) {
            populateData();

            const combinedOrders = visible?.combinedOrders || [];
            setSelectedRowKeys(combinedOrders);
        }
        return () => {
            setSelectedRowKeys([]);
            setData([]);
        };
    }, [visible]);

    const handleCombine = async () => {
        setLoading(true);
        try {
            if (!visible?.combinedOrders?.length) {                
                const res = await ordersApi.createCombineOrder({
                    orders: [...selectedRowKeys, visible.id],
                });

                //For new orders
                if (res?.combinedOrder?.id) {
                    message.success("Saved combined order successfully");                    
                    
                    let orderNo = visible?.orderNumber;
                    let source = visible?.source;
                    
                    if (visible?.orderNumber.includes('#')) {
                        orderNo = visible?.orderNumber.split('#')[1];                    
                    }
                    
                    console.log("main ID new " + orderNo);                                    
                            
                    switch(parentPage){
                        case("order-detail"):
                            onSuccess();
                            break;
                        case("orders"):{
                            history.push(`/create-shipment?source=${source}&orderNumber=${orderNo}`);
                            break;
                        }
                    }
                    //history.push(`/create-shipment?source=shopify&orderNumber=${orderNo}`);
                    //onSuccess();
                } else {
                    //message.error("Save combined order failure");
                    message.error(res?.response?.data?.message);
                }
                return;
            }

            const removeIds = [];
            const appendIds = [];
            selectedRowKeys.forEach((selectedKey) => {
                if (!visible.combinedOrders.includes(selectedKey) && selectedKey !== visible.id) {
                    appendIds.push(selectedKey);
                }
            });
            visible.combinedOrders?.forEach((combinedOrder) => {
                if (!selectedRowKeys.includes(combinedOrder) && combinedOrder !== visible.id) {                   
                    removeIds.push(combinedOrder);
                }
            });

            /*
            await Promise.all([
                ...removeIds?.map(async (o) => {                
                    console.log('calling removerOrderFromCombineOrder',o);
                    ordersApi.removeOrderFromCombineOrder({
                        //id: visible.combinedOrderId,
                        //data: { order: order.id },
                        id: combinedOrderId,
                        order: o
                    })
                }),
                ...appendIds?.map(async (o) => {
                    console.log('calling appendOrderToCombineOrder',o);
                    ordersApi.appendOrderToCombineOrder({
                        //id: visible.combinedOrderId,
                        //data: { order: order.id },
                        id: combinedOrderId,
                        order: o
                    })
                }),
            ]);*/

            //Switching to use blocking for-loop for manageable synch operations. Apparently, calling the
            //append endpt causing race condition; only latest call will go thru while the earlier one dropped
            let errorMsg = '';
            for(let i =0; i < removeIds.length; i++){
                const result = await ordersApi.removeOrderFromCombineOrder({                   
                    id: combinedOrderId,
                    order: removeIds[i]
                });
                
                if(result?.response?.data?.error){
                    errorMsg = errorMsg + ', ' + result?.response?.data?.message;
                    //message.error(result?.response?.data?.message);
                    break;
                }
            }

            for(let i =0; i < appendIds.length; i++){
                const result = await ordersApi.appendOrderToCombineOrder({                   
                    id: combinedOrderId,
                    order: appendIds[i]
                });
                 
                if(result?.response?.data?.error){
                    errorMsg = errorMsg + ', ' + result?.response?.data?.message;
                    //message.error(result?.response?.data?.message);
                    break;
                }
            }

            if(errorMsg){
                message.error(errorMsg);
                return;
            }

            message.success("Saved combined order successfully");           
            
            const orderNo = visible?.orderNumber.split('#')[1];
            console.log("main ID new " + orderNo);
            
            switch(parentPage){
                case("order-detail"):
                    onSuccess();
                    break;
                case("orders"):{
                    history.push(`/create-shipment?source=shopify&orderNumber=${orderNo}`);
                    break;
                }
            }

            //history.push(`/create-shipment?source=shopify&orderNumber=${orderNo}`);
            //onSuccess();
        } catch (error) {
            console.log(error);
            message.error("Save combined order failure");
        } finally {
            setLoading(false);
        }
    };

    const onSelectChange = (value) => {
        setSelectedRowKeys(value);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const onChange = (e) => {        
        setUnshippedOnly(e.target.checked);
    };

    
    const onChangeSameSource = (e) => {
        setSameSourceOnly(e.target.checked);        
    }


    useEffect(() => {
        switch(parentPage){
            case("order-detail"):
                setSaveBtnTitle("Save Combination");
                break;
            case("orders"):
                setSaveBtnTitle("Save & Create Shipment");
                break;
            default:
                setSaveBtnTitle("Undefined Button");
                break;
        }
    },[]);
    return (
        <CombineModalStyled title={`Combinable Orders of #${visible?.id}`} visible={!!visible} onCancel={onCancel} footer={null} width="80%" destroyOnClose>
            <Row justify="end" style={{ paddingTop: 16 }}>
                <Col style={{ paddingBottom: 16 }}>
                    <Checkbox onChange={onChangeSameSource} checked={sameSourceOnly}>Same source only</Checkbox>
                    <Checkbox onChange={onChange} checked={unshippedOnly}>Exclude Shipped & Completed Orders</Checkbox>
                    <Button type="primary" onClick={handleCombine} loading={loading}>
                        {saveBtnTitle}
                    </Button>
                </Col>
            </Row>
            <FilterTable bordered rowSelection={rowSelection} columns={columns(t)} dataSource={data} rowKey="id" />
        </CombineModalStyled>
    );
}

CombineModal.propTypes = {
    handleGetList: PropTypes.func,
};

export default CombineModal;
