import { Avatar, Button, Row, Col, List, Typography, message, Spin, Checkbox, Space, Card, Tag } from "antd";
import accountApi from "apis/accountApi";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import { OrderDetailPageStyled, TitleBox } from "./index.styles";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import LocalStorage, { SessionStorage} from "utils/storage";
import Path from "../../../commons/breadcrumbs";
//import { HeaderList, OpControls } from "./index.style";

const { Title, Paragraph } = Typography;
const CheckboxGroup = Checkbox.Group;

const ChangePermissions = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState();
    const [modules, setModules] = useState();
    const [checkedList, setCheckedList] = useState();

    const allModules = ["all","accounting","basic","shipment","orders","inventory","packing","email","comment","products","purchasing","media","test"];
    const { id } = useParams();
    
    const getUser = async () => {
        setIsLoading(true);
        accountApi.getUser(id)
        .then((res) => {
            console.log("USER RES", res);
            setUser(res);
            setModules(res?.profile?.modules? res.profile.modules:[]);
            setCheckedList(res?.profile?.modules? res.profile.modules:[]);
       })

       setIsLoading(false);
    };

    const onChange = (list) => {
        setCheckedList(list);
    };

    const updatePermissions = async () => {
        const payload = {
            userId:id,
            data: {
                profile: {
                    modules: checkedList
                }
            }
        };

        accountApi.updatePermissions(payload)
        .then((res) => {
            setIsLoading(true);

            console.log('UPDATE PERMISSIONS RES', res);
            setModules(res?.profile?.modules? res.profile.modules:[]);
            setCheckedList(res?.profile?.modules? res.profile.modules:[]);

            setIsLoading(false);
        });
    };

    useEffect(() => {
        console.log('ID', id);
        getUser();
    },[]);

    return (<>
        <TitleHeader
            listOfBreadcrumb={[
                { name: "Home", path: "/" },
                { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
                {
                    name: Path.USERS_AND_PERMISIONS.name, path: Path.USERS_AND_PERMISIONS.pathname,
                },
                {
                    name: Path.CHANGE_PERMISSIONS.name,
                },
            ]}
            title={Path.CHANGE_PERMISSIONS.name}
        />
        
            <OrderDetailPageStyled title="Change Permission">
                <Spin spinning={isLoading}>
                    <Title level={4}>User Info</Title>
                    <Row>
                        <Col xs={24} md={16}>
                            <Card className="order-info">
                                <div className="order-details">
                                    <Row className="detail-row">
                                        <Col xs={24} md={12} className="box-data">
                                            <div className="label">Username</div>
                                            <div className="data">                                           
                                                {user?.credentials?.userName || ''}                                        
                                            </div>
                                        </Col>
                                        <Col xs={24} md={12} className="box-data">
                                            <div className="label">User Id</div>
                                            <div className="data">                                           
                                                {user?.id || ''}                                        
                                            </div>
                                        </Col>
                                        <Col xs={24} md={8} className="box-data">
                                            <div className="label">Name</div>
                                            <div className="data">                                           
                                                {user?.profile?.name || ''}                                        
                                            </div>
                                        </Col>
                                        <Col xs={24} md={8} className="box-data">
                                            <div className="label">Locale</div>
                                            <div className="data">                                           
                                                {user?.profile?.locale || ''}                                        
                                            </div>
                                        </Col>
                                        <Col xs={24} md={8} className="box-data">
                                            <div className="label">Status</div>
                                            <div className="data">                                           
                                                {user?.status || ''}                                        
                                            </div>
                                        </Col>
                                        <Col xs={24} md={24} className="box-data">
                                            <div className="label">Current Permissions</div>
                                            <div className="data">                                           
                                                {modules? (modules.map((i) => {
                                                    return ( <Tag color="#108ee9">{i}</Tag>)
                                                })):(<></>)}      
                                                <br/>
                                                <br/>                                      
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card title="Permissions List">
                                <Space direction="vertical">
                                    {/**
                                    <Checkbox>Basic</Checkbox>
                                    <Checkbox>Shipment</Checkbox>
                                    <Checkbox>Orders</Checkbox>
                                    <Checkbox>Inventory</Checkbox>
                                    <Checkbox>Packing</Checkbox>
                                    <Checkbox>Email</Checkbox>
                                    <Checkbox>Comment</Checkbox>
                                    <Checkbox>Products</Checkbox>
                                    <Checkbox>Purchasing</Checkbox>
                                    <Checkbox>Media</Checkbox>
                                    <Checkbox>All</Checkbox>
                                    <Checkbox>Test</Checkbox>
                                     */}
                                    <CheckboxGroup options={allModules} value={checkedList} onChange={onChange} />
                                    <Button type="primary" onClick={updatePermissions}>Update</Button>
                                </Space>
                            </Card>                           
                        </Col>
                    </Row>
                </Spin>
            </OrderDetailPageStyled>
        
    </>);
};

export default ChangePermissions;