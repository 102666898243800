import { ShoppingCartOutlined } from "@ant-design/icons";
import { Icon } from "@iconify/react";
import React from "react";
import Breadcrumbs from "./breadcrumbs";

export const menu = [
    {
        name: "Orders",
        key: "orders",
        permission: ["orders","all"],
        icon: <ShoppingCartOutlined />,
        url: Breadcrumbs.ORDERS.pathname,
        children: [
            {
                url: Breadcrumbs.ORDERS.pathname,
                name: "All Orders",                
                permission: ["all","orders","accounting","shipment"],
                boldName: false,
            },
            {
                url: Breadcrumbs.MANUAL_ORDERS_UPLOAD.pathname,
                name: "Import Orders",                
                permission: ["all","orders","accounting","shipment"],
                boldName: false,
            },
        ]
    },
    {
        name: "Shipments",
        key: "shipments",
        permission: ["shipment","all"],
        icon: <Icon icon="mdi:truck-delivery" />,
        url: Breadcrumbs.SHIPMENTS.pathname,
        children: [
            // {
            //   url: Breadcrumbs.READY_TO_SHIP.pathname,
            //   name: 'Ready To Ship',
            //   permission: ['readyToShip'],
            //   boldName: false,
            // },
            {
                url: Breadcrumbs.CREATE_SHIPMENT.pathname,
                name: "Create Shipment",
                //permission: ["createShipment"],
                permission: ["shipment","all"],
                boldName: false,
            },
            {
                url: Breadcrumbs.RATE_SHIPMENT.pathname,
                name: "Rate Shipment",
                //permission: ["rateShipment"],
                permission: ["shipment","all"],
                boldName: false,
            },
            // {
            //   url: Breadcrumbs.TODAYS_SHIPMENT.pathname,
            //   name: "Today's Shipment",
            //   permission: ['todayShipment'],
            //   boldName: false,
            // },
            {
                url: Breadcrumbs.SHIPMENTS.pathname,
                name: "All Shipments",
                //permission: ["allShipments"],
                permission: ["shipment","all"],
                boldName: false,
            },
            {
                url: Breadcrumbs.SHIPPER_DETAILS.pathname,
                name: "Shipper Details",
                //permission: ["shipperDetails"],
                permission: ["shipment","all"],
                boldName: false,
            },
            {
                url: Breadcrumbs.BULK_LABEL_PRINT.pathname,
                name: "Bulk Label Print",
                permission: ["shipment","all"],
                boldName: false,
            },
            {
                url: Breadcrumbs.SHIP_FEES_TABLE.pathname,
                name: "Shipment Fees Table",
                permission: ["shipment","all"],
                boldName: false,
            }
            // {
            //   url: Breadcrumbs.COURIER_LIST.pathname,
            //   name: 'Courier List',
            //   permission: ['courierList'],
            //   boldName: false,
            // },
        ],
    },
    {
        name: "Products",
        key: "products",
        permission: ["products","all"],
        icon: <Icon icon="carbon:product" />,
        children: [
            {
                url: Breadcrumbs.PRODUCTS.pathname,
                name: "Products",
                boldName: false,
                permission: ["all","products"],
            },
            // {
            //   url: Breadcrumbs.CREATE_PRODUCT.pathname,
            //   name: "Create Product",
            //   boldName: false,
            // },
            {
                url: Breadcrumbs.BEENOS_EXPORT.pathname,
                name: "BUYEE Export",
                boldName: false,
                permission: ["all","products"],
            },
            {
                url: Breadcrumbs.SNAPPY_EXPORT.pathname,
                name: "SNAPPY Export",
                boldName: false,
                permission: ["all","products"],
            },
            {
                url: Breadcrumbs.MERCARI_EXPORT.pathname,
                name: "MERCARI Export",
                boldName: false,
                permission: ["all","products"],
            },
            {
                url: Breadcrumbs.STOCK_ADJUSTMENT.pathname,
                name: "Stock Adjustment",
                permission: ["all","products"],
            },
        ],
    },
    // {
    //   name: "Inventory",
    //   key: "inventory",
    //   permission: [],
    //   icon: <Icon icon="carbon:inventory-management" />,
    //   url: Breadcrumbs.INVENTORY.pathname,
    // },
    {
        name: "Purchases",
        key: "purchases",
        permission: ["purchasing","all"],
        icon: <Icon icon="clarity:dollar-line" />,
        url: Breadcrumbs.PURCHASES.pathname,
        children: [
            {
                url: Breadcrumbs.PENDING_PURCHASE.pathname,
                name: "Pending Purchases",
                permission: ["all","purchasing"],
                boldName: false,
            },
            {
                url: Breadcrumbs.PURCHASE_ORDERS.pathname,
                name: "Purchase Orders",
                permission: ["all","puchasing"],
                boldName: false,
            },
            // {
            //   url: Breadcrumbs.SUPPLIER_LIST.pathname,
            //   name: "Supplier List",
            //   boldName: false,
            // },
            // {
            //   url: Breadcrumbs.PROOF_OF_PURCHASE.pathname,
            //   name: "Proof of Purchase",
            //   boldName: false,
            // },
        ],
    },
    {
        name: "Customer Services",
        key: "custServices",        
        permission: ["all"],
        icon: <Icon icon="ri:customer-service-line" />,
        url: Breadcrumbs.CUST_SERVICES.pathname,
        children: [
            {
                url: Breadcrumbs.ORDER_SHIP_RATE_CHECK,
                name: "Shiprate Check",
                //permission: ["storeDetails"],
                permission: ["all"],
                boldName: false,
            },
        ]
    },
    {
        name: "Settings",
        key: "settings",
        //permission: ["settings"],
        permission: ["all"],
        icon: <Icon icon="icon-park-outline:setting-config" />,
        url: Breadcrumbs.SETTINGS.pathname,
        children: [
            {
                url: Breadcrumbs.STORE_DETAILS.pathname,
                name: "Store Details",
                //permission: ["storeDetails"],
                permission: ["all"],
                boldName: false,
            },
            {
                url: Breadcrumbs.USERS_AND_PERMISIONS.pathname,
                name: "Users And Permissions",
                //permission: ["usersAndPermissions"],
                permission: ["all"],
                boldName: false,
            },
            {
                url: Breadcrumbs.COURIERS.pathname,
                name: "Courier Accounts",
                //permission: ["usersAndPermissions"],
                permission: ["all"],
                boldName: false,
            },
            // {
            //   url: Breadcrumbs.SALES_AND_CHANNELS.pathname,
            //   name: "Sales And Channels",
            //   permission: ["salesAndChannels"],
            //   boldName: false,
            // },
            {
                url: Breadcrumbs.LANGUAGES.pathname,
                name: "Languages",
                //permission: ["languages"],
                permission: ["all"],
                boldName: false,
            },
            {
                url: Breadcrumbs.POLICIES.pathname,
                name: "Policies",
                //permission: ["policies"],
                permission: ["all"],
                boldName: false,
            },
            {
                url: Breadcrumbs.BULK_FETCH.pathname,
                name: "Bulk Fetch",
                boldName: false,
            },
            {
                url: Breadcrumbs.BULK_EXPORT.pathname,
                name: "Bulk Export",
                boldName: false,
            },
            {
                url: Breadcrumbs.DB_BACKUP.pathname,
                name: "Db Backup",
                boldName: false,
            }
        ],
    },
    {
        name: "Accounting",
        key: "accounting",        
        permission: ["all","purchasing"],
        icon: <Icon icon="map:accounting" />,
        url: Breadcrumbs.ACCOUNTING.pathname,
        children: [
            {
                url: Breadcrumbs.TRANSACTIONS.pathname,
                name: "Transactions",                
                permission: ["all","accounting"],
                boldName: false,
            },
            {
                url: Breadcrumbs.ORDERS_TXNS.pathname,
                name: "Accounting Log",                
                permission: ["all","accounting"],
                boldName: false,
            },
            {
                url: Breadcrumbs.BOOK_EXPORT.pathname,
                name: "Book Export",                
                permission: ["all","accounting"],
                boldName: false,
            },
            {
                url: Breadcrumbs.UPLOAD_SHIPMENTS.pathname,
                name: "Upload Shipments",                
                permission: ["all","accounting"],
                boldName: false,
            },
            {
                url: Breadcrumbs.UPLOAD_DOMESTIC_FEES.pathname,
                name: "Upload Domestic Fees",                
                permission: ["all","accounting"],
                boldName: false,
            },
            {
                url: Breadcrumbs.UPLOAD_SUPPLIER_PRICES.pathname,
                name: "Upload Supplier Prices",                
                permission: ["all","accounting"],
                boldName: false,
            },
            {
                url: Breadcrumbs.UPLOAD_EDN.pathname,
                name: "Upload EDN",                
                permission: ["all","accounting"],
                boldName: false,
            },          
        ]
    }
];
