import { Button, Col, Divider, message, Modal, Row, Space, Spin, Typography, Form, DatePicker, Checkbox, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import shipmentsApi from "../../apis/shipmentsApi";
import Path from "../../commons/breadcrumbs";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Container from "../../components/container/Container";
import ShipmentTable from "./ShipmentTable";
import ShipmentFilterForm from "./ShipmentFilterForm";
import { DownloadButton, ShipmentPageStyled, TitleBox } from "./index.style";
import LiveFeedsData from "./LiveFeedsData";
import useStateCallback from "hooks/useStateCallback";
import { useHistory } from "react-router";
import queryString from "query-string";
import { moment, removeBlankNull } from "../../utils/funcs";
import axios from "axios";

const { Title, Paragraph, Text } = Typography;
const { RangePicker } = DatePicker;

const Shipments = () => {
    const [form] = Form.useForm();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [labelLoading, setLabelLoading] = useState(false);
    const [data, setData] = useState({});
    const { t } = useTranslation();
    const [init, setInit] = useState(false);
    const [range, setRange] = useState([moment().subtract(90, "days"), moment()]);
    const [isLatest, setIsLatest] = useState(false);
    const [isDHLonly, setIsDHLonly] = useState(false);
    const [isUPSonly, setIsUPSonly] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [emails, setEmails] = useState("selkpout@dhl.com, selkbsc@dhl.com");

    const [params, setParams] = useStateCallback({
        page: 1,
        pageSize: 10,
        search: "",
        createdAt: [],
        courier: [],
        sort: ["id_desc"],
    });

    const [labelModal, setLabelModal] = useState({
        label: {
            status: false,
            payload: [],
        },
        invoice: {
            status: false,
            payload: "",
        },
    });

    // fetch shipment data
    const getShipments = async () => {
        setIsLoading(true);
        const filter = [];
        if (params.courier?.length) {
            params.courier?.map((item) => filter.push(item));
        }
        if (params.createdAt?.length) {
            filter.push(`createdAt_gte=${params.createdAt[0].clone().startOf("date").utc().format()}`);
            filter.push(`createdAt_lte=${params.createdAt[1].clone().endOf("date").utc().format()}`);
        }

        const param = {
            page: params.page,
            pageSize: params.pageSize,
            search: params.search,
            filter,
            fields: [
                "id",
                "orderId",
                "sourceId",
                "courier",
                "airwayBillNumber",
                "pltStatus",
                "payload",
                "shippingFee",
                "printedCount",
                "invoiceBase64",
                "labelBase64",
                "labelZpl",
                "status",
            ],
            sort: params.sort,
        };
        try {
            const res = await shipmentsApi.getShipments(param);
            if (res === undefined) {
                return alert("Shipment is undefined");
            }
            const { shipment, totalCount } = res;
            const reformShipment = shipment?.map((item, index) => {
                return { ...item, key: index };
            });
            setData((prev) => {
                return { ...prev, shipment: reformShipment, totalCount };
            });
        } catch (error) {
            message.error(error, 3);
        } finally {
            setIsLoading(false);
        }
    };

    const downloadAll = (urls) => {
        const link = document.createElement("a");

        link.setAttribute("download", "label");
        link.style.display = "none";

        document.body.appendChild(link);

        for (let i = 0; i < urls.length; i++) {
            link.setAttribute("href", urls[i]);
            link.click();
        }

        document.body.removeChild(link);
    };
  

    const downloadExportData = async () => {
        const param = {
            startDate: range ? range[0].toISOString().split( "T" )[0] + 'T00:00:00.000Z' : moment().subtract(90, "days"),
            endDate: range ? range[1].toISOString().split( "T" )[0] + 'T23:59:59.999Z' : moment(),  //this results to enddate -1 day
            firstTimeDownload: false,
            fromLastItem : isLatest,
            isDHLOnly : isDHLonly,   
            isUPSOnly : isUPSonly      
        };

        if (isUPSonly) {
            console.log("UPS ONLY EXPORT FILE");
            axios({
                url: `${process.env.REACT_APP_API_URL}/api/shipment/get-export-list?${queryString.stringify(param)}`,
                method: "GET",
                responseType: "blob", // important
                headers: {
                    "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                },
            })
            .then((response) => {
                    console.log('getExportList res', response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `Export Shipment ${range ? range[0].format("YYYY-MM-DD") : moment().subtract(90, "days")} to ${range ? range[1].format("YYYY-MM-DD") : moment()}.zip`);
                    document.body.appendChild(link);
                    link.click();
                })
            .catch((err) => {            
                if(err.response?.status === 404)
                    alert('No valid exports found');            
            });
        } else {
            axios({
                url: `${process.env.REACT_APP_API_URL}/api/shipment/get-export-list?${queryString.stringify(param)}`,
                method: "GET",
                responseType: "blob", // important
                headers: {
                    "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                },
            })
            .then((response) => {
                    console.log('getExportList res', response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `Export Shipment ${range ? range[0].format("YYYY-MM-DD") : moment().subtract(90, "days")} to ${range ? range[1].format("YYYY-MM-DD") : moment()}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
            .catch((err) => {            
                if(err.response?.status === 404)
                    alert('No valid exports found');            
            });
        }
        

        /*original code
        const response = await axios({
            url: `${process.env.REACT_APP_API_URL}/api/shipment/get-export-list?${queryString.stringify(param)}`,
            method: "GET",
            responseType: "blob", // important
            headers: {
                "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
            },
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Export Shipment ${range ? range[0].format("YYYY-MM-DD") : moment().subtract(90, "days")} to ${range ? range[1].format("YYYY-MM-DD") : moment()}.xlsx`);
        document.body.appendChild(link);
        link.click();*/

    };

    const downloadLastExportFile = async () => {
        if (isUPSonly) {
            console.log("UPS ONLY EXPORT FILE");
            axios({
                url: `${process.env.REACT_APP_API_URL}/api/shipment/get-last-export-file/UPS`,
                method: "GET",
                responseType: "blob", // important
                headers: {
                    "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                },
            })
            .then((response) => {
                console.log('getLastExportFile res', response);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `LastExportFile.zip`);
                document.body.appendChild(link);
                link.click();
            })
            .catch((err) => {            
                if(err.response?.status === 404)
                    alert('File not found');            
            });
        } else {
            axios({
                url: `${process.env.REACT_APP_API_URL}/api/shipment/get-last-export-file/UPS`,
                method: "GET",
                responseType: "blob", // important
                headers: {
                    "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                },
            })
            .then((response) => {
                    console.log('getLastExportFile res', response);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `LastExportFile.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
            .catch((err) => {            
                if(err.response?.status === 404)
                    alert('File not found');            
            });
        }       
    };

    const emailLastExportFile = async () => {
        //construct  recipients emails array
        let emailArr = emails.split(',').map(x => x.trim());
        console.log('EMAIL ARR', emailArr);

        if (emailArr.length === 1 && emailArr[0] === ""){
            emailArr = [];
        }

        const payload = { recipient: emailArr};
        try{
            const res = await shipmentsApi.emailLastExportFile(payload);
            if (res.message === "ok") {
                message.success("Email sent successfully");
                setShowEmailModal(false);
            } else {
                message.error(res.message);
            }
        } catch (err) {
            console.log('ERROR SENDING EMAIL', err);
        }
    };

    const handleEmailChange = (e) => {
        setEmails(e.target.value);
    };

    useEffect(() => {
        const paramsQuery = queryString.parse(history.location.search);
        const data = removeBlankNull(paramsQuery);
        if (data.page) {
            data.page = parseInt(data.page);
        }
        if (data.createdAt) {
            data.createdAt = [moment(data.createdAt[0] + "T00:00Z", "YYYY-MM-DDTHH:mmZ"), moment(data.createdAt[1] + "T00:00Z", "YYYY-MM-DDTHH:mmZ")];
        }

        setParams({ ...params, ...data }, () => {
            setInit(true);
        });
    }, []);

    useEffect(() => {
        // call an api to get new shipment
        const data = removeBlankNull(params);
        delete data.pageSize;
        if (data.page === 1) {
            delete data.page;
        }
        if (data.createdAt?.length) {
            data.createdAt = [data.createdAt[0].utc().format("YYYY-MM-DD"), data.createdAt[1].utc().format("YYYY-MM-DD")];
        }
        history.push({
            pathname: "/shipments",
            search: `?${queryString.stringify(data)}`,
        });
        getShipments();
    }, [params, init]);

    useEffect(() => {
        
        if (data) {            
            //if (data.shipment.courier === "ups") {
            //  console.log("ITS UPS MANNN");
            //}
        }
       
    },[data])
    return (
        <>
            <Modal
                title="Label"
                footer={
                    <Space>
                        {/* 
                            {labelModal.label.payload?.map((item, index) => (
                            <DownloadButton key={index} href={item} download="ShipmentLabel">
                            Download
                            </DownloadButton>
                            ))} 
                        */}
                        <Button onClick={() => downloadAll(labelModal.label.payload)}>Download</Button>
                    </Space>
                }
                visible={labelModal.label.status}
                onCancel={() =>
                    setLabelModal((prev) => {
                        const draft = {
                            ...prev.label,
                            status: false,
                        };
                        return {
                            ...prev,
                            label: draft,
                        };
                    })
                }
                width={900}
            >
                {labelLoading ? (
                    <Space>
                        <Spin />
                    </Space>
                ) : (
                    <>
                        {labelModal.label.payload.length > 1 ? (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {labelModal.label.payload?.map((item, index) => (
                                    <img key={index} src={item} width={400} alt="zplLabel" style={{ padding: "10px" }} />
                                ))}
                            </div>
                        ) : (
                            <div style={{ display: "flex", justifyContent: "center" }}>                                
                                <img src={labelModal.label.payload[0]} width={800} alt="zplLabel" style={{ padding: "10px"}} />
                            </div>
                        )}
                    </>
                )}
            </Modal>
            <Modal
                title="Invoice"
                footer={
                    <Space>
                        <DownloadButton href={`data:application/pdf;base64,${labelModal.invoice.payload}`} download="Invoice">
                            Download
                        </DownloadButton>
                    </Space>
                }
                visible={labelModal.invoice.status}
                onCancel={() =>
                    setLabelModal((prev) => {
                        const draft = {
                            ...prev.invoice,
                            status: false,
                        };
                        return {
                            ...prev,
                            invoice: draft,
                        };
                    })
                }
                width={800}
            >
                <iframe width="100%" height={800} frameBorder="0" src={`data:application/pdf;base64,${labelModal.invoice.payload}`} title="invoice"></iframe>
            </Modal>
            <Modal
                title="Email Last Generated Export File"
                centered
                visible={showEmailModal}
                onOk={emailLastExportFile}
                onCancel={() => setShowEmailModal(false)}
                width={1000}
            >           
                <br/>                

                <Space direction="vertical">
                    <Text>
                        Last generated export file will be send to the recipients entered below. Multiple emails by commas.
                    </Text>
                    <Text strong>Please check the email addresses before sending.</Text> 
                    <br/>
                    Recipients 
                    <Input placeholder="your@email.com" onChange={handleEmailChange} value={emails} length={200}/>
                              
                </Space> 
                <br/><br/>
                          
            </Modal>  
            <TitleBox className="title-page">
                <Title>{t("Shipments")}</Title>
                <Breadcrumb items={[{ name: "Home", path: "/" }, { name: Path.SHIPMENTS.name }]} />
            </TitleBox>
            <ShipmentPageStyled title={t("list_product")}>
                <LiveFeedsData />
                <Container>
                    <Space>
                        <div>
                            <Title>Shipment</Title>
                            <Space>
                                <RangePicker
                                    defaultValue={range}
                                    ranges={{
                                        Today: [moment(), moment()],
                                        Yesterday: [moment().subtract(1, "day"), moment().subtract(1, "day")],
                                        "Last 7 days": [moment().subtract(7, "day"), moment().endOf("day")],
                                        "This Week": [moment().startOf("week"), moment().endOf("week")],
                                        "This Month": [moment().startOf("month"), moment().endOf("month")],
                                    }}
                                    style={{ width: "100%" }}
                                    onChange={setRange}
                                />
                                <Checkbox
                                    onChange={(e) =>
                                        setIsLatest(true)
                                    }
                                >
                                    From last item downloaded
                                </Checkbox>
                                <Checkbox
                                    onChange={(e) => {
                                        console.log('DHL CHECKED?', e.target.checked);
                                        setIsDHLonly(e.target.checked);
                                    }}
                                >
                                    DHL Only
                                </Checkbox>
                                <Checkbox
                                    onChange={(e) => {
                                        console.log('UPS CHECKED?', e.target.checked);
                                        setIsUPSonly(e.target.checked)
                                    }}
                                >
                                    UPS Only
                                </Checkbox>
                                <Button type="primary" onClick={downloadExportData}>
                                    Download Export Data
                                </Button>
                                <Button  style={{backgroundColor:'#34c25a',color: 'white'}}  onClick={downloadLastExportFile}>
                                    Download Last Generated Export File
                                </Button>
                                <Button type="secondary" onClick={()=>setShowEmailModal(true)}>
                                    Email Last Generated Export File
                                </Button>
                            </Space>
                        </div>
                    </Space>
                    <Divider />
                    <Row gutter={24}>
                        {/* left side  */}
                        <Col sm={24} md={24} lg={6}>
                            <ShipmentFilterForm setParams={setParams} form={form} isLoading={isLoading} setIsLoading={setIsLoading} />
                        </Col>
                        {/* Table here  */}
                        <Col sm={24} md={24} lg={18}>
                            {isLoading ? (
                                <div
                                    style={{
                                        height: "100%",
                                        width: "100%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        grid: "10px",
                                    }}
                                >
                                    <Title>Fetching...</Title>
                                    <Spin size="large" />
                                </div>
                            ) : (
                                <CardWrapper>
                                    <ShipmentTable
                                        data={data}
                                        params={params}
                                        setParams={setParams}
                                        getShipments={getShipments}
                                        labelModal={labelModal}
                                        setLabelModal={setLabelModal}
                                        labelLoading={labelLoading}
                                        setLabelLoading={setLabelLoading}
                                    />
                                </CardWrapper>
                            )}
                        </Col>
                    </Row>
                </Container>
            </ShipmentPageStyled>
        </>
    );
};

export default Shipments;
const CardWrapper = styled.div`
    overflow: auto;
`;
