import { Input, Card, Avatar, Button, Row, Col, List, Typography, Form, notification, Table, Space, Descriptions, Spin } from "antd";
import accountApi from "apis/accountApi";
import productsApi from "apis/productsApi";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LocalStorage, { SessionStorage} from "utils/storage";
import Path from "../../commons/breadcrumbs";
import queryString from "query-string";
import { Link, useParams, useHistory } from "react-router-dom";
import FormItem from "antd/lib/form/FormItem";
import DescriptionsItem from "antd/lib/descriptions/Item";
import { render } from "nprogress";
import moment from "moment";
//import { HeaderList, OpControls } from "./index.style";

const { Title, Paragraph } = Typography;

const StockAdjustment = () => {
    const [product, setProduct] = useState();
    const [variant, setVariant] = useState();
    const [variantArr, setVariantArr] = useState([]);
    const [adjHistory, setAdjHistory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const { id } = useParams();
    const variantId = id;

    const getProductVariant = () => {
        setIsLoading(true);
        productsApi.getProductVariant(variantId)
        .then(r => {
            console.log("R",r);
            if (r.variant) {
                setVariant(r.variant);
                setVariantArr([r.variant]);
                
                productsApi.getProductById(r.variant.productId)
                .then(p => setProduct(p.product || null))
                .catch(err => console.log("ERROR GET PRODUCT",err));
            }
            
        })
        .catch(err => {
            console.log("err", err);            
        })
        .finally(() => {
            setIsLoading(false);
        })
    };

    const getAdjustHistory = () => {
        productsApi.adjustHistory(variantId)
        .then(r => {
            if (r) {
                setAdjHistory(r);
            }
        })
        .catch(err => console.log("ERROR GET ADJ HISTORY", err));
    };

    const column = [
        {
            title: "Allocated",
            dataIndex: "allocatedInventory",
            key: "allocatedInventory",
            render: (item) => {
                return (<span style={{fontSize:"200%", fontWeight:"bold"}}>{item}</span>);
            }
        },
        {
            title: "In Stock",
            dataIndex: "in_stock",
            key: "in_stock",
            render: (item) => {
                return (<span style={{fontSize:"200%", fontWeight:"bold"}}>{item}</span>);
            }
        },
        {
            title: "Purchased",
            dataIndex: "purchased",
            key: "purchased",
            render: (item) => {
                return (<span style={{fontSize:"200%", fontWeight:"bold"}}>{item}</span>);
            }
        },
        {
            title: "Reserved",
            dataIndex: "reserved",
            key: "reserved",
            render: (item) => {
                return (<span style={{fontSize:"200%", fontWeight:"bold"}}>{item}</span>);
            }
        }
    ];
    
    const historyColumn = [
        /*
        {
            title: "Desc",
            dataIndex: "desc",
            key: "desc",
        },
        {
            title: "Note",
            dataIndex: "note",
            key: "note",
        },
        */
        {
            title: "Prev Alloc",
            dataIndex: "prevAllocated",
            key: "prevAllocated",
        },
        {
            title: "Prev Stock",
            dataIndex: "prevInStock",
            key: "prevInStock",
        },
        {
            title: "Prev Purchased",
            dataIndex: "prevPurchased",
            key: "prevPurchased",
        },
        {
            title: "Prev Reserved",
            dataIndex: "prevReserved",
            key: "prevReserved",
        },
        {
            title: "New Alloc",
            dataIndex: "newAllocated",
            key: "newAllocated",
        },
        {
            title: "New Stock",
            dataIndex: "newInStock",
            key: "newInStock",
        },
        {
            title: "New Purchased",
            dataIndex: "newPurchased",
            key: "newPurchased",
        },
        {
            title: "New Reserved",
            dataIndex: "newReserved",
            key: "newReserved",
        },
        {
            title: "Created At",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (item) => {
                return(<>{moment(item).format("DD/MM/YYYY, HH:mm")}</>)
            }
        },        
    ];

    const onFinish = (values) => {
        const payload = {
            desc: "",
            note: "",
            newAlloc: values.allocated,
            newInStock: values.inStock,
            newPurchased: values.purchased,
            newReserved: values.reserved,
            variantId: variantId
        };


        productsApi.adjustStock(payload)
        .then(r => {
            console.log("ADJUST R",r);

            const key = `open${Date.now()}`;
            const btn = (
                <Button type="primary" onClick={() => window.location.reload(false)}>
                    Confirm
                </Button>
            );

            notification.open({
                message: 'Stock Adjusted',
                description:
                  'Product variant stock has been adjusted. The page will be refreshed',
                btn,
                key,
                onClose: () => { console.log("CLOSED");},
              })
        })
        .catch(err => console.log("ERR",err));
    };

    useEffect(() => {
        getProductVariant();
        getAdjustHistory();
        console.log("VARIANT ID", variantId);
    },[]);

    return (<>
        <TitleHeader
            listOfBreadcrumb={[
                { name: "Home", path: "/" },
                { name: Path.PRODUCTS.name, path: Path.PRODUCTS.pathname },
                {
                    name: Path.STOCK_ADJUSTMENT.name,
                },
            ]}
            title={Path.STOCK_ADJUSTMENT.name}
        />
        <Box>
            <Container>
                <Spin spinning={isLoading}>
                <Row gutter={16}>
                    <Col md={17}>
                        <Card title={"Current Stock"}>
                            {variant? 
                                (<>
                                <Table
                                    columns={column}
                                    dataSource={[variant]}
                                    pagination={false}
                                />   
                                </>):(<>No Data</>)
                            }                               
                        </Card>
                        <Card title={"Adjusted Stock"} style={{marginTop:"30px"}}>
                            {variant? 
                                (<>
                                <Form
                                onFinish={onFinish}
                                initialValues={{ remember: true }}
                                >
                                <Space direction="horizontal">                                
                                    <Form.Item
                                        label="Allocated"
                                        rules={[{ required: true, message: 'Please enter allocated quantity' }]}
                                        initialValue={variant.allocatedInventory}
                                        name="allocated"
                                    >
                                        <Input placeholder="0"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="In Stock"
                                        rules={[{ required: true, message: 'Please enter in-stock quantity' }]}
                                        initialValue={variant.in_stock}
                                        name="inStock"
                                    >
                                        <Input placeholder="0"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Purchased"
                                        rules={[{ required: true, message: 'Please enter purchased quantity' }]}
                                        initialValue={variant.purchased}
                                        name="purchased"
                                    >
                                        <Input placeholder="0"/>
                                    </Form.Item>
                                    <Form.Item
                                        label="Reserved"
                                        rules={[{ required: true, message: 'Please enter reserved quantity' }]}
                                        initialValue={variant.reserved}
                                        name="reserved"
                                    >
                                        <Input placeholder="0"/>
                                    </Form.Item>                               
                                </Space>
                                <Button type="primary" htmlType="submit" style={{float:"right", marginTop:"60px"}}>Adjust Stock</Button>
                                </Form>
                                </>):(<>No Product Variant fetched</>)
                            }                            
                        </Card>            
                        <Card title="Adjustment History">
                            {adjHistory.length > 0? 
                                (<>
                                    <Table
                                        columns={historyColumn}
                                        dataSource={adjHistory}
                                    />    
                                </>)
                            :(<>No Data</>)}
                        </Card>                                    
                    </Col>
                    <Col md={7}>
                        <Card title="Product Variant Info">
                            {variant?
                                (<>
                                    <Descriptions  bordered>
                                        <DescriptionsItem label="ID"  span={3}>{variant.id}</DescriptionsItem>
                                        <DescriptionsItem label="Product ID" span={3}>
                                            <Link to={`/products/product-detail/?id=${variant.productId}`}>{variant.productId}</Link>
                                        </DescriptionsItem>
                                        {product? 
                                            (<DescriptionsItem label="Product Title" span={3}>{product.title}</DescriptionsItem>)
                                            :(<></>)
                                        }
                                        <DescriptionsItem label="Variant Title" span={3}>{variant.title}</DescriptionsItem>
                                        <DescriptionsItem label="HS Code" span={3}>{variant.HSCode}</DescriptionsItem>
                                        <DescriptionsItem label="Price" span={3}>{variant.id}</DescriptionsItem>
                                        <DescriptionsItem label="Shopify ID" span={3}>{variant.shopifyId}</DescriptionsItem>
                                        <DescriptionsItem label="Created at" span={3}>{moment(variant.createdAt).format("DD/MM/YYYY, HH:mm")}</DescriptionsItem>                                        
                                    </Descriptions>
                                </>):(<>No Data</>)
                            }                            
                        </Card>
                    </Col>                    
                </Row>
                </Spin>
            </Container>
        </Box>
    </>);
};

export default StockAdjustment;