import { Col, Form, Input, Row, Select, Button, Modal, message, Checkbox } from "antd";
import accountingApi from "apis/accountingApi";
import { FormWrapper } from "./index.style";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import moment from "moment";

import { Title } from "pages/example/Example.styles";

const AccountTxnForm = ({openAccTxnForm, setOpenAccTxnForm, transaction, setRefreshFlag}) => {
    const [accountForm] = Form.useForm();

    const handleOk = async () => {
        const payload = {
            accNum: transaction.accNum,
            refKey: transaction.txnRefKey,
            changes: {
                category: accountForm.getFieldValue("category"),
                orderNo: accountForm.getFieldValue("orderNo"),
                notes: accountForm.getFieldValue("notes"),
                notMatched: accountForm.getFieldValue("notMatched")
            }
        };
        const res = await accountingApi.updateAccountTxn(payload);
        if(res){
            setOpenAccTxnForm(false);
            message.success("Transaction updated successfully");
            //Below is setting refreshFlag random number, to trigger useEffect in index.js
            setRefreshFlag(Math.floor(Math.random() * 101)); 
        }
    };

    const handleCancel = () => {
        setOpenAccTxnForm(false);
    };

    useEffect(() =>{
        if(transaction){
            accountForm.setFieldsValue({
                category: transaction.category,
                orderNo: transaction.orderNo,
                notes: transaction.notes,
                notMatched: transaction.notMatched
            });
        }
     
    },[transaction]);
    return(  
    <Modal
        title="Account Transaction"
        visible={openAccTxnForm}
        onOk={handleOk}           
        //confirmLoading={confirmLoading} 
        onCancel={handleCancel}
                   
    >
        <FormWrapper>
            <Form form={accountForm} layout='vertical'>
                <Title>Transaction Info</Title>
                <br/>
                <Row><Col md={8}>Corp#</Col><Col md={16}>{transaction?.corpNum}</Col></Row>
                <Row><Col md={8}>Account</Col><Col md={16}>{transaction?.accNum}</Col></Row>
                <Row><Col md={8}>Withdrawal</Col><Col md={16}>{transaction?.withdraw}</Col></Row>
                <Row><Col md={8}>Deposit</Col><Col md={16}>{transaction?.deposit}</Col></Row>
                <Row><Col md={8}>Balance</Col><Col md={16}>{transaction?.balance}</Col></Row>
                <Row><Col md={8}>Time (KST)</Col><Col md={16}>{moment(transaction?.txnTime).utcOffset("+0900").format("YYYY-MM-DD HH:mm")}</Col></Row>
                <Row><Col md={8}>Type</Col><Col md={16}>{transaction?.txnType}</Col></Row>
                <Row><Col md={8}>Txn Office</Col><Col md={16}>{transaction?.txnOffice}</Col></Row>
                <Row><Col md={8}>Txn Remark</Col><Col md={16}>{transaction?.txnRemark}</Col></Row>
                <Row><Col md={8}>Ref Key</Col><Col md={16}>{transaction?.txnRefKey}</Col></Row>
                <br/><br/>
                <Form.Item 
                    label='Category' 
                    name='category'
                    rules={[
                        {
                            required: false,                            
                        },
                    ]}
                >
                    <Input placeholder="Name" />
                </Form.Item>
                <Form.Item 
                    label='Order Number' 
                    name='orderNo'
                    rules={[
                        {
                            required: false,                            
                        },
                    ]}
                >
                    <Input placeholder="Order Number" />
                </Form.Item>
                <Form.Item 
                    label='Notes' 
                    name='notes'
                    rules={[
                        {
                            required: false,                            
                        },
                    ]}
                >
                    <Input placeholder="Name" />
                </Form.Item>
                <Form.Item 
                    label='Not Matched?' 
                    name='notMatched'
                    rules={[
                        {
                            required: false,                            
                        },
                    ]}
                    valuePropName="checked"
                >
                    <Checkbox>Not Matched?</Checkbox>
                </Form.Item>
            </Form>
        </FormWrapper>
    </Modal>
    );
}

export default AccountTxnForm;