import { message, Pagination, Spin, Typography, Table, Modal, Button, Card, Row, Col, Descriptions, Space, Input, Select, DatePicker, Tag } from "antd";
import productsApi from "apis/productsApi";
import purchaseApi from "apis/purchaseApi";
import ordersApi from "apis/purchaseApi";
import Container from "components/container/Container";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Path from "../../commons/breadcrumbs";
import Breadcrumbs from "../../components/breadcrumb/Breadcrumb";
import { CardGrid, TitleBox } from "./index.style";
import { Link, useHistory,useParams } from "react-router-dom";
import moment from "moment";

const { Title } = Typography;

const BulkExport = () => {
  const { t } = useTranslation();
  const [listProducts, setlistProducts] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedExportRows, setSelectedExportRows] = useState([]);
  const [dateRange, setDateRange] = useState([moment().subtract(1, "month"), moment()]);
  const [activeExportVariant, setActiveExportVariant] = useState([
    { key: "", id: "", name: ""},
  ]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    totalCount: 0,
  });
  const history = useHistory();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
};

const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
};


  const changePage = (page, pageSize) => {
    pagination.current = page.current;
    setPagination(pagination);

    console.log('pagination',pagination);

    params.pageSize = page.pageSize;
    params.page = page.current;
    setParams(params);

    selectedProduct();
  };

  const start = () => {
    setSelectedRowKeys([]);
};

  const goToProduct = (productId) => {
     window.open(
      `/products/product-detail/?id=${productId}`,
      "_blank"
  );
  }
  

  const onOkExport = async() => {
  
    if (selectedRowKeys.length < 1){
      console.log(selectedExportRows);
      //await setShowDetails(false);
      message.error("Please select export shop", 5);
    } 
    else {
      Promise.all(
        activeExportVariant
          .filter((item) => selectedRowKeys.includes(item.key))
          .map((shops) => {
            let id = shops.id
            const payload = {
              id : shops.id,
              title : searchKeyword
            };
            return productsApi.bulkExport(payload);
          })
      )
        .then(async(res) => {
          await message.success("Succesfully exported", 2);
          await setShowDetails(false);
          await start();
        })
        .catch( (error) => {
          message.error(error, 5);
          setShowDetails(false);
          start();
        });
    }
    // reset active variant
  
  };

  const columns = [
  
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (item, record, index) => {
        return(<>{((params.page-1) * params.pageSize) + (index+1)}</>)

      }
    },
     {
      title: 'Product Name',
      dataIndex: 'title',
      key: 'title', 
      render: (item,record) => {
        return( <div>   
        
            {/* <span>{i.title ? i.title :  record.title}</span> */}
           <a style={{ cursor:"pointer" }} href={null} onClick={() => goToProduct(record.id)}>{item}</a>
        </div>)
      }
    },
    {
      title: 'Variants',
      dataIndex: 'variants',
      key: 'variants.id',
      render: (item,record) => {
        return( <div style={{height:"80px",overflow:"scroll"}}>   
          {item?.map((i,index) => <>
            <span>{i.title ? i.title :  record.title}</span>
            {(index + 1) < item.length? (<>,&nbsp;</>) : (<></>) }
          </>)}
        </div>)
      }
    },
    // {
    //   title: 'Product',
    //   key: 'product',
    //   render: (record, index) => {
    //     // console.log('record',record)
    //     return(<>{record?.purchaseItems.map((i,index) => <div>
    //        {i.product_name + '\n'} 
    //       {(index + 1) < record.purchaseItems.length? (<>&nbsp;</>) : (<></>) }
    //     </div>)}</>)
    //   }
    // },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status'
    // },
    // {
    //   title: 'Purchased Qty',
    //   dataIndex: 'totalPurchasedQty',
    //   key: 'totalPurchasedQty'
    // },
    // {
    //   title: 'Pending Qty',
    //   dataIndex: 'totalPendingQty',
    //   key: 'totalPendingQty',
    // },
    // {
    //   title: 'Date & Time',
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   defaultSortOrder: 'descend',
    //   sorter: (a, b) => moment(a.createdAt)- moment(b.createdAt),
    //   render: (item) => {
    //     return(<>{moment(item).format("DD-MM-YYYY, hh:mm A")}</>);
    //   }
    // }
  ];

  


  const openModal = async () => {
    try {
       let exportShops = await productsApi.listExportShops()
          .then(({shops}) => {
            return shops
          }) 
       let draftExport = [];
        await exportShops.forEach(async(shops) => {
            console.log('shops',shops.name)
            let b = {
              key: shops.id,
              id: shops.id,
              name: shops.name.toUpperCase(),
              // variantId:await variantList,
              // productId : res[0].productId,
            }
            draftExport.push(b);
            console.log('draft',draftExport);
      })
        // await setExportShops(exportShops);
        setActiveExportVariant(draftExport);
        console.log('export List', exportShops);
      } catch (error) {
        console.log(error.message);
      } finally {
        // setExportButtonLoad(false);
        setShowDetails(true);
      }
   
  };

  const selectedProduct = async () => {
    try {
      
      let filter = [];
      if (searchKeyword) {
        filter.push(`title_iLike=%${searchKeyword}%`);
      }
      const paramsRequest = {
        page: params.page,
        pageSize: params.pageSize,
        includeVariants: 'true',
        filter,
      };

      console.log(paramsRequest);

      
      const res = await productsApi.getProducts(paramsRequest);
        pagination.total = res.totalCount;
        pagination.current = params.page;
        pagination.pageSize = params.pageSize;

        setPagination(pagination);
    
      await setlistProducts(res.products);
      if (res.products.length > 0) {
        setIsDisabled(false);
      } 
      console.log('res-------------',res.products);
    } catch (error) {
      console.log('errorsss',error)
      message.error(error, 3);
    }
  };

  // useEffect(() => {
  //   selectedProduct();
  //   // setIsDisabled(true);
  // }, [searchKeyword]);

  // useEffect(() => {
  //   getPurchaseOrder();
  //   setPagination(pagination);
  //   // setSearchOrder(orderNumberParam ? orderNumberParam : searchOrder );
   
  // }, [params.page,params.pageSize]);

  return (
    <>
      <TitleBox className="title-page">
        <Title>{t("Bulk Export")}</Title>
        <Breadcrumbs
          items={[
            { name: "Home", path: "/" },
            { name: Path.BULK_EXPORT.name },
          ]}
        />
      </TitleBox>
      <Container>
        <Row>
          <Col md={24}>
            <Row style={{marginTop:"0px", padding:"15px" ,backgroundColor:"#edf1f5", border:"#D7D7D7 solid 1px"}}>
              <Space direction="horizontal">
                Product Name
                <Input style={{width:"300px", marginRight:"10px"}} value={searchKeyword}
                  onChange={(e) => {setSearchKeyword(e.target.value)}} 
                />
              </Space>
              <Button type="primary" onClick={() => selectedProduct()} style={{marginLeft:"20px"}}>Search</Button>
              <Button type="primary" disabled={isDisabled} onClick={() => openModal()} style={{marginLeft:"20px"}}>Export all listed variants</Button>
            </Row>
          </Col>
        </Row>
        <br/>

        <Table 
          columns={columns}
          dataSource={listProducts}
          pagination={pagination}
          loading={tableLoading}
          onChange={changePage}
        />
        <br/>
        
      </Container>

      <Modal
        visible={showDetails}
        width={1000}
        // footer={[
        //     <Button type="primary" onClick={() =>{setShowDetails(false)}}>Close</Button>
        // ]}  
        onOk={onOkExport}
        onCancel={() => setShowDetails(false)} 
           
      >      
        <Spin spinning={isDetailsLoading}>
          <Row>
            <Col md={24}>
                <br/>
                <Card title="Export All Variants">
                  <Descriptions bordered>
                    <Descriptions.Item label="Variant Count" >{pagination.total}</Descriptions.Item>
                  </Descriptions>
                  <Table
                    // rowSelection={{
                    //   onChange: (selectedRowKeys) => {
                    //     setSelectedExportRows(selectedRowKeys);
                    //   },
                    // }}
                    rowSelection={rowSelection}
                    dataSource={activeExportVariant}
                    pagination={false}
                    style={{ marginTop: "26px" }}
        >
          <Table.Column title="Export Shops" key="name" dataIndex="name" />
         </Table>
                </Card>
            </Col>
          </Row>
        </Spin>          
      </Modal>
      
    </>
  );
};

export default BulkExport;
