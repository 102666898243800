import { Button, Carousel, Col, Divider, List, message, Modal, Row, Space, Table, Card, Descriptions } from "antd";
import purchaseApi from "apis/purchaseApi";
import React, { useEffect, useState } from "react";

const CheckInDetails = ({isCheckInDetailsOpen, onCheckInModalOk, onCheckInModalCancel, checkInObj}) => {
   
    return(<>
        <Modal
            visible={isCheckInDetailsOpen}
            
            footer={[
                <Button type="primary" onClick={onCheckInModalOk}>
                    Close
                </Button>
            ]}
        >
            <Card title="Check-In Details" bordered={false}>
                {checkInObj? (<>                
                    <Descriptions bordered>
                        <Descriptions.Item label="ID" span={3}>{checkInObj.id}</Descriptions.Item>
                        <Descriptions.Item label="Purchase Order ID" span={3}>{checkInObj.purchaseId}</Descriptions.Item>
                        <Descriptions.Item label="Order Number" span={3}>{checkInObj.orderNumber}</Descriptions.Item>
                        <Descriptions.Item label="Check-In Type" span={3}>{checkInObj.checkInType}</Descriptions.Item>
                        <Descriptions.Item label="Remark" span={3}>{checkInObj.remark}</Descriptions.Item>
                        <Descriptions.Item label="Created At" span={3}>{checkInObj.createdAt}</Descriptions.Item>                   
                    </Descriptions> 
                    <br/>
                    {checkInObj?.lineItems.length > 0? (<>
                        <List header={<div>Items</div>}>
                            {checkInObj.lineItems.map((l,index) => <List.Item>{index+1}. Variant id {l.productVariantId}, Quantity {l.quantity}</List.Item>)}
                        </List>
                    </>):(<></>)}             
                </>)                
                :(<></>)}               
            </Card>
        </Modal>
    </>);
};

export default CheckInDetails;