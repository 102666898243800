import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Input, message, Tooltip } from "antd";
import classNames from "classnames";
import React from "react";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import accountApis from "../../apis/accountApi";
import { COLORS } from "../../commons/styles";
import Box from "../../components/box/Box";
import { login, setProfile } from "../../store/slices/accountSlice";
import errorHelper from "../../utils/errorHelper";
import LocalStorage, { SessionStorage } from "../../utils/storage";
import yup from "../../utils/yup";
import { BoxForm, Label, StyleForm } from "./Login.styles";
import jwtDecode from "jwt-decode";

const schema = yup.object().shape({
    username: yup.string().required().trim(),
    password: yup.string().required().trim().min(6).max(32),
});

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
}

function Login() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const { control, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });
    const [isLoading, setIsLoading] = React.useState(false);
    const error = {};
                    
    const onSubmit = async (values) => {
        // setIsLoading(true);
        const data = {
            ...values,
            grant_type: "password",
        };
        
        const username = values.username;
        const password = values.password;

        if(username.toLowerCase().trim() === 'superadmin'){
            await accountApis
            .login(data)
            .then((res) => {
                if (res.name === 'Error'){
                    error.errors = [];
                    error.errors.push({message: 'Login failed. Check username and password'});
                    
                    throw(error);                    
                }
                if (res) {
                    
                    const { access_token, refresh_token, expires_in, token_type } = res;
                    //Change to LocalStorage for multi-tabs access
                    SessionStorage.set("access_token", access_token);
                    SessionStorage.set("refresh_token", refresh_token);
                    SessionStorage.set("expires_in", expires_in);
                    
                    LocalStorage.set("access_token", access_token);
                    LocalStorage.set("refresh_token", refresh_token);
                    LocalStorage.set("expires_in", expires_in);
                    LocalStorage.set("is_okta_login", false);
                    
                    LocalStorage.set("first_name", "Super");
                    LocalStorage.set("last_name", "Admin");
                    LocalStorage.set("username", "superadmin");
                    return accountApis.validate({
                        headers: {
                            Authorization: `${token_type} ${access_token}`,
                        },
                    });
                }
                // setIsLoading(false);
                // if (res.success) {

                //   LocalStorage.set("profile", res?.data?.info);
                //   axiosClient.defaults.headers.common = {
                //     Authorization: `Bearer ${res?.data?.token}`,
                //     "Accept-Language": i18n.language,
                //   };
                //   dispatch(login(res.data));
                //   console.log("RESPONDATA:", res.data);

                //   history.push(params.redirect ? params.redirect : "/orders");
                // } else {
                //   throw res;
                // }
            })
            .then((res) => {                
                // this is valid response
                // masuk ke dalam storages

                //Validation doesnt work right now. Just allow it temporarily
                if (res) {
                    console.log('Validated login');
                    const { id, name, permissions, roles, username } = res;
                    console.log('AUTH VALIDATE', res);
                    const profile = {
                        id,
                        name,
                        roles,
                        username,
                    };
                    console.log('PROFILE', profile);
                    const token = "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy";
                    SessionStorage.set("token", token);

                    LocalStorage.set("permissions", ["all"]);
                    LocalStorage.set("profile", profile);
                    dispatch(login({ token: true }));
                    dispatch(
                        setProfile({
                            profile,
                            pagePermissions: permissions,
                            permissionKey: ["all"]
                            /*
                            permissionKey: [
                                "allOrders",
                                "createOrder",
                                "orders",
                                "orderDetails",
                                "shipments",
                                "readyToShip",
                                "createShipment",
                                "todayShipment",
                                "allShipments",
                                "courierList",
                                "products",
                                "productDetail",
                                "settings",
                                "storeDetails",
                                "usersAndPermissions",
                                "salesAndChannels",
                                "languages",
                                "policies",
                                "addStaff",
                                "editStaff",
                                "createProduct",
                                "createdShipment",
                                "rateShipment",
                                "shipperDetails",
                            ],*/
                        })
                    );
                    //history.push("/orders");
                    //force reload page
                    history.go(0);

                } else {                    
                    throw res;
                }
            })
            .catch((err) => {               
                setIsLoading(false);
                errorHelper(err);
            });
        } else {
            //we use okta
            const payload = {
                password: password,
                username: username
            };

            accountApis.oktaLogin(payload)
            .then((r) => {
                console.log('OKTA LOGIN RESPONSE', r);
                
                if(r.response?.data?.message){
                    if(r.response.data.message === "Invalid login"){
                        message.error(r.response.data.message);
                        return;
                    }                   
                }

                message.info("Login successful");

                const decoded = jwtDecode(r.accessToken);

                SessionStorage.set("access_token", r.accessToken);
                SessionStorage.set("refresh_token", r.refreshToken);
                SessionStorage.set("id_token", r.idToken);
                SessionStorage.set("expires_in", r.expiresIn);
                SessionStorage.set("is_okta_login", true);

                LocalStorage.set("access_token", r.accessToken);
                LocalStorage.set("refresh_token", r.refreshToken);
                LocalStorage.set("id_token", r.idToken);
                LocalStorage.set("expires_in", r.expiresIn);
                LocalStorage.set("is_okta_login", true);

                LocalStorage.set("allowed_ops",decoded.allowed_ops);
                LocalStorage.set("first_name", decoded.first_name);
                LocalStorage.set("last_name", decoded.last_name);
                LocalStorage.set("modules", decoded.modules);
                LocalStorage.set("userid", decoded.uid);
                LocalStorage.set("username", decoded.sub);
                
                dispatch(login({ token: true }));

                //Should be after validate
                console.log('Validated login');
                
                const permissions = decoded.modules;
                console.log('USER Permissions', permissions);
                const profile = {
                    id: undefined,
                    name: undefined,
                    roles: undefined,
                    username: undefined
                };
                const token = "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy";
                SessionStorage.set("token", token);

                LocalStorage.set("permissions", permissions);
                
                LocalStorage.set("profile", profile);
                dispatch(login({ token: true }));
                dispatch(
                    setProfile({
                        profile,
                        pagePermissions: permissions,
                        //permissionKey: permissions
                        permissionKey: permissions
                      
                    })
                );
           
                history.go(0);
            })
            .catch((e) => {
                console.log('OKTA LOGIN ERROR ', e);               
            });          
        }        
    };

    useEffect(() => {
        LocalStorage.remove("permissionKey");
    }, []);

    return (
        <Box title={t("login")}>
            <BoxForm>
                <StyleForm onSubmit={handleSubmit(onSubmit)}>
                    <div className="login__logo">
                        <img src="/images/ic_glover.png" className="img__logo" alt="" />
                    </div>
                    <h1 className="login__title">{t("login")}</h1>
                    <div className="login__form-detail">
                        <div className="field">
                            <Label>{t("username")}</Label>
                            <Tooltip color={COLORS.COLOR_TOOLTIP_ERROR} placement="top" title={errors.username?.message}>
                                <Controller
                                    name="username"
                                    control={control}
                                    as={Input}
                                    className={classNames("field-input", {
                                        "error-field-input": !!errors.username?.message,
                                    })}
                                    defaultValue=""
                                />
                            </Tooltip>
                        </div>
                        <div className="field">
                            <Label>{t("password")}</Label>
                            <Tooltip color={COLORS.COLOR_TOOLTIP_ERROR} placement="top" title={errors.password?.message}>
                                <Controller
                                    name="password"
                                    control={control}
                                    defaultValue=""
                                    as={Input.Password}
                                    className={classNames("field-input", {
                                        "error-field-input": !!errors.password?.message,
                                    })}
                                />
                            </Tooltip>
                        </div>
                        <div className="field btn-login">
                            <Button type="primary" htmlType="submit" block loading={isLoading}>
                                {t("login")}
                            </Button>
                        </div>
                    </div>
                </StyleForm>
            </BoxForm>
        </Box>
    );
}

export default Login;
