import { Col, Form, Input, Row, Select, Button, Modal, notification } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FormWrapper } from "./index.style";

const NewShipperForm = ({openShipperForm, setOpenShipperForm, shipmentsApi, setShipper, activeShipper, setActiveShipper, form, openNotificationWithIcon}) => {
    const [shipperForm] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = () =>{
        submitForm();
        setConfirmLoading(true);
        setTimeout(() => {
            setOpenShipperForm(false);
            setConfirmLoading(false);
        }, 2000);
    };
    const handleCancel = () => {     
        const HARUMIO_SHIPPER_ID = Number(2);
        setOpenShipperForm(false);

        //Defaulted to active shipper        
        form.setFieldsValue({'shipperName': activeShipper.id });
    };

    const submitForm = () => {
        shipperForm.validateFields()
            .then(async (res) => {
                const value = res;
                const newShipper = {
                    name: value?.name,
                    phonenumber: value?.phoneNo,
                    companyName: value?.companyName,
                    utcOffset: value?.utcOffset,
                    address1: value?.address1,
                    address2 : value?.address2,
                    city : value?.city,
                    postal : value?.postal,
                    provinceCode : value?.provinceCode,
                    countryCode : value?.countryCode,
                    email : value?.email,
                    country : value?.country
                };

                //call api
                const result = await shipmentsApi.addShipperDetails(newShipper);
                if(result){
                    console.log('ADD SHIPPER RESPONSE :' + JSON.stringify(result));
                    
                    if(result.shipper_id){
                        openNotificationWithIcon('success', 'Success', 'New shipper succesfully registered.');
                        
                        //cleanup the form                    
                        shipperForm.resetFields();
                        
                        //refresh shipper from api
                        const params = {
                            page: 1,
                            pageSize: 10,
                        };
                        
                        shipmentsApi.getListShipper(params)
                            .then(({ shipper_details, errors }) => {                                
                                setShipper(shipper_details);
                                const newShipper = shipper_details.find(s => {return s.id === result.shipper_id;});
                                setActiveShipper(newShipper);
                            })
                            .catch(err => {
                                console.log(err);
                                openNotificationWithIcon('error', 'Error', 'New shipper registration failed.');
                            });
                    }
                }                                
            })
            .catch((err) => {
                console.log('CREATE NEW SHIPPER ERROR: ' + err);
                alert('Error in creating new shipper: ' + err);
            });
    };

    return(
        <Modal
            title="Add New Shipper"
            visible={openShipperForm}
            onOk={handleOk}           
            confirmLoading={confirmLoading} 
            onCancel={handleCancel}            
        >
            <FormWrapper>
                <Form form={shipperForm} layout='vertical'>
                    <Form.Item 
                        label='Name' 
                        name='name'
                        rules={[
                            {
                              required: true,
                              message: "Name is required",
                            },
                        ]}
                    >
                        <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item 
                        label='Company Name' 
                        name='companyName'
                        rules={[
                            {
                              required: true,
                              message: "Company name is required",
                            },
                        ]}
                    >
                        <Input placeholder="Company Name" />
                    </Form.Item>
                    <Form.Item 
                        label='Phone No.' 
                        name='phoneNo'
                        rules={[
                            {
                              required: true,
                              message: "Phone number is required",
                            },
                        ]}
                    >
                        <Input placeholder="Phone No" />
                    </Form.Item>
                    <Form.Item 
                        label='Email' 
                        name='email'
                        rules={[
                            {
                              type: 'email',
                              required: true,
                              message: "Email is required",
                            },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item 
                        label='UTC Offset' 
                        name='utcOffset'
                        rules={[
                            {
                              required: true,
                              message: "UTC Offset is required",
                            },
                        ]}                        
                        initialValue='+09:00'
                    >
                        <Input placeholder="UTC Offset" disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        label='Address 1' 
                        name='address1'
                        initialValue='3rd Flr'
                        rules={[
                            {
                              required: true,
                              message: "Please insert address 1",
                            },
                        ]}                        
                    >
                        <Input placeholder="Address 1" disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        label='Address 2' 
                        name='address2'
                        initialValue='77 World Cup buk-ro'
                        rules={[
                            {
                              required: false
                            },
                        ]}
                    >
                        <Input placeholder="Address 2" disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        label='City' 
                        name='city'
                        initialValue='Mapo-gu'
                        rules={[
                            {
                              required: true,
                              message: "City is required",
                            },
                        ]}
                    >
                        <Input placeholder="City" disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        label='Province Code' 
                        name='provinceCode'
                        initialValue='KR'
                        rules={[
                            {
                              required: true,
                              message: "Province code is required",
                            },
                        ]}
                    >
                        <Input placeholder="Province Code" disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        label='Postal' 
                        name='postal'
                        initialValue='03967'
                        rules={[
                            {
                              required: true,
                              message: "Postal is required",
                            },
                        ]}
                    >
                        <Input placeholder="Postal Code" disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        label='Country' 
                        name='country'
                        initialValue='South Korea'
                        rules={[
                            {
                              required: true,
                              message: "Country is required",
                            },
                        ]}
                    >
                        <Input placeholder="Country" disabled={true}/>
                    </Form.Item>
                    <Form.Item 
                        label='Country Code' 
                        name='countryCode'
                        initialValue='KR'
                        rules={[
                            {
                              required: true,
                              message: "Country code is required",
                            },
                        ]}
                    >
                        <Input placeholder="Country Code" disabled={true}/>
                    </Form.Item>                    
                </Form>    
            </FormWrapper>                    
        </Modal>
    );
};

export default NewShipperForm;
