import styled from 'styled-components'
import { Col, Row } from "antd";

export const HeaderList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1,
  a {
    margin: none !important;
  }
`
export const OpControls = styled(Row)`
  height:50px;
  .op{
    margin-top: 10px;
    margin-bottom: auto;
    float: right;
  }
`
