import axios from "axios";
import axiosClient from "./axiosClient";

const ordersApi = {
  postFullFillOrder: (payload, data) =>
    axiosClient.post(`/api/orders/fulfill-order?${data}`, payload),
  postCancelFulfillment: (payload) =>
    axiosClient.post(`/api/orders/cancel-fulfillment`, payload),
  getOrders: (payload) =>
    axiosClient.get("/api/orders/list", { params: payload }),
  getCombineOrders: (payload) =>
    axiosClient.get("/api/orders/combined", { params: payload }),
  getOrderById: ({ id, params }) => axiosClient.get(`/api/orders/get/${id}`, { params }),
  createOrder: (payload) => axiosClient.post(`/api/orders/create`, payload),
  getPossibleCombineOrders: () =>
    axiosClient.get(`/api/orders/list-possible-combine-orders`),
  createCombineOrder: (payload) =>
    axiosClient.post(`/api/orders/combined/create`, payload),
  getCombinedOrderById: (id) => 
    axiosClient.get(`/api/orders/combined/find/${id}`),
  appendOrderToCombineOrder: ({id, order}) =>
    axiosClient.patch(`/api/orders/combined/append/${id}`, {order}),
  removeOrderFromCombineOrder: ({id, order}) =>
    axiosClient.patch(`/api/orders/combined/pop/${id}`, {order}), 

  getOrdersContainingVariant: (id, data) =>
    axiosClient.get(`/api/orders/list-orders-containing-variant/${id}`, {
      params: data,
    }),
  
  refetchOrderByNo: (orderNo) =>
    axiosClient.get(`/api/orders/refetchByOrderNo/${orderNo}`),

  refetchOrderByEmail: (emailAdd) =>
    axiosClient.get(`/api/orders/refetchAllOrderByEmail/${emailAdd}`),
    
  getPossibleCombo: (id) => axiosClient.get(`/api/orders/get-possible-combo/${id}`),
  bulkFetchOrders: (minDate, maxDate) => axiosClient.get(`/api/orders/bulk-refetch/${minDate}/${maxDate}`),
  getEvents: (id) => axiosClient.get(`/api/orders/get-events/${id}`),
  getTransactions: (minDate, maxDate, filter) => axiosClient.get(`/api/orders/get-txns/${minDate}/${maxDate}?filter=${filter}`),
  getShiprateCheck: (filter, page) => axiosClient.get(`/api/orders/shiprate-test?filter=${filter}&page=${page}`),
  getRefunds: (id) => axiosClient.get(`/api/orders/refunds/${id}`),
  getBulkPrintOrders: (payload) => axiosClient.post(`/api/orders/get-bulk-print-orders`, payload),
  getOrderByNum: (payload) => axiosClient.post(`/api/orders/get-by-num`, payload),
  createManualPayment: (payload) =>
    axiosClient.post(`/api/orders/create-manual-payment`, payload),
  getManualPayment: (orderNumber) =>
    axiosClient.get(`/api/orders/get-manual-payment/${orderNumber}`),
  generateTxnLog: (minDate,maxDate,filter) => axiosClient.get(`/api/orders/get-txns/${minDate}/${maxDate}/true?filter=${filter}`),
  getTxnLogFiles: () => axiosClient.get(`/api/orders/get-txn-log-files`),
};

export default ordersApi;
