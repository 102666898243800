import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Typography, message } from "antd";
import Container from "components/container/Container";
import Breadcrumb from "components/breadcrumb/Breadcrumb";
import Path from "../../commons/breadcrumbs";
import { useTranslation } from "react-i18next";
import ShipmentRateDetail from "pages/create-shipment/ShipmentRateDetail";
import ShipmentDetail from "./ShipmentDetail";
import ConsigneeDetail from "./ConsingeeDetail";
import PackagingInfo from "./PackagingInfo";
import { useSelector } from "react-redux";
import queryString from "query-string";
import ExportDeclare from "./ExportDeclare";
import ordersApi from "apis/ordersApi";
import moment from "moment";
import { debounce, lowerCase, some } from "lodash";
import RightColumn from "./RightColumn";
import shipmentsApi from "apis/shipmentsApi";
import { generateIdWithChar, getCountryCode, getProvince, getStateProvinceCode, gramToKg, twoDecimal } from "utils/funcs";
import ShipperDetail from "./ShipperDetail";
import produce from "immer";
import { TitleBox } from "components/title-header/index.style";

import accents from "remove-accents";
import { remove } from "diacritics";

import etdPlt from "../../utils/etd-plt";
import {ShipmentDoneWarn} from "./index.style"

import { companyNameChecker, detectBookCategory, getInsuranceParams, ifNullThenUndefined, restructureWeight } from "./utils";
import LocalStorage from "utils/storage";
import productsApi from "apis/productsApi";
const { Title } = Typography;
global.insuranceSet = false;

const CreateShipment = ({ history }) => {
    const { t } = useTranslation();
    const { currency } = useSelector((state) => state.common);
    const { orderNumber, source } = queryString.parse(history.location.search);
    const [orderId, setOrderId] = useState("");
    const [orderNo, setOrderNo] = useState("");
    const [orderSource, setOrderSource] = useState("");
    const [trackingNo, setTrackingNo] = useState(undefined);
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [fromOrder, setFromOrder] = useState({});
    const [rates, setRates] = useState([]);
    const [ratesError, setRatesError] = useState([]);
    const [createShipmentError, setCreateShipmentError] = useState([]);
    const [summary, setSummary] = useState();
    const [shippingAccounts, setShippingAccounts] = useState([]);
    const [activeCourier, setActiveCourier] = useState({});
    const [shipper, setShipper] = useState([]);
    const [activeShipper, setActiveShipper] = useState({});
    const [generatedOrderId, setGeneratedOrderId] = useState("");
    const [etdPltInfo, setEtdPltInfo] = useState({});
    const [manualChange, setManualChange] = useState({
        status: false,
        manualValue: "0.0",
    });  
    // const [discountApps, setDiscountApps] = useState();
    const [discountApps, setDiscountApps] = useState([]);
    const [initValues, setInitValues] = useState();
    const [subTotal, setSubTotal] = useState();
    const [doneLoadingFlag, setDoneLoadingFlag] = useState(false);
    const [discountCodes, setDiscountCodes] = useState();
    const [shipInfo, setShipInfo] = useState();
    const [shipmentUrl, setShipmentUrl] = useState();
    const [oriItemsQty, setOriItemsQty] = useState();
    const [totalRefunds, setTotalRefunds] = useState();

    const userId = useSelector((state) => state.account.profile.id);
    const courierSelected = some(activeCourier) && activeCourier.apiType;
    const countryCodeSelected = useRef("");
    const etdPltSatisfied = !!courierSelected && !!countryCodeSelected;
    
    // find orderId using orderNumber
    const searchOrder = async (orderNumber, source) => {
        setOrderSource(source);

        const filter = [];
        if (orderNumber) {            
            filter.push(`orderNumber_iLike=%${orderNumber}%`);
        }
        if (source) {
            filter.push(`source_eq=${source}`);
        }

        const payload = {
            page: 1,
            pageSize: 1,
            filter,
        };
        if (!filter.length) {
            return;
        }
        setIsLoading(true);
        try {
            const res = await ordersApi.getOrders(payload);
            if (res.orders && !!res.orders.length) {
                
                //original below
                //const orderId = res.orders.find((item) => item.orderNumber === `#${orderNumber}`).id;
                let order = undefined;
                
                if (source === "shopify") {
                    order = res.orders.find((item) => item.orderNumber === `#${orderNumber}`);
                } else {
                    order = res.orders.find((item) => item.orderNumber === `${orderNumber}`);
                }                
                const orderId = order.id;
                setTrackingNo(order.trackingNumber);
                setOrderId(String(orderId));
            } else {
                message.info("No order was found");
            }
            setIsLoading(false);
        } catch (error) {
            message.error(error.message);
            setIsLoading(false);
        }
    };

    const onFinish = () => {        
        if (activeCourier.hasOwnProperty("id")) {
            const shippingCost = form.getFieldValue('shippingCost');
            const shippingCurrency = form.getFieldValue('shippingCurrency');
            
            form.validateFields()
                .then(async (res) => {
                    setIsLoading(true);
                                                          
                    const value = res;                  
                    const shippingAccountId = activeCourier?.id;

                    //Check if name is in latin characters
                    const name =  value?.personName? remove(value.personName) : "";
                    const isNameInLatinChars = !!name.match(/^[a-z]*$/i);
                    const isPoBox = value?.isPoBox;

                    console.log('INVOICE', value.invoice)
                    const newCS = {
                        userId,
                        orderId: orderId ? orderId : generatedOrderId,
                        // orderNumber: value.orderNumber,
                        orderNumber: typeof value.orderNumber == 'object' ? value.orderNumber.toString() : value.orderNumber,
                        shippingAccountId,
                        shipperDetailsId: activeShipper.id,
                        sampleResponse: false,
                        // invoiceNumber: String(value?.invoice),
                        invoiceNumber: typeof value.invoice == 'object' ? value.invoice.toString() : value.invoice,
                        invoiceDate: moment(value?.invoiceDate).utc().endOf("day").format(),
                        shipmentDate: moment(value?.shipmentDate).utc().endOf("day").format(),
                        declaredValue: String(value?.totalPackageValue),
                        email: value?.personEmail,
                        notificationMessage: value?.notificationMessage,
                        notification: value?.additionalCourierOptions?.includes("notification"),
                        shopifyNotification: value?.additionalCourierOptions?.includes("shopifyNotification"),
                        contents: value?.contents?.substring(0, 69) || '',
                        pltShipment: value?.additionalCourierOptions?.includes("pltShipment"),
                        documentsOnly: value?.additionalCourierOptions?.includes("documentsOnly"),
                        signatureRequired: value?.additionalCourierOptions?.includes("signatureRequired"),
                        signatureType: "DIRECT", // <-- hardcoded
                        useOwnInvoice: value?.additionalCourierOptions?.includes("useOwnInvoice"),
                        fedexPackagingType: value?.fedexPackagingType,
                        fedexServiceType: value?.fedexServiceType,
                        fedexDutiesPaidAccount: value?.fedexDutiesPaidAccount,
                        shipmentPurpose: value?.shipmentPurpose, // <-- please refer documentation from Edward and Kaamesh
                        ...getInsuranceParams(activeCourier, value, currency),
                        shippingCost: shippingCost, 
                        shippingCurrency: shippingCurrency,
                        packingCost: String(value?.totalPackageValue),
                        orderGrandTotal: String(value?.totalPackageValue),
                        handlingCost: "", // <-- hardcoded
                        otherCost: "", // <-- hardcoded
                        trackingNumber: "80329091290", // <-- hardcoded
                        invoiceRemark: value?.invoiceRemark,
                        invoiceCurrency: currency,
                        invoiceWeightUnit: "kg", // <-- hardcoded
                        invoiceLetterhead: {
                            content: "", // <-- hardcoded
                            fedexImageId: "IMAGE_1", // <-- hardcoded
                        },
                        invoiceSignature: {
                            content: "", // <-- hardcoded
                            fedexImageId: "IMAGE_2", // <-- hardcoded
                        },
                        invoiceData: {
                            documentType: "COMMERCIAL_INVOICE", // <-- hardcoded
                            fileName: "Invoice.pdf", // <-- hardcoded
                            content: "JVBERi0xLjQKJeLjz9MKNiAwIG9iago8PC9GaWx0ZXIvRmxhdGVEZWNvZGUvTGVuZ3RoIDUxPj5zdHJl", // <-- hardcoded
                        },
                        rincosServiceCode: "", // <-- hardcoded
                        rincosServiceName: "", // <-- hardcoded
                        labelType: "ZPLII",
                        consignee: {
                            companyName: value?.companyName? remove(value.companyName) : "",
                            personName: value?.personName? remove(value.personName) : "",
                            personPhone: value?.personPhone,
                            personEmail: value?.personEmail,
                            address1: value?.address1? remove(value.address1) : "",
                            address2: value?.address2? remove(value.address2) : "",
                            stateOrProvinceName: value?.stateOrProvinceName? remove(value.stateOrProvinceName) : "",
                            city: value?.city? remove(value.city) : "",
                            division: value?.division? remove(value.division) : "",
                            provinceCode: value?.provinceCode,
                            postal: value?.postal,
                            countryCode: value?.countryCode,
                            iossNumber: value?.iossNumber
                        },
                        exportDeclaration: restructureWeight(
                            value?.exportDeclarationItems?.map((item) => {
                                return {
                                    id: generateIdWithChar(),
                                    quantity: Number(item.quantity),
                                    quantityUnit: "PCS",
                                    description: item.description.substring(0, 69),
                                    value: String(Number(item.unitPrice).toFixed(2)),
                                    totalValue: String(Number(item.totalValue).toFixed(2)),
                                    weight: activeCourier?.apiType === "dhl"? String(Number(item.weight).toFixed(1)) : String(Number(item.weight).toFixed(2)),
                                    grossWeight: activeCourier?.apiType === "dhl"? String(Number(item.grossWeight).toFixed(1)) : String(Number(item.grossWeight).toFixed(2)),
                                    HSCode: item.hsCode,
                                    sku: item.sku,
                                    originCountry: item.originCountry,
                                    category: item.category,
                                    productVariantId: item.productVariantId ? item.productVariantId : "",
                                    updateVariant: item.updateVariant?.includes("updateVariant") || false,
                                };
                            })
                        , activeCourier?.apiType),
                        pieces: value?.pieces?.map((item) => {
                            return {
                                id: generateIdWithChar(),
                                height: String(item.height),
                                width: String(item.width),
                                depth: String(item.depth),
                                value: String(item.value),
                                weight: activeCourier?.apiType === "dhl"? String(Number(item.weight).toFixed(1)) : String(Number(item.weight).toFixed(2)), //String(item.weight),
                                reference: item.reference,
                            };
                        }),
                        shipper: {
                            name: value.shipperName,
                            phonenumber: value.shipperPhoneNumber,
                            utcOffset: shipper[value.shipper - 1]?.utcOffset,
                            email: value.shipperEmail,
                            address1: value.shipperAddress1,
                            address2: value.shipperAddress2,
                            city: value.shipperCity,
                            postal: value.shipperPostal,
                            provinceCode: value.shipperProvinceCode,
                            countryCode: value.shipperCountryCode,
                            country: value.shipperCountryCode,
                        },
                        isPoBox: isPoBox,
                        isNameInLatinChars: isNameInLatinChars,
                        freightCharge: value?.freightCharge || null,
                    };

                    const chooseCourier = activeCourier?.apiType;

                    console.log(`${chooseCourier.toUpperCase()} CREATE SHIPMENT JSON: `, JSON.stringify(newCS));

                    const pushResponse = (response) => {
                        // const payload = {
                        //     trackingNumber: response?.trackingNumber,
                        //     orderId: orderId !== undefined ? orderId : generatedOrderId,
                        //     shopifyNotification: value?.additionalCourierOptions?.includes("shopifyNotification"),
                        //     etd: value?.additionalCourierOptions?.includes("pltShipment"),
                        // };

                        // ----------------------------------------------------------------------------------------------- //
                        const getShipment = async () => {
                            const trackingNumber = response?.trackingNumber;
                            const etd = value?.additionalCourierOptions?.includes("pltShipment");
                            try {
                                const params = {
                                    page: 1,
                                    pageSize: 1,
                                    search: trackingNumber,
                                    fields: ["id", "labelBase64", "invoice_base64"],
                                };
                                const { shipment } = await shipmentsApi.getShipments(params);
                                if (etd === false) {
                                    try {
                                        const payload = {
                                            id: shipment[0]?.id,
                                            documentType: ["invoice_base64"],
                                        };
                                        const resPrint = await shipmentsApi.printShipmentDocuments(payload);
                                        window.location = "/shipments";
                                    } catch (error) {
                                        console.log(error);
                                    }
                                } 
                                try {
                                    const payload = {
                                        id: shipment[0]?.id,
                                        documentType: ["label_base64"],
                                    };
                                    const resPrint = await shipmentsApi.printShipmentDocuments(payload);
                                    console.log(resPrint);
                                    return (window.location = "/shipments");
                                } catch (error) {
                                    message.error(error.message);
                                }                               
                            } catch (error) {
                                message.error(error.message);
                            }
                        };

                        const postFullFillOrder = async () => {
                            try {
                                const payload = {
                                    orderId,
                                };

                                const data = {
                                    shopifyNotification: true,
                                };
                                const res = await ordersApi.postFullFillOrder(payload, queryString.stringify(data));
                                console.log(res);
                            } catch (error) {
                                message.error(error.message);
                            }
                        };

                        // if developemnet they will not print out
                        if (process.env.REACT_APP_DEVELOPMENT === "false") {
                            getShipment();
                            
                            if(chooseCourier !== "rincos")
                                postFullFillOrder();
                        }
                        // ----------------------------------------------------------------------------------------------- //
                        
                        //COP Shipment Webhook     
                        //Safety if- only run in development mode                  
                        console.log("RESPONSE FOR COP WEBHOOK", response);
                        if (orderSource === "custom" || orderSource === "cop") {
                            shipmentsApi.notifyShipment({trackingNo: response?.trackingNumber || '', orderNo: orderNo});
                            
                            /* Moved to order creation
                            const payload = {
                                orderNo: orderNo
                            };
                           
                            if (isPoBox) {
                                shipmentsApi.notifyPoBox(payload);
                            }
                            
                            if (!isNameInLatinChars) {
                                shipmentsApi.notifyNonLatinName(payload);
                            }
                            */
                        }
                        
                        
                        return history.push({
                            pathname: "/shipments",
                        });
                    };

                    if (chooseCourier === "fedex") {
                        try {
                            const resFedex = await shipmentsApi.createShipmentFedex(newCS);                            
                            
                            if (resFedex.response === undefined) {
                                message.success("Succesfull", 3);
                                pushResponse(resFedex);

                                if(resFedex.trackingNumber){
                                    const payload = {
                                        trackingNumber: resFedex.trackingNumber,
                                        username: LocalStorage.get("username","N/A")
                                    };
                                    shipmentsApi.createShipmentOperation(payload);
                                    flushProduct(orderId);
                                }

                                setIsLoading(false);
                            } else if (resFedex.response && resFedex.response?.data) {
                                setCreateShipmentError((prev) => {
                                    return [...prev, resFedex?.response.data];
                                });
                                setIsLoading(false);
                            }
                        } catch (error) {
                            message.error(error.message);
                            setIsLoading(false);
                        }
                    }
                    if (chooseCourier === "dhl") {
                        try {
                            const resDhl = await shipmentsApi.createShipmentDhl(newCS);
                            if (resDhl.response === undefined) {
                                message.success("Succesfull", 3);
                                pushResponse(resDhl);
                                
                                if(resDhl.trackingNumber){
                                    const payload = {
                                        trackingNumber: resDhl.trackingNumber,
                                        username: LocalStorage.get("username","N/A")
                                    };                                    
                                    shipmentsApi.createShipmentOperation(payload);
                                    flushProduct(orderId);
                                }

                                setIsLoading(false);
                            } else if (resDhl.response && resDhl.response?.data) {
                                setCreateShipmentError((prev) => {
                                    return [...prev, resDhl?.response.data];
                                });
                                setIsLoading(false);
                            }
                        } catch (error) {
                            message.error(error.message);
                            setIsLoading(false);
                        }
                    }
                    if (chooseCourier === "rincos") {
                        try {
                            const resRincos = await shipmentsApi.createShipmentRincos(newCS);
                            if (resRincos.response === undefined) {
                                message.success("Succesfull", 3);
                                pushResponse(resRincos);

                                if(resRincos.trackingNumber){
                                    const payload = {
                                        trackingNumber: resRincos.trackingNumber,
                                        username: LocalStorage.get("username","N/A")
                                    };
                                    shipmentsApi.createShipmentOperation(payload);
                                    flushProduct(orderId);
                                }

                                setIsLoading(false);
                            } else if (resRincos.response && resRincos.response?.data) {
                                setCreateShipmentError((prev) => {
                                    return [...prev, resRincos?.response.data];
                                });
                                setIsLoading(false);
                            }
                        } catch (error) {
                            message.error(error.message);
                            setIsLoading(false);
                        }
                    }
                    if (chooseCourier === "ups") {
                        try {
                            const resUps = await shipmentsApi.createShipmentUps(newCS);
                            if (resUps.response === undefined) {
                                message.success("Succesfull", 3);
                                pushResponse(resUps);

                                if(resUps.trackingNumber){
                                    const payload = {
                                        trackingNumber: resUps.trackingNumber,
                                        username: LocalStorage.get("username","N/A")
                                    };
                                    shipmentsApi.createShipmentOperation(payload);
                                    flushProduct(orderId);
                                }
                                setIsLoading(false);
                            } else if (resUps.response && resUps.response?.data) {
                                setCreateShipmentError((prev) => {
                                    return [...prev, resUps?.response.data];
                                });
                                setIsLoading(false);
                            }
                        } catch (error) {
                            message.error(error.message);
                            setIsLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    //////weight validation
                    if (error.errorFields.length > 0) {
                        if (error.errorFields[0].name[0] === 'totalDeclaredWeight')
                        message.error("Total declared weight cannot be 0.00");
                    }
                   
                    setIsLoading(false);
                });
        } else {
            alert("Please choose your courier first");
        }
    };

    const flushProduct = (shippedOrderId) => {
        ordersApi.getOrderById({id: shippedOrderId})
        .then(o => {
            
            const flushes = o.order.lineItems.map(l => {
                return {variantId: l.productVariantId, quantity: l.quantity};
            });
            
            const payload = {
                flushes: flushes
            };
            //console.log("FLUSH PAYLOAD", payload);
            productsApi.flushProductStock(payload)
            .catch(err => console.log("Product flush error", err));
        });
    }

    const debounceOnChange = debounce((event) => {
        setSummary(() => form.getFieldsValue());
    }, 1000);

    const recheckDataHandler = debounce(() => {
        console.log("RUN CHECK HANDLER PIECES");
        const piecesArr = form.getFieldValue("pieces");
        if (piecesArr === undefined || piecesArr.length === 0) {
            return form.setFieldsValue({
                totalPackageWeight: 0,
                totalPackageGrossWeight: 0,
                totalPackageValue: 0,
                totalPackagePiece: 0,
            });
        } else if (piecesArr.length === 1) {
            form.setFieldsValue({
                totalPackageWeight: Number(piecesArr[0].weight) === 0 ? "0.00" : piecesArr[0].weight,
                totalPackageGrossWeight: Number(piecesArr[0].grossWeight) === 0 ? "0.00" : piecesArr[0].grossWeight,
                totalPackageValue: !piecesArr[0].value ? 0 : piecesArr[0].value,
                totalPackagePiece: piecesArr.length,
            });
        } else if (piecesArr.length > 1) {
            // total weight
            const totalPackageWeight = piecesArr.map((item) => item.weight).reduce((prev, curr) => Number(prev) + Number(curr));

            // total gross weight
            const totalPackageGrossWeight = piecesArr.map((item) => item.grossWeight).reduce((prev, curr) => Number(prev) + Number(curr));

            const totalPackageValue = piecesArr.map((item) => item.value).reduce((prev, curr) => Number(prev) + Number(curr));

            form.setFieldsValue({
                totalPackageWeight: totalPackageWeight || "0.00",
                totalPackageGrossWeight: totalPackageGrossWeight || "0.00",
                totalPackageValue: totalPackageValue || 0,
                totalPackagePiece: piecesArr.length,
            });
        }
    }, 500);

    const changeETDPLTOption = () => {
        if (etdPltInfo.outBound === false || etdPltInfo.hasOwnProperty("info")) {
            const additionalCourierOptions = form.getFieldValue("additionalCourierOptions");
            if (additionalCourierOptions.includes("pltShipment")) {
                form.setFieldsValue({
                    additionalCourierOptions: additionalCourierOptions.filter((item) => item !== "pltShipment"),
                });
            }
        }

        if (etdPltInfo.outBound === true) {
            const additionalCourierOptions = form.getFieldValue("additionalCourierOptions");
            if (!additionalCourierOptions.includes("pltShipment")) {
                const result = produce(additionalCourierOptions, (draft) => {
                    draft.push("pltShipment");
                });
                form.setFieldsValue({
                    additionalCourierOptions: result,
                });
            }
        }
    };

    const checkingEtdPltOutBound = (countryCode, courier) => {
        if (!!countryCode && !!some(activeCourier)) {
            if (courier === "fedex") {
                console.log("CHECKING ETD PLT OUT BOUND FEDEX");
                const info = etdPlt.findByCountryCode(countryCode, "etd");
                setEtdPltInfo(info);
            } else if (courier === "dhl") {
                console.log("CHECKING ETD PLT OUT BOUND DHL");
                const info = etdPlt.findByCountryCode(countryCode, "plt");
                setEtdPltInfo(info);
            } else if (courier === "ups") {
                console.log("CHECKING EDI OUT BOUND UPS");
                const info = etdPlt.findByCountryCode(countryCode, "edi");
                setEtdPltInfo(info);
            } else {
                console.log("CHECKING ETD PLT OUT BOUND RINCOS");
                setEtdPltInfo((prev) => {
                    return {
                        info: "Rincos has no ETD or PLT",
                    };
                });
            }
        }
    };

    const calculateDiscountedPrice = async (item) => {
        let price = item.price;

        if(item.discountAllocations){
            for (let p=0; p < item.discountAllocations.length; p++) {
                
                const index = item.discountAllocations[p].discount_application_index;
                const discountApp = item.discountApplications[index];
                if( discountApp.value_type === 'fixed_amount' && discountApp.target_selection !== 'all'){
                    price = price - Number( discountApp.value);
                    console.log(price)
                } else if (discountApp.value_type === 'percentage' && discountApp.target_selection !== 'all'){
                    const percentage = (100 - Number( discountApp.value))/100;
                    price = price * percentage;
                    console.log(price)
                }                  
            }
        }
        return price;
    };

    useEffect(() => {
        const initial = () => {
            const go = generateIdWithChar();
            setGeneratedOrderId(go);
            const params = {
                page: 1,
                pageSize: 10,
            };

            shipmentsApi
                .getListShippingAccounts()
                .then((res) => {
                    
                    if (res === undefined) {
                        return alert("Undefined response from /list-shipping-accounts");
                    }
                    
                    const { shipping_account } = res;
                    setShippingAccounts(shipping_account);
                })
                .catch((error) => {
                    message.error(error?.response?.data);
                });

            // get list of shipper
            shipmentsApi
                .getListShipper(params)
                .then(({ shipper_details, errors }) => {
                    setShipper(shipper_details);                    
                    const selectedShipper = shipper_details[0];
                    console.log("SELECTED SHIPPER", selectedShipper);
                    if (shipper) {
                        form.setFieldsValue({
                            shipper: selectedShipper?.id,
                            shipperAddress1: selectedShipper?.address1,
                            shipperAddress2: selectedShipper?.address2,
                            shipperCity: selectedShipper?.city,
                            shipperCompanyName: selectedShipper?.companyName,
                            shipperCountryCode: selectedShipper?.countryCode,
                            shipperEmail: selectedShipper?.email,
                            shipperName: selectedShipper?.name,
                            shipperPhoneNumber: selectedShipper?.phonenumber,
                            shipperPostal: selectedShipper?.postal,
                            shipperProvinceCode: selectedShipper?.provinceCode,
                        });
                    }
                    setActiveShipper(selectedShipper);
                })
                .catch((error) => {
                    message.error(error?.response?.data);
                });

            searchOrder(orderNumber, source);
        };
        initial();        
    }, []);

    useEffect(() => {
        // this effect to resend create shipment after uncheck ETD/PLT Additional
        // please refer JIRA OP-552 for more info
        const etdError = createShipmentError.find((item) => item?.data?.detail.match("7008"));
        const additionalCourier = form.getFieldValue("additionalCourierOptions")?.includes("pltShipment");
        if (!!etdError && additionalCourier === false) {
            onFinish();
        }
    }, [createShipmentError]);

    useEffect(() => {
        const go = generateIdWithChar();
        // fetch data from order
        if (orderId) {
            setIsLoading(true);
            ordersApi
                .getOrderById({
                    id: orderId,
                    params: { listCombinedOrders: true },
                })
                .then(async (res) => {
                    
                    if(res === undefined){
                        return alert("Order is undefined");
                    }

                    console.log('ORDER DETAILS ' + JSON.stringify(res.order));
                    setOriItemsQty(res.order.lineItems?.length);
                    setDiscountCodes(res.order.discountCodes);
                    setOrderNo(res.order.orderNumber);

                    if(res?.order?.lineItems){
                        res.order.lineItems.forEach((li) => {
                            for (let v = 0; v < li.discountApplications.length ; v++) {
                                li.discountApplications[v].id = li.id
                            }
                            
                            setDiscountApps(current => [...current, li.discountApplications]);
                            // setDiscountApps([...discountApps, li.discountApplications]);
                           
                        })

                        //kaye get the sum of subtotalPrice
                        const combinedSubtotalPrice = res?.order?.combinedSubtotalPrice ? res.order.combinedSubtotalPrice : res?.order?.subtotalPrice
                        setSubTotal(combinedSubtotalPrice);                
                    }                    
                  
                    //Czechia quickfix. Shopify returns as Czech Republic while official name
                    //is Czechia.
                    if(res?.order?.addressCountry === 'Czech Republic'){
                        res.order.addressCountry = 'Czechia';
                    }

                    // change all gram to kg included weight
                    const lineItems = res?.order?.lineItems?.map((item) => {
                        const weightUnit = item.weightUnit.toLowerCase();
                        const validGram = weightUnit === "gram" || weightUnit === "gm" || weightUnit === "g" || weightUnit === "grams";

                        if (validGram) {
                            const weight = gramToKg(Number(item.weight));
                            return { ...item, weight, weightUnit: "kg" };
                        }
                    });
                    const newRes = produce(res?.order, (draft) => {
                        draft.lineItems = lineItems;
                    });

                    if(res?.order?.trackingNumber){
                        shipmentsApi.getOrderShipmentInfo(res.order.trackingNumber)
                        .then((info) => {
                            setShipInfo(info);
                        })
                        .catch((e) => {
                            console.log('SHIPMENT INFO RETRIEVAL FAIL', e.toString());
                        });
                    }

                    setIsLoading(false);
                    message.success(`Successfully fetch Order ${orderId}`, 5);

                    //Retrieve refunds                    
                    await ordersApi.getRefunds(res?.order?.id)
                    .then((refunds) => {
                        console.log('REFUNDS', refunds);
                        let totalAdjAmt = Number(0);
                        refunds.forEach(r => {
                            totalAdjAmt = totalAdjAmt + Number(r.adjAmount);
                        });
                        setTotalRefunds(totalAdjAmt);
                    })
                    .catch((err) => {
                        console.log('ERR R', err)
                    });
                    
                    setDoneLoadingFlag(true);
                    return setFromOrder(newRes);
                })
                .catch((error) => {
                    setIsLoading(false);
                    message.error(error?.message);
                });
        } else {
            form.setFieldsValue({
                orderId: go,
                orderNumber: go,
                invoice: go,
                additionalCourierOptions: ["shopifyNotification", "pltShipment"],
                pieces: [
                    {
                        quantity: 1,
                        depth: "10",
                        grossWeight: 1,
                        height: "10",
                        packagingType: "YOUR_PACKAGING",
                        reference: "",
                        value: 1,
                        weight: 1,
                        width: "10",
                    },
                ],
                exportDeclarationItems: [
                    {
                        originCountry: "KR",
                        quantityUnit: "PCS",
                        description: "",
                        sku: "",
                        commCode: null,
                        hsCode: null,
                        weight: 0, 
                        unitPrice: 0,
                        quantity: 0,
                        category: "Other",
                        grossWeight: "0.00",
                        totalValue: 0,
                    },
                ],
            });
        }
    }, [orderId]);

    useEffect(() => {
        
        if (fromOrder) {
            let additionalCourierOptions = ["shopifyNotification", "pltShipment"];

            let signatureNeeded = fromOrder?.lineItems?.some(function(item) {
                return item.productTitle === 'Signature Upon Delivery'
            });

            global.insuranceSet = fromOrder?.lineItems?.some(function(item) {
                return item.productTitle === 'Courier Insurance'
            });;

            if(signatureNeeded){
                additionalCourierOptions.push("signatureRequired");
            }

            const firstName = ifNullThenUndefined(fromOrder.addressFirstName) || ifNullThenUndefined(fromOrder.billingFirstName);
            const lastName = ifNullThenUndefined(fromOrder.addressLastName) || ifNullThenUndefined(fromOrder.billingLastName);
            const fullName = !lastName ? firstName : `${firstName} ${lastName}`;            

            const nonShipmentItems = [
                "Korea Shopping Agent",
                "Courier ",
                "Signature ",
                "Domestic Shipping",
                "Bunjang",
                "Top-up",
                "Top up",
                "Topup",
                "Keyword Search",
                "Change Of",
                "Shipping top up",
                "Shipping topup",
                "Shipping top-up",
                "Service Fee",
            ].map((item) => item.toLowerCase());

            const exportDeclarationItems1 = fromOrder?.lineItems?.filter(
                (item) => !nonShipmentItems.some((nonShipTitle) => lowerCase(item.productTitle).startsWith(nonShipTitle))
            );

            const exportDeclarationItems2 = exportDeclarationItems1?.filter(
                (item) => !nonShipmentItems.some((nonShipTitle) => lowerCase(item.customProductName).startsWith(nonShipTitle))
            );

            const exportDeclarationItems = exportDeclarationItems2?.filter(
                (item) => (item.fulfillableQuantity > 0 ) || (item.fulfillableQuantity == 0 & item.fulfillmentStatus == "fulfilled")
            );           

            const totalInArray = (unit) => {
                return twoDecimal(exportDeclarationItems?.map((item) => item[unit]).reduce((prev, current) => prev + current, 0));
            };

            const exportItemDescription = (productTitle, productVariantTitle, customProductName) => {
                //const description = productTitle ? `${productTitle} ${productVariantTitle}` : customProductName;
                const description = customProductName ? customProductName : `${productTitle} ${productVariantTitle}`;

                if (detectBookCategory(description)) {
                    return `BOOK ${description}`;
                } else {
                    return description;
                }
            };

            const decriptionAndContent = exportDeclarationItems
                ?.map((item) => exportItemDescription(item.productTitle, item.productVariantTitle, item.customProductName))
                .join(" ");

            const provinceCode = getProvince(getCountryCode(fromOrder.addressCountry))?.find((obj) => obj.name === fromOrder.addressProvince)?.code;
            const companyName = fromOrder.companyName ? fromOrder.companyName : fullName;

            form.setFieldsValue({
                // orderNumber: fromOrder.orderNumber,
                // fromOrder.combinedOrderNumber?.length > 0 ? fromOrder.combinedOrderNumber?.map((obj, index) => index <= 3 ? Object.values(obj) + ',' : "" ).join(""):fromOrder.orderNumber , 
                // try this code to display all ordernumber if combined -> 
                orderNumber: fromOrder.combinedOrderNumber?.length > 0 ? fromOrder.combinedOrderNumber?.map((obj) => Object.values(obj) ) :fromOrder.orderNumber,
                courier: activeCourier?.apiType?.toLowerCase(),
                courierAccount: activeCourier,
                fedexPackagingType: "YOUR_PACKAGING",
                invoiceRemark: "",
                fedexServiceType: "INTERNATIONAL_PRIORITY",
                fedexDutiesPaidAccount: "31200310",
                shipmentPurpose: "SOLD",
                shipmentDate: moment().utc(),
                contents: decriptionAndContent,
                notificationMessage: "",
                additionalCourierOptions,
                address1: ifNullThenUndefined(fromOrder.addressLineOne),
                address2: ifNullThenUndefined(fromOrder.addressLineTwo),
                city: ifNullThenUndefined(fromOrder.addressCity),
                countryCode: getCountryCode(fromOrder?.addressCountry),
                personEmail: ifNullThenUndefined(fromOrder.customerEmail),
                personName: fullName,
                companyName: companyName,
                personPhone: fromOrder?.addressPhone,
                postal: ifNullThenUndefined(fromOrder.addressZip),
                division: ifNullThenUndefined(fromOrder.addressProvince) || ifNullThenUndefined(fromOrder.addressCity),
                provinceCode,
                // provinceCode:
                //     ifNullThenUndefined(getStateProvinceCode(fromOrder.addressProvince, getCountryCode(fromOrder?.addressCountry))) ||
                //     ifNullThenUndefined(fromOrder.addressProvince),
                totalPackageWeight: totalInArray("weight"),
                totalPackageGrossWeight: totalInArray("weight"),
                totalPieces: 1,
                invoice: fromOrder.combinedOrderNumber?.length > 0 ? fromOrder.combinedOrderNumber?.map((obj) => Object.values(obj) ) :fromOrder.orderNumber,
                invoiceDate: moment().utc(),
                totalDeclaredValue: totalInArray("price"),
                totalDeclaredWeight: totalInArray("weight"),
                totalDeclaredGrossWeight: totalInArray("weight"),
                pieces: [
                    {
                        quantity: 1,
                        depth: "10",
                        grossWeight: totalInArray("weight"),
                        height: "10",
                        packagingType: "YOUR_PACKAGING",
                        reference: "",
                        value: 1,
                        weight: totalInArray("weight"),
                        width: "10",
                    },
                ],               
                exportDeclarationItems: exportDeclarationItems?.map((item, itemIndex) => {
                    console.log('ORDER REFUNDS', totalRefunds);
                    //attempt at dispersing courier insurance
                    //get line items without cancelled item
                    const oriLineItems = fromOrder.lineItems.filter((item) => 
                        (item.fulfillableQuantity > 0 ) || (item.fulfillableQuantity == 0 & item.fulfillmentStatus == "fulfilled")
                    );
                    
                    const oriLength = oriLineItems.length;

                    //Difference array between actual shipment items vs other non shipment items
                    const difference = oriLineItems.filter(x => !exportDeclarationItems.includes(x));

                    let diffTotalAppliedDiscounts = Number(0);
                    difference.map(d => {
                        for(let j = 0; j < d.discountAllocations.length; j++){
                            diffTotalAppliedDiscounts = Number(diffTotalAppliedDiscounts) + Number(d.discountAllocations[j].amount);
                        }                        
                    });

                    //this is to get qty of non zero price of line items. we need to get it as 
                    //denominator for dispersing differential discounts (discounts those dont get into final line items)
                    const legit = exportDeclarationItems.filter(x => {
                        let inprice = Number(x.price);

                        if(x.discountAllocations){
                            for (let p=0; p < x.discountAllocations.length; p++) {
                               
                                const index = x.discountAllocations[p].discount_application_index;
                               
                                const discountApp = x.discountApplications[index];
                                if( discountApp.value_type === 'fixed_amount' /*&& discountApp.target_selection !== 'all'*/){                                    
                                    inprice = inprice - Number(x.discountAllocations[p].amount/x.quantity);
                                    
                                } else if (discountApp.value_type === 'percentage' /*&& discountApp.target_selection !== 'all'*/){
                                    const percentage = (100 - Number( discountApp.value))/100;
                                    inprice = inprice * percentage;                                    
                                }                  
                            }
                        }
                        if(Number(inprice) > 0){                            
                            return x;
                        }
                    });

                    let dispersed = Number(0);
                    if(diffTotalAppliedDiscounts > 0){
                        dispersed = Number(diffTotalAppliedDiscounts/legit.length);
                    }
                    
                    //FOR REFUNDS CAUSING ORDER ADJUSTMENT
                    let dispersedRefund = Number(0);
                    if (totalRefunds) {
                        dispersedRefund = Number(totalRefunds/legit.length);
                    }
                    console.log('DISPERSED REFUND', dispersedRefund);

                    let price = Number(item.price);
                    let itemId = item.id;                   

                    //this is a bit confusing.
                    //discount_codes apply to whole order, not line item.
                    //so if type = discount_code, we dont apply it I guess?
                    if(item.discountAllocations){
                        for (let p=0; p < item.discountAllocations.length; p++) {
                           
                            const index = item.discountAllocations[p].discount_application_index;
                           
                            const discountApp = item.discountApplications[index];
                            if( discountApp.value_type === 'fixed_amount' /*&& discountApp.target_selection !== 'all'*/){
                                price = price - Number(item.discountAllocations[p].amount/item.quantity);//Number( discountApp.value);
                                //console.log(price)
                            } else if (discountApp.value_type === 'percentage' /*&& discountApp.target_selection !== 'all'*/){
                                const percentage = (100 - Number( discountApp.value))/100;
                                price = price * percentage;
                                //price = price - Number(item.discountAllocations[p].amount);
                                //console.log(price)
                            }
                            
                            if(price > 0){
                                //This recent change (28/3/23) is to avoid potential bug from
                                //price discount not divided by quantity.
                                price = price - Number(dispersed/item.quantity); 
                            }
                        }
                    }
                    
                    //Apply REFUNDS adjustment if applicable
                    //REFUNDS adjustment comes in negative wrt the orders
                    if (Math.abs(dispersedRefund) > 0){
                        price = price + (dispersedRefund/item.quantity);
                    }

                    console.log('DISCOUNT CODES', discountCodes);
    
                    const totalVal = (Number(price).toFixed(2) * Number(item.quantity)).toFixed(2);
                    let weight = item.variant? item.variant.weight : item.weight ? item.weight : Number(0);
                                                                            
                    //For initializing auto rate-check if the weight is zero
                    if(itemIndex === 0){
                        weight = weight > 0? weight: Number(0.5);
                    }  
                    
                    const itemDescription = exportItemDescription(item.productTitle, item.productVariantTitle, item.customProductName);
                    return {
                        productVariantId: item.productVariantId,
                        originCountry: "KR",
                        quantityUnit: "PCS",
                        description: itemDescription,

                        sku: "",
                        commCode: null,
                        hsCode: item.variant ? item.variant.HSCode : "",
                        weight: twoDecimal(Number(weight)),
                        grossWeight: twoDecimal(Number(weight)),
                        //unitPrice: Number(item.price) < 0 ? 0 : (Number(item.price) / Number(item.quantity)).toFixed(2),
                        //unitPrice: item.variant? Number(item.variant.price).toFixed(2) : Number(item.price).toFixed(2),
                        unitPrice: price.toFixed(2),

                        quantity: Number(item.quantity),
                        category: detectBookCategory(itemDescription) ? "BOOK" : "OTHER",
                        //totalValue: Number(item.price),
                        //totalValue: item.variant? (Number(item.variant.price) * Number(item.quantity)).toFixed(2) : (Number(item.price) * Number(item.quantity)).toFixed(2)
                        totalValue: totalVal
                    };
                }),

            });            
            setInitValues(form.getFieldsValue());       
        }
    }, [fromOrder]);

    useEffect(() => {
        changeETDPLTOption();
    }, [etdPltInfo]);

    useEffect(() => {        
        if (activeShipper) {
            form.setFieldsValue({
                shipper: activeShipper.id,
                shipperAddress1: activeShipper.address1,
                shipperAddress2: activeShipper.address2,
                shipperCity: activeShipper.city,
                shipperCompanyName: activeShipper.companyName,
                shipperCountryCode: activeShipper.countryCode,
                shipperEmail: activeShipper.email,
                shipperName: activeShipper.name,
                shipperPhoneNumber: activeShipper.phonenumber,
                shipperPostal: activeShipper.postal,
                shipperProvinceCode: activeShipper.provinceCode,
            });
        }
        console.log('after assign ' + form.getFieldValue('shipperCompanyName'));
    }, [activeShipper]);
    
    //Initial validations for contents/desc run when the form items populated
    useEffect(() => {               
        form.validateFields(['contents']); 
        const exportItems = form.getFieldValue('exportDeclarationItems');
        if(exportItems){
            const size = exportItems.length;
            
            for(let i = 0; i < size; i ++){
                form.validateFields([["exportDeclarationItems", i, "description"]]);                                 
            }
        }
        const tmpSubtotal = subTotal;
        setSubTotal(tmpSubtotal);
    },[form, initValues]);
    
    useEffect(() => {
        if(shipInfo?.id){
            const url = "/shipments/details/" + shipInfo?.id;
            setShipmentUrl(url); 
        }
    },[shipInfo]);

    return (
        <>
            <TitleBox className="title-page">
                <Title>{t("Create Shipment")}</Title>
                <Breadcrumb items={[{ name: "Home", path: "/" }, { name: Path.CREATE_SHIPMENT.name }]} />
            </TitleBox>
 
            <Container style={{ backgroundColor: "#ececec" }}>                   
                {trackingNo? 
                (<ShipmentDoneWarn>
                    <Row>                   
                        <Col md={24} className="warn">
                            <div className="data">
                                Shipment has been made for this order. Tracking number: <a className="data" href={shipmentUrl? shipmentUrl:''}>{trackingNo}</a>                                        
                            </div>
                        </Col>             
                    </Row> 
                </ShipmentDoneWarn>) : undefined
                }                      
                <Row>
                    {/* left */}
                    <Col md={18}>
                        <Form wrapperCol={{ span: 24 }} layout="verticle" form={form} scrollToFirstError onChange={debounceOnChange}>
                            <ShipmentRateDetail
                                isLoading={isLoading}
                                form={form}
                                setIsLoading={setIsLoading}
                                setRates={setRates}
                                setRatesError={setRatesError}
                                ratesError={ratesError}
                                shippingAccounts={shippingAccounts}
                                activeShipper={activeShipper}
                                manualChange={manualChange}
                                setManualChange={setManualChange}
                                doneLoadingFlag={doneLoadingFlag}
                                setSummary={setSummary}
                            />
                            <ShipmentDetail
                                shipper={shipper}
                                form={form}
                                setActiveShipper={setActiveShipper}
                                activeCourier={activeCourier}
                                shippingAccounts={shippingAccounts}
                                setActiveCourier={setActiveCourier}
                                currency={currency}
                                companyNameChecker={companyNameChecker}
                                checkingEtdPltOutBound={checkingEtdPltOutBound}
                            />                
                             <ConsigneeDetail
                                fromOrder={fromOrder}
                                form={form}
                                checkingEtdPltOutBound={checkingEtdPltOutBound}
                                etdPltSatisfied={etdPltSatisfied}
                                activeCourier={activeCourier}
                                setRatesError={setRatesError}
                            />            
                            <ShipperDetail form={form}                                
                                shipper={shipper}   
                                activeShipper={activeShipper}                             
                                setActiveShipper={setActiveShipper}
                                shipmentsApi={shipmentsApi}
                                setShipper={setShipper}
                            />                           
                            <ExportDeclare
                                // setMinWeight={setMinWeight} <-- incase they want back 0.02
                                form={form}
                                currency={currency}
                                detectBookCategory={detectBookCategory}
                                piecesRecheckDataHandler={recheckDataHandler}
                                setManualChange={setManualChange} 
                                subTotal={subTotal}   
                                discountCodes={discountCodes}                           
                            />
                            <PackagingInfo currency={currency} fromOrder={fromOrder} form={form} recheckDataHandler={recheckDataHandler}  />
                        </Form>
                    </Col>
                    {/* right */}
                    <Col md={6}>
                        <RightColumn
                            isLoading={isLoading}
                            activeCourier={activeCourier}
                            rates={rates}
                            ratesError={ratesError}
                            createShipmentError={createShipmentError}
                            summary={summary}
                            setActiveCourier={setActiveCourier}
                            form={form}
                            shippingAccounts={shippingAccounts}
                            onFinish={onFinish}
                            companyNameChecker={companyNameChecker}
                            setEtdPltInfo={setEtdPltInfo}
                            etdPltInfo={etdPltInfo}                            
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default CreateShipment;
