import { Space } from 'antd';
import styled from 'styled-components';
import Box from '../../components/box/Box';

export const TitleBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  background-color: #edf1f5;
  padding: 12px 24px;
  border-bottom: 1px solid #78828c21;
  h1.ant-typography {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
  }
  h2.ant-typography {
    font-size: 16px;
    text-transform: uppercase;
    color: #3e5569;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 6px;
  }
`;
export const OrderDetailPageStyled = styled(Box)`
  .order-info .ant-card-body {
    padding: 0;
    .order-details .box-data {
      padding: 10px;
      border-right: 1px solid #dddfeb;
      border-bottom: 1px solid #dddfeb;
      background-color: #f8f9fc;
      .label {
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .data {
        font-size: 20px;
        word-break: break-word;
        &.bold {
          font-weight: bold;
        }
        &.uppercase {
          text-transform: uppercase;
        }
      }
    }
    .order-details .warn{
      text-align:center;
      padding-top:5px;
      padding-bottom:5px;
      background-color:rgba(182, 219, 143, 0.8);
      .view-shipment{
        background-color:rgb(104, 140, 67);
        border-color:rgb(64, 87, 41);
      }
      .data {
        font-size: 20px;
        word-break: break-word;
        &.bold {
          font-weight: bold;
        }
        &.uppercase {
          text-transform: uppercase;
        }
      }
    }
    .order-details .redwarn{
      text-align:center;
      padding-top:5px;
      padding-bottom:5px;
      background-color:rgb(255, 0, 0);
      .view-shipment{
        background-color:rgb(104, 140, 67);
        border-color:rgb(64, 87, 41);
      }
      .data {
        color : white;
        font-size: 20px;
        word-break: break-word;
        &.bold {
          font-weight: bold;
        }
        &.uppercase {
          text-transform: uppercase;
        }
      }
    }
    .order-details .riskMedWarn{
      text-align:center;
      padding-top:5px;
      padding-bottom:5px;
      background-color:rgba(249, 200, 0, 0.62);
      .view-shipment{
        background-color:rgb(104, 140, 67);
        border-color:rgb(64, 87, 41);
      }
      .data {
        font-size: 20px;
        word-break: break-word;
        &.bold {
          font-weight: bold;
        }
        &.uppercase {
          text-transform: uppercase;
        }
      }
    }
    .order-details .riskHighWarn{
      text-align:center;
      padding-top:5px;
      padding-bottom:5px;
      background-color:rgba(249, 0, 0, 0.53);
      .view-shipment{
        background-color:rgb(104, 140, 67);
        border-color:rgb(64, 87, 41);
      }
      .data {
        font-size: 20px;
        word-break: break-word;
        &.bold {
          font-weight: bold;
        }
        &.uppercase {
          text-transform: uppercase;
        }
      }
    }
    .billing-shipping-info .box-data {
      padding: 10px;
      .label {
        text-transform: uppercase;
        font-size: 12px;
        margin-bottom: 5px;
      }
      .data {
        font-size: 14px;
        word-break: break-word;
      }
    }
  }
  .order-actions {
    width: 100%;
    .create-shipment {
    }
    button, a {
      width: 100%;
    }
    .view-shipment {
      background-color:rgb(104, 140, 67);
      border-color:rgb(64, 87, 41);
    }    
  }
  .collapse-line-items {
    margin-bottom: 6px;
    .ant-collapse-content-box {
      padding: 0;
    }
  }
  .line-items {
    border-bottom: none;
    .ant-card-head {
      border-bottom: 2px solid #f0f0f0;
      background-color: #fafafa;
      .ant-card-head-title {
        color: var(--info);
      }
    }
    .ant-card-body {
      padding: 0;
    }
    .ant-table-thead > tr > th {
      background-color: transparent;
    }
    table .actions {
      padding-top: 12px;
      display: none;
      .btn-success {
        border-color: var(--success);
        color: var(--success);
        &:hover {
          color: #fff;
          background-color: var(--success);
        }
      }
      .btn-info {
        border-color: var(--info);
        color: var(--info);
        &:hover {
          color: #fff;
          background-color: var(--info);
        }
      }
      .btn-warning {
        border-color: var(--warning);
        color: var(--warning);
        &:hover {
          color: #fff;
          background-color: var(--warning);
        }
      }
    }
  }
`;

export const ActionBox = styled(Space)`
  &.action-box {
    button {
      min-width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      &.btn-update {
        border-color: #d9d9d9;
      }
    }
  }
`;
