export const PRODUCT_OPTIONS = [
    {
        value: "size",
        label: "SIZE",
    },
    {
        value: "color",
        label: "COLOR",
    },
    {
        value: "material",
        label: "MATERIAL",
    },
    {
        value: "style",
        label: "STYLE",
    }
    
];
  
  