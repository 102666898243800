import { createSlice } from "@reduxjs/toolkit";
import { SessionStorage } from "../../utils/storage";

const accountSlice = createSlice({
    name: "account",
    initialState: {
        token: SessionStorage.has("token"),
        profile: {},
        pagePermissions: [],
        permissionKey: [],
    },
    reducers: {
        login(state, action) {
            const { token } = action.payload;
            state.token = token;
        },
        logout(state) {
            state.token = "";
            state.profile = {};
            state.permissionKey = [];
        },
        setProfile(state, action) {
            state.profile = action?.payload.profile;
            state.permissionKey = action?.payload.permissionKey;
            state.pagePermissions = action?.payload.pagePermissions;
        },
    },
});

const { actions, reducer } = accountSlice;

export const { login, logout, setProfile } = actions;

export default reducer;
