import {
  Button,
  Col,
  Modal,
  Row,
  Space,
  Spin,
  Tag,
  Typography,
  Table,
  Input,
  message,
  Tabs
} from "antd";
import productsApi from "apis/productsApi";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { removeBlankNull } from "utils/funcs";
import Path from "../../../commons/breadcrumbs";
import Breadcrumbs from "../../../components/breadcrumb/Breadcrumb";
import AllPendingPurchases from "./AllPendingPurchase";
import { CardContainer, TitleBox } from "./index.style";
const { Title } = Typography;

const { TabPane } = Tabs;

const Snappy = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [isDeleteExport, setDeleteExport] = useState(false);
  const [deleteList, setDeleteList] = useState([]);
  const [params, setParams] = useState({
    page: 1,
    pageSize: 10,
    totalPage: null,
    sort: [],
    productFilter: '',
    variantFilter: '',
    orderFilter: [],
  });
  

  const gettingProductExportList = async (newParam) => {
    const param = removeBlankNull({ ...params, ...newParam });
    param.filter = 'exportShopId_eq=2';
    delete param.totalPage;
    if (param.productFilter) {
      param.productFilter = `title_iLike=%${param.productFilter}%`;
    }
    if (param.variantFilter) {
      param.variantFilter = `title_iLike=%${param.variantFilter}%`;
    }
    const { orderFilter } = param;
    if (Array.isArray(orderFilter) && orderFilter.some((item) => item)) {
      param.orderFilter = [];
      if (orderFilter[0]) {
        param.orderFilter.push(`createdAt_gte=${orderFilter[0].utc().startOf('date').format()}`)
      }
      if (orderFilter[1]) {
        param.orderFilter.push(`createdAt_lte=${orderFilter[1].utc().startOf('date').format()}`)
      }
    }
    setIsLoading(true);
    try {
      const res = await productsApi.getListProductExports(param);
      if (res === undefined) {
        return alert("Response undefined");
      }

      console.log('RES',res)

      const { productExport, totalCount } = res;

      const reformPendingProds = productExport.map((obj, index) => {
        return { ...obj, key: index };
      });

      console.log('reformPendingProds',reformPendingProds)

      setDataList(reformPendingProds);
      setParams((prev) => {
        return {
          ...prev,
          totalPage: totalCount,
        };
      });
      message.success("Successfully fetch all Snappy export", 2);
      setIsLoading(false);

    } catch (error) {
      message.error(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    gettingProductExportList(params);
  }, []);


  return (
    <>
      <TitleBox className="title-page">
        <Title>{t("Snappy Export")}</Title>
        <Breadcrumbs
          items={[
            { name: "Home", path: "/" },
            { name: Path.SNAPPY_EXPORT.name },
          ]}
        />
      </TitleBox>
      <CardContainer>
            <AllPendingPurchases
              params={params}
              setParams={setParams}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              dataList={dataList}
              setDataList={setDataList}
              gettingProductExportList={gettingProductExportList}
              // removeExport={removeExport}
              setDeleteExport={setDeleteExport}
              isDeleteExport={isDeleteExport}
              deleteList={deleteList}
              setDeleteList={setDeleteList}
            />
      </CardContainer>
    </>
  );
};

export default Snappy;
