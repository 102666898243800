import { Input, Card, Avatar, Button, Row, Col, List, Typography, Form, message } from "antd";
import accountApi from "apis/accountApi";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LocalStorage, { SessionStorage} from "utils/storage";
import Path from "../../commons/breadcrumbs";
import { HeaderList, OpControls } from "./index.style";

const { Title, Paragraph } = Typography;


const ResetPassword = ({ history, location, match }) => {    
    const [resetPassword, setResetPassword] = useState({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    });
    
    const inputHandler = (event) => {
        event.preventDefault();
        return setResetPassword((prev) => {
            return {
                ...prev,
                [event.target.name]: event.target.value,
            }
        })
    };

    const changePassword = () => {
        //check if new password === confirm passowrd
        if(resetPassword.newPassword.trim() !== resetPassword.confirmNewPassword){
            message.error('New password and confirmation must be the same');
            return;
        }

        const access_token = LocalStorage.get("access_token");  
        const userId = LocalStorage.get("userid");  
        const payload = {
            userId: userId,
            oldPassword: resetPassword.oldPassword.trim(),
            newPassword: resetPassword.newPassword.trim()
        }

        accountApi.resetPassword(payload,{
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
            
        })
        .then((r) => { 
            if(r.password){                 
                message.success('Password reset successfully');
                history.push('/settings/users-and-permissions');
            } else {
                throw(r);
            }
            //setIsLoading(false)            
        })
        .catch((e) => {
            console.log('Error', e);
            //setIsLoading(false)
            message.error('Failed to reset password. ' + JSON.stringify(e.response.data.errorCauses));
        });
    }
    
    useEffect(() => {
        const userId = match.params.id;
    },[]);

    return(
        <>
            <TitleHeader
                listOfBreadcrumb={[
                    { name: "Home", path: "/" },
                    { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
                    {
                        name: Path.RESET_PASSWORD.name,
                    },
                ]}
                title={Path.RESET_PASSWORD.name}
            />
            <Box>
                <Container>
                    <Title level={3}>{("Change Password")}</Title>
                    <Card style={{ marginBottom: '24px' }} title='Details'>
                        <Form layout='vertical'>            
                            <Row gutter={16}>
                                <Col md={12}>
                                    <Form.Item label='Current Password' required>
                                        <Input
                                            name='oldPassword'
                                            type='password'
                                            placeholder='Old Password'
                                            value={resetPassword.oldPassword}
                                            onChange={inputHandler}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={8}>
                                    <Form.Item label='New Password' required>
                                        <Input
                                            name='newPassword'
                                            type='password'
                                            placeholder='New Password'
                                            value={resetPassword.newPassword}
                                            onChange={inputHandler}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={8}>
                                    <Form.Item label='Confirm New Password' required>
                                        <Input
                                            name='confirmNewPassword'
                                            type='password'
                                            placeholder='Confirm New Password'
                                            value={resetPassword.confirmNewPassword}
                                            onChange={inputHandler}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col md={8}>
                                    <Button type="primary" onClick={changePassword}>
                                        Reset Password
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Container>
            </Box>
        </>
    );
}   

export default ResetPassword;