import { Avatar, Button, Row, Col, List, Typography, message, Spin, Upload, Card, Space, Select, Table, Input } from "antd";
import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import Path from "../../commons/breadcrumbs";
import { Link, useHistory } from "react-router-dom";
import Box from "components/box/Box";
import { useTranslation } from "react-i18next";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import { Icon } from "@iconify/react";
import accountingApi from "apis/accountingApi";
import moment from "moment";
import AccountTxnForm from "./AccountTxnForm"
import CardTxnForm from "./CardTxnForm";
import axios from "axios";

const { Title, Paragraph, Text } = Typography;

const AccountsTransactions = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [creditCards, setCreditCards] = useState([]);
    const [txnTypes, setTxnTypes] = useState([]);
    
    const [selectedAccount, setSelectedAccount] = useState('');
    const [selectedAccName, setSelectedAccName] = useState('');
    const [selectedAccType, setSelectedAccType] = useState('');
    const [selectedMonth, setSelectedMonth] = useState();
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedTxn, setSelectedTxn] = useState();

    const [currentLog, setCurrentLog] = useState([]);
    const [currentCols, setCurrentCols] = useState([]);
    const [yearOptions, setYearOptions] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [searchProp, setSearchProp] = useState('category');
    const [searchKey, setSearchKey] = useState('');

    const [openAccTxnForm, setOpenAccTxnForm] = useState(false);
    const [openCardTxnForm, setOpenCardTxnForm] = useState(false);

    const [refreshFlag, setRefreshFlag] = useState(0);

    const getTransLog = async () => {
        const type = selectedAccType;
        const baseMonth = selectedYear.toString() + selectedMonth;
        let log = [];
      
        switch(type){
            case('bank'):                
                log = await accountingApi.getAccountLogEx(selectedAccount, selectedAccName, baseMonth);
                console.log('LOG', log);
                setTotalRows(log.length);
                //setTotalPages(retObj.maxPageNum);
                //setPageRows(retObj.countPerPage);
                //setCurrentPage(retObj.currentPage);
                
                //sort logs
                //objs.sort((a,b) => a.last_nom - b.last_nom);
                log.sort((a,b) => a.id - b.id);

                setCurrentLog(log);
                setCurrentCols(accountColumns);

                break;
            case('card'):
                log  = await accountingApi.getCardLogEx(selectedAccount, selectedAccName, baseMonth);
                console.log('LOG', log);
                setTotalRows(log.length);
                //setTotalPages(retObj.maxPageNum);
                //setPageRows(retObj.countPerPage);
                //setCurrentPage(retObj.currentPage);
                
                log.sort((a,b) => a.id - b.id);

                setCurrentLog(log);
                setCurrentCols(cardColumns);
                             
                break;                
        }
    };

    const fetchTransLog = async () => {
        const type = selectedAccType;
        const baseMonth = selectedYear.toString() + selectedMonth;
        let log = [];
      
        switch(type){
            case('bank'):                
                
                log = await accountingApi.fetchAccountLogEx(selectedAccount, selectedAccName, baseMonth);
                console.log('LOG', log);
                setTotalRows(log.length);
                //setTotalPages(retObj.maxPageNum);
                //setPageRows(retObj.countPerPage);
                //setCurrentPage(retObj.currentPage);
                
                //sort logs
                //objs.sort((a,b) => a.last_nom - b.last_nom);
                log.sort((a,b) => a.id - b.id);

                setCurrentLog(log);
                setCurrentCols(accountColumns);

                break;
            case('card'):
                log  = await accountingApi.fetchCardLogEx(selectedAccount, selectedAccName, baseMonth);
                console.log('LOG', log);
                setTotalRows(log.length);
                //setTotalPages(retObj.maxPageNum);
                //setPageRows(retObj.countPerPage);
                //setCurrentPage(retObj.currentPage);
                
                log.sort((a,b) => a.id - b.id);

                setCurrentLog(log);
                setCurrentCols(cardColumns);
                             
                break;                
        }
    };

    const accountColumns = [
        {
            title: '#',
            dataIndex: 'CorpNum',
            key: 'CorpNum',
            render: (item, record, index) => {
                return (<>{index+1}</>);
            }
        },
        {
            title: 'Corp#',
            dataIndex: 'corpNum',
            key: 'corpNum',
        },
        {
            title: 'Account',
            dataIndex: 'accNum',
            key: 'accNum',
        },
        {
            title: 'Withdrawal',
            dataIndex: 'withdraw',
            key: 'withdraw',
        },
        {
            title: 'Deposit',
            dataIndex: 'deposit',
            key: 'deposit',
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
        },
        {
            title: 'Time (KST)',
            dataIndex: 'txnTime',
            key: 'txnTime',
            render: (item) => {
                return(<>{moment(item).utcOffset("+0900").format("YYYY-MM-DD HH:mm")}</>)
            }
        },
        {
            title: 'Type',
            dataIndex: 'txnType',
            key: 'txnType',
        },
        {
            title: 'Txn Office',
            dataIndex: 'txnOffice',
            key: 'txnOffice',
        },
        {
            title: 'Remark',
            dataIndex: 'txnRemark',
            key: 'txnRemark',
        },
        /*obsolete in latest API
        {
            title: 'Mgt Remark1',
            dataIndex: 'MgtRemark1',
            key: 'MgtRemark1',
        },
        {
            title: 'Mgt Remark2',
            dataIndex: 'MgtRemark2',
            key: 'MgtRemark2',
        },
        */
        {
            title: 'Ref Key',
            dataIndex: 'txnRefKey',
            key: 'txnRefKey',
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Order#',
            dataIndex: 'orderNo',
            key: 'orderNo',
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
        },
        {
            title: 'Not Matched',
            dataIndex: 'notMatched',
            key: 'notMatched',
            render: (item, record) =>{
                return(<>{item? "Yes":"No"}</>)
            }
        },
        {
            title: 'Action',
            dataIndex: 'txnRefKey',
            key: 'txnRefKey',
            render: (item, record, index) => {
               // setSelectedTxn(record);
                return (<Button type="primary" size="small" onClick={() => testOpen(record)}>Edit</Button>);
            }
        }

    ];

    const cardColumns = [
        {
            title: '#',
            dataIndex: 'CorpNum',
            key: 'CorpNum',
            render: (item, record, index) => {
                return (<>{index+1}</>);
            }
        },
        {
            title: 'Corp#',
            dataIndex: 'corpNum',
            key: 'corpNum',
        },
        {
            title: 'Card#',
            dataIndex: 'cardNum',
            key: 'cardNum',
        },
        {
            title: 'Time (KST)',
            dataIndex: 'usageTime',
            key: 'usageTime',
            render: (item) => {
                return(<>{moment(item).utcOffset("+0900").format("YYYY-MM-DD HH:mm")}</>)
            }
        },
        {
            title: 'Approv#',
            dataIndex: 'approvalNum',
            key: 'approvalNum',
        },
        {
            title: 'Approv Type',
            dataIndex: 'approvalType',
            key: 'approvalType',
        },
        {
            title: 'Approv Cost',
            dataIndex: 'approvalCost',
            key: 'approvalCost',
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Tax',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: 'Serv Chg',
            dataIndex: 'serviceCharge',
            key: 'serviceCharge',
        },
        {
            title: 'Total Amt',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
        },
        {
            title: 'Store#',
            dataIndex: 'storeNum',
            key: 'storeNum',
        },
        {
            title: 'Store Name',
            dataIndex: 'storeName',
            key: 'storeName',
        },
        {
            title: 'Usage Key',
            dataIndex: 'usageKey',
            key: 'usageKey',
        }, 
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Order#',
            dataIndex: 'orderNo',
            key: 'orderNo',
        },
        {
            title: 'Notes',
            dataIndex: 'notes',
            key: 'notes',
        },
        {
            title: 'Not Matched',
            dataIndex: 'notMatched',
            key: 'notMatched',
            render: (item, record) =>{
                return(<>{item? "Yes":"No"}</>)
            }
        },
        {
            title: 'Action',
            dataIndex: 'usageKey',
            key: 'usageKey',
            render: (item, record, index) => {
                return (<Button type="primary" size="small" onClick={()=> showCardTxnDetails(record)}>Edit</Button>);
            }
        }
    ];

    const initYearOptions = () => {
        const currYear = new Date().getFullYear();
        const currMonth = moment().format("MM");
        const arr = [];

        setSelectedYear(currYear);
        setSelectedMonth(currMonth);
       
        //start from 2010
        for(let i = 2010; i <= currYear; i++){
            arr.push(i);
        }
        
        setYearOptions(arr);
    };

    const testOpen = (txn) => {
        if(txn){
            setSelectedTxn(txn);
            setOpenAccTxnForm(true);
        }        
    };

    const showCardTxnDetails = (txn) => {
        if(txn){
            setSelectedTxn(txn);
            setOpenCardTxnForm(true);
        }       
    }

    const chooseAcc = (value, text) => {
        const type = value.split('-')[0].trim();
        const accNum = value.split('-')[1].trim();
        const accName = text.split('-')[0].trim();
        setSelectedAccType(type);
        setSelectedAccount(accNum); 
        setSelectedAccName(accName);
    };

    const changeSearchProp = (value) => {
        console.log(`selected ${value}`);
        setSearchProp(value);
    };

    const handleSearchChange = (event) => {
        setSearchKey(event.target.value);
    };

    const searchTxn = () => {
        //alert('NOT YET IMPLEMENTED');
        const type = selectedAccType;
        console.log('SEARCHING');
        let tmp;
        switch(searchProp){
            case('notMatched'):
                console.log('notmatched');
                
                break;
            case('notes'):
                tmp = currentLog.filter(c => {
                    const notes = c?.notes || '';
                    if(notes.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
                        return c; 
                });
                setCurrentLog(tmp);
                break;
            case('category'):                
                tmp = currentLog.filter(c => {
                    const category = c?.category || '';
                    if(category.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)
                        return c; 
                });
                setCurrentLog(tmp);
                break;
            case('refKey'):
                tmp = currentLog.filter(c => {
                    let refKey;
                    
                    if (type === "bank"){
                        console.log('BANK SEARCH');
                        refKey = c?.txnRefKey || '';
                    } else if (type === "card") {
                        console.log('CARD SEARCH');
                        refKey = c?.usageKey || '';
                    }
                    console.log('RREF',refKey, ' KEY', searchKey);
                    if(refKey.indexOf(searchKey) > -1)
                        return c; 
                });
                setCurrentLog(tmp);
                break;
        }
    };

    const fetchExcelData = async () => {
        const type = selectedAccType;
        const baseMonth = selectedYear.toString() + selectedMonth;

        switch(type){
            case('bank'):
                setIsLoading(true);
                axios.get(process.env.REACT_APP_API_URL+`/api/accounting/get-account-log-ex/${selectedAccount}/${selectedAccName}/${baseMonth}/false/true`,{
                    headers: {              
                        "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                    },
                    responseType: "blob"
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `AccountTxns_${selectedAccName}_${selectedAccount}_${moment().format("YYYYMMDD-HHmmss")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    if(err.response?.status === 404)
                        alert('No order transactions found');  
                })
                .finally(() => {
                    setIsLoading(false);
                });   
                break;
                
            case('card'):
                setIsLoading(true);
                axios.get(process.env.REACT_APP_API_URL+`/api/accounting/get-card-log-ex/${selectedAccount}/${selectedAccName}/${baseMonth}/false/true`,{
                    headers: {              
                        "X-Server-Key": "KdrIZNijCpOBZaItgQrCpUNrwL7dRfaIGUYDNKhSxT6iDrrefO61BF6M6lfU9gwy",
                    },
                    responseType: "blob"
                })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `CreditCardTxns_${selectedAccName}_${selectedAccount}_${moment().format("YYYYMMDD-HHmmss")}.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((err) => {
                    if(err.response?.status === 404)
                        alert('No order transactions found');  
                })
                .finally(() => {
                    setIsLoading(false);
                });   

                break;
        } 
    };

    useEffect(async () => {
        getTransLog(); 
    },[refreshFlag]);

    //Initialization, get bank accounts and credit cards
    useEffect(async () => {
        const all = [];
        const accounts = await accountingApi.getBankAccounts();
        console.log('BANK ACCOUNTS', accounts);
        setBankAccounts(accounts);

        accounts.forEach(a => {
            const temp = {label: a.BankName + ' - ' + a.BankAccountNum, key: 'bank-' + a.BankAccountNum};
            all.push(temp);
        });

        const cards = await accountingApi.getCreditCards();
        console.log('CREDIT CARDS', cards);
        setCreditCards(cards);

        cards?.forEach(c => {
            const temp = {label: c.CardCompanyName + ' - ' + c.CardNum, key: 'card-' + c.CardNum};
            all.push(temp);
        });

        setTxnTypes(all);
        initYearOptions();
    },[]);
    return (
        <>
            <TitleHeader
                listOfBreadcrumb={[
                    { name: "Home", path: "/" },
                    { name: Path.ACCOUNTING.name, path: Path.ACCOUNTING.pathname },
                    {
                        name: Path.TRANSACTIONS.name,
                    },
                ]}
                title={t(Path.TRANSACTIONS.name)}
            />

            <Box>
                <Container>
                    <Spin spinning={isLoading}>                      
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={24}>
                                <Space direction="horizontal">
                                    Accounts                                
                                    <select onChange={e => chooseAcc(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                                        <option value="">[select accounts]</option>
                                        {txnTypes? (
                                            txnTypes.map(t => {
                                                return(<option value={t.key}>{t.label}</option>)
                                            })
                                        ):(<option>No accounts available</option>)}
                                    </select>
                                    
                                    Month
                                    <select onChange={e => setSelectedMonth(e.target.value)}
                                        value={selectedMonth}
                                    >
                                        <option value="01">January</option>
                                        <option value="02">February</option>
                                        <option value="03">March</option>
                                        <option value="04">April</option>
                                        <option value="05">May</option>
                                        <option value="06">June</option>
                                        <option value="07">July</option>
                                        <option value="08">August</option>
                                        <option value="09">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </select>

                                    Year        
                                    <select onChange={e => setSelectedYear(e.target.value)}
                                        value={selectedYear}
                                    >
                                        {yearOptions?.map(y => {
                                            return (<option value={y}>{y}</option>)
                                        })}
                                    </select>
                                                                      
                                    <Button type="primary" onClick={getTransLog}>Get Transactions</Button>
                                  
                                    <Button style={{backgroundColor:'#34c25a',color: 'white'}} onClick={fetchTransLog}>Fetch from Server</Button>
                                  
                                    <Button style={{backgroundColor:'#34c25a',color: 'white'}} onClick={fetchExcelData}>Get Data in Excel</Button>
                                </Space>                              
                            </Col>
                            
                            {/**
                            <Col xs={24} md={9}>
                                <Space direction="horizontal">
                                    Search &nbsp;&nbsp;
                                   
                                   
                                    <Button type="primary" onClick={searchTxn}>Go</Button>  
                                </Space>                                        
                            </Col>
                             */}
                            <Col xs={24} md={24}>
                                <Space direction="horizontal">
                                    Filter
                                    <Select
                                        style={{
                                                width: 180,
                                            }}
                                        onChange={changeSearchProp}
                                        defaultValue="category"
                                        options={[
                                            {
                                                value: "notMatched",
                                                label: "Not Matched"
                                            },
                                            {
                                                value: "category",
                                                label: "Category"
                                            },
                                            {
                                                value: "notes",
                                                label: "Notes"
                                            },
                                            {
                                                value: "refKey",
                                                label: "Reference Key"
                                            }
                                        ]}
                                    />                                    
                                    <Input type="text" placeholder="Search Keyword" value={searchKey} onChange={handleSearchChange}/> 
                                    <Button type="primary" onClick={searchTxn}>Apply Filter to the Data</Button>
                                </Space>                                
                            </Col>
                        </Row>  
                        <Row gutter={[16, 16]}>
                            
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={24}>&nbsp;</Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} md={24}>
                                <Table dataSource={currentLog} columns={currentCols} pagination={false} size="small"/>
                            </Col>                           
                        </Row>                      
                    </Spin>
                </Container>
                <AccountTxnForm 
                    openAccTxnForm={openAccTxnForm} 
                    setOpenAccTxnForm={setOpenAccTxnForm}
                    transaction={selectedTxn}
                    setRefreshFlag={setRefreshFlag}
                />
                <CardTxnForm 
                    openCardTxnForm={openCardTxnForm} 
                    setOpenCardTxnForm={setOpenCardTxnForm}
                    transaction={selectedTxn}
                    setRefreshFlag={setRefreshFlag}
                />
            </Box>           
        </>
    );
};

export default AccountsTransactions;