import { Modal, Col, Row, Descriptions ,Statistic, Table, Tag, Typography, message, Button, Collapse, Switch } from "antd";
import { WarningOutlined, WarningTwoTone } from "@ant-design/icons"; 
import groupby from "lodash.groupby";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import ordersApi from "../../apis/ordersApi";
import Path from "../../commons/breadcrumbs";
import Breadcrumb from "../../components/breadcrumb/Breadcrumb";
import Container from "../../components/container/Container";
import { Badge } from "../../components/text/badge";
import LinkButton from "../../components/linkButton/LinkButton";
import useStateCallback from "../../hooks/useStateCallback";
import useModal from "../../hooks/useModal";
import { moment, removeBlankNull, truncateText } from "../../utils/funcs";
import { OrdersPageStyled, PossibleButton, TitleBox } from "./index.styles";
import SearchPanel from "./SearchPanel";
import { ORDER_SORT, ORDER_FILTER_STATUS } from "./SearchPanel/util";
import CombineModal from "./CombineModal";
import { lowerCase } from "lodash";


const { Title, Text } = Typography;
const { Panel } = Collapse;

const nonShipmentItems = [
    "Korea Shopping Agent",
    "Courier ",
    "Signature ",
    "Domestic Shipping",
    "Bunjang",
    "Top-up",
    "Top up",
    "Topup",
    "Keyword Search",
    "Change Of",
    "Shipping top up",
    "Shipping topup",
    "Shipping top-up",
    "Service Fee",
].map((item) => item.toLowerCase());

function ListProduct() {
    const { t } = useTranslation();

    const history = useHistory();
    const pushedAction = history.action === "PUSH";

    const [isLoading, setIsLoading] = useState(false);
    const [init, setInit] = useState(false);
    const [dataList, setDataList] = useState({ orders: [], totalCount: 0 });
    const [params, setParams] = useStateCallback({
        page: 1,
        pageSize: 10,
        source: "",
        orderNumber: "",
        productTitle: "",
        productVariantTitle: "",
        fulfillmentStatus: undefined,
        sort: ORDER_SORT.OrderNumberDesc.value,
        status: undefined,
        createdAt: [],
        financialStatus: undefined,
    });
    const [selectRowKey, setSelectRowKey] = useState({});
    const [openCombineModal, onCloseCombineModal, onOpenCombineModal] = useModal();
    const [showAlert, setShowAlert] = useState(false);
    const [alerts, setAlerts] = useState([]);

    const searchOrder = async (orderNumber) => {
        const filter = [];
        filter.push(`orderNumber_iLike=%${orderNumber}%`);

        const payload = {
            page: 1,
            pageSize: 1,
            filter,
        };
        setIsLoading(true);
        try {
            const res = await ordersApi.getOrders(payload);
            if (res.orders && res.orders.length) {
                const orderId = res.orders.find((item) => item.orderNumber === orderNumber.match(/#/g) ? `${orderNumber}` : `#${orderNumber}` ).id;
                history.push({
                    pathname: Path.ORDER_DETAIL.pathParam(orderId),
                });
            } else {
                message.info("No order was found. Finding and fetching the order from Shopify...");
                //start here
                let rawOrderNo = orderNumber;
                if(orderNumber.includes('#'))
                    rawOrderNo = orderNumber.split('#')[1];

                ordersApi.refetchOrderByNo(rawOrderNo)
                .then((res) => {
                    console.log('AFTER REFETCH ' + JSON.stringify(res));
                    
                    if(res.returnMessage.errors)
                        message.error(res.returnMessage.errors);
                    else
                        message.info(res.returnMessage.message);
                    
                    if(res.returnMessage.orderId){
                        history.push({
                            pathname: Path.ORDER_DETAIL.pathParam(res.returnMessage.orderId),
                        });
                    }                    
                })
                .catch((err) => {
                    console.log('ERR REFETCH ' + JSON.stringify(err));
                    message.error(err);
                });
            }
            setIsLoading(false);
        } catch (error) {
            message.error(error.message);
            setIsLoading(false);
        }
    };

    const handleGetList = async (callback = () => null) => {
        setIsLoading(true);
        const filter = [];
        if (params.source) {
            filter.push(`source_eq=${params.source}`);
        }
        if (params.orderNumber) {
            const remWhiSpaceOrderNum = params.orderNumber.trim();
            filter.push(`orderNumber_iLike=%${remWhiSpaceOrderNum}%`);
        }
        if (params.customerName) {
            const remWhiSpaceCustomerName = params.customerName.trim();
            filter.push(`customerName_iLike=%${remWhiSpaceCustomerName}%`);
        }
        if (params.customerEmail) {
            await ordersApi.refetchOrderByEmail(params.customerEmail);
            const remWhiSpaceCustomerEmail = params.customerEmail.trim();
            filter.push(`customerEmail_iLike=%${remWhiSpaceCustomerEmail}%`);
        }
        if (params.shippingAddress) {
            const remWhiSpaceShippingAddress = params.shippingAddress.trim();
            filter.push(`shippingAddress_iLike=%${remWhiSpaceShippingAddress}%`);
        }
        if (params.productTitle) {
            const remWhiSpaceProductTitle = params.productTitle.trim();
            filter.push(`lineItems.productTitle_iLike=%${remWhiSpaceProductTitle}%`);
        }
        if (params.productVariantTitle) {
            const remWhiSpaceProductVarTitle = params.productVariantTitle.trim();
            filter.push(`lineItems.productVariantTitle_iLike=%${remWhiSpaceProductVarTitle}%`);
        }
        if (params.fulfillmentStatus === "fulfilled") {
            filter.push(`fulfillmentStatus_eq=${params.fulfillmentStatus}`);
        }
        if (params.fulfillmentStatus === "notFullfilled") {
            filter.push(`fulfillmentStatus_ne=fulfilled`);
        }
        if (params.status) {
            filter.push(`status_eq=${params.status}`);
        }
        if (params.financialStatus) {
            filter.push(`financialStatus_eq=${params.financialStatus}`);
        }
        if (params.createdAt?.length) {
            filter.push(`createdAt_gte=${params.createdAt[0].clone().startOf("date").utc().format()}`);
            filter.push(`createdAt_lte=${params.createdAt[1].clone().endOf("date").utc().format()}`);
        }
        if (params.risk){
            filter.push(`risk=${params.risk}`);
        }
        const paramsRequest = {
            page: params.page,
            pageSize: params.pageSize,
            sort: params.sort?.includes("&") ? params.sort.split("&") : params.sort,
            filter,
            includeLineItems: false,
            showCombineOrder: true,
            combinedOrders: true,
        };

        try {
            console.log('paramRequest', paramsRequest)
            const resOrder = await ordersApi.getOrders(paramsRequest);
            
            if (!Array.isArray(resOrder.orders)) {
               return;
            }
            if(resOrder.orders.length < 1) {
                if(params.orderNumber) {
                    searchOrder(params.orderNumber);
                }
            }
            /*
            let ordersGroup = [];
            let combinedOrders = [];
            let combinedOrderGroupIds = [];
            resOrder.orders.forEach((item) => {
                if (Array.isArray(item.combinedOrders) && item.combinedOrders.length > 0) {
                    if (
                        !combinedOrderGroupIds.some(
                            (combinedOrderIds) => JSON.stringify(combinedOrderIds.sort()) === JSON.stringify(item.combinedOrders.sort())
                        )
                    ) {
                        combinedOrderGroupIds.push(item.combinedOrders);
                    }
                }
            });            
            
            if (combinedOrderGroupIds.length > 0) {
                // const resCombineOrder = await ordersApi.getCombineOrders({
                //   filter: `subOrders_eq=${JSON.stringify(combinedOrderGroupIds)}`,
                // });
                // if (
                //   Array.isArray(resCombineOrder?.combinedOrders) &&
                //   resCombineOrder.combinedOrders?.length > 0
                // ) {
                //   combinedOrders = resCombineOrder.combinedOrders;
                // }
                const resCombineOrders = await Promise.all(
                    combinedOrderGroupIds.map((combinedOrderIds) =>
                        ordersApi.getCombineOrders({
                            filter: `subOrders_eq=${JSON.stringify(combinedOrderIds)}`,
                        })
                    )
                );                              
                
                resCombineOrders.forEach((resCombineOrder) => {
                    if (Array.isArray(resCombineOrder?.combinedOrders) && resCombineOrder.combinedOrders?.length > 0) {
                        combinedOrders = [...combinedOrders, ...resCombineOrder.combinedOrders];
                    }
                });                
            }
            
            const group = groupby(resOrder.orders, "combinedOrders");                     
           
            for (const key in group) {
                const element = group[key];
                if (`${key}` === "null") {
                    ordersGroup = [...ordersGroup, ...element];                   
                }
                
                else {                    
                    const combinedOrder = combinedOrders.find((item) => item.subOrders.includes(element[0].id));
                    const newElement = element.map((item) => {
                        return { ...item, sub: true };
                    });                   
                    
                    ordersGroup = [
                        ...ordersGroup,
                        {
                            ...newElement[0],
                            combinedOrderId: combinedOrder?.id,
                            status: "Combined order",
                            children: [...newElement],
                        },
                    ];                    
                }
            }            
            
            const sortedOrders = [];
            resOrder.orders?.forEach((element) => {
                if (!Array.isArray(element.combinedOrders) || element.combinedOrders.length === 0) {
                    sortedOrders.push(element);
                    return;
                }
                const itemCombine = ordersGroup.find((item) => item.id === element.id);
                if (itemCombine) {
                    sortedOrders.push(itemCombine);
                }
            });    
            */
            setDataList({
                orders: resOrder.orders,
                //orders:sortedOrders,
                totalCount: resOrder.totalCount,
            })
            // eslint-disable-next-line no-empty
        } catch (error) {
            message.error("Load order error");
            console.error(error);
        } finally {
            setIsLoading(false);
            callback();
        }
    };

    const initialFetch = () => {
        const paramsQuery = queryString.parse(history.location.search);
        const data = removeBlankNull(paramsQuery);
        if (data.page) {
            data.page = parseInt(data.page);
        }
        if (data.createdAt) {
            data.createdAt = [moment(data.createdAt[0] + "T00:00Z", "YYYY-MM-DDTHH:mmZ"), moment(data.createdAt[1] + "T00:00Z", "YYYY-MM-DDTHH:mmZ")];
        }
        setParams({ ...params, ...data }, () => {
            setInit(true);
        });
    };

    useEffect(() => {
        initialFetch();
    }, [pushedAction]);

    useEffect(() => {
        if (init) {
            const data = removeBlankNull(params);
            delete data.pageSize;
            if (data.page === 1) {
                delete data.page;
            }
            if (data.sort === ORDER_SORT.OrderDateDesc.value) {
                delete data.sort;
            }
            if (data.source === "shopify") {
                delete data.source;
            }
            if (data.createdAt?.length) {
                data.createdAt = [data.createdAt[0].utc().format("YYYY-MM-DD"), data.createdAt[1].utc().format("YYYY-MM-DD")];
            }
            // history.push({
            //   pathname: "/orders",
            //   search: `?${queryString.stringify(data)}`,
            // });
            handleGetList();
        }        
    }, [params, init]);

    const handleChangeTable = (pagination) => {
        setParams({
            ...params,
            page: pagination.current,
            pageSize: pagination.pageSize,
        });
    };

    const onSelectRowChange = (selectedRowKeys) => {
        setSelectRowKey({
            ...selectRowKey,
            [params.page]: selectedRowKeys,
        });
    };

    const setParamsSearch = (value) => {
        setParams(value);
        setSelectRowKey({});
    };

    const handleSuccessCombine = () => {
        onCloseCombineModal();
        handleGetList();
    };

    const toggleItemsList = (id) => {
        const display = document.getElementById(id).style.display;
        switch(display){
            case("none"):
                document.getElementById(id).style.display = "block";
                break;
            case("block"):
                document.getElementById(id).style.display = "none";
                break;
        }
    }

    const displayAlerts = (incAlerts) => {
        console.log("ALERTs", incAlerts);
        setAlerts(incAlerts);
        setShowAlert(true);
    };

    /*
    useEffect(() => {
        console.log("alerts changed");
        if (alerts.length > 0) {
            console.log("alerts aint zero", alerts);
            setShowAlert(true);
        } else { 
            console.log("alerts updated with zero len", alerts.length);
        }
    },[alerts]);
    */
    const columns = [
        {
            title: t("Status"),
            dataIndex: "status",
            key: "status",
            align: "left",
            width: 180,
            render: (item, record) => {
                let color = "#87d068";
                switch (item) {
                    case "confirmed":
                        color = "#28a745";
                        break;
                    case "received":
                        color = "#17a2b8";
                        break;
                    case "cancelled":
                        color = "#dc3545";
                        break;
                    case "refunded":
                        color = "#e9be67";
                        break;
                    case "partially refunded":
                        color = "#e9be67";
                        break;
                    case "validate":
                        color = "#f59642";
                        break;
                    case "payment pending":
                        color = "#f59642";
                        break;

                    default:
                        break;
                }
                return (<>
                    <Tag color={color} style={{ borderRadius: 20 }}>
                        {item}
                    </Tag>
                    {record.alerts?.length > 0? 
                        (<WarningTwoTone onClick={()=> {displayAlerts(record.alerts)}} style={{fontSize:"28px"}} twoToneColor={"#eb2f96"}/>)
                        :(<></>)
                    }    
                </>);
            },
        },
        {
            title: t("Order"),
            align: "center",
            key: "id",
            dataIndex: "id",
            width: 50,
            className: "order-id",
            render: (item, record) => {
                return record.combinedOrderId ? (
                    <Link to={`${Path.ORDER_DETAIL.pathParam(record.combinedOrderId)}?combine=true`}>#{record.combinedOrderId}</Link>
                ) : !record.sub ? (
                    <Link to={Path.ORDER_DETAIL.pathParam(item)}>
                        {record.source === "shopify" && record.orderNumber}
                        {record.source === "custom" && `${record.orderNumber}`}
                        {record.source === "whatsapp" && `WHA${record.orderNumber}`}
                        {record.source === "cop" && record.orderNumber}
                        {record.source === "ware" && record.orderNumber}
                        {record.source === "yahey" && record.orderNumber}
                        {record.source === "others" && record.orderNumber}
                    </Link>
                ) : (
                    ""
                );
            },
        },
        {
            title: t("Source"),
            dataIndex: "source",
            key: "source",
            width: 100,


        },
        {
            title: t("Date ordered"),
            dataIndex: "createdAt",
            key: "createdAt",
            width: 180,
            render: (item) => moment(item).format("DD MMM YYYY, hh:mm a"),
        },
        {
            title: t("Show Items?"),
            dataIndex: "customerEmail",
            key: "customerEmail",
            width: 100,
            render: (email, record)=>{
                return <Switch defaultChecked size="small" onChange={() => {toggleItemsList(record.id)}} />;
            }
        },
        {
            title: t("Customer"),
            dataIndex: "customerEmail",
            key: "customerEmail",
            render: (email, record) => {
                return (<>
                    <Row gutter={[8, 8]}>
                        <Col>
                            <div>{email}</div>
                            {record.trackingNumber ? (
                                <div>
                                    <Tag color="#17a2b8" style={{ borderRadius: 20 }}>
                                        {record.trackingNumber}
                                    </Tag>
                                </div>
                            ) : (
                                ""
                            )}
                        </Col>
                        {/* REMOVED THIS FOR ROLLOUT item.status === "received") */}
                        {record?.possibleCombinedOrders.filter((item) => item.status !== "").length > 1 && (
                            <Col>
                                <PossibleButton type="primary" onClick={() => onOpenCombineModal(record)}>
                                    Possible Combine
                                </PossibleButton>
                            </Col>  
                        )}
                    </Row>  
                    <Row>
                        <Col>                                                     
                            <div id={record.id} style={{display: "block", marginLeft: "10px"}}>                                
                                <br/>
                                <ul>
                                    {record.lineItems?.filter((item) => !nonShipmentItems.some((nonShipTitle) => lowerCase(item.customProductName).startsWith(nonShipTitle)))
                                    .map((r, index) => {
                                        return r.productTitle? 
                                                (<li><Text code>{index+1}. {truncateText(r.productTitle,45)}</Text></li>) 
                                                    :
                                                (<li><Text code>{index+1}. {truncateText(r.customProductName,45)}</Text></li>)                                        
                                    })}                                   
                                </ul>                                 
                            </div>                           
                        </Col>
                    </Row>                
                </>);
            },
        },
        {
            title: t("Risk"),
            dataIndex: "risk",
            key: "risk",
            width: 120,
            className: "financial-status",
            render: (item) => {
                let color = "success";
                let score = Number(item);
                let commend = "";
                switch (score) {
                    case 0:
                        color = "success";
                        commend = "low"
                        break;
                    case 0.5:
                        color = "warning";
                        commend = "medium"
                        break;
                    case 1:
                        color = "danger";
                        commend = "high"
                        break;
                    default:
                        break;
                }
                return <Badge color={color}>{commend}</Badge>;
            },
        },
        {
            title: t("Financial status"),
            dataIndex: "financialStatus",
            key: "financialStatus",
            width: 120,
            className: "financial-status",
            render: (item) => {
                let color = "success";
                switch (item) {
                    case "paid":
                        color = "success";
                        break;
                    case "pending":
                        color = "warning";
                        break;
                    case "voided":
                        color = "danger";
                        break;
                    default:
                        break;
                }
                return <Badge color={color}>{item}</Badge>;
            },
        },
        {
            title: t("Items"),
            dataIndex: "lineItems",
            key: "lineItems",
            width: 100,
            align:"center",
            render: (lineItems, record) =>{
                let quantity = 0;
                lineItems.map(l => {
                    quantity = quantity + l.quantity;
                });

                return quantity;
            }
        },
        {
            title: t("Total"),
            dataIndex: "totalPrice",
            key: "totalPrice",
            width: 120,
            render: (cell) => (cell || cell === 0 ? `$${cell}` : ""),
        },
        {
            title: t("Action"),
            key: "action",
            width: 80,
            className: "action",
            render: (_, record) => {
                let orderNo = "";
                let source = "";
                
                if (record.source === "shopify") {
                    orderNo = record.orderNumber.split('#')[1] ;
                    source = record.source;
                } else if (record.source === "custom" || record.source === "cop" || record.source === "ware") {
                    orderNo = record.orderNumber;
                    source = record.source;
                }
                //const orderNo = record.orderNumber.split('#')[1] ;
                //const source = record.source;
                const link = `/create-shipment?source=${source}&orderNumber=${orderNo}`;
                return(                    
                    <Link to={link}>
                        <Button type="primary">
                            Create Shipment
                        </Button>
                    </Link>
                );
                {/*
                            
                return (                    
                    <LinkButton
                        //disabled={record?.status !== ORDER_FILTER_STATUS[`ready to pack`].value}
                        type="primary"
                        to={{
                            pathname: "/create-shipment",
                            search: `?orderId=${record.id}`,
                        }}
                    >
                        Create Shipment
                    </LinkButton>
                );
                */}
            },
        },
    ];

    return (
        <>
            <TitleBox className="title-page">
                <Title>{t("Orders")}</Title>
                <Breadcrumb items={[{ name: "Home", path: "/" }, { name: Path.ORDERS.name }]} />
            </TitleBox>
            <OrdersPageStyled title={t("Orders")}>
                <Container>
                    {/* TEMPORARILY REMOVED */}
                    {/* <Row className="statistic" gutter={24}>
                        <Col xs={12} sm={12} md={6}>
                            <Card bordered={false}>
                                <Statistic title="Today's Orders" value={56} />
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <Card bordered={false}>
                                <Statistic title="Unfulfilled Orders" value={138123} />
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <Card bordered={false}>
                                <Statistic title="Fulfilled Today" value={40} />
                            </Card>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <Card bordered={false}>
                                <Statistic title="High-Risk Orders" value={4} />
                            </Card>
                        </Col>
                    </Row> */}
                    <SearchPanel params={params} setParams={setParamsSearch} handleGetList={handleGetList} selectRowKey={selectRowKey} />
                    <div className="table-box">
                        <Row justify="space-between">
                            <Col xs="auto">
                                <div className="last-updated">List last updated at : {moment().format("MMM DD YYYY, hh:mm:SS A Z")}</div>
                            </Col>
                            <Col xs="auto">
                                {`Showing ${(params.page - 1) * params.pageSize + 1} to ${(params.page - 1) * params.pageSize + dataList.orders.length} of ${
                                    dataList.totalCount
                                } entries`}
                            </Col>
                        </Row>
                        <Table
                            size="small"
                            bordered
                            className="table-custom"
                            scroll={{
                                x: "max-content",
                            }}
                            dataSource={dataList.orders}
                            columns={columns}
                            rowKey="id"
                            loading={isLoading}
                            pagination={{
                                current: params.page,
                                total: dataList.totalCount || 0,
                                defaultPageSize: params.pageSize,
                                showSizeChanger: true,
                            }}
                            onChange={handleChangeTable}
                            // rowSelection={{
                            //   selectedRowKeys: selectRowKey[params.page],
                            //   onChange: onSelectRowChange,
                            // }}
                        />
                    </div>
                </Container>
                <CombineModal visible={openCombineModal} onCancel={onCloseCombineModal} onSuccess={handleSuccessCombine} parentPage="orders"/>
            </OrdersPageStyled>
            <Modal
                visible={showAlert}     
                width={900}            
                footer={[
                    <Button type="primary" onClick={() =>{setShowAlert(false);}}>Close</Button>
                ]}  
            >
                <br/>
                <Row>
                    <Col md={24}>                       
                        <Descriptions  title="Order Alerts" bordered>
                            {alerts.length > 0? 
                                (alerts.map(a => {
                                    return (<Descriptions.Item label={a.alert} span={3}>
                                        {a.remark}
                                    </Descriptions.Item>)
                                })):(<p>No alerts found</p>)
                            }
                        </Descriptions>
                    </Col>
                </Row>
            </Modal>
        </>
    );
}

export default ListProduct;
