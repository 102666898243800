import { DownOutlined, LogoutOutlined } from "@ant-design/icons";
import { Avatar, Drawer, Dropdown, Form, Input, Menu, message, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import accountApis from "../../apis/accountApi";
import { BREAKPOINT } from "../../commons/styles";
import DialogConfirmLogout from "../dialog-confirm-logout/DialogConfirmLogout";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { logout } from "../../store/slices/accountSlice";
import errorHelper from "../../utils/errorHelper";
import LocalStorage, { SessionStorage } from "../../utils/storage";
import { toggedSideBar } from "../../store/slices/commonSlice";
import SideBar from "../sidebar/SideBar";
import { BoxHeader } from "./Header.styles";
import ordersApi from "apis/ordersApi";
import Path from "../../commons/breadcrumbs";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function Header() {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const history = useHistory();
    const { sideBarMobile } = useSelector((state) => state.common);
    const [isLogout, setIsLogout] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [fullName, setFullName] = useState('superadmin');
    const [userId, setUserId] = useState('');
    const [searchKey, setSearchKey] = useState();

    const onToggleMenu = () => {
        dispatch(toggedSideBar());
    };

    const onLogout = () => {
        accountApis
            .logout()
            .then(() => {
                dispatch(logout());
                SessionStorage.remove("token");
                SessionStorage.clear();
                
                if(LocalStorage.has("is_okta_login")){                   
                    accountApis.oktaLogout(LocalStorage.has("access_token"));                    
                }
                LocalStorage.clear();                
                history.push("/login");
            })
            .catch((err) => {
                errorHelper(err);
            });
    };

    const menu = (
        <Menu>
            <Menu.Item>Profile</Menu.Item>
            <Menu.Item><Link to={`/settings/users-and-permissions/reset-password/${userId}`}>Change Password</Link></Menu.Item>
            <Menu.Item>
                Logout <LogoutOutlined onClick={() => setIsLogout(true)} className="icon__logout" />
            </Menu.Item>
        </Menu>
    );

    const searchOrder = async (orderNumber) => {
        const filter = [];
        filter.push(`orderNumber_iLike=%${orderNumber}%`);

        const payload = {
            page: 1,
            pageSize: 1,
            filter,
        };
        setIsLoading(true);
        try {
            const res = await ordersApi.getOrders(payload);
            if (res.orders && res.orders.length) {
                const orderId = res.orders.find((item) => item.orderNumber === orderNumber.match(/#/g) ? `${orderNumber}` : `#${orderNumber}` ).id;
                history.push({
                    pathname: Path.ORDER_DETAIL.pathParam(orderId),
                });
            } else {
                message.info("No order was found. Finding and fetching the order from Shopify...");
                //start here
                let rawOrderNo = orderNumber;
                if(orderNumber.includes('#'))
                    rawOrderNo = orderNumber.split('#')[1];

                ordersApi.refetchOrderByNo(rawOrderNo)
                .then((res) => {
                    console.log('AFTER REFETCH ' + JSON.stringify(res));
                    
                    if(res.returnMessage.errors)
                        message.error(res.returnMessage.errors);
                    else
                        message.info(res.returnMessage.message);
                    
                    if(res.returnMessage.orderId){
                        history.push({
                            pathname: Path.ORDER_DETAIL.pathParam(res.returnMessage.orderId),
                        });
                    }                    
                })
                .catch((err) => {
                    console.log('ERR REFETCH ' + JSON.stringify(err));
                    message.error(err);
                });
            }
            setIsLoading(false);
        } catch (error) {
            message.error(error.message);
            setIsLoading(false);
        }
    };

    const onSearch = (orderNumber, event) => {
        if (orderNumber) {
            searchOrder(orderNumber);
        }
   
        setSearchKey('');
    };

    const handleChange = (event) => {
        setSearchKey(event.target.value);
    };

    useEffect(()=>{
        if(LocalStorage.has("is_okta_login")){
            setFullName(LocalStorage.get("first_name",'') + LocalStorage.get(" last_name",''));
            setUserId(LocalStorage.get("userid",''));
        }
           
    },[])

    return (
        <BoxHeader>
            {/* <Form>
        <Form.Item
          style={{ width: "300px", margin: "0 20px", borderRadius: "10px" }}
          name='searchOrderNumber'
          rules={[
            {

            }
          ]}
        >
          <Input.Search placeholder="Search Order Number" />
        </Form.Item>
      </Form> */}

            <Input.Search
                placeholder="Search Order Number"
                style={{
                    width: "300px",
                    margin: "0 20px",
                    borderRadius: "10px",
                }}
                name="keyword"
                onSearch={onSearch}
                loading={isLoading}                
                allowClear
                value={searchKey}
                onChange={(e) => handleChange(e)}
            />
            <div />
            <Dropdown overlay={menu} className="box__right-header">
                <Space>
                    <Avatar style={{ verticalAlign: "middle" }} size="large">
                        {fullName.charAt(0)}
                    </Avatar>
                    <div>{fullName}</div>
                    <DownOutlined />
                </Space>
            </Dropdown>

            {width <= BREAKPOINT.XL && (
                <Drawer placement="left" closable={false} onClose={onToggleMenu} visible={!sideBarMobile} width={250}>
                    <SideBar />
                </Drawer>
            )}
            <DialogConfirmLogout
                onSubmit={onLogout}
                open={isLogout}
                onCancel={() => {
                    setIsLogout(false);
                }}
            />
        </BoxHeader>
    );
}

export default Header;
