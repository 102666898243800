import { push } from "connected-react-router";
import { forEach, isEmpty } from "lodash";
import Breadcrumbs from "../commons/breadcrumbs";
import { STATUS_REQUEST } from "../commons/constants";
import Message from "../components/message";
import i18n from "../locales/i18n";
import store, { history } from "../store";
import { logout } from "../store/slices/accountSlice";
import { SessionStorage } from "./storage";

function errorHelper(err) {
    const errors = err?.response?.data?.errors || err?.errors;
    if (
        Array.isArray(errors) &&
        errors.some((item) => item.code === STATUS_REQUEST.UNAUTHORIZED)
    ) {
        const urlDirect = history.location.pathname;
        store.dispatch(logout());
        SessionStorage.remove("token");
        store.dispatch(push(`${Breadcrumbs.LOGIN}?redirect=${urlDirect}`));
    }
    if (isEmpty(errors)) {
        return Message.error(err?.message || i18n.t("error.system_error"));
    }
    forEach(errors, (item) => {
        if (item.code === 500) {
            return Message.error(i18n.t("error.system_error"));
        }

        if (item.message) {
            return Message.error(i18n.t(item.message));
        }
    });
}

export default errorHelper;
