import { Avatar, Button, Row, Col, List, Typography, message, Spin } from "antd";
import accountApi from "apis/accountApi";
import Box from "components/box/Box";
import Container from "components/container/Container";
import TitleHeader from "components/title-header";
import React, { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import LocalStorage, { SessionStorage} from "utils/storage";
import Path from "../../commons/breadcrumbs";
import { HeaderList, OpControls } from "./index.style";

const { Title, Paragraph } = Typography;

const UsersAndPermissions = () => {
    const history = useHistory();
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);
    const [admins, setAdmins] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const currentUser = useSelector((state) => state.account.profile);
    console.log(currentUser);

    const testClick = () => {
        const access_token = SessionStorage.get("access_token");
        console.log('ACCESS TOKEN ' + access_token);
    }

    const suspendUser = async (userId, email) => {
        const answer = window.confirm("Are you sure to suspend " + email + "?");

        if(!answer)
            return;

        accountApi.suspendUser(userId)
        .then((r) => {
            
            if(r.response?.data?.errorSummary){
                console.log('R', JSON.stringify(r.response.data));
                if(r.response.data.errorSummary === "Invalid session"){
                    message.error(r.response.data.errorSummary);
                    return;
                }
            }

            console.log('SUSPENDING USER', r);
            message.success("User " + email + "  suspended");
        })
        .finally(() => {
            loadData();
        })
        .catch((err) => {
            message.error(err.toString());
        })        
    };

    const unsuspendUser = async (userId, email) => {
        const answer = window.confirm("Are you sure to unsuspend " + email + "?");

        if(!answer)
            return;

        accountApi.unsuspendUser(userId)
        .then((r) => {
            
            if(r.response?.data?.errorSummary){
                console.log('R', JSON.stringify(r.response.data));
                if(r.response.data.errorSummary === "Invalid session"){
                    message.error(r.response.data.errorSummary);
                    return;
                }
            }

            console.log('UNSUSPENDING USER', r);
            message.success("User " + email + " unsuspended");
        })
        .finally(() => {
            loadData();
        })
        .catch((err) => {
            message.error(err.toString());
        })
    };

    const removeUser = async (userId, email) => {
        const answer = window.confirm("Are you sure to remove " + email + "?");

        if(!answer)
            return;

        accountApi.removeUser(userId)
        .then((r) => {
            
            if(r.response?.data?.errorSummary){
                console.log('R', JSON.stringify(r.response.data));
                if(r.response.data.errorSummary === "Invalid session"){
                    message.error(r.response.data.errorSummary);
                    return;
                }
            }

            console.log('DELETING USER', r);
            message.success("User " + email + " removed");
        })
        .finally(() => {
            loadData();
        })
        .catch((err) => {
            message.error(err.toString());
        })
    };

    const unlockUser = async (userId, email) => {
        const answer = window.confirm("Are you sure to unlock " + email + "?");

        if(!answer)
            return;

        accountApi.unlockUser(userId)
        .then((r) => {
            
            if(r.response?.data?.errorSummary){
                console.log('R', JSON.stringify(r.response.data));
                if(r.response.data.errorSummary === "Invalid session"){
                    message.error(r.response.data.errorSummary);
                    return;
                }
            }

            console.log('UNLOCKING USER', r);
            message.success("User " + email + " unlocked");
        })
        .finally(() => {
            loadData();
        })
        .catch((err) => {
            message.error(err.toString());
        })
    };

    const changePermissions = async (userId, email) => {
        history.push("/settings/users-and-permissions/change-permissions/" + userId);
    };

    const loadUsers = async () => {
        const access_token = SessionStorage.get("access_token");     
        accountApi.getUsers({
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        })
        .then((r) => {
            setUsers(r);
            console.log('USERS',r);
        })
        .catch((e) => {
            console.log('Error in fetching users');
            console.log(e);
        });
    };

    const loadAdmin = async () => {
        const access_token = SessionStorage.get("access_token");   
        accountApi.getAdmins({
            headers: {
                Authorization: `Bearer ${access_token}`,
            },
        })
        .then((r) => {            
            setAdmins(r);
            console.log('ADMINDS',r);
        })
        .catch((e) => {
            console.log('Error in fetching admins');
            console.log(e);
        });           
    }

    const loadData = async () => {
        setIsLoading(true);
        loadUsers();
        loadAdmin();
        setIsLoading(false);
    };
    // first load
    useEffect(() => {
       loadData();     
    }, []);
    return (
        <>
            <TitleHeader
                listOfBreadcrumb={[
                    { name: "Home", path: "/" },
                    { name: Path.SETTINGS.name, path: Path.SETTINGS.pathname },
                    {
                        name: Path.USERS_AND_PERMISIONS.name,
                    },
                ]}
                title={t(Path.USERS_AND_PERMISIONS.name)}
            />
            <Box>
                <Container>
                    <Spin spinning={isLoading}>
                        <Title level={4}>{t("Admins")}</Title>
                        <Paragraph>{t("Ware system admins")}</Paragraph>
                        <List
                            itemLayout="horizontal"
                            dataSource={admins}
                            renderItem={(item) => (                            
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                        title={item.profile.email}
                                        description={`${item.profile.firstName} ${item.profile.lastName}, status: ${item.status}`}
                                    />
                                </List.Item>
                            )}
                        />
                
                        <Title level={4}>{t("Ware Users")}</Title>
                        <Paragraph>{t("Normal users")}</Paragraph>

                        <List
                            header={
                                <HeaderList>
                                    <h1>Staff ({users.length})</h1> <Link to="/settings/users-and-permissions/add-staff">Add Staff</Link>
                                </HeaderList>
                            }
                            itemLayout="horizontal"
                            dataSource={users}
                            renderItem={(item) => (                            
                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                                        title={item.profile.email}
                                        description={`${item.profile.firstName} ${item.profile.lastName}, status: ${item.status}`}
                                    />
                                    <div>
                                        
                                        <Button type="primary" shape="round" size="small" onClick={()=> changePermissions(item.id, item.profile.email)}>Change Permissions</Button>
                                        &nbsp;
                                        
                                        {item.status === "LOCKED_OUT"? (<>
                                            <Button type="primary" shape="round" size="small" onClick={()=> unlockUser(item.id, item.profile.email)}>Unlock</Button>
                                            &nbsp;
                                        </>):(<></>)}    

                                        {item.status === "SUSPENDED"? (<>
                                            <Button type="primary" shape="round" size="small" onClick={()=> unsuspendUser(item.id, item.profile.email)}>Unsuspend</Button>
                                            &nbsp;
                                        </>):(<></>)}                                
                                    
                                        {item.status !== "SUSPENDED"? (<>
                                            <Button type="primary" shape="round" size="small" onClick={()=> suspendUser(item.id, item.profile.email)}>Suspend</Button>
                                            &nbsp;
                                        </>):(<></>)}
                                    
                                        <Button type="danger" shape="round" size="small" onClick={() => removeUser(item.id, item.profile.email)}>Remove</Button>
                                    </div>
                                </List.Item>
                            )}
                        />                    
                    </Spin>
                     
                    {/* <List
                        header={
                            <HeaderList>
                                <h1>Store Owner ({storeOwner?.length})</h1> <Link to="/settings/users-and-permissions/add-staff">Add Staff</Link>
                            </HeaderList>
                        }
                        dataSource={storeOwner}
                        bordered
                        style={{ marginBottom: "24px" }}
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    style={{ alignItems: "center" }}
                                    avatar={
                                        <Avatar
                                            style={{
                                                border: "1px solid black",
                                            }}
                                            src={`https://ui-avatars.com/api/?name=${item.firstName}+${item.lastName ? item.lastName : ""}`}
                                        />
                                    }
                                    title={<Link to={`/settings/users-and-permissions/edit-staff/${item.id}`}>{item.firstName + " " + item.lastName}</Link>}
                                    description={`Last login was ${item.lastLogin}`}
                                />
                            </List.Item>
                        )}
                    />
                    <List
                        header={
                            <HeaderList>
                                <h1>Staff ({users.length})</h1> <Link to="/settings/users-and-permissions/add-staff">Add Staff</Link>
                            </HeaderList>
                        }
                        dataSource={users}
                        bordered
                        renderItem={(item) => (
                            <List.Item>
                                <List.Item.Meta
                                    style={{ alignItems: "center" }}
                                    avatar={
                                        <Avatar
                                            style={{
                                                border: "1px solid black",
                                            }}
                                            src={`https://ui-avatars.com/api/?name=${item.firstName}+${item.lastName ? item.lastName : ""}`}
                                        />
                                    }
                                    title={
                                        <Link
                                            to={{
                                                pathname: `/settings/users-and-permissions/edit-staff/${item.id}`,
                                                state: {
                                                    id: 1,
                                                    firstName: item.firstName,
                                                    lastName: item.lastName,
                                                    email: item.email,
                                                    isOwner: item.isOwner,
                                                },
                                            }}
                                        >
                                            {item.firstName + " " + item.lastName}
                                        </Link>
                                    }
                                    description={`Last login was ${item.lastLogin}`}
                                />
                                {item.permissionsPage.length > 12 ? <div>Store Owner</div> : <div>Limited Permission</div>}
                            </List.Item>
                        )}
                    /> */}
                </Container>
                {/**
                <OpControls>
                    <Col md={16}></Col>
                    <Col md={8}>
                        <Button type="primary" className="op" onClick={testClick}>Test Token</Button>
                    </Col>
                </OpControls>
                 */}
            </Box>            
        </>
    );
};

export default UsersAndPermissions;
